function PackagePage() {
    return (
        <div className="packagepage-container">
            <div className="package-layout">
                <form>
                    <input value="input" placeholder="Count" />
                    <input value="input" placeholder="Period" />
                    <p>Discount?</p><input type="radio" />
                    <p>Personal Trainer?</p> <input type="radio" />
                    <button>Add</button>

                </form>

            </div>
        </div>
    )
}

export default PackagePage;