import { TextField, Button, Container } from '@mui/material';
import { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Height } from '@mui/icons-material';

function EditClinicPackage() {
	const API = process.env.REACT_APP_API

	const validationSchema = Yup.object({
		packageName: Yup.string()
			.required('Package Name is required'),
		packagePrice: Yup.number()
			.required('Package Price is required')
			.positive('Package Price must be a positive number')
			.min(1, 'Package Price must be at least 1'),
	});
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [open, setOpen] = useState(false);
	const [packID, setPackID] = useState()
	const [reRender, setReRender] = useState(false)
	const [packName, setPackName] = useState()
	const [packPrice, setPackPrice] = useState()
	



	// // {name:"one", price:"100", count:"2" , total :"200"}
	// const handleCountChange = (event) => {
	//   setAge(event.target.value);
	// };

	
	

	const [openModal, setOpenModal] = useState(false);
	function handleOpenModal(row) {
		setPackName(row.name)
		setPackPrice(row.price)
		setPackID(row.packID)
		setOpenModal(true)

	}
	const handleCloseModal = () => setOpenModal(false);

	const style = {
		position: 'absolute',
		borderRadius: "15px",

		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '400px', // Adjust the width if needed
		height: '450px', // Set height to 400px
		bgcolor: 'background.paper',
		border: '2px solid #e4e4e4',
		boxShadow: 24,
		p: 4,
		overflowY: 'auto', // To enable scrolling if content overflows
	};

	function handleClickOpen(id) {
		setPackID(id)
		console.log(id)
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};



	function handleDeletePackage (e) {
		e.preventDefault()
		fetch(`${API}/DeleteClinicPack`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},

			body: JSON.stringify({
				"id": packID

			})

		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

					handleClose()
					setReRender(!reRender)
				} else {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
				}
			})

	}



	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		fontFamily: 'Poppins, sans-serif',
		fontWeight: 'bold',
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({

	}));




	useEffect(function () {
		async function fetchData() {
			try {
				const res = await fetch(`${API}/getClinicPack`)
				const data = await res.json()
				console.log(data)
				setData(data.data)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchData()
	}, [reRender])
	return (
		<Container>

			<Modal
				open={openModal}
				onClose={handleCloseModal}
				closeAfterTransition
			>
				<Fade in={openModal}>
					<Box sx={style}>
						<h5 style={{ width: "fit-content", margin: "auto" }}>{packName}</h5>
						<Formik
							initialValues={{ packageName: packName, packagePrice: packPrice }}
							validationSchema={validationSchema}
							onSubmit={(values) => {

								fetch(`${API}/UpdateClinicPack`, {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},

									body: JSON.stringify({
										"id": packID,
										"packageName": values.packageName,
										"packagePrice": values.packagePrice

									})

								}).then(response => response.json())
									.then(result => {
										if (result.status == 200) {
											toast.success(`${result.Message}`, {
												position: "top-center",
												autoClose: 5000,
												hideProgressBar: false,
												closeOnClick: true,
												pauseOnHover: true,
												draggable: true,
												progress: undefined,
												theme: "colored",
											});

											handleClose()
											setReRender(!reRender)
										} else {
											toast.error(`${result.Message}`, {
												position: "top-center",
												autoClose: 5000,
												hideProgressBar: false,
												closeOnClick: true,
												pauseOnHover: true,
												draggable: true,
												progress: undefined,
												theme: "colored",
											});
										}
									})




								handleCloseModal(); // Close modal after submission
							}}
						>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
								<form style={{ padding: "6px", height: "60%", padding: "10px", backgroundColor: "white", width: "80%", margin: "65px auto" }} onSubmit={handleSubmit}>
									<TextField
										sx={{
											marginBottom: "15px",
											'& .MuiOutlinedInput-root': {
												'& fieldset': {
													borderWidth: 2,
												},
												'&:hover fieldset': {
													borderColor: '#1976d2',
												},
												'&.Mui-focused fieldset': {
													borderColor: 'black',
												},
												'& input': {
													color: 'black', // Input text color
													'&::placeholder': {
														color: 'rgba(0, 0, 0, 0.5)', // Placeholder color
													},
												},
											},
											'& .MuiInputLabel-root': {
												color: 'gray', // Default label color
												'&.Mui-focused': {
													color: 'black', // Change to black on focus
												},
											},
										}}
										fullWidth
										label="Package Name"
										name="packageName"
										value={values.packageName}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.packageName && Boolean(errors.packageName)}
										helperText={touched.packageName && errors.packageName}
										defaultValue={packName}
									/>
									<TextField
										sx={{
											'& .MuiOutlinedInput-root': {
												'& fieldset': {
													borderWidth: 2,
												},
												'&:hover fieldset': {
													borderColor: '#1976d2',
												},
												'&.Mui-focused fieldset': {
													borderColor: 'black',
												},
												'& input': {
													color: 'black', // Input text color
													'&::placeholder': {
														color: 'rgba(0, 0, 0, 0.5)', // Placeholder color
													},
												},
											},
											'& .MuiInputLabel-root': {
												color: 'gray', // Default label color
												'&.Mui-focused': {
													color: 'black', // Change to black on focus
												},
											},
										}}
										fullWidth
										label="Package Price"
										name="packagePrice"
										type="text"
										value={values.packagePrice}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.packagePrice && Boolean(errors.packagePrice)}
										helperText={touched.packagePrice && errors.packagePrice}
									/>
									<Button sx={{
										height: "45px",
										width: "250px",
										borderRadius: "15px",
										":hover": {
											backgroundColor: "black"
										},



									}} type="submit">Submit</Button>
								</form>
							)}
						</Formik>
					</Box>
				</Fade>
			</Modal>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>

				<DialogContent>
					<DialogContentText sx={{ fontWeight: "bold", fontFamily: "poppins" }} id="alert-dialog-description">
						Are You Sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleDeletePackage} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<TableContainer sx={{ width: "70%", margin: "100px auto" }} component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow sx={{ backgroundColor: '#e4e4e4' }}>
							<StyledTableCell align="center">Name</StyledTableCell>
							<StyledTableCell align="center">Price</StyledTableCell>
							<StyledTableCell align="center"></StyledTableCell>
							<StyledTableCell align="center"></StyledTableCell>

						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<StyledTableRow key={row.name}>

								<StyledTableCell align="center">{row.name}</StyledTableCell>
								<StyledTableCell align="center">{row.price}</StyledTableCell>
								<StyledTableCell align="center">
									<Button
										variant="contained"
										sx={{ backgroundColor: '#23395d', borderRadius: "7px", color: 'white' }}
										startIcon={<EditIcon sx={{ color: 'white' }} />}
										onClick={() => handleOpenModal(row)}>
										Edit
									</Button>

								</StyledTableCell >


								<StyledTableCell align="center">
									<Button
										variant="contained"
										sx={{ backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
										startIcon={<DeleteIcon sx={{ color: 'white' }} />}
										onClick={() => { handleClickOpen(row.packID) }}>
										Delete
									</Button>
								</StyledTableCell>



							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>



			</Container>
	)
}

export default EditClinicPackage