import { useState } from "react"
import Navbar from "../components/navbar"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API = process.env.REACT_APP_API



function AddBranch(e) {
	const [branchName, setBranch] = useState()
	function hndleAdding(e) {
		e.preventDefault()

		fetch(`${API}/branches`, {
			method: 'POST',
			headers: {
			   'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			   'branchName': branchName,
			
			})
		}).then(response => response.json())
		.then(result=>{
			if (result.status == 200) {
				toast.success(`${result.Message}`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					});
			}
		})
	}
	return(
		<div>
			<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
			<Navbar/>
			<form   style={{margin:"200px auto" ,height:"200px",padding:"10px", border:"1px solid #e4e4e4"}}>
<input onChange={(e)=> setBranch(e.target.value)} type="text" placeholder="Branch name" />
<button onClick={(e)=> hndleAdding(e)}>Add</button>
			</form>
		</div>
	)
}

export default AddBranch