import * as React from 'react';
import { useEffect, useState } from 'react';
import "../../styles/classStyles/class.css"
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import { TimePicker } from "antd";
import dayjs from 'dayjs';

import { PiNumberSquareOneBold } from "react-icons/pi";
import { PiNumberSquareTwo } from "react-icons/pi";




function AddClassPage() {
    const API = process.env.REACT_APP_API

    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedCityDays, setSelectedCityDays] = useState([]);

    const [selectedHours, setSelectedHours] = useState([]);
    const [classname, setClassName] = useState()
    const [classfamily, setClassfamily] = useState()
    const [classesFamily, setClassesFmily] = useState([])
    const [loading, setLoading] = useState()
    const [trainer, setTrainer] = useState()
    const [allClasses, setAllClasses] = useState()
    const [branches, setAllBranches] = useState()


    const [trainerName, settrainerName] = useState()
    const [value, setValue] = useState([]);
    const [cityValue, setCityValue] = useState([]);
    const [expanded, setExpanded] = React.useState(false);
    const [trainersLoading, setTrainersLoading] = useState()
    const [classesLoading, setClassesLoading] = useState()
    const [rerender, setRender] = useState(false)
    const [selectedClassID, setSelectedClassID] = useState()
    const [selectedBranch, setSelectedBrach] = useState()
    const [branchesLoading, setBranchesLoading] = useState()




    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };


    // const [cityValue, setCityValue] = useState(null);
    const format = 'HH:mm';

    const EnergyHours = []
    const x = "render"


    const onEnergyChange = (time, timeString) => {
        setValue([...value, timeString])

        console.log(value)
    };
    const onCityChange = (time, timeString) => {
        setCityValue([...cityValue, timeString])
        console.log(cityValue)

    };



    const DaysoptionList = [
        { value: 'Saturday', label: "Saturday" },
        { value: 'Sunday', label: "Sunday" },
        { value: 'Monday', label: "Monday" },
        { value: 'Tuesday', label: "Tuesday" },
        { value: 'Wednesday', label: "Wednesday" },
        { value: 'Thursday', label: "Thursday" },
        { value: 'Friday', label: "Friday" }
    ]


    const HoursoptionList = [
        { value: '1PM', label: "1PM" },
        { value: '2PM', label: "2PM" },
        { value: '3PM', label: "3PM" },
        { value: '4PM', label: "4PM" },
        { value: '5PM', label: "5PM" },
        { value: '6PM', label: "6PM" },
        { value: '7PM', label: "7PM" },
        { value: '8PM', label: "8PM" },
        { value: '9PM', label: "9PM" },
        { value: '10PM', label: "10PM" },
        { value: '11PM', label: "11PM" },
        { value: '12PM', label: "12PM" },


    ]
    function handleSelectedDays(day) {
        setSelectedDays(day);
    }

    function handleCitySelectedDays(day) {
        setSelectedCityDays(day);
    }


    function handleSelectHours(hour) {
        setSelectedHours(hour);
    }


    const customStyles = {
        control: base => ({
            ...base,
            height: 35,
            minHeight: 35
        })
    };

    useEffect(function () {
        async function fetchClassFamily() {
            setLoading(true)
            const res = await fetch(`${API}/getAllClassFamilies`)
            const data = await res.json()
            setClassesFmily(data.data)
            setLoading(false)
        }
        fetchClassFamily()
    }, [x])

    function handleAddClass(e) {
        e.preventDefault()
        fetch(`${API}/addClass`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'description': classname,
                // 'days': selectedDays,
                // 'hours': value,
                // "cityDay": selectedCityDays,
                // "cityHours": cityValue,
                "family": classfamily,
                "trainerName": trainerName
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    setRender(!rerender)
                } else {
                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }


    function handleAddClassTimes(e) {
        e.preventDefault()
        fetch(`${API}/addClassTimes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // 'description': classname,

                'Days': selectedDays,
                'Hours': value,
                "branch": selectedBranch,
                "classID": selectedClassID,          
                  "trainerName": trainerName

                // "trainerName":trainerName
                // "family": classfamily,
                // "trainerName": trainerName
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    setRender(!rerender)
                } else {
                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }
            })
    }

    useEffect(function () {
        async function getTrainers() {
            try {
                setTrainersLoading(true)
                const res = await fetch(`${API}/getAllPTrainers`)
                const data = await res.json()
                setTrainer(data.result)

            } catch (e) {
                console.log(e)
            } finally {
                setTrainersLoading(false)
            }
        } getTrainers()

    }, [x])

    useEffect(function () {
        async function getBranches() {
            try {
                setBranchesLoading(true)
                const res = await fetch(`${API}/branches`)
                const data = await res.json()
                setAllBranches(data.branches)
                console.log(data)

            } catch (e) {
                console.log(e)
            } finally {
                setBranchesLoading(false)
            }
        } getBranches()

    }, [x])
    useEffect(function () {
        async function getClasses() {
            try {
                setClassesLoading(true)
                const res = await fetch(`${API}/classesData`)
                const data = await res.json()
                setAllClasses(data.classes)

            } catch (e) {
                console.log(e)
            } finally {
                setClassesLoading(false)
            }
        } getClasses()

    }, [rerender])
    return (

        <div className="add-class-page">

            <ToastContainer
                position="top-center"
                autoClose={false}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />


            {loading || trainersLoading || classesLoading || branchesLoading ? (<div style={{ justifyContent: "center", display: "flex" }}> <CircularProgress sx={{ margin: "200px auto" }} /></div>) : (
                <>
                    <form style={{ height: "auto", backgroundColor: "white", padding: "20px", marginBottom: "10px" }} onSubmit={(e) => handleAddClass(e)}>
                        <div style={{ display: "flex", margin: "0 auto", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>            <PiNumberSquareOneBold size={30} />  <h2 style={{ marginLeft: "5px", marginBottom: "0" }}>Add class</h2></div>
                        <input onChange={(e) => setClassName(e.target.value)} className='add-class-page-input' type="text" placeholder="Name" /> <br />
                        <select style={{ border: "1px solid #e4e4e4", height: "40px", backgroundColor: "whitesmoke", width: "200px", alignItems: "center", textAlign: "center" }} onChange={(e) => setClassfamily(e.target.value)} className="class-select" name="" id="">
                            <option value="" disabled selected>Class Family</option>



                            {classesFamily?.map(m => {

                                return <option value={m.Description}>{m.Description}</option>


                            })}
                        </select> <br />







                        <button style={{ margin: "20px auto", marginBottom: "0", width: "200px", borderRadius: "20px" }}>Add</button>

                    </form>


                    <form onSubmit={(e) => handleAddClassTimes(e)} style={{ height: "auto", backgroundColor: "white", padding: "20px" }}>



                        <div style={{ display: "flex", margin: "0 auto", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>            <PiNumberSquareTwo size={30} />  <h4 style={{ marginLeft: "5px", marginBottom: "0" }}>Times</h4></div>

                        <div style={{ width: "70%", margin: "0 auto", borderRadius: "10px", padding: "10px" }} className="days-hours-drop-container">
                            <select onChange={(e) => setSelectedClassID(e.target.value)} style={{ width: "200px", marginBottom: "10px", alignItems: "center", textAlign: "center", backgroundColor: "whitesmoke", width: "200px", border: "1px solid #e4e4e4", height: "40px" }}>
                                <option disabled selected>Select Class</option>
                                {allClasses?.map(c => {
                                    return (
                                        <option value={c.id}>{c.Description}</option>
                                    )
                                })}
                            </select>

                            <select onChange={(e) => settrainerName(e.target.value)} style={{ width: "200px", marginBottom: "10px", alignItems: "center", textAlign: "center", backgroundColor: "whitesmoke", width: "200px", border: "1px solid #e4e4e4", height: "40px" }} name="" id="">

                                <option value="" disabled selected>Select Trainer</option>
                                {trainer?.map(t => {
                                    return (
                                        <option value={t.fullName}>{t.fullName}</option>
                                    )
                                })}

                            </select>
                            <select onChange={(e) => setSelectedBrach(e.target.value)} style={{ width: "200px", marginBottom: "10px", alignItems: "center", textAlign: "center", backgroundColor: "whitesmoke", width: "200px", border: "1px solid #e4e4e4", height: "40px" }}>
                                <option disabled selected>Select Branch</option>
                                {branches?.map(b => {
                                    return (
                                        <option value={b.name}>{b.name}</option>
                                    )
                                })}
                            </select>

                            <Select
                                NamePrefix="mySelect"
                                options={DaysoptionList}
                                placeholder="Select days"
                                value={selectedDays}
                                onChange={handleSelectedDays}
                                required

                                isSearchable={true}
                                isMulti
                            />

                            <TimePicker onChange={onEnergyChange} required style={{ marginTop: "10px", height: "40px", width: "250px" }} defaultValue={dayjs('12:08', format)} format={format} />
                            <button style={{ margin: "20px auto", width: "200px", borderRadius: "20px" }}>Confirm</button>

                        </div>




                    </form>

                </>)}

        </div >
    )
}

export default AddClassPage