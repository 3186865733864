
import { TextField, Button, Container } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/joy/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import CalculateIcon from '@mui/icons-material/Calculate';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


import Avatar from '@mui/material/Avatar';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogContentText from '@mui/material/DialogContentText';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import moment from 'moment-timezone';

import {
	FormControl,
	Select,
	Checkbox,
} from '@mui/material';
import { CircularProgress } from '@mui/material';


function ClinicData({ }) {
	const [data, setData] = useState([])
	const [buttonLoading, setButtonLoading] = useState(false)
	const [loading, setLoading] = useState(true)
	const [checkedItems, setCheckedItems] = useState([]);
	const [ages, setAges] = useState({}); // To track selected ages for each pack
	const [reRender, setReRender] = useState(false)
	const [subsData, setsSubData] = useState([])
	const [subsLoading, setsSubsLoading] = useState(true)
	const [packsLoading, setPackagsLoading] = useState(true)

	const [Role, setRole] = useState()
	const user = localStorage.getItem("User")
	const loggedBranch = localStorage.getItem("loggedBranch");
	const [customerData, setCustomerData] = useState([]);
	const [LoadingPackageData, setisLoadingPackageData] = useState()
	const [packages, setPackages] = useState([])
	const params = useParams()
	const customerID = params.id
	const [paid, setPaid] = useState(null)

	const [open, setOpen] = React.useState(false);
	const [subID, setSubID] = useState()
	const [subStatus, setSubStatus] = useState()
	const [debtOpen, setDebtOpen] = React.useState(false);
	const [giftOpen, setGiftOpen] = React.useState(false);
	const [invtiationOpen, setInvitationOpen] = React.useState(false);
	const [subscriptionID, setSubscriptionID] = useState()
	const packageidUseRef = useRef()
	const PayDebtValue = useRef()
	const [activationOpen, setActivationOpen] = React.useState(false);
	const [deleteSub, setDeleteSub] = React.useState(false);
	const [remainingSessions, setRemaininsSessions] = useState()
	const paybackvalue = useRef()
	const [archived, setArchived] = useState()
	const [increaseSub, setIncreaseSub] = useState(false)
	const cancelCause = useRef()
	const days_list = Array.from({ length: 30 }, (_, index) => index + 1);
	const sessionsRef = useRef()
	const daysRef = useRef()
	const [days, setDays] = useState({});
	const [checked, setChecked] = React.useState(false);
	const [RemainsGracePeriod, setGracePeriod] = useState()
	const [graceDays, setGraceDays] = React.useState('');
	const [paidValue, setPaidValue] = useState()
	const [remainsValue, setRemainsValue] = useState()
	const [packID, setPackID] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [subDate, setSubDate] = useState()

	const handleGraceChange = (event) => {
		setGraceDays(event.target.value);
	};
	const validationSchema = Yup.object().shape({
		packs: Yup.array().of(Yup.boolean()).test(
			'at-least-one',
			'At least one package must be selected',
			value => value.includes(true)
		),
		discount: Yup.number().required('Required').min(0, 'Must be at least 0'),
	});

	const formik = useFormik({
		initialValues: {
			discount: '0',
			packs: Array(data?.length).fill(false),
			paid: "",
			graceDays: "", // Initialize graceDays
		},
		validationSchema,
		onSubmit: (values) => {
			// Manual validation for graceDays based on paid
			if (values.paid && !values.graceDays) {
				formik.setErrors({ graceDays: "Grace Days is required" });
				return; // Stop submission
			}

			// Handle form submission
			handleCustomerSub(values);
		},
	});


	const employee = localStorage.getItem("User")

	const handleChange = () => {
		setChecked((prev) => !prev);
	};


	const icon = (
		<Paper elevation={0} sx={{ padding: 2, marginBottom: 2, width: "100%" }}>
			<TextField

				style={{ marginBottom: "10px", width: "200px" }}
				type="text"
				placeholder='Paid'
				id="paid"
				label="paid"
				variant="outlined"

				value={formik.values.paid}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched.paid && Boolean(formik.errors.paid)}
				helperText={formik.touched.paid && formik.errors.paid}
				fullWidth
			/>
			{formik.values.paid && (
				<FormControl sx={{ margin: "auto", width: "fit-content" }} fullWidth variant="outlined">
					<InputLabel id="grace-days-label">Grace Days</InputLabel>
					<Select
						labelId="grace-days-label"
						value={formik.values.graceDays}
						onChange={(e) => formik.setFieldValue('graceDays', e.target.value)}
						label="Grace Days"
						sx={{
							width: "200px",
							margin: "0",
							'& .MuiSelect-select': {
								height: '30px',
								display: 'flex',
								alignItems: 'center',
							},
						}}
					>
						{[...Array(30)].map((_, index) => (
							<MenuItem key={index + 1} value={index + 1}>
								{index + 1}
							</MenuItem>
						))}
					</Select>
					{formik.errors.graceDays && (
						<Typography color="error">{formik.errors.graceDays}</Typography>
					)}
				</FormControl>
			)}


		</Paper>
	);

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		'& .MuiDialogContent-root': {
			padding: theme.spacing(2),
		},
		'& .MuiDialogActions-root': {
			padding: theme.spacing(1),
		},
	}));


	function subStatusAndID(x, y,z) {
		setSubStatus(x)
		setSubID(y)
		setPackID(z)
		handleClickOpenActivation()


	}



	function subStatusAndIDCancel(x, y) {
		setSubStatus(x)
		setSubID(y)


	}
	function subStatusAndIDArchive(x, y) {
		setSubStatus(x)
		setSubID(y)
		handleClickOpenArchive()


	}

	const handleClickOpenActivation = () => {
		setActivationOpen(true);
	};

	const handleCloseActivation = () => {
		setActivationOpen(false);
	};


	const handleClickOpenArchive = () => {
		setArchived(true);
	};

	const handleCloseArchive = () => {
		setArchived(false);
	};

	const handleDebtOpen = (date) => {
		setSubDate(date)
		setDebtOpen(true);
	};
	const handleDebtClose = () => {
		setDebtOpen(false);
	};

	const handleGiftOpen = () => {
		setGiftOpen(true);
	};
	const handleGiftClose = () => {
		setGiftOpen(false);
	};


	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};


	const handleDeleteSubOpen = () => {
		setDeleteSub(true);
	};

	const handleDeleteSubClose = () => {
		setDeleteSub(false);
	};



	const handleIncreaseSubOpen = () => {
		setIncreaseSub(true);
	};

	const handleIncreaseSubClose = () => {
		setIncreaseSub(false);
	};

	const handleInvitationOpen = () => {
		setInvitationOpen(true);
	};


	useEffect(() => {
		const totals = Object.values(checkedItems).map(m => {
			return Number(m.price) * Number(m.count);
		});

		const total = totals.reduce((acc, total) => acc + total, 0);
		formik.setFieldValue('totalGrand', total); // Update totalGrand in Formik values
	}, [checkedItems]);

	function HandleGymPackageChange(e) {
		e.preventDefault()
		fetch(`${API}/ChangeClinicPack`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				"subID": subID,
				"chackedItems": checkedItems,
				"subDate":subDate

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					setReRender(!reRender)
					handleClose()


				} else {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}
			})

	}

	function handleIncreaseGymQuota(e) {
		e.preventDefault()
		fetch(`${API}/IncreaseClinicSub`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'sessions': sessionsRef.current.value,
				'days': daysRef.current.value,
				"subID": subID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						autoClose: false
					})
					handleIncreaseSubClose()

					setReRender(!reRender)




				} else if (result.status == 404) {
					toast.error(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						autoClose: false
					})


				}

			})

	}

	function PayGymDebt(e) {
		e.preventDefault()
		fetch(`${API}/payClinicDebt`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'debtValue': PayDebtValue.current.value,
				"user":user,
				"branch":loggedBranch,
				"subDate":subDate

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					setReRender(!reRender)

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				} else   {

					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				}
				setButtonLoading(false)
				handleDebtClose()
			})

	}


	function updateClinicSubStatus() {
		fetch(`${API}/updateClinicStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'subscriptionID': subID,
				'status': subStatus,
				"customerID": customerID,
				"packID": packID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					setReRender(!reRender)
					handleCloseActivation()

				} else  {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					handleCloseActivation()


				}
			})
	}



	function archiveSub() {
		fetch(`${API}/archiveClinicSub`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'subscriptionID': subID,
				'status': subStatus,
				"customerID": customerID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					setReRender(!reRender)
					handleCloseArchive()

				} else if (result.status == "402") {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				}
			})
	}


	function cancelGymSub(e) {
		e.preventDefault()
		fetch(`${API}/cancelClinicSub`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'SubID': subID,
				"paybackvalue": paybackvalue.current.value,
				"cause": cancelCause.current.value,
				"branch": loggedBranch,
				"subDate":subDate
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					setReRender(!reRender)
					handleDeleteSubClose()


				} else {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}
			})
	}

	useEffect(() => {
		const items = localStorage.getItem('Roles')
		console.log(items)
		if (items) {
			setRole(items);
		}
	}, []);
	const API = process.env.REACT_APP_API


	function ClinicDataComponent({ sub }) {
		return (

			<Card variant="outlined" sx={{ padding: "20px", width: "800px", margin: "20px auto", width: '100%' }}>

				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-evenly", }} fontWeight="md" textColor="success.plainColor">
						<div className='package-name'>
							<div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Category</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", alignItems: "center", textAlign: "center" }}>{sub?.category}</label></div>

						</div>



						<div className='package-status'>

							<div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px", marginLeft: "20px" }}>Status</label></div>
							{sub?.status == "inactive" ? (<div onClick={() => subStatusAndID("active", sub.subID, sub.packID)} style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "red", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {sub?.status}</label></div>
							) : (<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "green", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {sub?.status}</label></div>
							)}
						</div>


						<div className='package-used'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}> <CancelPresentationIcon style={{ height: "20px", size: "30px", color: "darkred" }} />  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Used Sessions</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.usedSessions}</p></div>

						</div>


						<div className='package-remaining'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>  <CheckBoxIcon style={{ height: "20px", size: "30px", color: "green" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Remaining Sessions</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.remainingSession}</p></div>

						</div>

						<div className='pack-endDate'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <CalendarMonthIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Package End Date</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.endDate}</p></div>

						</div>

						<div className='pack-endDate'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <CalendarMonthIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Grace End Date</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.graceEndDate }</p></div>

						</div>


						{sub?.employee ? (
							<div className='pack-endDate'>
								<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonAddIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Employee</label></div>
								<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.employee}</p></div>

							</div>
						) : ("")}




					</div>



				</CardContent> <br />




				{sub?.remainingSession == "0" && sub?.status == "active"|| new Date(endDate) >=  new Date(sub?.endDate)   && sub?.status == "active" ? ( <Button onClick={() => subStatusAndIDArchive("archived", sub.subID)} style={{ width: "100px", margin: "0 auto", border: "2px solid black", color: "black", fontWeight: "bold" }} variant="outlined" startIcon={<ArchiveIcon />}>Archive</Button>):("")
				}

				<div style={{ display: "flex", justifyContent: "space-around" }}>


					{Role == "Admin" || Role == "admin" || Role == "Supervisor" ? (<Button style={{ whiteSpace: "nowrap", border: "1px solid darkblue", color: "red", fontWeight: "bold", fontSize: "10px" }} onClick={() => { setSubID(sub.subID);  setSubDate( sub.startDate); handleClickOpen() }} variant="outlined">
						<EditIcon sx={{ color: "darkblue" }} />

					</Button>) : ("")}


					{Role == "Admin" || Role == "admin" ? (<Tooltip title={<Typography fontSize={15}>cancel sub</Typography>}>  <Button onClick={() => { setRemaininsSessions(sub.RemainingSessions); setSubDate( sub.startDate); setSubID(sub.subID); handleDeleteSubOpen() }} sx={{ border: "1px solid red" }} variant="outlined">
						<CancelIcon sx={{ color: "Red" }} />

					</Button> </Tooltip>) : ("")}


					{Role == "Admin" || Role == "admin" || Role == "General Manager" ? (
						<Tooltip title={<Typography fontSize={15}>increase sub</Typography>}>   <Button onClick={() => { setSubID(sub.subID); handleIncreaseSubOpen() }} sx={{ border: "1px solid green" }} variant="outlined">
							<AddCircleOutlineIcon sx={{ color: "Green" }} />

						</Button> </Tooltip>) : ("")}





				</div>
			</Card>



		)
	}


	const totals = Object.values(checkedItems).map(m => {
		return Number(m.price) * Number(m.count);
	});

	// Calculate the grand total
	const handleCheckboxChange = (event) => {
		const { name, checked } = event.target;
		setCheckedItems((prevValues) => {
			if (checked) {
				const selectedPack = data?.find(pack => pack.name === name);
				return {
					...prevValues,
					[name]: {
						name: selectedPack.name,
						price: selectedPack.price,
						count: "1", // Default count, you can change this logic if needed
						days: "1", // Default days, can be adjusted based on your requirements
						customerID: customerID,
						packID: selectedPack.packID,

					},
				};
			} else {
				const { [name]: removed, ...rest } = prevValues; // Remove the package name
				return rest;
			}
		});
	};
	const handleAgeChange = (event, packName) => {
		const selectedAge = event.target.value;

		setAges({
			...ages,
			[packName]: selectedAge, // Set age for specific pack
		});

		// Update checkedItems with the selected count
		setCheckedItems((prevValues) => ({
			...prevValues,
			[packName]: {
				...prevValues[packName],
				count: selectedAge, // Update count
			},
		}));
	};

	const handleDaysChange = (event, packName) => {
		const selectedDays = event.target.value;

		setDays({
			...days,
			[packName]: selectedDays, // Set number of days for specific pack
		});

		// Update checkedItems with the selected days
		setCheckedItems((prevValues) => ({
			...prevValues,
			[packName]: {
				...prevValues[packName],
				days: selectedDays, // Update days
			},
		}));
	};
	useEffect(function () {
		async function fetchData() {
			try {
				const res = await fetch(`${API}/getClinicPack`)
				const data = await res.json()
				console.log(data)
				setData(data.data)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchData()
	}, [reRender])

	function handleCustomerSub(values) {
		setButtonLoading(true)
		fetch(`${API}/clinicCustomer`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},

			body: JSON.stringify({
				body: checkedItems, customerID, values, customerID, loggedBranch, user


			})

		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					setButtonLoading(false)

					setReRender(!reRender)
				} else {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					setButtonLoading(false)

				}
			})

	}

	useEffect(function () {
		async function fetchData() {
			try {
				const res = await fetch(`${API}/getCinicSubs/${customerID}`)
				const data = await res.json()
				setsSubData(data.data)
				console.log(data)
				console.log(subsData)
				setPaidValue(data.debtSum)



			} catch (e) {
				console.log(e)
			} finally {
				setsSubsLoading(false)

			}
		} fetchData()
	}, [reRender])

	{ if (subsLoading || loading ) return <CircularProgress sx={{ margin: "auto", width: "fit-content", display: "flex" }} /> }
	return (

		<Container sx={{ border: "2px solid #e4e4e4", padding: "10px", borderRadius: "10px" }}>
			<ToastContainer
				position="top-center"
				autoClose={false}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle
					sx={{ m: 0, p: 2, fontWeight: "bold", textAlign: "center" }}
					id="customized-dialog-title"
				>
					Select package
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						minHeight: "250px",
						minWidth: "350px",
						margin: "auto"
					}}
					dividers
				>
					<form style={{ margin: "auto", backgroundColor: "inherit", height: "auto", width: "400px", padding: "8px", paddingTop: "10px" }} onSubmit={formik.handleSubmit}>
						<Grid container spacing={2}>
							{data?.map((pack, index) => (
								<Card
									key={pack.name}
									sx={{
										width: "auto",
										minWidth: "350px",
										maxWidth: "400px",
										display: "flex",
										padding: "5px",
										alignItems: "center",
										textAlign: "center",
										flexDirection: "row",
										margin: "auto",
										fontFamily: "poppins",
										marginBottom: "10px",
									}}
									variant="outlined"
								>
									<Box sx={{ display: "flex", margin: "auto" }}>
										<Checkbox
											price={pack.price}
											name={pack.name}
											checked={formik.values.packs[index]} // Use formik values for checked state
											onChange={(e) => {
												const checked = e.target.checked;
												formik.setFieldValue(`packs[${index}]`, checked);
												handleCheckboxChange(e); // Call your original checkbox change handler
											}}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
										<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center" }}>
											<Typography variant="body1" sx={{ fontWeight: "bold" }}>{pack.name}</Typography>
											<Typography variant="body2" sx={{ marginTop: "0", marginLeft: "5px", fontWeight: "bold" }}>
												({pack.price} EGP)
											</Typography>
										</Box>
									</Box>
									{formik.values.packs[index] && (
										<Box sx={{ display: "flex", marginLeft: 2 }}>
											<Box sx={{ height: "30px", marginRight: 2 }}>
												<Select
													sx={{ height: "30px" }}
													labelId={`age-select-label-${pack.name}`}
													id={`age-select-${pack.name}`}
													value={ages[pack.name] || ""}
													label="Age"
													onChange={(e) => handleAgeChange(e, pack.name)}
												>
													<MenuItem value="">
														<em>None</em>
													</MenuItem>
													{[...Array(8).keys()].map((num) => (
														<MenuItem key={num + 1} value={num + 1}>{num + 1}</MenuItem>
													))}
												</Select>
											</Box>

										</Box>
									)}
								</Card>
							))}
						</Grid>

						{/* Error handling for required checkbox */}
						{formik.touched.packs && formik.errors.packs && (
							<Typography color="error">{formik.errors.packs}</Typography>
						)}

						{Object.values(checkedItems)?.map(m => (
							<Card
								key={m.name} // Add a unique key for each card
								variant='soft'
								color="primary"
								sx={{ display: "flex", fontFamily: "poppins", flexDirection: "row", width: "fit-content", minWidth: "350px", margin: "auto auto 10px auto" }}
							>
								<h6>{m.name}</h6>
								<h6>{m.price} x {m.count}   </h6>
								<h6><strong>Total:</strong> {Number(m.price) * Number(m.count)}</h6>
								<h6> ({m.days} days)</h6>
							</Card>
						))}
						<Button
							style={{ backgroundColor: "#006241", color: "white", fontWeight: "bold", width: "250px", borderRadius: "8px", height: "40px" }}
							autoFocus
							onClick={HandleGymPackageChange}
						>
							Confirm
						</Button>
					</form>
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center", width: "100%" }}>

				</DialogActions>
			</BootstrapDialog>













			{/* Delete Dialog */}

			<BootstrapDialog
				onClose={handleDeleteSubOpen}
				open={deleteSub}
				aria-labelledby="customized-dialog-title"
			

			>
				<IconButton
					aria-label="close"
					onClick={handleDeleteSubClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon  />
				</IconButton>


				<DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
					<h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Cancel Subscription?</h5>
					<h8 style={{ marginLeft: "15px" }}> {remainingSessions} Remaining sessions</h8>


				</DialogContent>

				<DialogContent sx={{ margin: "0 auto" }}>
					<img style={{ width: "150px", margin: "0 auto" }} src='/cancelsub.png' />
				</DialogContent>

				<DialogContent sx={{ margin: "0 auto" }}>
					<form onSubmit={(e) => cancelGymSub(e)} style={{ height: "150px", width: "200px", backgroundColor: "white", padding: "10px", marginLeft: "0", width: "auto" }}>
						<input ref={paybackvalue} required type='text' placeholder='Payback Value' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} /> <br />
						<select ref={cancelCause} style={{ marginBottom: "7px", width: "200px", border: "1px solid #e4e4e4", alignItems: "center", textAlign: "center" }}>
							<option style={{ alignItems: "center", textAlign: "center" }} disabled selected>Cause</option>
							<option style={{ alignItems: "center", textAlign: "center" }} value="Retrun">Retrun</option>
							<option style={{ alignItems: "center", textAlign: "center" }} value="Wrong">Wrong</option>

						</select>
						<button type='submit' style={{ backgroundColor: "#006241", color: "white", fontWeight: "bold", width: "250px", borderRadius: "8px", height: "40px" }} autoFocus >
							Confirm
						</button>
					</form>
				</DialogContent>





			</BootstrapDialog>




			{/* Increase Quota */}



			<BootstrapDialog
				onClose={handleIncreaseSubClose}
				open={increaseSub}
				aria-labelledby="customized-dialog-title"



			>
				<IconButton
					aria-label="close"
					onClick={handleIncreaseSubClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>


				<DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
					<h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Increase Subscription</h5>


				</DialogContent>

				<DialogContent sx={{ margin: "0 auto", height: "190px" }}>
					<img style={{ width: "150px", margin: "0 auto", marginBottom: "0" }} src='/add.png' />
				</DialogContent>

				<DialogContent sx={{ margin: "0 auto", marginTop: "0" }}>
					<form onSubmit={(e) => handleIncreaseGymQuota(e)} style={{ height: "150px", width: "250px", backgroundColor: "white", padding: "5px", marginLeft: "0", marginBottom: "10px" }}>
						<input ref={sessionsRef} required type='text' placeholder='Num of sessions' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />
						<input ref={daysRef} required type='text' placeholder='Days' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />

						<button type='submit' style={{ backgroundColor: "#006241", color: "white", fontWeight: "bold", width: "250px", borderRadius: "8px", height: "40px" }} autoFocus >
							Confirm
						</button>
					</form>
				</DialogContent>





			</BootstrapDialog>


			{/* end of increase quota  */}




			<Dialog
				open={activationOpen}

				onClose={handleCloseActivation}
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseActivation}>cancel</Button>
					<Button onClick={updateClinicSubStatus}>Activate</Button>
				</DialogActions>
			</Dialog>




			<Dialog
				open={archived}

				onClose={handleCloseArchive}
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseArchive}>cancel</Button>
					<Button onClick={archiveSub}>Archive</Button>
				</DialogActions>
			</Dialog>



			<Dialog
				maxWidth="sm"
				open={debtOpen}
				onClose={handleDebtClose}
			>
				<DialogContent sx={{ margin: "0 auto" }}>
					<img style={{ width: "200px", margin: "0 auto", height: "200px" }} src="/pay.png" />
				</DialogContent>
				<DialogContent>
					<form onSubmit={(e) => PayGymDebt(e)} style={{ height: "100px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0" }}>
						<input required ref={PayDebtValue} style={{ border: "1px solid grey", alignItems: "center", textAlign: "center", borderRadius: "7px", marginTop: "4px" }} type='text' placeholder='Value' />
						<div style={{ display: "flex", margin: "0 auto", justifyContent: "space-evenly" }}>
							<Button sx={{color:"darkred", fontWeight:"bold", border:"1px solid darkred", width:"40%", height:"35px", borderRadius:"8px"}} onClick={handleDebtClose}>Close</Button>
							<LoadingButton
								style={{ backgroundColor: "#00ab41",borderRadius:"8px",height:"35px", marginTop: "0", color: "white", fontWeight: "bold" , width:"40%"}}
								loading={buttonLoading}
								loadingPosition="start"
								type='submit'
								startIcon={<AttachMoneyIcon sx={{color:'white'}} />}

							>
								Pay
							</LoadingButton>
						</div>


					</form>
				</DialogContent>
				<DialogActions>


				</DialogActions>
			</Dialog>
{console.log(paidValue)}
			{paidValue.length > 0 ? (

paidValue?.map(remains => {
					return (
						<Box sx={{ 
							display: "flex", 
							flexDirection: "row", 
							justifyContent: "space-between", 
							alignItems: "center", 
							border: "1px solid #daedfe", 
							borderRadius: "8px", 
							padding: "10px"
						  }}>
							{/* Remains Section */}
							<Box sx={{
							  display: "flex", 
							  alignItems: "center", 
							  textAlign: "center"
							}}>
							  <Avatar sx={{ marginRight: "15px" }}>
								<AttachMoneyIcon sx={{ color: "black" }} />
							  </Avatar>
							  <p style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
								{`Remains: ${remains?.debt}`}
							  </p>
							</Box>
						  
							{/* Subscription Date Section */}
							<Box sx={{
							  display: "flex", 
							  alignItems: "center", 
							  textAlign: "center"
							}}>
							  <Avatar sx={{ marginRight: "15px" }}>
								<CalendarTodayIcon sx={{ color: "black" }} />
							  </Avatar>
							  <p style={{ fontWeight: "bold", fontSize: "15px", whiteSpace: "nowrap" }}>
								{`Subscription Date: ${remains?.PayDate}`}
							  </p>
							</Box>
						  
							{/* Pay Button (Visible only for Admin, Receptionist roles) */}
							{(Role === "Admin" || Role === "admin" || Role === "Receptionist") && (
							  <Button 
								sx={{
								  backgroundColor: "#e4e4e4", 
								  fontWeight: "bold", 
								  height: "40px", 
								  color: "black", 
								  width: "100px"
								}} 
								onClick={() => handleDebtOpen(remains?.PayDate)} 
								startIcon={<PaidIcon />}
							  >
								Pay
							  </Button>
							)}
						  </Box>
						  
					)
				})

		) : ("")}
			{subsData?.map(sub => {
				return (
					<ClinicDataComponent sub={sub} />
				)
			})}
			<form style={{ margin: "auto", backgroundColor: "inherit", height: "auto", width: "400px", padding: "8px", paddingTop: "10px" }} onSubmit={formik.handleSubmit}>
				<Grid container spacing={2}>
					{data?.map((pack, index) => (
						<Card
							key={pack.name}
							sx={{
								width: "auto",
								minWidth: "350px",
								maxWidth: "400px",
								display: "flex",
								padding: "5px",
								alignItems: "center",
								textAlign: "center",
								flexDirection: "row",
								margin: "auto",
								fontFamily: "poppins",
								marginBottom: "10px",
							}}
							variant="outlined"
						>
							<Box sx={{ display: "flex", margin: "auto" }}>
								<Checkbox
									price={pack.price}
									name={pack.name}
									checked={formik.values.packs[index]} // Use formik values for checked state
									onChange={(e) => {
										const checked = e.target.checked;
										formik.setFieldValue(`packs[${index}]`, checked);
										handleCheckboxChange(e); // Call your original checkbox change handler
									}}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
								<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center" }}>
									<Typography variant="body1" sx={{ fontWeight: "bold" }}>{pack.name}</Typography>
									<Typography variant="body2" sx={{ marginTop: "0", marginLeft: "5px", fontWeight: "bold" }}>
										({pack.price} EGP)
									</Typography>
								</Box>
							</Box>
							{formik.values.packs[index] && (
								<Box sx={{ display: "flex", marginLeft: 2 }}>
									<Box sx={{ height: "30px", marginRight: 2 }}>
										<Select
											sx={{ height: "30px" }}
											labelId={`age-select-label-${pack.name}`}
											id={`age-select-${pack.name}`}
											value={ages[pack.name] || ""}
											label="Count"
											onChange={(e) => handleAgeChange(e, pack.name)}
											placeholder='Count'
										>
											<MenuItem value="">
												<em>Count</em>
											</MenuItem>
											{[...Array(15).keys()].map((num) => (
												<MenuItem key={num + 1} value={num + 1}>{num + 1}</MenuItem>
											))}
										</Select>
									</Box>
									<Box sx={{ height: "30px" }}>
										<Select
											sx={{ height: "30px" }}
											labelId={`days-select-label-${pack.name}`}
											id={`days-select-${pack.name}`}
											value={days[pack.name] || ""}
											label="Days"
											onChange={(e) => handleDaysChange(e, pack.name)}
										>
											<MenuItem value="">
												<em>Days</em>
											</MenuItem>
											{[...Array(60).keys()].map((num) => (
												<MenuItem key={num + 1} value={num + 1}>{num + 1}</MenuItem>
											))}
										</Select>
									</Box>
								</Box>
							)}
						</Card>
					))}
				</Grid>

				{/* Error handling for required checkbox */}
				{formik.touched.packs && formik.errors.packs && (
					<Typography color="error">{formik.errors.packs}</Typography>
				)}

				{Object.values(checkedItems)?.map(m => (
					<Card
						key={m.name} // Add a unique key for each card
						variant='soft'
						color="primary"
						sx={{ display: "flex", fontFamily: "poppins", flexDirection: "row", width: "fit-content", minWidth: "350px", margin: "auto auto 10px auto" }}
					>
						<h6>{m.name}</h6>
						<h6>{m.price} x {m.count}   </h6>
						<h6><strong>Total:</strong> {Number(m.price) * Number(m.count)}</h6>
						<h6> ({m.days} days)</h6>
					</Card>
				))}

				<Card sx={{ width: "fit-content", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "200px", marginBottom: "10px" }}>
					<CalculateIcon />
					<h6 style={{ textAlign: "center", fontFamily: "poppins" }}>{formik.values.totalGrand} EGP</h6>
				</Card>

				<TextField
					sx={{ width: "200px" }}
					id="discount"
					label="Discount"
					variant="outlined"

					value={formik.values.discount}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.discount && Boolean(formik.errors.discount)}
					helperText={formik.touched.discount && formik.errors.discount}
				/>


				<Box sx={{ height: 180, margin: "0 auto" }}>
					<FormControlLabel
						control={
							<Button
								style={{ border: "1px solid #e4e4e4", margin: "0 auto", marginBottom: "10px", fontWeight: "bold", width: "200px", height: "30px" }}
								endIcon={<AttachMoneyIcon style={{ color: "red" }} />}
								onClick={handleChange}
								variant="outlined"
								color="error"
							>
								Debt
							</Button>
						}
					/>

					<Box sx={{ display: 'flex' }}>
						<Fade in={checked}>{icon}</Fade>

					</Box>

				</Box>






				<Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
					<LoadingButton
						sx={{
							height: "40px",
							fontWeight: "bold",
							borderRadius: "12px",
							backgroundColor: "#152238",
							width: "250px",
							color: "white",
							margin: "auto",
							":hover": {
								backgroundColor: "#152238",
							},
						}}
						type="submit" // Change to submit
						loading={buttonLoading}
						variant="contained"
					>
						Submit
					</LoadingButton>


				</Box>
			</form>
		</Container>


	)
}

export default ClinicData