// import "../styles/customersPage.css"

import { React, useEffect, useState, useRef, memo } from 'react';
import { FaRegistered, FaWeight } from "react-icons/fa";
import ErrorIcon from '@mui/icons-material/Error';
import Navbar from "../components/navbar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CardContent from '@mui/joy/CardContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import qs from 'qs';
import DialogTitle from '@mui/material/DialogTitle';
import { IoMdClose } from "react-icons/io";
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Drawer from '@mui/material/Drawer';
import LoadingButton from '@mui/lab/LoadingButton';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CloseIcon from '@mui/icons-material/Close';
import { Box, ColumnLayout, Container } from '@cloudscape-design/components';
import { Box as MUIBOX, TextField, Container as MUIContainer } from '@mui/material';

import { GoPackage } from "react-icons/go";
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import { Table } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import PreCheckInDialog from "../components/precheck/gym";

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Tabs, Tab } from '@mui/material';

//Parent component
function CustomersPage() {
  const Role = localStorage.getItem("Roles")
  const [customerID, setCustomerID] = useState()
  const [render, rerender] = useState(false);
  const navigate = useNavigate()
  const API = process.env.REACT_APP_API
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [DialogOpen, setDialogOpen] = useState(false);
  const param = useParams()
  const [sideOpen, setSideOpen] = useState(false);
  const [count, setCount] = useState()
  const [pages, setPages] = useState()
  const [subInfo, setSubInfo] = useState("")
  const [customerInfo, setCustomerInfo] = useState("")
  const [inActiveCount, setInActiveCount] = useState("")

  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const branch = localStorage.getItem("loggedBranch");
  const { handleSubmit, register, formState: { errors } } = useForm();
  const [activeTab, setActiveTab] = useState('active'); // Default active filter


  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
   
  });
  const columns = [
    {
      title: 'CustomerID',
      dataIndex: 'customerID',
      align: 'center',
      responsive: ['xs', 'sm'],
    },
    {
      title: 'Name',
      dataIndex: 'Fullname',
      align: 'center',
      className: 'table-cell',
    },
    {
      title: 'Used Sessions',
      dataIndex: 'UsedSessions',
      align: 'center',
      className: 'table-cell',
    },
    {
      title: 'Remaining Sessions',
      dataIndex: 'RemainingSessions',
      align: 'center',
      className: 'table-cell',
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      align: 'center',
      className: 'table-cell',
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      align: 'center',
      className: 'table-cell',
    },
    {
      title: 'Last Check-in',
      dataIndex: 'LastCheck',
      align: 'center',
      className: 'table-cell',
    },
  ];




  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    status:activeTab,
    ...params,
  });


  const onSubmit = async (data) => {
    setCustomerID(data.customerID);
    await getCustomerInfo(data.customerID);
  };
  const fetchData = () => {
    setLoading(true);
    fetch(`${API}/customers/getAllSubscribed?${qs.stringify(getRandomuserParams(tableParams))}`)
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setCount(data.totalCount) // number of rows
        console.log(data.totalPages)
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.totalCount, // number of pages
          },
        });
      });
  };
  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    activeTab
  ]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  function toggleDrawer(newOpen) {
    setSideOpen(newOpen);
  };



  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,  // Reset pagination to the first page
      },
    });
  };



  const DrawerList = (

    <>
      <IoMdClose style={{
        position: 'absolute',
        left: '0',
        cursor: "pointer"
      }}
        size="30px"
        color="black" onClick={() => toggleDrawer(false)} />
      <MUIBOX component="form" onSubmit={handleSubmit(onSubmit)}


        sx={{
          margin: "70% auto", // Reduced margin for mobile
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: 3,
          height:"200px",
          width: "90%", // 90% width to give some space on the sides
          maxWidth: "500px", // Maximum width for larger screens
          '@media (max-width: 600px)': {
            marginTop: "20px", // Adjust the margin for mobile
            marginBottom: "20px", // Adjust the margin for mobile
          },
        }}
      >
        <TextField
          {...register('customerID', { required: true })}
          autoFocus
          id="customerID"
          type="text"
          variant="outlined"
          label="Customer ID"
          error={Boolean(errors.customerID)}
          helperText={errors.customerID ? 'Customer ID is required' : ''}
          sx={{
            width: "100%", // Full width for smaller screens
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderWidth: 2,
              },
              '&:hover fieldset': {
                borderColor: '#1976d2',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
              '& input': {
                color: 'black', // Input text color
                '&::placeholder': {
                  color: 'rgba(0, 0, 0, 0.5)', // Placeholder color
                },
              },
            },
            '& .MuiInputLabel-root': {
              color: 'gray', // Default label color
              '&.Mui-focused': {
                color: 'black', // Change to black on focus
              },
            },
          }}
        />

        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "100%", // Full width for mobile
            maxWidth: "250px", // Limit max width for larger screens
            height: "45px", // Increase height for better touch targets on mobile
            borderRadius: "15px",
            fontWeight: "bold",
            marginTop: "16px", // Add space between TextField and Button
          }}
        >
          Check in
        </Button>
      </MUIBOX >


    </>


  )



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const todayDate = moment().tz("Africa/Cairo").format("YYYY-MM-DD hh:mm:ss").split(" ")
  const today = todayDate[0]



  async function getCustomerInfo(customerID) {
    try {
      const res = await fetch(`${API}/gymPreSub/${customerID}`);
      const data = await res.json();

      // Only update state if the data is different
      setCustomerInfo(data.customerInfo);

      setSubInfo(data.subInfo);
      setInActiveCount(data.inactiveNum[0].count)

    } catch (e) {
      console.log(e);
    } finally {
      handleClickOpen(); // Ensure this doesn't trigger further updates
    }
  }
  return (

    <MUIBOX sx={{ minWidth: "90%", maxWidth: "90%", margin: "0 auto" }}>
      <PreCheckInDialog
        CheckIcon={CheckIcon}
        subInfo={subInfo}
        customerInfo={customerInfo}
        IconButton={IconButton}
        Button={Button}
        open={open}
        BootstrapDialog={BootstrapDialog}
        DialogTitle={DialogTitle}
        DialogContent={DialogContent}
        DialogActions={DialogActions}
        CloseIcon={CloseIcon}
        ErrorIcon={ErrorIcon}
        Checkbox={Checkbox}
        Container={Container}
        ColumnLayout={ColumnLayout}
        GoPackage={GoPackage}
        DateRangeIcon={DateRangeIcon}
        FaRegistered={FaRegistered}
        PaidIcon={PaidIcon}
        CancelPresentationIcon={CancelPresentationIcon}
        LoadingButton={LoadingButton}
        handleClose={handleClose}
        setButtonLoading={setButtonLoading}
        buttonLoading={buttonLoading} // Adjust according to your state
        customerID={customerID}
        render={render}
        rerender={rerender}
        toast={toast}
        loggedBranch={branch}
        inActiveCount={inActiveCount}
      />

      <Drawer
        PaperProps={{
          style: {
            width: "30%", // Adjusted for a consistent drawer width
          },
        }}
        anchor="right"
        open={sideOpen}
        onClose={() => toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Navbar />

      <Box sx={{ margin: "100px auto", width: "10%" }}>
        {/* Flex container for buttons */}
        <MUIBOX
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, row on larger screens
            alignItems: "center",
            justifyContent: "center",  // Center the buttons horizontally
            gap: { xs: 1, sm: 2 },  // Gap between buttons based on screen size
            textAlign: "center",
            padding: "10px",  // Padding around the Box for better spacing
            width: "100%",  // Ensure this box takes full width available
            maxWidth: "100%",  // Ensure it doesn't exceed the viewport width
            marginTop: "20px"
          }}
        >
          {/* Conditional rendering for Admin or Receptionist */}
          {(Role === "Admin" || Role === "Receptionist") && (
            <Button
              sx={{
                backgroundColor: "#192841",
                fontFamily: 'Poppins',
                color: "white",
                margin: "10px",
                width: { xs: "100%", sm: "170px" },  // Full width on mobile, fixed width on larger screens
                height: "40px",
                border: "none",
                borderRadius: "7px",
                fontWeight: "lighter",
                textAlign: "center",
                fontSize: { xs: "12px", sm: "13px" },
              }}
              onClick={() => navigate(`/add-customer`)}
              variant="contained"
              endIcon={<AddIcon style={{ color: "white" }} />}
            >
              Add Customer
            </Button>
          )}

          {/* Conditional rendering for Admin */}
          {Role === "Admin" && (
            <>
              <Button
                sx={{
                  backgroundColor: "#192841",
                  fontFamily: 'Poppins',
                  color: "white",
                  margin: "10px",
                  width: { xs: "100%", sm: "170px" },
                  height: "40px",
                  border: "none",
                  borderRadius: "7px",
                  fontWeight: "lighter",
                  textAlign: "center",
                  fontSize: { xs: "12px", sm: "13px" },
                }}
                onClick={() => navigate(`/add-gym-package`)}
                variant="contained"
                endIcon={<AddIcon style={{ color: "white" }} />}
              >
                Add Package
              </Button>

              <Button
                sx={{
                  backgroundColor: "#192841",
                  fontFamily: 'Poppins',
                  color: "white",
                  margin: "10px",
                  width: { xs: "100%", sm: "170px" },
                  height: "40px",
                  border: "none",
                  borderRadius: "7px",
                  fontWeight: "lighter",
                  textAlign: "center",
                  fontSize: { xs: "12px", sm: "13px" },
                }}
                onClick={() => navigate(`/edit-gym-package`)}
                variant="contained"
                endIcon={<EditIcon style={{ color: "white" }} />}
              >
                Edit Package
              </Button>
            </>
          )}

          {/* Check-in button - Always visible */}
          <Button
            sx={{
              backgroundColor: "#192841",
              fontFamily: 'Poppins',
              color: "white",
              margin: "10px",
              width: { xs: "100%", sm: "170px" },
              height: "40px",
              border: "none",
              borderRadius: "7px",
              fontWeight: "lighter",
              textAlign: "center",
              fontSize: { xs: "12px", sm: "13px" },
            }}
            onClick={() => toggleDrawer(true)}
            variant="contained"
            endIcon={<CheckCircleOutlineIcon style={{ color: "white" }} />}
          >
            Check-in
          </Button>
        </MUIBOX>

        <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Filter Tabs"
        centered
        sx={{
          marginBottom: 2,
          '& .MuiTab-root': { fontWeight: 'bold', fontFamily: 'Poppins' },
        }}
      >
        <Tab label="Active" value="active" />
        <Tab label="Inactive" value="inactive" />
        <Tab label="Archived" value="archived" />
      </Tabs>

    
    </Box>
        <Box>
          {/* Active Subscriptions Card */}
          <Card
            sx={{
              boxShadow: 1,
              margin: "20px auto",
              borderRadius: "10px",
              width: "fit-content",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              color: "black",
              height: "60px",
            }}
            variant="outlined"
          >
          <CardContent
  sx={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <Typography level="title-md" textColor="inherit">
    <AccountCircleIcon
      sx={{
        fill: activeTab === "active" ? "#1b874b" : activeTab === "inactive" ? "red" : "gray", // Change color based on activeTab
      }}
      fontSize="large"
    />
  </Typography>
  <label
    style={{
      color: activeTab === "active" ? "#1b874b" : activeTab === "inactive" ? "red" : "gray", // Change color based on activeTab
      fontSize: "20px",
      fontFamily: "poppins",
      fontWeight: "bold",
    }}
  >
    {count} {activeTab} subscriptions
  </label>
</CardContent>

          </Card>

          {/* Table Container with Scroll */}
          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Table
              columns={columns}
              dataSource={data}
              loading={false}
              pagination={tableParams.pagination}

              onChange={handleTableChange}
              bordered
              scroll={{ x: 'max-content' }}
            />
          </Box>
        </Box>
      </Box>
    </MUIBOX>



  )
}










export default CustomersPage