import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import moment from 'moment-timezone';


import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../components/navbar";
import { Divider, Table } from 'antd';
import qs from 'qs';
import CardContent from '@mui/joy/CardContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Tabs, Tab, Box } from '@mui/material';

const API = process.env.REACT_APP_API








function AcademiesCustomers() {
  const [isLoading, setIsloading] = useState()
  const [query, setQuery] = useState("")
  const [loading, setLoading] = useState(false);
  const todayDate = moment().tz("Africa/Cairo").format("YYYY-MM-DD hh:mm:ss").split(" ")
  const today = todayDate[0]
  const [activeTab, setActiveTab] = useState('active');
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,  // Reset pagination to the first page
      },
    });
  };
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  // const [data, setData] = useState()
  const param = useParams()
  const [filteredDays, setFilteredDays] = useState()
  const [filter, setFilted] = useState(false)
  const [count, setCount] = useState()
  const id = param.id

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    status:activeTab,
    ...params,
  });



  const [data, setData] = useState([]);

  const fetchData = () => {
    setLoading(true);
    fetch(`${API}/academyCustomers/${id}?${qs.stringify(getRandomuserParams(tableParams))}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setData(data.result);
        setCount(data.totalCount)
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.totalCount,
          },
        });
      });
  };
  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    activeTab
  ]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };


  const columns = [
    {
      title: 'customerID',
      dataIndex: 'customerID',
      align: "center"
    },
    {
      title: 'Name',
      dataIndex: 'Fullname',
      align: "center"

    },
    {
      title: 'Used sessions',
      dataIndex: 'UsedSessions',
      align: "center"

    },
    {
      title: 'Remaining sessions',
      dataIndex: 'RemainingSessions',
      align: "center"

    },

    {
      title: 'Start date',
      dataIndex: 'StartDate',
      align: "center"

    },
    {
      title: 'EndDate',
      dataIndex: 'EndDate',
      align: "center"

    },
    {
      title: 'Last Check in',
      dataIndex: 'LastCheck',
      align: "center"

    },
  ];

  function filterToday() {
    const result = data.filter(a => {
      return (
        a.LastCheck?.split(" ")[0] == today
      )
    })
    console.log(result)
    setFilteredDays(result)
    setFilted(true)

  }


  // useEffect(function () {
  //     async function fetchData() {
  //         try {
  //             setIsloading(true)
  //             const res = await fetch(`${API}/academyCustomers/${id}`)
  //             const data = await res.json()
  //             setData(data.result)
  //             console.log(data.result)
  //             setIsloading(false)
  //         } catch (e) {
  //             console.log(e)
  //         } finally {

  //         }
  //     } fetchData()
  // }, [])

  return (
    <>
      <Navbar />
      <div className="academy-cutomers-page">
      <Box sx={{ width: '100%', typography: 'body1' , marginTop:"40px"}}>

        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Filter Tabs"
          centered
          sx={{
            marginBottom: 2,
            '& .MuiTab-root': { fontWeight: 'bold', fontFamily: 'Poppins' },
          }}
        >
          <Tab label="Active" value="active" />
          <Tab label="Inactive" value="inactive" />
          <Tab label="Archived" value="archived" />
        </Tabs>
        </Box>
        <Card
            sx={{
              boxShadow: 1,
              margin: "20px auto",
              borderRadius: "10px",
              width: "fit-content",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              color: "black",
              height: "60px",
            }}
            variant="outlined"
          >
          <CardContent
  sx={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <Typography level="title-md" textColor="inherit">
    <AccountCircleIcon
      sx={{
        fill: activeTab === "active" ? "#1b874b" : activeTab === "inactive" ? "red" : "gray", // Change color based on activeTab
      }}
      fontSize="large"
    />
  </Typography>
  <label
    style={{
      color: activeTab === "active" ? "#1b874b" : activeTab === "inactive" ? "red" : "gray", // Change color based on activeTab
      fontSize: "20px",
      fontFamily: "poppins",
      fontWeight: "bold",
    }}
  >
    {count} {activeTab} subscriptions
  </label>
</CardContent>

          </Card>
        <Table
          style={{ width: "70%", margin: "0 auto" }}
          columns={columns}
          //   rowKey={(record) => record.login.uuid}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div></>
  )
}


export default AcademiesCustomers