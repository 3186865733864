import { useState } from "react";

import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Badge,
    Button
} from '@mui/material';

import { useEffect } from "react";




  
  function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];
function RentCategory(){



	const [categories, setCategories] = useState()
	const [loading, setLoading] = useState()

	const API = process.env.REACT_APP_API

	useEffect(function () {
		async function fetchDate() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getRentCategories`)
				const data = await res.json()
				console.log("//////////")
				console.log(data)
				setCategories(data.categories)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchDate()
	}, [])



	return(

<>


(
	<TableContainer sx={{ width: "70%", margin: "0 auto", borderRadius: "10px" }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: '#e4e4e4', fontWeight: "bold", fontFamily:"Poppins" }} align="center">Category</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4', fontWeight: "bold", width: "30%", fontFamily:"Poppins" }} align="center">Status</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4', fontWeight: "bold", width: "30%", fontFamily:"Poppins" }} align="center">End Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories?.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell style={{ fontWeight: "bold", fontFamily:"Poppins" }} align="center">{row.name}</TableCell>
                            <TableCell align="center">
							<Badge
                                    color={row.status === "Rented" ? "error" : "success"}
                                    badgeContent={row.status}
                                    sx={{ cursor: "default" }}
                                />
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold", fontFamily:"Poppins"  }} align="center">{row.EndDate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
</>
	)
}


export default RentCategory