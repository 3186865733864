import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { data } from 'jquery';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Navbar from '../components/navbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import { ToastContainer, toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';



const API = process.env.REACT_APP_API


 
  

function EditAcademyPackages() {
	const [loading, setLoading] = React.useState()
	const [packageData, setPackageData] = React.useState([])
	const [open, setOpen] = React.useState(false);
	const [packagePrice, setPackagePrice] = React.useState() 
	const [packageName, setPackageName] = React.useState()
	const [packageCount, setPackageCount] = React.useState()
	const [selectedPackage, setSelectedPackage] = React.useState()
	const [render, setRender] = React.useState(false)


	const [deletePpen, setDeleteOpen] = React.useState(false);

	const handleClickOpenDelete = () => {
		setDeleteOpen(true);
	};
  
	const handleCloseDelete = () => {
		setDeleteOpen(false);
	};


const priceref = React.useRef()
const SessionsRef = React.useRef()
const packName = React.useRef()




function updatePackage(e) {
	e.preventDefault()
	fetch(`${API}/updateAcademyPackageInfo`, {
		method: 'POST',
		headers: {
		   'Content-Type': 'application/json',
		},
		body: JSON.stringify({
		   'packageID':selectedPackage ,
		   'sessionsCount': SessionsRef.current.value,
		   'Price': priceref.current.value,
		   "name" :packName.current.value
		   
		})
	}).then(response => response.json())
	.then(result=> {
		if (result.status === 200) {
			toast.success(result.Message, {
				position: "top-center",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			})
			handleClose()
			setRender(!render)
			
		}
	})
}


function deletePackage(e) {
	fetch(`${API}/deleteAcademyPackage`, {
		method: 'POST',
		headers: {
		   'Content-Type': 'application/json',
		},
		body: JSON.stringify({
		   'packageID':selectedPackage ,
		  
		})
	}).then(response => response.json())
	.then(result=> {
		if (result.status === 200) {
			toast.success(result.Message, {
				position: "top-center",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			})
			handleCloseDelete()
				setRender(!render)
			
		}
	})
}
	const handleClickOpen = (x, y, z,m) => {
		setPackageName(x)
		setPackageCount(y)
		setSelectedPackage(z)
		setPackagePrice(m)
		setOpen(true);
	  };

	  const ClickOpenDelete = (x, y) => {
		setPackageName(x)
		setSelectedPackage(y)
		handleClickOpenDelete()
		  };
	
	  const handleClose = () => {
		setOpen(false);
	  };
  
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
	  [`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	  },
	  [`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	  },
	}));
	
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
	  '&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	  },
	  // hide last border
	  '&:last-child td, &:last-child th': {
		border: 0,
	  },
	}));
	
	function createData(name, calories, fat, carbs, protein) {
	  return { name, calories, fat, carbs, protein };
	}
	
	const rows = [
	  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	  createData('Eclair', 262, 16.0, 24, 6.0),
	  createData('Cupcake', 305, 3.7, 67, 4.3),
	  createData('Gingerbread', 356, 16.0, 49, 3.9),
	];

	
React.useEffect(function(){
	async function fetchAcademyPackages() {
	try{
		setLoading(true)
		const res = await fetch(`${API}/getAcademyPackage`)
		const data = await res.json()
		console.log(data)
		setPackageData(data.data)
	} catch(e) {
		console.log(e)
	} finally{
		setLoading(false)

	}
	}fetchAcademyPackages()
}, [render])

console.log(packageData)
	return(
<>

<Dialog
        open={deletePpen}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure?"}
        </DialogTitle>
      
        <DialogActions>
          <Button onClick={handleCloseDelete}>cancel</Button>
          <Button onClick={deletePackage} autoFocus>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
<ToastContainer


                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
<Dialog
        open={open}
        onClose={handleClose}
       
      >
        <DialogTitle>{packageName +  " " +  packageCount}</DialogTitle>
		<IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
         
          <form  onSubmit={(e)=> updatePackage(e)} style={{width:"300px", height:"auto",backgroundColor:"white", padding:"10px", margin:"0 auto", border:"2px solid #e4e4e4"}}>
		  <input
            required
           
            fullWidth
            variant="standard"
            placeholder="Name"
            ref={packName}
          /> <br />

            <input
            required
           
            fullWidth
            variant="standard"
            placeholder="sessions count"
            ref={SessionsRef}
          /> <br />
              <input
            
            required
            margin="dense"
       
            fullWidth
            variant="standard"
            placeholder='Price'
            ref={priceref}
          /> <br />
          <Button  style={{backgroundColor:"#001833", color:"white", width:"200px", borderRadius:"15px", fontWeight:"bold"}}  type="submit">Submit</Button>
          </form>
        <DialogActions>
          
        </DialogActions>
      </Dialog>

<Navbar/>
		
<TableContainer style={{ width: "70%", margin: "100px auto" }} component={Paper}>
  <Table sx={{ minWidth: 700, width: "70%", borderTopLeftRadius: "10px" }} aria-label="customized table">
    <TableHead>
      <TableRow>
        <TableCell style={{ backgroundColor: "#e4e4e4", borderTopLeftRadius: "10px", fontWeight: "bold" }} align="center">Package ID</TableCell>
        <TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold" }} align="center">Package Name</TableCell>
        <TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold" }} align="center">Sessions Count</TableCell>
        <TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold" }} align="center">Price</TableCell>
        <TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold" }} align="center"></TableCell>
        <TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold" }} align="center"></TableCell>
      </TableRow>
    </TableHead>
    {loading ? (
      <p>Loading</p>
    ) : (
      <TableBody>
        {packageData.filter(a => a.price !== "0").map((row) => (
          <TableRow key={row.name}>
            <TableCell align="center">{row.academyPackageID}</TableCell>
            <TableCell align="center">{row.description}</TableCell>
            <TableCell align="center">{row.sessionsCount}</TableCell>
            <TableCell align="center">{row.price}</TableCell>
            <TableCell align="center">
              <Button
                 variant="contained"
				 sx={{ backgroundColor: '#23395d', borderRadius: "7px", color: 'white' }}
				 startIcon={<EditIcon sx={{ color: 'white' }} />}
                onClick={() => handleClickOpen(row.description, row.sessionsCount, row.academyPackageID, row.price)}>
                Edit
              </Button>
            </TableCell>
            <TableCell align="center">
              <Button
                 variant="contained"
				 sx={{ backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
				 startIcon={<DeleteIcon sx={{ color: 'white' }} />}
                onClick={() => ClickOpenDelete(row.description, row.academyPackageID)}>
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )}
  </Table>
</TableContainer>
</>
		
	)
}

export default EditAcademyPackages