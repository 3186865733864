import Sidenav from 'rsuite/Sidenav';
import Nav from 'rsuite/Nav';

// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/Sidenav/styles/index.css';
import 'rsuite/Nav/styles/index.css';
import 'rsuite/dist/rsuite.min.css';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import MagicIcon from '@rsuite/icons/legacy/Magic';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react'
import "../styles/searchBox.css"
import NavDropdownMenu from 'rsuite/esm/Nav/NavDropdownMenu';
import { Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Navbar from '../components/navbar';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TabList from '@mui/lab/TabList';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TabPanel from '@mui/lab/TabPanel';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from "antd";
import InputLabel from '@mui/material/InputLabel';

import Select from '@mui/material/Select';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HomeIcon from '@mui/icons-material/Home';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PaidIcon from '@mui/icons-material/Paid';
import PercentIcon from '@mui/icons-material/Percent';
import ApartmentIcon from '@mui/icons-material/Apartment';
const API = process.env.REACT_APP_API



function AvatarPreview({ img, avatarOpen, handleAvatarClose, avatar }) {
	const style = {
		position: 'absolute',
		display: "flex",
		alignItems: "stretch",
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		height: 400,
		bgcolor: 'none',
		border: 'none',
		boxShadow: 24,
		borderRadius: "50%",


		p: 4,
	};
	return (
		<Modal
			open={avatarOpen}
			onClose={handleAvatarClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<img style={{ position: "absloute", width: "100%", height: "100%", borderRadius: "50%" }} src={avatar} />
			</Box>
		</Modal>
	)
}

function ModalComponent({ handleClose,setReRender, reRender,  onChange, style, handleChange, handleContractChange, open, changedName, changedGender, changedBirthDate, changedDepartment, changedQualifications, changedHiringDate, changedPhone, changedSecPhone, changedMail, changedContractType, changedSalary, changedPercentage, changedAddress, changedNationalID, setChangedName,
	setChangedGender,setChangedContractID,
	setChangedBirthDate,
	setChangedDepartment
	, setChangedQualifications,
	setChangedHiringDate,
	setChangedPhone,
	setChangedSecPhone,
	setChangedMail,
	setChangedContractType
	, setChangedSalary,
	setChangedPercentage,
	setChangedAddress,
	setChangedNationalID,
	changedContractID
}) {



	const onHiringChange = (date, dateString) => {
		setChangedHiringDate(dateString)
	};

	const onBirthChange = (date, dateString) => {
		setChangedBirthDate(dateString)
	};

	function updateContractData(e) {
		e.preventDefault()
		fetch(`${API}/updateTrainersContracts`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({


				"changedName": changedName ,
				"changedGender" : changedGender,
				"changedBirthDate": changedBirthDate,
				"changedDepartment": changedDepartment,
				"changedQualifications": changedQualifications,
				"changedHiringDate": changedHiringDate,
				"changedPhone": changedPhone,
				'changedSecPhone': changedSecPhone,
				'changedMail': changedMail,
				'changedContractType': changedContractType,
				"changedSalary": changedSalary,
				'changedPercentage': changedPercentage,
				'changedAddress': changedAddress,
				'changedNationalID': changedNationalID ,
				"contractID":changedContractID

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						});
						setReRender(!reRender)
						handleClose()

				} else {
					toast.error("Something went wrong", {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						});
				}
			
			})
	}
	return (


		<Modal
		sx={{zIndex:"1000"}}
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={handleClose}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={open}>
				<FormControl onSubmit={(e)=>updateContractData(e)} component="form" sx={style}>
					<Typography id="transition-modal-title" variant="h6" component="h2">
						Edit Contract
					</Typography>
					<FormControl style={{ position: "ab" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div>
								<TextField
									value={changedName}
									onChange={(e) => setChangedName(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}}


									id="outlined-basic" label="Name" variant="outlined" />

								<TextField
									value={changedAddress}
									onChange={(e) => setChangedAddress(e.target.value)}
									sx={{


										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="Address" variant="outlined" />




								<TextField
									value={changedPhone}
									onChange={(e) => setChangedPhone(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="Phone" variant="outlined" />
								<TextField
									value={changedSecPhone}
									onChange={(e) => setChangedSecPhone(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="Second phone" variant="outlined" />


								<DatePicker   style={{ width: "300px",zIndex:"100000 !important", height: "50px", color: "black", margin: "7px" }} placeholder="Birth Date" onChange={onBirthChange} /><br />
								<TextField
									value={changedQualifications}
									onChange={(e) => setChangedQualifications(e.target.value)}

									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
												fontWeight: 'bold',

											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
												fontWeight: 'bold',

											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}}
									id="outlined-basic" label="Qualifications" variant="outlined" />

								<FormControl sx={{ width: "300px", margin: "7px" }}>
									<InputLabel id="demo-simple-select-label">Gender</InputLabel>
									<Select
										

										SelectDisplayProps={{ style: { color: 'black', fontWeight: "bold" }, }}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										// value={gender}
										label="Gender"
										onChange={(e)=> setChangedGender(e.target.value)}
									>
										<MenuItem value="Male">Male</MenuItem>
										<MenuItem value="Female">Female</MenuItem>
									</Select>
								</FormControl>





							</div>
							<div>

								<DatePicker  style={{ width: "300px", height: "50px", color: "black", margin: "7px" }} placeholder="Hiring Date" onChange={onHiringChange} />
								<TextField
									value={changedDepartment}
									onChange={(e) => setChangedDepartment(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="Department" variant="outlined" />


								<TextField
									value={changedMail}
									onChange={(e) => setChangedMail(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="Email" variant="outlined" />
								<TextField
									value={changedSalary}
									onChange={(e) => setChangedSalary(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="Salary" variant="outlined" />

								<TextField
									value={changedPercentage}
									onChange={(e) => setChangedPercentage(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="Percentage" variant="outlined" />
								<TextField
									value={changedNationalID}
									onChange={(e) => setChangedNationalID(e.target.value)}
									sx={{
										width: "300px", height: "50px", color: "black", margin: "7px",
										'& .MuiFormLabel-root': {
											color: 'black',
											'&.Mui-focused': {
												color: 'black',
											},
										},
										'& .MuiInputBase-input': {
											color: 'text.primary',
										},
										'& .MuiInputBase-input::placeholder': {
											color: 'text.secondary',
											fontWeight: 'bold',
										},
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'grey.400',
											},
											'&:hover fieldset': {
												borderColor: 'black',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'black',
											},
										},
									}} id="outlined-basic" label="National ID" variant="outlined" />

								<FormControl sx={{ width: "300px", margin: "7px" }}>
									<InputLabel id="demo-simple-select-label">Contract Type</InputLabel>
									<Select
										
										SelectDisplayProps={{ style: { color: 'black', fontWeight: "bold" }, }}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										// value={gender}
										label="Gender"
										onChange={(e)=> setChangedContractType(e.target.value)}
									>
										<MenuItem value="Salary">Salary</MenuItem>
										<MenuItem value="Percantege">Percantege</MenuItem>
										<MenuItem value="Salary and percantege">Salary and percantege </MenuItem>
									</Select>
								</FormControl>


							</div>
						</div>
					</FormControl>

					<button style={{ width: "250px", height: "40px", borderRadius: "15px" }} type='submit'>Confirm</button>

				</FormControl>
			</Fade>
		</Modal>
	)
}

function TrainerComponent({ d, setAvatar, openPdf, setPdfUrl,setReRender, reRender,
	setChangedContractID,

	setChangedName,
	setChangedGender,
	setChangedBirthDate,
	setChangedDepartment
	, setChangedQualifications,
	setChangedHiringDate,
	setChangedPhone,
	setChangedSecPhone,
	setChangedMail,
	setChangedContractType
	, setChangedSalary,
	setChangedPercentage,
	setChangedAddress,
	setChangedNationalID,
	handleOpen,
	handleAvatarOpen }) {


	function TerminatContract(e) {
		e.preventDefault()

		fetch(`${API}/deleteTrainersContracts`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'contractID': contractID,

			})
		}).then(response => response.json())
			.then(result =>{
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						});
						setReRender(!reRender)
					handleCloseDialog()
				}
			})
	}


	const options = [
		'Edit',
		'Delete',


	];
	const ITEM_HEIGHT = 48;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [contractID, setContractID] = useState()
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [dialogOpen, setDialogOpen] = React.useState(false);

	const handleClickOpen = () => {
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};
	function handleEditContract(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o) {
		setChangedName(a)
		setChangedGender(b)
		setChangedBirthDate(c)
		setChangedDepartment(d)
		setChangedQualifications(e)
		setChangedHiringDate(f)
		setChangedPhone(g)
		setChangedSecPhone(h)
		setChangedMail(i)
		setChangedContractType(j)
		setChangedSalary(k)
		setChangedPercentage(l)
		setChangedAddress(m)
		setChangedNationalID(n)
		setChangedContractID(o)
		handleOpen()



	}


	return (

		<>
			<Dialog
				open={dialogOpen}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Terminate Trainer Contract"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>cancel</Button>
					<Button onClick={(e) => TerminatContract(e)} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<Card sx={{ width: "600px", padding: "14px", margin: "0 auto", marginBottom: "10px" }} variant="outlined">
				<div>
					<IconButton
						aria-label="more"
						id="long-button"
						aria-controls={open ? 'long-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 4.5,
								width: '20ch',
							},
						}}
					>
						<MenuItem key={"Edit"} onClick={() => handleEditContract(d.name, d.gender, d.birthdate, d.department, d.qualifications, d.hiringDate, d.phone, d.secPhone, d.email, d.contractType, d.salary, d.percentage, d.address, d.nationalID, d.contractID)}>
							Edit
						</MenuItem>
						<MenuItem key={"Delete"} onClick={() => setContractID(d.contractID) & handleClickOpen()}>
							Delete
						</MenuItem>
					</Menu>
				</div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "0 auto", textAlign: "center", alignContent: "center", width: "350px" }}>
					<div style={{ textAlign: "center", alignContent: "center", }}>
						<img onClick={() => handleAvatarOpen() & setAvatar(d.avatar)} style={{ height: "100px", width: "100px", borderRadius: "50%", border: "1px solid #cce9ff" }} src={d.avatar} />

					</div>

					<div>

						<div style={{ display: "inline-block" }}>
							
							<div style={{ display: "flex", justifyContent: "start" }}><PersonIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.name}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><PhoneAndroidIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.phone}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><PhoneAndroidIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.secPhone}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><AccountBoxIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.nationalID}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><HomeIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.address}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><AlternateEmailIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.email}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><SchoolIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.qualifications}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><CalendarMonthIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.hiringDate}</p>					</div>


							<div style={{ display: "flex", justifyContent: "start" }}><ListAltIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.contractType}</p> 					</div>

							<div style={{ display: "flex", justifyContent: "start" }}><PaidIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.salary}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><PercentIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.percentage}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><ApartmentIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.department}</p>					</div>


						</div>
						<FileCopyIcon color='#59afff' sx={{ width: "40px", height: "40px", margin: "0 auto", borderRadius: "7px", padding: "5px" }} onClick={() => { setPdfUrl(d.pdf); openPdf() }} />

					</div>

				</div>
			</Card>
		</>




	)
}


function DeletedTrainerComponent({ d, setAvatar, setPdfUrl, openPdf,
	setChangedContractID,


	setChangedName,
	setChangedGender,
	setChangedBirthDate,
	setChangedDepartment
	, setChangedQualifications,
	setChangedHiringDate,
	setChangedPhone,
	setChangedSecPhone,
	setChangedMail,
	setChangedContractType
	, setChangedSalary,
	setChangedPercentage,
	setChangedAddress,
	setChangedNationalID,
	handleOpen,
	handleAvatarOpen }) {





	const options = [
		'Edit',
		'Delete',


	];
	const ITEM_HEIGHT = 48;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [contractID, setContractID] = useState()
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [dialogOpen, setDialogOpen] = React.useState(false);

	const handleClickOpen = () => {
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};
	function handleEditContract(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o) {
		setChangedName(a)
		setChangedGender(b)
		setChangedBirthDate(c)
		setChangedDepartment(d)
		setChangedQualifications(e)
		setChangedHiringDate(f)
		setChangedPhone(g)
		setChangedSecPhone(h)
		setChangedMail(i)
		setChangedContractType(j)
		setChangedSalary(k)
		setChangedPercentage(l)
		setChangedAddress(m)
		setChangedNationalID(n)
		setChangedContractID(o)
		handleOpen()



	}


	return (

		<>


			<Card sx={{ width: "600px", padding: "14px", margin: "0 auto", marginBottom: "10px", border: "1px solid #ffb3b2", backgroundColor: "#FFEFEA" }} variant="outlined">

				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "0 auto", textAlign: "center", alignContent: "center", width: "350px" }}>
					<div style={{ textAlign: "center", alignContent: "center", }}>
						<img onClick={() => handleAvatarOpen() & setAvatar(d.avatar)} style={{ height: "100px", width: "100px", borderRadius: "50%", border: "1px solid #ffb3b2" }} src={d.avatar} />

					</div>

					<div>

						<div style={{ display: "inline-block", marginTop: "50px" }}>
							<div style={{ display: "flex", justifyContent: "start" }}><PersonIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.name}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><PhoneAndroidIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.phone}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><PhoneAndroidIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.secPhone}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><AccountBoxIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.nationalID}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><HomeIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.address}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><AlternateEmailIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.email}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><SchoolIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.qualifications}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><CalendarMonthIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.hiringDate}</p>					</div>


							<div style={{ display: "flex", justifyContent: "start" }}><ListAltIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.contractType}</p> 					</div>

							<div style={{ display: "flex", justifyContent: "start" }}><PaidIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.salary}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><PercentIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.percentage}</p>					</div>
							<div style={{ display: "flex", justifyContent: "start" }}><ApartmentIcon />	<p style={{ width: "max-content", height: "max-content", fontWeight: "bold" }}>{d.department}</p>					</div>





						</div>
						<FileCopyIcon color='#59afff' sx={{ border: "1px solid  #FFEFEA", width: "40px", height: "40px", margin: "0 auto", borderRadius: "7px", padding: "5px" }} onClick={() => { setPdfUrl(d.pdf); openPdf() }} />


					</div>

				</div>
				<p style={{ fontWeight: "bold", margin: "0 auto" }}>Terminated in {d.termianationDate}</p>

			</Card>
		</>




	)
}



const styles = {
	width: 240,
	display: 'inline-table',
	marginRight: 10
};


function TrainersDataBase() {

	const [query, setQuery] = useState("")
	const [TQuery, setTQuery] = useState("")

	const [loading, setLoading] = useState()
	const [data, setData] = useState()
	const [value, setValue] = React.useState('1');
	const [open, setOpen] = React.useState(false);
	const [avatarOpen, setAvatarOpen] = React.useState(false);


	const handleAvatarOpen = () => setAvatarOpen(true);
	const handleAvatarClose = () => setAvatarOpen(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [birthdate, setBirthdate] = useState()
	const [hiringDate, sethHiringdate] = useState()
	const [contractType, setContractType] = useState()


	const [changedName, setChangedName] = useState()
	const [changedGender, setChangedGender] = useState()
	const [changedBirthDate, setChangedBirthDate] = useState()
	const [changedDepartment, setChangedDepartment] = useState()
	const [changedQualifications, setChangedQualifications] = useState()
	const [changedHiringDate, setChangedHiringDate] = useState()
	const [changedPhone, setChangedPhone] = useState()
	const [changedSecPhone, setChangedSecPhone] = useState()
	const [changedMail, setChangedMail] = useState()
	const [changedContractType, setChangedContractType] = useState()
	const [changedSalary, setChangedSalary] = useState()
	const [changedPercentage, setChangedPercentage] = useState()
	const [changedAddress, setChangedAddress] = useState()
	const [changedNationalID, setChangedNationalID] = useState()
	const [changedContractID, setChangedContractID] = useState()
	const [contractRerender, setContranctRender] = useState(false)

	const [avatar, setAvatar] = useState()
	/////

	const [pdfUrl, setPdfUrl] = useState();
	const [reRender, setReRender] = useState(false)

	const openPdf = () => {
		if (pdfUrl) {
			window.open(pdfUrl, '_blank');
		} else {
			console.error('PDF URL is not set');
		}
	};







	const handleContractChange = (e) => {
		setContractType(e.target.value);
	};


	// const onBirthChange = (date, dateString) => {
	// 	setBirthdate(dateString)
	// };

	const onHiringChange = (date, dateString) => {
		sethHiringdate(dateString)
	};

	const style = {
		borderRadius: "12px",
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: "auto",
		bgcolor: 'white',
		border: '2px solid #e4e4e4',
		boxShadow: 24,
		height: "auto",
		width: "auto",
		p: 4,
	};


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(function () {
		async function fetchData() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getTrainersContracts`)
				const data = await res.json()
				setData(data.data)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}

		} fetchData()
	}, [reRender])




	// const [data, setData] = useState(["a", "dsd", "a", "dsd","a", "dsd","a", "dsd","a", "dsd","a", "dsd","a", "dsd","a", "dsd","a", "dsd","a", "dsd"])

	const navigate = useNavigate()

	return (
		<div className="Trainers-page">
						<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>

			<Navbar />
			<AvatarPreview avatar={avatar} handleAvatarClose={handleAvatarClose} avatarOpen={avatarOpen} />

			<ModalComponent
			setReRender= {setReRender}
			reRender={reRender}
				setChangedName={setChangedName}
				setChangedGender={setChangedGender}
				setChangedBirthDate={setChangedBirthDate}
				setChangedDepartment={setChangedDepartment}
				setChangedQualifications={setChangedQualifications}
				setChangedHiringDate={setChangedHiringDate}
				setChangedPhone={setChangedPhone}
				setChangedSecPhone={setChangedSecPhone}
				setChangedMail={setChangedMail}
				setChangedContractType={setChangedContractType}
				setChangedSalary={setChangedSalary}
				setChangedPercentage={setChangedPercentage}
				setChangedAddress={setChangedAddress}
				setChangedNationalID={setChangedNationalID}
				setChangedContractID= {setChangedContractID}
				changedName={changedName} changedGender={changedGender} changedBirthDate={changedBirthDate} changedDepartment={changedDepartment} changedQualifications={changedQualifications} changedHiringDate={changedHiringDate} changedPhone={changedPhone} changedSecPhone={changedSecPhone} changedMail={changedMail} changedContractType={changedContractType} changedSalary={changedSalary} changedPercentage={changedPercentage} changedAddress={changedAddress} changedNationalID={changedNationalID} changedContractID={changedContractID}
				onChange={onchange} handleClose={handleClose} style={style} handleChange={handleChange}  handleContractChange={handleContractChange} open={open} />


			<div style={{ marginTop: "100px" }} className='trainers-data'>
				<Box sx={{ width: '60%', typography: 'body1', marginTop: "200px", margin: "0 auto" }}>
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider', margin: "0 auto" }}>
							<TabList sx={{ margin: "0 auto" }} onChange={handleChange} aria-label="lab API tabs example">
								<Tab sx={{ margin: "0 auto", fontWeight: "bold" }} label="Current Contracts" value="1" />
								<Tab sx={{ margin: "0 auto", fontWeight: "bold" }} label="Terminated Contracts" value="2" />
							</TabList>
						</Box>
						<TabPanel value="1">
							<div style={{ padding: "5px", margin: "20px auto", height: "35px", marginBottom: "10px", width: "200px", display: "flex", borderRadius: "20px", justifyContent: "space-between", border: "1px solid #cccccc", alignItems: "center" }} className='search-container'>
								<input className='search-input' onChange={(e) => { setQuery(e.target.value) }} style={{ border: "none", borderRadius: "20px", alignItems: "center", textAlign: "center", height: "30px", fontSize: "14px", width: "150px", marginBottom: "0", marginLeft: "0", marginRight: "0", backgroundColor: "none" }} placeholder="Search Name" />

								<span>  <SearchIcon sx={{ backgroundColor: "white", width: "25px", right: "0", height: "70%", color: "black", borderRadius: "20px" }} /></span>

							</div>

							{loading ? (<CircularProgress />) : (
								data?.filter(c => {
									return (
										c.status == "current"
									)
								}).filter(a => {
									return (
										a.name.toLowerCase().includes(query.toLowerCase())
									)
								}).map(d => {
									return (
										<TrainerComponent reRender={reRender} setReRender={setReRender} setPdfUrl={setPdfUrl} openPdf={openPdf} setAvatar={setAvatar} handleAvatarOpen={handleAvatarOpen} setChangedName={setChangedName} setChangedGender={setChangedGender} setChangedBirthDate={setChangedBirthDate} setChangedDepartment={setChangedDepartment} setChangedQualifications={setChangedQualifications} setChangedHiringDate={setChangedHiringDate} setChangedPhone={setChangedPhone} setChangedSecPhone={setChangedSecPhone} setChangedMail={setChangedMail} setChangedContractType={setChangedContractType} setChangedSalary={setChangedSalary} setChangedPercentage={setChangedPercentage} setChangedAddress={setChangedAddress} setChangedNationalID={setChangedNationalID} handleOpen={handleOpen} d={d} setChangedContractID={setChangedContractID} />
									)

								})
							)}
						</TabPanel>
						<TabPanel value="2">
							<div style={{ padding: "5px", margin: "20px auto", height: "35px", marginBottom: "10px", width: "200px", display: "flex", borderRadius: "20px", justifyContent: "space-between", border: "1px solid #cccccc", alignItems: "center" }} className='search-container'>
								<input className='search-input' onChange={(e) => { setTQuery(e.target.value) }} style={{ border: "none", borderRadius: "20px", alignItems: "center", textAlign: "center", height: "30px", fontSize: "14px", width: "150px", marginBottom: "0", marginLeft: "0", marginRight: "0", backgroundColor: "none" }} placeholder="Search Name" />

								<span>  <SearchIcon sx={{ backgroundColor: "white", width: "25px", right: "0", height: "70%", color: "black", borderRadius: "20px" }} /></span>

							</div>
							{loading ? (<CircularProgress />) : (
								data?.filter(t => {
									return (
										t.name.toLowerCase().includes(TQuery.toLowerCase())
									)
								}).filter(c => {
									return (
										c.status == "terminated"
									)
								}).map(d => {
									return (
										<DeletedTrainerComponent openPdf={openPdf} setPdfUrl={setPdfUrl} setAvatar={setAvatar} handleAvatarOpen={handleAvatarOpen} setChangedName={setChangedName} setChangedGender={setChangedGender} setChangedBirthDate={setChangedBirthDate} setChangedDepartment={setChangedDepartment} setChangedQualifications={setChangedQualifications} setChangedHiringDate={setChangedHiringDate} setChangedPhone={setChangedPhone} setChangedSecPhone={setChangedSecPhone} setChangedMail={setChangedMail} setChangedContractType={setChangedContractType} setChangedSalary={setChangedSalary} setChangedPercentage={setChangedPercentage} setChangedAddress={setChangedAddress} setChangedNationalID={setChangedNationalID} handleOpen={handleOpen} d={d} />
									)

								})
							)}

						</TabPanel>
					</TabContext>
				</Box>


			</div>




		</div>
	)
}

export default TrainersDataBase