import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// Utility to check if the token has expired
const isAuthenticated = () => localStorage.getItem('Token') !== null;

const isTokenExpired = () => {
  const expiryTime = localStorage.getItem('tokenExpiry'); 
  return expiryTime && !isNaN(expiryTime) && new Date().getTime() > parseInt(expiryTime, 10) && isAuthenticated();
};

const logoutChannel = new BroadcastChannel('logout');

const AuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to check if the token has expired
    const checkAuth = () => {
      if (isTokenExpired()) {
        logoutChannel.postMessage('Logout');
        localStorage.clear();
        navigate('/login');
      }
    };

    // Get the stored expiry timestamp
    const expiryTime = localStorage.getItem('tokenExpiry');
    if (expiryTime && !isNaN(expiryTime)) {
      const timeLeft = parseInt(expiryTime, 10) - new Date().getTime();
      console.log(timeLeft);

      if (timeLeft > 0) {
        // If timeLeft is positive, set a timeout to check when the token expires
        const timeoutId = setTimeout(checkAuth, timeLeft);
        return () => clearTimeout(timeoutId);
      } else {
        // Token has already expired, perform the logout immediately
        checkAuth();
      }
    } else {
      // No expiry time set, user is logged out (or token doesn't exist)
      checkAuth();
    }

  }, [navigate]);  // Ensure navigate is part of the dependencies

  return null; // This component does not render anything
};

export default AuthCheck;
