

import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { useEffect, useState, useCallback } from 'react';
import Navbar from '../components/navbar';
import debounce from "lodash/debounce";
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';






export default function AllCustomersPage() {
  const API = process.env.REACT_APP_API
  const [loading, setLoading] = useState()
  const [custonersData, setCustomersData] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [paginationCount, setPAginationCount] = useState()
  const [query, setQuery] = useState("")

  const navigate = useNavigate()


  const lazyLog = useCallback(
    debounce(async (query, currentPage) => {
      try {
        setLoading(true)
        const res = await fetch(`${API}/getAllCustomers?page=${currentPage}&customer=${query}`)
        const data = await res.json()
        setCustomersData(data.customers)
        setPAginationCount(data.count)


      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }

    }, 1000),
    []
  );



  useEffect(function () {
    lazyLog(query, currentPage)

  }, [currentPage, query])




  // async function fetchCustomerData() {
  //   try {
  //     setLoading(true)
  //     const res = await fetch(`${API}/getAllCustomers?customerID=${query}`)
  //     const data = await res.json()
  //     setCustomersData(data.customers)
  //     setPAginationCount(data.count)


  //   } catch (e) {
  //     console.log(e)
  //   }  finally {
  //     setLoading(false)
  //   }

  // }


  return (


    <Box>
    <Navbar />
  
    {/* Search Bar Container */}
 <Box marginTop="100px">
 <Box
      sx={{
        padding: "5px",
        margin: "20px auto",
        height: "35px",
        marginBottom: "10px",
        width: "200px",
        display: "flex",
        borderRadius: "20px",
        justifyContent: "space-between",
        border: "1px solid #cccccc",
        alignItems: "center",
        '@media (max-width: 600px)': {
          width: '100%', // Full width on small screens
          margin: '10px 20px', // Adjust margin
        },
      }}
      className='search-container'
    >
      <input
        className='search-input'
        onChange={(e) => { setQuery(e.target.value) }}
        style={{
          border: "none",
          borderRadius: "20px",
          alignItems: "center",
          textAlign: "center",
          height: "30px",
          fontSize: "14px",
          width: "150px",
          backgroundColor: "none",
          '@media (max-width: 600px)': {
            width: '100%', // Full width for input on small screens
          }
        }}
        placeholder="Search Name"
      />
  
      <span>
        <SearchIcon
          sx={{
            backgroundColor: "white",
            width: "25px",
            height: "70%",
            color: "black",
            borderRadius: "20px",
          }}
        />
      </span>
    </Box>
  
    {/* Table Container */}
    <Box>
      <TableContainer >
        <Table
          sx={{
            minWidth: "70%",
            width: "70%",
            margin: "auto",
             borderTopLeftRadius:"10px",
             borderTopRightRadius:"10px",
            borderRadius: "10px",
            border: "2px solid #e4e4e4",
            '@media (max-width: 600px)': {
              width: '100%', // Full width on mobile
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {/* Table Headers */}
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  borderTopLeftRadius:"10px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px', // Smaller font size on mobile
                    padding: '8px', // Reduced padding for small screens
                  },
                }}
                align="center"
              >
                Customer ID
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Customer Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Address
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                National ID
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Work
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Gender
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Phone
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Second Phone
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Registration Date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  backgroundColor: "#e4e4e4",
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
                align="center"
              >
                Registration Branch
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {custonersData?.map((user) => (
              <TableRow key={user.realName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <Link to={`/profile/${user.customerID}`}>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">{user.customerID}</TableCell>
                </Link>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }}>{user.Fullname}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.Address}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.NationalID}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.work}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.Gender}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.Phone}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.secondPhone}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.RegistrationDate}</TableCell>
                <TableCell sx={{ fontFamily: "poppins", fontWeight: "bold" }} align="center">{user.branch}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  
    {/* Pagination */}
    <Pagination
      onChange={(e, value) => setCurrentPage(value - 1)}
      sx={{
        margin: "10px auto",
        width: "400px",
        '@media (max-width: 600px)': {
          width: '100%', // Full width for pagination on small screens
        },
      }}
      count={paginationCount}
          />

 </Box>
  </Box>
  


  );
}

