import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';

import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: 'bold',
	fontFamily: 'Poppins',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	// Add any additional styling if needed
}));



const API = process.env.REACT_APP_API

function ClassTrainerFinance() {
	const [filtered, setFiltered] = useState("All")

	const [loading, setLoading] = useState(true)
	const [financeDage, setFinanceData] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainer, setTrainer] = useState()
	const [trainersLoading, setTrainersLoading] = useState()
	const [trainerName, settrainerName] = useState()
	const [classes, setClasses] = useState()
	const [classLoading, setClassLoading] = useState()
	const [className, setClassName] = useState()


	let total = []

	function onChange(date, dateString) {
		console.log(dateString)
		setDate(dateString)
		console.log(startDate)
		setFiltered("filtered")

	}




	function onEndDatehange(date, dateString) {
		console.log(dateString)
		setEnDate(dateString)
		console.log(endDate)
		setFiltered("filtered")


	}







	const rows = [
		createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
		createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
		createData('Eclair', 262, 16.0, 24, 6.0),
		createData('Cupcake', 305, 3.7, 67, 4.3),
		createData('Gingerbread', 356, 16.0, 49, 3.9),
	];


	function createData(name, calories, fat, carbs, protein) {
		return { name, calories, fat, carbs, protein };
	}

	async function handleFilter() {
		try {
			setLoading(true)
			const res = await fetch(`${API}/AllclassAttendance?startDate=${startDate}&endDate=${endDate}&trainer=${trainerName}`)
			const data = await res.json()
			setFinanceData(data.result)
		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)


		}
	}
	useEffect(function () {
		async function fetchDate() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/AllclassAttendance?startDate=${startDate}&endDate=${endDate}&trainer=${trainerName}`)
				const data = await res.json()
				console.log(data.result)
				setFinanceData(data.result)

			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchDate()
	}, [rednder])


	useEffect(function () {
		async function getTrainers() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
				console.log(data.result)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getTrainers()

	}, [])


	useEffect(function () {
		async function getClasses() {
			try {
				setClassLoading(true)
				const res = await fetch(`${API}/getAllClasses`)
				const data = await res.json()
				setClasses(data.result)
				console.log(data.result)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setClassLoading(false)
			}
		} getClasses()

	}, [])

	var dateFrom = "01/03/2024";
	var dateTo = "12/03/2024";
	var dateCheck = "05/09/2017";

	var d1 = dateFrom.split("/");
	var d2 = dateTo.split("/");
	var c = dateCheck.split("/");

	var from = new Date(d1);  // -1 because months are from 0 to 11
	var to = new Date(d2);
	var check = new Date(c);






	return (

		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">

<h3 style={{ margin: "0 auto", width: "350px", }}>Class Trainers Finance</h3>

			<Container

				sx={{
					padding: '20px',
					borderRadius: '8px',
					border: "2px solid #e4e4e4",

					marginBottom: "20px",


				}}
			>

				<Container
					sx={{
						padding: '20px',
						borderRadius: '8px',
						backgroundColor: "white",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						marginBottom: "20px",


					}}
				>



						<div style={{ display: "block", justifyContent: "space-evenly", margin: "0 auto", alignItems: "center", textAlign: "center" }}>
							<div style={{ borderRadius: "8px", width: "40%", margin: "20px auto", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
								<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
								<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

							</div>
							<select onChange={(e) => settrainerName(e.target.value)} style={{
								height: "40px",
								border: "none",
								textAlign: "center",
								alignItems: "center",
								marginTop: "10px", // Add space below the label
								backgroundColor: "#eaedf4",
								marginBottom:"15px",
								borderRadius: "8px",
							}} name="" id="">

								<option value="" disabled selected>Trainer</option>
								{trainer?.map(t => {
									return (
										<option value={t.fullName}>{t.fullName}</option>
									)
								})}
							</select> <br />
							<Button
								variant="contained"
								onClick={handleFilter}
								startIcon={<SearchIcon style={{ color: "black" }} />}
								sx={{
									":hover": {
										backgroundColor: '#eaedf4',

										boxShadow: 'none',

									},
									backgroundColor: "#d7d9e9",

									color: 'black',
									borderRadius: '6px',
									fontWeight: "bold",
									margin: "auto",
									width: "150px",
									fontWeight: "Bold",
									boxShadow: 'none',
								}}
							>
								Search
							</Button>
						</div>








					{loading ? (<Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
						<CircularProgress />
					</Box>) : (<>

						<TableContainer
							sx={{ width: '70%', margin: '15px auto', position: 'relative' }}
							component={Paper}
						>
							<Table sx={{ minWidth: 700 }} aria-label="customized table">
								<TableHead sx={{ backgroundColor: '#e4e4e4' }}>
									<TableRow>
										<StyledTableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Poppins, sans-serif' }}>
											Class
										</StyledTableCell>
										<StyledTableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Poppins, sans-serif' }}>
											Date
										</StyledTableCell>
										<StyledTableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Poppins, sans-serif' }}>
											Trainer
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{financeDage.filter((value, index, self) =>
										self.findIndex(v => v.AttendanceDate.split(" ")[0] === value.AttendanceDate.split(" ")[0] && v.Trainer === value.Trainer) === index
									).map((row) => (
										<StyledTableRow key={row.name}>
											<StyledTableCell align="center">{row.className}</StyledTableCell>
											<StyledTableCell align="center">{row.AttendanceDate.split(" ")[0]}</StyledTableCell>
											<StyledTableCell align="center">{row.Trainer}</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

					</>


					)




					}
				</Container>


			</Container>
		</div>
	)

}

export default ClassTrainerFinance