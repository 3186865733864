import Navbar from "../components/navbar"
import { Link } from "react-router-dom"
function TrainersDashboad() {
	<Navbar/>
	function IconComponent({m}) {
		return (
			<div className="icons-card">
				<img style={{height:"40px"}}  src= {m.icon} />
				<label style={{fontWeight:"bold", fontSize:"15px",borderBottomStyle:"none !important", textDecoration:"none",color:"black", whiteSpace: "nowrap",  overflow: "hidden",marginTop:"6px", marginLeft:"10px"}}> {m.name}</label>
			</div>
		)
	}
	return (
<div style={{width:"100%", height:"100%", position:"absolute", backgroundColor:"#f0f7f7"}}>
	<Navbar/>
<div style={{display:"flex",top:"50%", left:"50%",transform:"translate(-50%, -50%)", position:"absolute" }}>

<Link to="/trainer-contract">	<IconComponent   m =  {{icon: "branch.png", name: "Add Contract"}} />
</Link>	
<Link to="/trainers">	<IconComponent   m =  {{icon: "user.png", name: "Show Contracts"}} />
</Link>		

	</div>
</div>

	)
}


export default TrainersDashboad