import { useState } from "react"
import "../styles/AddAcademyPackage.css"
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../components/navbar";
import { useNavigate } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { Slider } from 'antd';

function AddAcademyPackage() {
    const [sessionsCount, setSessionsCount] = useState()
    const [Period, setPeriod] = useState()
    const [Price, setPrice] = useState()
    const [Discount, setDiscount] = useState()
    const [Describtion, setDescribtion] = useState()
    const [PersonalTrainer, setPersonalTrainer] = useState()
    const [inbody, setInbody] = useState(false)
    const [extraClass, setExtraClass] = useState(false)
    const [ageRange, setAgeRange] = useState()
    const API = process.env.REACT_APP_API
    const navigate = useNavigate()




    function handleAddAcademyPackage(e) {
        e.preventDefault()
        fetch(`${API}/AddGymPackages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'sessionsCount': sessionsCount,
                'Period': Period,
                'Discount': Discount,
                'PersonalTrainer': PersonalTrainer,
                'PackageName': Describtion,
                "Price": Price,
                "inbody":inbody,
                "classInvite":extraClass,
                "ageRange": ageRange
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        navigate("/customers")
                    }, "2000");
                }
                else if (result.status === 404) {
                    toast.error(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });


                }
            })
    }








    return (

        <>
            <Navbar />
            <ToastContainer
                autoClose={false}
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="add-gym-package">
                <form style={{ margin: "100px auto", height: "700px", padding: "10px" }} onSubmit={(e) => handleAddAcademyPackage(e)} type="submit" >
                    <h3>Add Gym package</h3>
                    <input required onChange={(e) => setDescribtion(e.target.value)} type="text" placeholder="Name" /> <br />


                    <input required onChange={(e) => setSessionsCount(e.target.value)} type="text" placeholder="Sessions count" /> <br />
                    <input required onChange={(e) => setPeriod(e.target.value)} type="text" placeholder="Period" /> <br />

                    <input required onChange={(e) => setPrice(e.target.value)} type="text" placeholder="Price" /> <br />
                    <input required onChange={(e) => setDiscount(e.target.value)} type="text" placeholder="Discount" /> <br />
                <div style={{backgroundColor:"#e4e4e4", alignItems:"cemter", textAlign:"cemter", borderRadius:"10px", width:"70%", margin:"0 auto", padding:"10px"}}>
                <p style={{fontWeight:"bold"}}>Age Range</p>
                    <Slider
                        range={{
                            draggableTrack: true,
                        }}
                        defaultValue={[20, 50]}
                        style={{ width: "70%", margin: "0 auto" }}
                        onAfterChange={(value) => setAgeRange(value)}
                    />
                </div>
                    <div style={{ display: "inline-block", margin: "0 auto", width: "70%" }}>
                        <FormControlLabel sx={{fontWeight:"bold", width: "auto", margin: "0 auto" }} checked={inbody} onChange={(e) => setInbody(!inbody)} control={<Checkbox  sx={{color:"black"}}  />} label="Inbody" /> <br />
                        <FormControlLabel sx={{ width: "auto", margin: "0 auto" }} checked={extraClass}  onChange={(e) => setExtraClass (!extraClass)} control={<Checkbox  />} label="Class Invitation" />


                    </div>
              
                    <button style={{ width: "50%", height: "34px", borderRadius: "14px", marginTop:"20px" }}>Add</button>

                </form>
            </div>
        </>

    )
}


export default AddAcademyPackage