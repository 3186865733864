import { useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const API = process.env.REACT_APP_API

function Rent() {
	const [ name, setName] = useState("")
	const [ price, setPrice ] = useState("")
	function handleAddCategory(e) {
		e.preventDefault()
		fetch(`${API}/addRentCategory`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'name': name,
				// 'price': price,
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
				}
			})
	}

	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<form onSubmit={(e) => handleAddCategory(e)} style={{ margin: "0 auto", height: "200px", padding: "30px", backgroundColor: "white", border: "1px solid #e4e4e4" }}>
				<input required onChange={(e) => setName(e.target.value)} type="text" placeholder="Rent category"></input>
				{/* <input onChange={(e) => setPrice(e.target.value)} type="text" placeholder="Price"></input> */}
				<button>Add</button>
			</form>
		</>
	)
}

export default Rent