import "../../styles/classStyles/class.css"

import { React, useEffect, useState } from 'react';

import Navbar from "../../components/navbar";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { IoCloseCircle } from "react-icons/io5";
import '../../styles/academiesPage.css'
import ClassNav from "../../components/classNav";
import qs from 'qs';
import moment from 'moment-timezone';
import { Divider, Table } from 'antd';
import CardContent from '@mui/joy/CardContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Tabs, Tab } from '@mui/material';

const API = process.env.REACT_APP_API




//Parent component
function AllClassesPage() {
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [classPackageData, setClassPackageData] = useState([])
    const [classPackageID, setClassPackageID] = useState()
    const [PackageSideNavVisibility, setPackageSideNavVisibility] = useState(false)
    const [customerSideNavVisibility, setCustomerSideNavVisibility] = useState()
    const [registeringCustomerID, setRegisteringCustomerID] = useState()
    const [packageCount, setPackageCount] = useState()
    const [discount, setDiscount] = useState()
    const [price, setPrice] = useState()
    const [branch, setBranch] = useState()
    const navigate = useNavigate()
    const [customersData, setcustomersData] = useState()
    const [query, setQuery] = useState("")
    const todayDate = moment().tz("Africa/Cairo").format("YYYY-MM-DD hh:mm:ss").split(" ")
    const today = todayDate[0]
    console.log(today)
    const [filteredDays, setFilteredDays] = useState()
    const [filter, setFilted] = useState(false)
    const [count, setCount] = useState()

    const [activeTab, setActiveTab] = useState('active'); 
    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,  // Reset pagination to the first page
          },
        });
      };
    
    const getRandomuserParams = (params) => ({
        results: params.pagination?.pageSize,
        page: params.pagination?.current,
        ...params,
        status:activeTab
    });



    const [data, setData] = useState([]);

    const fetchData = () => {
        setLoading(true);
        fetch(`${API}/getAllClassesSubscribers?${qs.stringify(getRandomuserParams(tableParams))}`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                setData(data.data);
                setCount(data.totalCount)
                setLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: data.totalCount,
                    },
                });
            });
    };
    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
        activeTab
    ]);
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };


    const columns = [
        {
            title: 'customerID',
            dataIndex: 'customerID',
            align: "center"
        },
        {
            title: 'Name',
            dataIndex: 'Fullname',
            align: "center"

        },
        {
            title: 'Used sessions',
            dataIndex: 'UsedSessions',
            align: "center"

        },
        {
            title: 'Remaining sessions',
            dataIndex: 'RemainingSessions',
            align: "center"

        },

        {
            title: 'Start date',
            dataIndex: 'StartDate',
            align: "center"

        },
        {
            title: 'EndDate',
            dataIndex: 'EndDate',
            align: "center"

        },
        {
            title: 'Last Check in',
            dataIndex: 'LastCheck',
            align: "center"

        },
    ];


    function filterToday() {
        const result = customersData?.filter(a => {
            return (
                a.LastCheck?.split(" ")[0] == today
            )
        })
        console.log(result)
        setFilteredDays(result)
        setFilted(true)

    }

    // useEffect(() => {
    //     fetch(`${API}/getAllClassesSubscribers`)
    //         .then(response => response.json())
    //         .then(data => setcustomersData(data.result))
    //         .catch(error => console.error(error))

    // }, [])






    return (


        <div className="classpage-container">
            <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Filter Tabs"
        centered
        sx={{
          marginBottom: 2,
          '& .MuiTab-root': { fontWeight: 'bold', fontFamily: 'Poppins' },
        }}
      >
        <Tab label="Active" value="active" />
        <Tab label="Inactive" value="inactive" />
        <Tab label="Archived" value="archived" />
      </Tabs>
      <Card
            sx={{
              boxShadow: 1,
              margin: "20px auto",
              borderRadius: "10px",
              width: "fit-content",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              color: "black",
              height: "60px",
            }}
            variant="outlined"
          >
          <CardContent
  sx={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <Typography level="title-md" textColor="inherit">
    <AccountCircleIcon
      sx={{
        fill: activeTab === "active" ? "#1b874b" : activeTab === "inactive" ? "red" : "gray", // Change color based on activeTab
      }}
      fontSize="large"
    />
  </Typography>
  <label
    style={{
      color: activeTab === "active" ? "#1b874b" : activeTab === "inactive" ? "red" : "gray", // Change color based on activeTab
      fontSize: "20px",
      fontFamily: "poppins",
      fontWeight: "bold",
    }}
  >
    {count} {activeTab} subscriptions
  </label>
</CardContent>

          </Card>


            <Table
                style={{ width: "70%", margin: "20px auto", borderCollapse:"collapse" }}
                columns={columns}
                //   rowKey={(record) => record.login.uuid}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            {/* 
            <table id="customers">

                <tr>

                    <th>Customer ID</th>
                    <th>Family</th>
                    <th>EndDate</th>
                    <th>Remaining sessions</th>
                    <th>Used sessions</th>
                    <th>Last Check in</th>


                </tr>
                {customersData?.length == 0 && <div className='no-data'>
                    <p>No data found</p>
                </div>}
                <>
                    <ToastContainer
                        autoClose={false}
                        position="top-center"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />

                    {filter?(filteredDays?.filter(f => {
                        return f.CustomerID.toString().includes(query)
                    }).map(c => {
                        return (
                            <tr>
                                <td>{c.CustomerID}</td>
                                <td>{c.family}</td>

                                <td>{c.EndDate}</td>
                                <td>{c.RemainingSessions}</td>
                                <td>{c.UsedSessions}</td>
                                <td>{c.LastCheck }</td>
                            </tr>
                        )
                    })):(customersData?.filter(f => {
                        return f.CustomerID.toString().includes(query)
                    }).map(c => {
                        return (
                            <tr>
                                <td>{c.CustomerID}</td>
                                <td>{c.family}</td>

                                <td>{c.EndDate}</td>
                                <td>{c.RemainingSessions}</td>
                                <td>{c.UsedSessions}</td>
                                <td>{c.LastCheck }</td>
                            </tr>
                        )
                    }))}

                </>
            </table> */}
        </div>
    )







}






export default AllClassesPage