import { useState, useEffect, useRef, useReducer } from 'react'
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "../../styles/customerProfile.css"
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaidIcon from '@mui/icons-material/Paid';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import moment from 'moment-timezone';


import ReturnToCustomerAcademy from './returnToCustomerModalAcademy';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DialogContentText from '@mui/material/DialogContentText';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Divider from '@mui/joy/Divider';
import ClearIcon from '@mui/icons-material/Clear';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {

	FormControl,
	InputLabel,
	Select,
	MenuItem,

} from '@mui/material';


const API = process.env.REACT_APP_API


function AcademyTable({ isActive, hideShowDiv, customerID }) {
	const loggedBranch = localStorage.getItem("loggedBranch");
	const cancelCause = useRef()


	const [customerAcademyData, setCustomerAcademyData] = useState()
	const [loading, setLoading] = useState()
	const [academiesData, setAcademiesData] = useState([])
	const [academiesLoading, setAcadmiesLoading] = useState()
	const [discount, setDiscount] = useState("0")
	const [academyDesc, setAcademyDesc] = useState([])
	const params = useParams()
	const [searchParams] = useSearchParams();
	const [open, setOpen] = React.useState(false);
	const [RemainsGracePeriod, setGracePeriod] = useState("0")
	const [academyPackageID, setAcademyPackageID] = useState()
	const [changeAcademyID, setChangeAcademyID] = useState()
	const days_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
	const [paid, setPaid] = useState()
	const [checked, setChecked] = React.useState(false);
	const [debtValue, setDebtValue] = useState()
	const [debtOpen, setDebtOpen] = React.useState(false);
	const [subscriptionID, setSubscriptionID] = useState()
	const [value, setValue] = useState()
	const [render, rerender] = useState(value)
	const [subID, setSubID] = useState()
	const [subStatus, setSubStatus] = useState()
	const [activationOpen, setActivationOpen] = React.useState(false);
	const [archived, setArchived] = useState()
	const [activeAcademyID, setActiveAcadmyID] = useState()
	const [remainingSessions, setRemaininsSessions] = useState()
	const [deleteSub, setDeleteSub] = React.useState(false);
	const [increaseSub, setIncreaseSub] = useState(false)
	const [buttonLoading, setButtonLoading] = useState()
	const [invtiationOpen, setInvitationOpen] = React.useState(false);
	const invitedCustomerName = useRef()
	const invitedCustomerPhone = useRef()
	const [btnLoading, setBtnLoading] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))

	const employee = localStorage.getItem("User")

	const [Role, setRole] = useState()


	const [openReturnCustomerAcademy, setOpenRetrunCustomerAcademy] = React.useState(false);
	const handleOpenRetrunCustomerAcademy = () => setOpenRetrunCustomerAcademy(true);
	const handleCloseReturnCustomerAcademy = () => setOpenRetrunCustomerAcademy(false);
    const [remainingValue, setRemainingValue] = useState()

	// purge class sub 

	const [openPurge, setOpenPurge] = useState(false);
	function PurgeConfirmation() {
		return (
			<Modal open={openPurge} onClose={() => setOpenPurge(false)}>
				<ModalDialog variant="outlined" role="alertdialog">
					<DialogTitle sx={{ padding: "0", fontWeight: "bold" }}>
						<WarningRoundedIcon />
						Confirmation
					</DialogTitle>
					<Divider />
					<DialogContent sx={{ padding: "0" }}>
						Are you sure you want to entirely delete the subscription?
					</DialogContent>
					<DialogActions>

						<Button variant="plain" color="neutral" onClick={() => setOpenPurge(false)}>
							Cancel
						</Button>

						<Button variant="solid" sx={{
							backgroundColor: '#C41C1C',
							color: "white",
							":hover": {
								backgroundColor: '#C41C1C',
								color: "white",
							}
						}} onClick={() => handleSubPurge()}>
							Delete
						</Button>
					</DialogActions>
				</ModalDialog>
			</Modal>
		)
	}

	function handleSubPurge() {
		fetch(`${API}/purgeAcademySub`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({

				"subID": subID,
			})
		}).then(response => response.json())
			.then(result => {
				console.log(result)
				if (result.status == "200") {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					setOpenPurge(false)


				} else {
					toast.error(`Something went wrong`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

					setOpenPurge(false)

				}
			})

	}



	const formik = useFormik({
		initialValues: {
			academyPackageID: '',
			discount: 0,
			paid: '',
			graceDays: '',
		},
		validationSchema: Yup.object({
			academyPackageID: Yup.string().required('Required'),
			discount: Yup.number().nullable(),
			paid: Yup.number().nullable(),
			gracePeriod: Yup.number().nullable(),
		}),
		onSubmit: (values) => {
			if (values.paid !== "" && !values.graceDays) {
				formik.setErrors({ graceDays: "Grace Days is required" });
				return; // Stop submission
			}
			handleAddCustomerToAcademy(values);
		},
	});
	useEffect(() => {
		const items = localStorage.getItem('Roles')
		if (items) {
			setRole(items);
		}
	}, []);




	const academyPackageRef = useRef()
	const PayDebtValue = useRef()
	const paybackvalue = useRef()
	const sessionsRef = useRef()
	const discountRef = useRef()

	const daysRef = useRef()



	const handleDebtOpen = () => {
		setDebtOpen(true);
	};
	const handleDebtClose = () => {
		setDebtOpen(false);
	};


	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenActivation = () => {
		setActivationOpen(true);
	};

	const handleCloseActivation = () => {
		setActivationOpen(false);
	};
	const handleClickOpenArchive = () => {
		setArchived(true);
	};

	const handleCloseArchive = () => {
		setArchived(false);
	};


	function subStatusAndIDCancel(x, y) {
		setSubStatus(x)
		setSubID(y)


	}



	const handleDeleteSubOpen = () => {
		setDeleteSub(true);
	};

	const handleDeleteSubClose = () => {
		setDeleteSub(false);
	};



	const handleIncreaseSubOpen = () => {
		setIncreaseSub(true);
	};

	const handleIncreaseSubClose = () => {
		setIncreaseSub(false);
	};

	const handleInvitationOpen = () => {
		setInvitationOpen(true);
	};
	const handleInvitationClose = () => {
		setInvitationOpen(false);
	};

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		'& .MuiDialogContent-root': {
			padding: theme.spacing(2),
		},
		'& .MuiDialogActions-root': {
			padding: theme.spacing(1),
		},
	}));


	function subStatusAndID(x, y) {
		setSubStatus(x)
		setSubID(y)
		handleClickOpenActivation()


	}
	function subStatusAndIDArchive(x, y) {
		setSubStatus(x)
		setSubID(y)
		handleClickOpenArchive()


	}

	function handleCustomerInvitation(e) {
		e.preventDefault()
		fetch(`${API}/inviteCustomerAcademy`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'subID': subID,
				'invitedName': invitedCustomerName.current.value,
				'invitedPhone': invitedCustomerPhone.current.value,
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})


					handleInvitationClose()
					rerender(!render)


				} else {
					toast.error(`Internal server error`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}


			})
	}


	function handleIncreaseGymQuota(e) {
		e.preventDefault()
		fetch(`${API}/increaseCustomerAcQuota`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'sessions': sessionsRef.current.value,
				'days': daysRef.current.value,
				"subID": subID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						autoClose: false
					})
					handleIncreaseSubClose()

					rerender(!render)




				} else {
					toast.error(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						autoClose: false
					})


				}

			})

	}


	// pay debt function

	function PayAcademyDept(e) {
		e.preventDefault()
		fetch(`${API}/payAcademyDebt`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'debtValue': PayDebtValue.current.value,
				"subscriptionID": subscriptionID,
				"branch": loggedBranch
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					setButtonLoading(false)

					rerender(!render)
					handleDebtClose()

				} else {

					toast.error(`Internal server error`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}


			})

	}
	function handleAddCustomerToAcademy(values) {

		fetch(`${API}/addCustomerToAcademy`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				// "AcademyID": values.academyID,
				"academyPackageID": values.academyPackageID,
				"CustomerID": customerID,
				"discount": values.discount,
				"debtValue": debtValue,
				"paid": values.paid,
				"branch": loggedBranch,
				// "RemainingSessions": selectedAcademyPackage,
				"graceDays": values.graceDays,
				"subscriptionID": subscriptionID,
				"employee": employee


			})
		}).then(response => response.json())
			.then(result => {
				if (result.status === 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)

				} else {
					toast.error(`${result.Message}`, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				}
			})
	}




	function archiveSub() {
		fetch(`${API}/ArchiveAcademySub`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'subscriptionID': subID,
				'status': subStatus,
				"customerID": customerID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleCloseArchive()

				} else {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				}
			})
	}


	useEffect(function () {
		async function fetchData() {
			try {
				setLoading(true)

				const res = await fetch(`${API}/getCustomerFromAcademy/${customerID}`)
				const data = await res.json()
				console.log(data)
				setCustomerAcademyData(data.result)
				console.log(data.result)
				setLoading(false)
			} catch (e) {
				console.log(e)
			} finally {

			}
		} fetchData()
	}, [render])

	function HandleGymPackageChange(e) {
		e.preventDefault()
		fetch(`${API}/changeAcademyPakcage`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'packageID': academyPackageRef.current.value,
				"subID": subID,
				"discount": discountRef.current.value

			})

		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleClose()


				} else {
					toast.error(`Internal server error`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}
			})

	}


	function updateGymSubStatus() {
		fetch(`${API}/updateAcademySubStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'subscriptionID': subID,
				'status': subStatus,
				"customerID": customerID,
				"academyID": activeAcademyID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleCloseActivation()

				} else if (result.status == "402") {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				}
			})
	}


	function cancelAcademySub(e) {
		e.preventDefault()
		fetch(`${API}/CancelAcademySunscription`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'SubID': subID,
				"paybackvalue": paybackvalue.current.value,
				"cause": cancelCause.current.value,
				"branch": loggedBranch

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleDeleteSubClose()


				} else {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}
			})
	}







	useEffect(function () {
		async function fetchAcademies() {
			try {
				setAcadmiesLoading(true)
				const res = await fetch(`${API}/getAcademyPackage`)
				const data = await res.json()
				console.log(data.data)
				setAcademiesData(data.data)
			} catch (e) {
				console.log(e)
			} finally {
				setAcadmiesLoading(false)

			}
		} fetchAcademies()
	}, [render])



	var ac = academyDesc.toString()


	console.log(ac.split(","))
	const selectedAcademy = ac.split(",")
	const academyID = selectedAcademy[2]
	const selectedAcademyPackage = selectedAcademy[1]


	const handleChange = () => {
		setChecked((prev) => !prev);
	};





	// Academy cutomer data able 




	function AcademyTableComponenet({ ac }) {


		return (


			<Card orientation="vertical" variant="outlined" sx={{
				position: 'relative',
				transition: 'transform 0.3s, box-shadow 0.3s',
				padding: "20px",
				paddingTop: "50px", // Extra space for the button
				width: "auto",
				minWidth: "fit-content",
				margin: "9px auto"
			}}>




				<BootstrapDialog
					onClose={handleIncreaseSubClose}
					open={increaseSub}
					aria-labelledby="customized-dialog-title"



				>
					<IconButton
						aria-label="close"
						onClick={handleIncreaseSubClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>


					<DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
						<h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Increase Subscription</h5>
					</DialogContent>

					<DialogContent sx={{ margin: "0 auto", height: "190px" }}>
						<img style={{ width: "150px", margin: "0 auto", marginBottom: "0" }} src='/add.png' />
					</DialogContent>

					<DialogContent sx={{ margin: "0 auto", marginTop: "0" }}>
						<form onSubmit={(e) => handleIncreaseGymQuota(e)} style={{ height: "150px", width: "200px", backgroundColor: "white", padding: "5px", marginLeft: "0", marginBottom: "10px" }}>
							<input ref={sessionsRef} required type='text' placeholder='Num of sessions' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />
							<input ref={daysRef} required type='text' placeholder='Days' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />

							<button type='submit' style={{ backgroundColor: "black", color: "white", margin: "0 auto" }} autoFocus >
								Confirm
							</button>
						</form>
					</DialogContent>





				</BootstrapDialog>

				<BootstrapDialog
					sx={{ padding: "50px" }}
					onClose={handleInvitationClose}
					aria-labelledby="customized-dialog-title"
					open={invtiationOpen}
				>

					<IconButton
						aria-label="close"
						onClick={handleInvitationClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<h4 style={{ margin: "10px auto", fontWeight: "bold" }}>Invitation</h4>
					<img style={{ width: "200px", margin: "0 auto" }} src="/invite.png" />
					<DialogContent sx={{ margin: "0 auto" }}>
						<form onSubmit={(e) => handleCustomerInvitation(e)} style={{ height: "120px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0", border: "1px solid #e4e4e4", padding: "5px" }}>
							<input ref={invitedCustomerName} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Name' /> <br />
							<input ref={invitedCustomerPhone} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Phone' /> <br />
							<Button type='submit' style={{ backgroundColor: "black", color: "white", fontWeight: "bold", margin: "0 auto", borderRadius: "10px" }} autoFocus onClick={handleClose}>
								Confirm
							</Button>
						</form>


					</DialogContent>

				</BootstrapDialog>

				{Role == "Admin" || Role == "admin" || Role == "Receptionist" ? (<Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>

					<IconButton
						backgroundColor="white"
						aria-label="cancel"
						sx={{
							color: 'grey',

						}}
						onClick={() => {
							setSubID(ac?.subscriptionID)
							setOpenPurge(true);
						}}    >
						<ClearIcon />
					</IconButton>
				</Box>) : ("")}


				{/* end of increase quota  */}
				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-around", }} fontWeight="md" textColor="success.plainColor">
						<div className='package-name'>
							<div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Academy Name</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", alignItems: "center", textAlign: "center" }}>{ac?.AcademyName}</label></div>

						</div>



						<div className='package-status'>

							<div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px", marginLeft: "20px" }}>Status</label></div>
							{ac.status == "inactive" ? (<div onClick={() => { setActiveAcadmyID(ac?.academyID); subStatusAndID("active", ac?.subscriptionID) }} style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "red", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {ac?.status}</label></div>
							) : (<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "green", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {ac?.status}</label></div>
							)}
						</div>


						<div className='package-used'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}> <CancelPresentationIcon style={{ height: "20px", size: "30px", color: "darkred" }} />  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Used Sessions</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{ac?.UsedSessions}</p></div>

						</div>


						<div className='package-remaining'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>  <CheckBoxIcon style={{ height: "20px", size: "30px", color: "green" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Remaining Sessions</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{ac?.RemainingSessions}</p></div>

						</div>

						<div className='pack-endDate'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <CalendarMonthIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Package End Date</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{ac?.EndDate}</p></div>

						</div>


						{ac?.employee ? (
							<div className='pack-endDate'>
								<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonAddIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Employee</label></div>
								<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{ac?.employee}</p></div>

							</div>
						) : ("")}




					</div>

					{/* <Button style={{ margin:"0 auto" ,whiteSpace: "nowrap", border: "1px solid red", color: "red", fontWeight: "bold", width:"120px", fontSize:"10px" }} onClick={ ()=> {setSubID(ac.subscriptionID);handleClickOpen()}} variant="outlined">
                                     change package
                              </Button> */}
				</CardContent> <br />


			
				{ac?.Remains != 0 ? (

<List sx={{ width: '100%', border: "1px solid #e4e4e4", bgcolor: 'background.paper', display: "flex", borderRadius: "10px", margin: "0 auto" }}>
	<ListItem>
		<ListItemAvatar>
			<Avatar>
				<MoneyOffIcon sx={{ color: "black" }} />

			</Avatar>
		</ListItemAvatar>
		<>
			<label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`Paid ${ac?.Paid}  `}</label>

		</>
	</ListItem>
	<ListItem>
		<ListItemAvatar>
			<Avatar>
				<AttachMoneyIcon sx={{ color: "black" }} />
			</Avatar>
		</ListItemAvatar>
		<label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`Remains ${Math.abs(ac?.Remains)}  `}</label>
	</ListItem>


	<ListItem>
		<ListItemAvatar>
			<Avatar>
				<DateRangeIcon sx={{ color: "black" }} />
			</Avatar>
		</ListItemAvatar>
		<label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`  ${ac?.RemainsGraceEndDate}  `}</label>

	</ListItem>



	<ListItem>



	{ 
	ac?.Remains > 0 && 
	(["admin", "receptionist"].includes(Role.toLowerCase())) ? (
	  <Button 
		style={{width:"120px", border: "2px solid green",borderRadius:"7px", color: "green", fontWeight: "bold" }} 
		onClick={() => { 
		  setSubscriptionID(ac.subscriptionID); 
		  handleDebtOpen(); 
		}} 
		startIcon={<PaidIcon sx={{ color:'green'}} />}
	  >
		Pay
	  </Button>
	) : ("")
  }

		

   { 
	ac?.Remains < 0 && 
	(["admin", "receptionist"].includes(Role.toLowerCase())) ? (
	  <Button 
		style={{ width:"120px", border: "2px solid red", color: "red",borderRadius:"7px", fontWeight: "bold" }} 
		onClick={() => { 
		  setSubscriptionID(ac.subscriptionID); 
		  setRemainingValue(ac.Remains)
		  handleOpenRetrunCustomerAcademy(); 
		}} 
		startIcon={<PaidIcon sx={{ color:'red'}} />}
	  >
		Return
	  </Button>
	) : ("")
  }
  


	</ListItem>




</List>) : ("")}
				{ac?.RemainingSessions == "0" || new Date(endDate) >= new Date(ac?.EndDate) ? (<Button onClick={() => subStatusAndIDArchive("archived", ac?.subscriptionID)} style={{ width: "100px", margin: "0 auto", border: "2px solid black", color: "black", fontWeight: "bold" }} variant="outlined" startIcon={<ArchiveIcon />}>Archive</Button>) : ("")
				}

				<div style={{ display: "flex", justifyContent: "space-around" }}>

					{Role == "Admin" || Role == "admin" || Role == "Supervisor" ? (<Button style={{ whiteSpace: "nowrap", border: "1px solid darkblue", color: "red", fontWeight: "bold", fontSize: "10px" }} onClick={() => { setSubID(ac?.subscriptionID); handleClickOpen() }} variant="outlined">
						<EditIcon sx={{ color: "darkblue" }} />

					</Button>) : ("")}


					{Role == "Admin" || Role == "admin" ? (<Button onClick={() => { setRemaininsSessions(ac?.RemainingSessions); subStatusAndIDCancel("canceled", ac?.subscriptionID); handleDeleteSubOpen() }} sx={{ border: "1px solid red" }} variant="outlined">
						<CancelIcon sx={{ color: "Red" }} />

					</Button>) : ("")}






					{Role == "Admin" || Role == "admin" || Role == "General Manager" ? (
						<Button onClick={() => { setSubID(ac?.subscriptionid); handleIncreaseSubOpen() }} sx={{ border: "1px solid green" }} variant="outlined">
							<AddCircleOutlineIcon sx={{ color: "Green" }} />

						</Button>) : ("")}

					{ac?.invitation == "Available" ? (<Tooltip title={<Typography fontSize={15}>invite</Typography>}>  <Button onClick={() => { setSubID(ac.subscriptionID); handleInvitationOpen() }} sx={{ border: "1px solid #003b46" }} variant="outlined"> <PersonAddIcon sx={{ color: "#003b46" }} />  </Button> </Tooltip>) : ("")}







				</div>

			</Card>
			// 	<table style={{ width: "500px", margin: "0 auto" }} class="table align-middle mb-0 bg-light shadow p-3 mb-5 bg-info rounded">
			// 	<thead >
			// 		<tr>
			// 			<th style={{ backgroundColor: "#e4e4e4", fontSize: "15px", fontFamily: "Poppins", textAlign: "center" }}>Academy</th>
			// 			<th style={{ backgroundColor: "#e4e4e4", fontSize: "15px", fontFamily: "Poppins" }}>Used Sessions</th>
			// 			<th style={{ backgroundColor: "#e4e4e4", fontSize: "15px", fontFamily: "Poppins" }}>Remaining Sessions</th>
			// 			<th style={{ backgroundColor: "#e4e4e4", fontSize: "13px", fontFamily: "Poppins" }}>Package end date</th>
			// 			<th style={{ backgroundColor: "#e4e4e4", fontSize: "13px", fontFamily: "Poppins" }}></th>


			// 		</tr>
			// 	</thead>
			// 	<tbody>
			// 		<tr style={{ alignItems: "center", textAlign: "center" }}>
			// 			<td>
			// 				<p style={{ fontFamily: "Poppins" }}>{ac.AcademyName}</p>
			// 			</td>
			// 			<td>
			// 				<p style={{ fontFamily: "Poppins" }}>      {ac.UsedSessions}</p>

			// 			</td>
			// 			<td>
			// 				<p style={{ fontFamily: "Poppins", textAlign: "center", alignItems: "center" }}>{ac.RemainingSessions}</p>
			// 			</td>

			// 			<td>
			// 				<p style={{ fontFamily: "Poppins", textAlign: "center", alignItems: "center" }}>{ac.EndDate}</p>

			// 			</td>
			// 			{/* <td><button >Upgrade package</button></td> */}
			// 			<Button style={{ whiteSpace: "nowrap", border: " 1px solid red", color: "red", fontWeight: "bold" }} onClick={handleClickOpen} variant="outlined" startIcon={<PriorityHighIcon style={{ color: "red" }} />}>
			// 				change package
			// 			</Button>
			// 			<td>

			// 			</td>
			// 		</tr>


			// 	</tbody>


			// 	<br />


			// 	{ac.Remains != 0 ? (<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', display: "flex" }}>
			// 		<ListItem>
			// 			<ListItemAvatar>
			// 				<Avatar>
			// 					<MoneyOffIcon sx={{ color: "black" }} />

			// 				</Avatar>
			// 			</ListItemAvatar>
			// 			<ListItemText style={{ width: "40px" }} primary={`Paid ${ac.Paid}`} />
			// 		</ListItem>
			// 		<ListItem>
			// 			<ListItemAvatar>
			// 				<Avatar>
			// 					<AttachMoneyIcon sx={{ color: "black" }} />
			// 				</Avatar>
			// 			</ListItemAvatar>
			// 			<ListItemText style={{ width: "40px" }} primary={`Remains ${ac.Remains}`} />
			// 		</ListItem>


			// 		<ListItem>
			// 			<ListItemAvatar>
			// 				<Avatar>
			// 					<DateRangeIcon sx={{ color: "black" }} />
			// 				</Avatar>
			// 			</ListItemAvatar>
			// 			<ListItemText style={{ width: "90px" }} primary={ac.RemainsGraceEndDate} />
			// 		</ListItem>

			// 		<ListItem>

			// 			<Button onClick={()=> {setSubscriptionID(ac.subscriptionID); handleDebtOpen()}} variant="outlined" startIcon={<PaidIcon />}>
			// 				Pay
			// 			</Button>
			// 		</ListItem>




			// 	</List>) : ("")}


			// </table>
		)
	}




	return (



		<Box sx={{ padding: "20px" }}>
			<PurgeConfirmation />
			<ReturnToCustomerAcademy toast={toast} 	rerender={rerender} render={render} subscriptionID= {subscriptionID} remainingValue={remainingValue} openReturnCustomerAcademy={openReturnCustomerAcademy} handleCloseReturnCustomerAcademy={handleCloseReturnCustomerAcademy} setOpenRetrunCustomerAcademy={setOpenRetrunCustomerAcademy}  handleOpenRetrunCustomerAcademy ={handleOpenRetrunCustomerAcademy} customerID={customerID} />


			{loading || academiesLoading ? (<Box
				sx={{

				}}
			>
				<CircularProgress sx={{ width: 'fit-content', display: 'flex' }} />
			</Box>) : (<>
				<Dialog
					maxWidth="sm"
					open={debtOpen}
					onClose={handleDebtClose}
				>
					<DialogContent sx={{ margin: "0 auto" }}>
						<img style={{ width: "200px", margin: "0 auto", height: "200px" }} src="/pay.png" />
					</DialogContent>
					<DialogContent>


						<form onSubmit={(e) => PayAcademyDept(e)} style={{ height: "100px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0" }}>
							<input ref={PayDebtValue} style={{ border: "1px solid grey", alignItems: "cemter", textAlign: "center", borderRadius: "10px", marginTop: "4px" }} type='text' placeholder='Pay value' />
							<div style={{ display: "flex", margin: "0 auto", justifyContent: "space-evenly" }}>
								<Button onClick={handleDebtClose}>Close</Button>
								<LoadingButton
									style={{ backgroundColor: "#e4e4e4", marginTop: "0", color: "black", fontWeight: "bold" }}
									loading={buttonLoading}
									loadingPosition="start"
									type='submit'
									startIcon={<AttachMoneyIcon />}

								>
									Pay
								</LoadingButton>
							</div>


						</form>






					</DialogContent>

				</Dialog>

				<Dialog
					open={archived}

					onClose={handleCloseArchive}
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseArchive}>cancel</Button>
						<Button onClick={archiveSub}>Archive</Button>
					</DialogActions>
				</Dialog>





				{/* end of pay deb popup */}




				{/* Delete Dialog */}

				<BootstrapDialog
					onClose={handleDeleteSubOpen}
					open={deleteSub}
					aria-labelledby="customized-dialog-title"



				>
					<IconButton
						aria-label="close"
						onClick={handleDeleteSubClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>


					<DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
						<h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Cancel Subscription?</h5>
						<h8 style={{ marginLeft: "15px" }}> {remainingSessions} Remaining sessions</h8>


					</DialogContent>

					<DialogContent sx={{ margin: "0 auto" }}>
						<img style={{ width: "150px", margin: "0 auto" }} src='/cancelsub.png' />
					</DialogContent>

					<DialogContent sx={{ margin: "0 auto" }}>
						<form onSubmit={(e) => cancelAcademySub(e)} style={{ height: "150px", width: "200px", backgroundColor: "white", padding: "10px", marginLeft: "0", border: "1px solid #e4e4e4", width: "auto" }}>
							<input ref={paybackvalue} required type='text' placeholder='Payback Value' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid #e4e4e4", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} /> <br />
							<select ref={cancelCause} style={{ marginBottom: "7px", width: "200px", border: "1px solid #e4e4e4", alignItems: "center", textAlign: "center" }}>
								<option style={{ alignItems: "center", textAlign: "center" }} disabled selected>Cause</option>
								<option style={{ alignItems: "center", textAlign: "center" }} value="Retrun">Retrun</option>
								<option style={{ alignItems: "center", textAlign: "center" }} value="Wrong">Wrong</option>

							</select>
							<button type='submit' style={{ backgroundColor: "black", color: "white", margin: "0 auto" }} autoFocus >
								Confirm
							</button>
						</form>
					</DialogContent>





				</BootstrapDialog>
				<Dialog
					open={activationOpen}

					onClose={handleCloseActivation}
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseActivation}>cancel</Button>
						<Button onClick={updateGymSubStatus}>Activate</Button>
					</DialogActions>
				</Dialog>




				<BootstrapDialog
					onClose={handleClose}
					aria-labelledby="customized-dialog-title"
					open={open}

				>
					<DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", fontFamily: "Poppins", width: "fit-content", margin: "auto" }} id="customized-dialog-title">
						Change package
					</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent style={{ width: "100%", height: "100%", padding: "40px" }}
						dividers>						<form
							onSubmit={(e) => HandleGymPackageChange(e)}
							style={{ display: "block",marginTop:"0",fontFamily:"poppins",paddingTop:"0", flexDirection: "column", backgroundColor:"white", width:"250px", height:"250px", margin:"auto" }}						>
							<select
								style={{ marginBottom: "7px", width: "200px", border: "1px solid #e4e4e4", alignItems: "center", textAlign: "center" }}
								required
								ref={academyPackageRef}
								name=""
								id=""
							>
								<option value="" selected disabled>Package</option>
								{academiesData?.map(pack => (
									<option value={pack.academyPackageID}>
										{pack.description} ({pack.price} EGP/{pack.sessionsCount} Sessions)
									</option>
								))}
							</select>

							<input
								ref={discountRef}
								defaultValue="0"
								required
								type='text'
								placeholder='Discount Value'
								style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />

							<button type='submit' style={{ margin: "auto", marginTop: "15px", backgroundColor: "#006241", color: "white", fontWeight: "bold", width: "250px", borderRadius: "8px", height: "40px" }} autoFocus >
								Confirm
							</button>
						</form>
					</DialogContent>

					<DialogActions>

					</DialogActions>
				</BootstrapDialog>


				<div style={{ margin: "0 auto" }}>
					<div className="d-grid mb-4">

					</div>

					{customerAcademyData?.map(ac => {
						return (
							<AcademyTableComponenet ac={ac} />
						)
					})}
					<form style={{ width: '340px', height: "auto", margin: '0 auto', padding: "20px", backgroundColor: "white", border: "1px solid #e4e4e4" }} onSubmit={formik.handleSubmit}>
						<h5>Select Package</h5>
						<FormControl fullWidth variant="outlined" margin="normal">
							<InputLabel id="academy-select-label">Academy</InputLabel>
							<Select
								labelId="academy-select-label"
								id="academyPackageID"
								name="academyPackageID"
								value={formik.values.academyPackageID}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								label="Academy"
								error={formik.touched.academyPackageID && Boolean(formik.errors.academyPackageID)}
							>
								<MenuItem value="" disabled>
									Academy
								</MenuItem>
								{academiesData?.filter(a => a.price !== "0").map(ac => (
									<MenuItem key={ac.academyPackageID} value={ac.academyPackageID}>
										{ac.description} - {ac.sessionsCount} Sessions - {ac.price} EGP
									</MenuItem>
								))}
							</Select>
							{formik.touched.academyPackageID && formik.errors.academyPackageID ? (
								<div style={{ color: 'red' }}>{formik.errors.academyPackageID}</div>
							) : null}
						</FormControl>

						<TextField
							style={{ marginBottom: '10px' }}
							id="discount"
							name="discount"
							label="Discount"
							value={formik.values.discount}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
							type="text"
						/>

						<Box sx={{ height: 180 }}>
							<FormControlLabel
								control={
									<Button
										style={{ marginLeft: '20px', marginBottom: '10px' }}
										endIcon={<AttachMoneyIcon style={{ color: 'red' }} />}
										variant="outlined"
										color="error"
										onClick={() => setChecked(!checked)}
									>
										Debt
									</Button>
								}
							/>
							<Box sx={{}}>
								<Fade in={checked}>
									<Paper>
										<TextField
											id="paid"
											name="paid"
											label="Paid"
											value={formik.values.paid}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											variant="outlined"
											style={{ marginBottom: '10px' }}
											type="text"
										/>

										<FormControl fullWidth variant="outlined" margin="normal">
											{formik.values.paid && (
												<Select
													name="graceDays"
													value={formik.values.graceDays}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													displayEmpty
													fullWidth
													margin="normal"
													error={formik.touched.graceDays && Boolean(formik.errors.graceDays)}
												>
													<MenuItem value="" disabled>Grace period (days)</MenuItem>
													{days_list.map((d) => (
														<MenuItem key={d} value={d}>{d}</MenuItem>
													))}
												</Select>
											)}
											{formik.touched.graceDays && formik.errors.graceDays && (
												<div style={{ color: 'red' }}>{formik.errors.graceDays}</div>
											)}

										</FormControl>
									</Paper>
								</Fade>
							</Box>
						</Box>

						<LoadingButton
							sx={{
								height: "40px",
								fontWeight: "bold",
								borderRadius: "12px",
								backgroundColor: "#152238",
								width: "250px",
								color: "white",
								margin: "auto",
								":hover": {
									backgroundColor: "#152238",
								},
							}}
							type="submit" // Change to submit
							loading={btnLoading}
							variant="contained"
						>
							Submit
						</LoadingButton>
					</form>
				</div>
			</>)}



		</Box>

	)
}

export default AcademyTable