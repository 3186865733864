import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { useState, useEffect, useCallback} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Navbar from '../components/navbar';

import { debounce } from "lodash"

import "../styles/searchBox.css"
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];




function InvitationsPage() {
	const API = process.env.REACT_APP_API

	const [query, setQuery] = useState(null)
	const [loading, setLoading] = useState()
	const [invitations, setInvitations] = useState(null)
	const [invitationsLoading, setInvLoading] = useState()
	const [page, setPage] = useState(0)
	const [pageCount, setPageCount] = useState()


	const lazyLog = useCallback(
		debounce( async (query, page) => {
		  try {
			setInvLoading(true)
			const res = await fetch(`${API}/getAllInvitations?customerID=${query}&page=${page}`)
			const data = await res.json()
			console.log(data)
			setInvitations(data.data)
			setPageCount(data.count)
	  
		  } catch (e) {
			console.log(e)
		  }  finally {
			setInvLoading(false)
		  }
	
		 }, 1000),
		[]
	  );
	
	
	
	  useEffect(function() {
		lazyLog(query, page)
	
	  }, [page, query])
	



	

	return (

		<div className="invitations-page">
			<Navbar />
			<div style={{ padding: "5px", margin: "100px auto", height: "35px", marginBottom: "10px", width: "200px", display: "flex", borderRadius: "20px", justifyContent: "space-between", border: "1px solid #cccccc", alignItems: "center" }} className='search-container'>
				<input className='search-input' onChange={(e) => { setQuery(e.target.value) }} style={{ border: "none", borderRadius: "20px", alignItems: "center", textAlign: "center", height: "30px", fontSize: "14px", width: "150px", marginBottom: "0", marginLeft: "0", marginRight: "0", backgroundColor: "none" }} placeholder="Search ID" />


				{loading ? (<CircularProgress sx={{ margin: "0 auto", position: "absolute", justifyContent: "center" }} size={10} />
				) : ("")}
				<span>  <SearchIcon sx={{ backgroundColor: "white", width: "25px", right: "0", height: "70%", color: "black", borderRadius: "20px" }} /></span>

			</div>


{invitationsLoading? (<CircularProgress  sx={{margin:"0 auto", color:"black", position:"relative", marginLeft:"50%"}}/>):(	<TableContainer sx={{ boxShadow: "none"}} component={Paper}>

<Table sx={{ minWidth: 700, width: "80%", margin: "5px auto", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", boxShadow: "none", alignItems:"center" }} aria-label="customized table">
	<TableHead sx={{ height: "20px" }}>
		<TableRow>
			<StyledTableCell sx={{ borderTopLeftRadius: "8px", fontWeight: "bold" }} align="center">Customer Name</StyledTableCell>
			<StyledTableCell sx={{ fontWeight: "bold" }} align="center">Customer ID</StyledTableCell>
			<StyledTableCell sx={{ fontWeight: "bold" }} align="center">Invited Name</StyledTableCell>
			<StyledTableCell sx={{ fontWeight: "bold" }} align="center">Invited Phone</StyledTableCell>
			<StyledTableCell sx={{ fontWeight: "bold" }} align="center">Category</StyledTableCell>
			<StyledTableCell sx={{ borderTopRightRadius: "8px", fontWeight: "bold" }} align="center">Date</StyledTableCell>


		</TableRow>
	</TableHead>

<TableBody>
		{invitations?.map((invitation) => (
			<StyledTableRow>

				<StyledTableCell align="center">{invitation.Fullname}</StyledTableCell>
				<StyledTableCell align="center">{invitation.customerID}</StyledTableCell>
				<StyledTableCell align="center">{invitation.invitedName}</StyledTableCell>
				<StyledTableCell align="center">{invitation.invitedPhone}</StyledTableCell>
				<StyledTableCell align="center">{invitation.type}</StyledTableCell>
				<StyledTableCell align="center">{invitation.date}</StyledTableCell>

			</StyledTableRow>
		))}
	</TableBody>
</Table>
</TableContainer>)}
{console.log(pageCount)}
		
			<Pagination onChange={(e, value) => setPage(value - 1)} sx={{ margin: "20px auto", width: "auto", justifyContent: "center", display: "flex" }} count={pageCount} variant="outlined" shape="rounded" />


		</div>
	)
}


export default InvitationsPage