import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: '8px',

};

function ReturnToCustomerAcademy({ toast, openReturnCustomerAcademy,	rerender, render, subscriptionID, remainingValue, openReturnCustomer, handleCloseReturnCustomerAcademy ,setOpenRetrunCustomerAcademy, handleOpenRetrunCustomerAcademy, customerID}) {

	const API = process.env.REACT_APP_API


	const [value, setValue] = useState("")

	useEffect(() => {
		if (remainingValue) {
			setValue(remainingValue);
		}
	}, [remainingValue]);

	function handlePayBack(e) {
		e.preventDefault(); // Prevent the default form submission behavior
	
		fetch(`${API}/payCustomerBackAcademy`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'subID': subscriptionID,
				'value': value,
				"customerID": customerID,
			}),
		})
			.then(response => response.json())
			.then(data => {
				console.log(data); // Inspect the response
	
				if (data.status == 200) {
					toast.success(data.Message || "Payment successful!", {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					rerender(!render)

					handleCloseReturnCustomerAcademy()
				} else {
					toast.error(data.Message || "Something went wrong!", {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
				}
			})
			.catch(error => {
				console.error('Error:', error); // Log any errors
				toast.error("An unexpected error occurred. Please try again.", {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
			});
	}
	
	

	return (
		<div>
		
			<Modal
				open={openReturnCustomerAcademy}
				onClose={handleCloseReturnCustomerAcademy}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...style, display: "grid", placeItems: "center", position: "relative" }}>
					<form onSubmit={(e) => handlePayBack(e)} style={{ height: "fit-content", width: "fit-content", backgroundColor: "white", padding: "10px", marginLeft: "0", border: "1px solid #e4e4e4", width: "auto" }}>
						<img
							style={{ margin: "auto" }}
							src="/return.png"
							alt="Return"
						/>
						<input onChange={e => setValue(e.target.value)} value={Math.abs(value)} required style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} placeholder='value' type="text" />

						<button type='submit' style={{ backgroundColor: "black", color: "white", margin: "0 auto", marginTop: "20px" }} autoFocus >
							Confirm
						</button>
					</form>
				</Box>
			</Modal>
		</div>
	)
}

export default ReturnToCustomerAcademy