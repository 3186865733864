import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Person3Icon from '@mui/icons-material/Person3';

import AddBoxIcon from '@mui/icons-material/AddBox';
import Navbar from '../../components/navbar';
console.log("Hello")




function NewClasses() {
	const [value, setValue] = React.useState(0);
  const [items, setItems] = useState([]);
  const [Role, setRole] = useState()

	const navigate = useNavigate()

  useEffect(() => {
    const items = localStorage.getItem('Roles')
    if (items) {
      setRole (items);
    }
  }, []);
  
  console.log(items)
  

	return (
<Box >
<Navbar />

<Box sx={{
    position: 'relative', // Keep it absolute
    width: '100%', // Make it span the full width
    padding: { xs: 1, sm: 2 }, // Padding for mobile view
    backgroundColor: 'white', // Background color for the top nav
    marginTop:"30px"
}}>
  <BottomNavigation
    showLabels
    value={value}
    onChange={(event, newValue) => {
      setValue(newValue);
    }}
    sx={{
      display: 'flex', 
      justifyContent: 'space-around', // Distribute space evenly
      flexDirection: 'row', // Keep items in a row
      flexWrap: 'nowrap', // Disable wrapping
      alignItems: 'center', // Align items in the center
    }}
  >
    <BottomNavigationAction
      onClick={() => navigate('all-classes')}
      label="All Classes"
      icon={<AddBoxIcon style={{ color: 'black' }} />}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 'unset',
        fontSize: { xs: '12px', sm: '14px' }, // Adjust font size for mobile
      }}
    />

    {Role === 'Admin' && (
      <BottomNavigationAction
        onClick={() => navigate('add-class-family')}
        label="Add Class Family"
        icon={<AddBoxIcon style={{ color: 'black' }} />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 'unset',
          fontSize: { xs: '12px', sm: '14px' }, // Adjust font size for mobile
        }}
      />
    )}

    {Role === 'Admin' && (
      <BottomNavigationAction
        onClick={() => navigate('class-package')}
        label="Add Package"
        icon={<AddBoxIcon style={{ color: 'black' }} />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 'unset',
          fontSize: { xs: '12px', sm: '14px' },
        }}
      />
    )}

    {Role === 'Admin' && (
      <BottomNavigationAction
        onClick={() => navigate('edit-class-packages')}
        label="Edit Package"
        icon={<AddBoxIcon style={{ color: 'black' }} />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 'unset',
          fontSize: { xs: '12px', sm: '14px' },
        }}
      />
    )}

    {Role === 'Admin' && (
      <BottomNavigationAction
        onClick={() => navigate('add-class')}
        label="Add Class"
        icon={<AddBoxIcon style={{ color: 'black' }} />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 'unset',
          fontSize: { xs: '12px', sm: '14px' },
        }}
      />
    )}

    <BottomNavigationAction
      onClick={() => navigate('customers')}
      label="Customers"
      icon={<Person3Icon style={{ color: 'black' }} />}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 'unset',
        fontSize: { xs: '12px', sm: '14px' },
      }}
    />
  </BottomNavigation>
</Box>


<Outlet/>

</Box>



	)
}
export default NewClasses