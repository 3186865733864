import React, { useLayoutEffect } from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { HiOutlineDocumentReport } from "react-icons/hi";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));



const API = process.env.REACT_APP_API

function FinancePage() {

	const [loading, setLoading] = useState(true)
	const [financeDage, setFinanceData] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainer, setTrainer] = useState()
	const [trainersLoading, setTrainersLoading] = useState()
	const [trainerName, settrainerName] = useState()
	// const [totalPaid, setTotoalPaid] = useState()
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPayBackPage, setCurrentPayBackPage] = useState(1);
	const [currentPayDebtPage, setCurrentPayDebtPage] = useState(1);


	const [paidRemains, setPaidRemains] = useState([])
	const [payBack, setPayBack] = useState()
	const [paybaclLoading, setPayBackLoading] = useState()
	const [paybackvalue, setPayBackValue] = useState([])
	const [payDebt, setPayDebt] = useState()
	const [payDebtLoading, setPayDebtLoading] = useState()
	const [payDebtValue, setPayDebtValue] = useState([])
	const [pagesCount, setPagesCount] = useState(0);
	const [payBackPagesCount, setPayBackPagesCount] = useState(0);
	const [debtPagesCount, setDebtPagesCount] = useState(0);
	const [branchesLoading, setBranchesLoading] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState("All");
	const [branches, setBranches] = useState([]);
	const [isSearchActive, setIsSearchActive] = useState(false);







	// let total = []

	function onChange(date, dateString) {
		console.log(dateString)
		setDate(dateString)
		console.log(startDate)
		// setFiltered("filtered")

	}
	function onEndDatehange(date, dateString) {
		console.log(dateString)
		setEnDate(dateString)
		console.log(endDate)
		// setFiltered("filtered")


	}
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e4e4e4', // Head color
			color: theme.palette.common.black,
			fontFamily: 'Poppins',
			fontWeight: 'bold',
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			fontFamily: 'Poppins',
			fontWeight: 'bold',
		},
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));


	useEffect(() => {
		const fetchBranches = async () => {
			setBranchesLoading(true);
			const response = await fetch(`${API}/branches`);
			const data = await response.json();
			setBranches(data.branches);
			setBranchesLoading(false);
		};
		fetchBranches();
	}, []);
	


	const fetchData = async (url, setter, setLoading, callback = null, defaultValue = []) => {
		try {
			setLoading(true);
			const response = await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			});
			const result = await response.json();
			setter(result || defaultValue);
			if (callback) callback(result);
		} catch (e) {
			console.error(`Error fetching data from ${url}:`, e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const url = `${API}/getFinanceAll?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&branch=${selectedBranch}`;
		fetchData(
			url,
			(data) => {
				setFinanceData(data.rows || []);
				setPaidRemains(data.sumPaidRemaining); // Fallback for empty result
				setPagesCount(data.numPages || 0);
			},
			setLoading
		);
	}, [currentPage, isSearchActive, startDate, endDate, selectedBranch]); // Dependencies
	

	useEffect(() => {
		const url = `${API}/getPayBack?startDate=${startDate}&endDate=${endDate}&page=${currentPayBackPage}&branch=${selectedBranch}`;
		fetchData(
			url,
			(data) => {
				setPayBack(data.rows || []);
				setPayBackValue(data.paidbackvalue ); // Fallback for empty value
				setPayBackPagesCount(data.numPages );
			},
			setPayBackLoading
		);
	}, [currentPayBackPage, isSearchActive, startDate, endDate, selectedBranch]); // Dependencies
	

	useEffect(() => {
		const url = `${API}/getPayDebt?startDate=${startDate}&endDate=${endDate}&page=${currentPayDebtPage}&branch=${selectedBranch}`;
		fetchData(
			url,
			(data) => {
				setPayDebt(data.rows || []);
				setPayDebtValue(data.paydebtValue ); // Fallback for empty value
				setDebtPagesCount(data.numPages );
			},
			setPayDebtLoading
		);
	}, [currentPayDebtPage, isSearchActive, startDate, endDate, selectedBranch]); // Dependencies
	

	// function handleFilter() {


	// 	function fetchAll() {

	// 		fetch(`${API}/getFinanceAll?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&branch=${selectedBranch}`, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},

	// 		}).then(response => response.json()).then(result => {
	// 			setFinanceData(result.rows)
	// 			setPaidRemains(result.sumPaidRemaining[0])
	// 			setPagesCount(result.numPages)



	// 			setLoading(false)
	// 		})
	// 	}


	// 	async function fetchPayBack() {
	// 		try {
	// 			setPayBackLoading(true)
	// 			const res = await fetch(`${API}/getPayBack?startDate=${startDate}&endDate=${endDate}&page=${currentPayBackPage}&branch=${selectedBranch}`)
	// 			const data = await res.json()
	// 			console.log(data)

	// 			setPayBack(data.rows)
	// 			setPayBackValue(data.paidbackvalue)
	// 			setPayBackPagesCount(data.numPages)
	// 		} catch (e) {
	// 			console.log(e)
	// 		} finally {
	// 			setPayBackLoading(false)
	// 		}
	// 	} 


	// 	async function fetchPayDebt() {
	// 		try {
	// 			setPayBackLoading(true)
	// 			const res = await fetch(`${API}/getPayDebt?startDate=${startDate}&endDate=${endDate}&page=${currentPayBackPage}&branch=${selectedBranch}`)
	// 			const data = await res.json()
	// 			console.log(data)

	// 			setPayDebt(data.rows)
	// 			setPayDebtValue(data.paydebtValue)
	// 			setDebtPagesCount(data.numPages)

	// 			console.log(DebtpagesCount)

	// 		} catch (e) {
	// 			console.log(e)
	// 		} finally {
	// 			setPayDebtLoading(false)
	// 		}
	// 	} 



	// 	fetchAll()
	// 	fetchPayBack()
	// 	fetchPayDebt()




	// }




	useEffect(function () {
		async function getTrainers() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
				console.log(data.rows)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getTrainers()

	}, [])





	return (

		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">

<Container
				sx={{
					padding: '20px',
					borderRadius: '8px',
					backgroundColor: "white",
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow

				}}
			>				

				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: '20px', marginTop: "40px" }}>
				<div style={{ borderRadius: "8px", width: "40%", margin: "20px auto", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

				</div>
				<Box sx={{ margin: 2 }}>
					{branchesLoading ? (
						<CircularProgress />
					) : (
						<select onChange={(e) => setSelectedBranch(e.target.value)} style={{ width:  "170px",
							height: "40px",
							border: "none",
							textAlign: "center",
							alignItems: "center",
							marginTop: "10px", // Add space below the label
							backgroundColor: "#eaedf4",
							borderRadius: "8px", }}>
							<option value="" disabled selected>Branch</option>
							<option value="All">All</option>
							{branches.map(branch => (
								<option key={branch.id} value={branch.name}>{branch.name}</option>
							))}
						</select>
					)}

				</Box>
				<Button
				
					variant="contained"
					color="primary" // You can change this to your desired color
					onClick={() => setIsSearchActive(!isSearchActive)}
					startIcon={<SearchIcon style={{ color: "black" }} />}
					sx={{
						":hover": {
							backgroundColor: '#eaedf4',

							boxShadow: 'none',

						},
						backgroundColor: "#d7d9e9",

						color: 'black',
						borderRadius: '6px',
						fontWeight: "bold",
						marginTop: "15px",
						margin: "auto",
						width: "150px",
						fontWeight: "Bold",
						boxShadow: 'none',
					}}				>
					Search
				</Button>

 </div>

			
			</Container>







			<div  style={{ border: "2px solid #e4e4e4", padding: "10px", backgroundColor: "white", alignItems: "center", textAlign: "center", margin: "10px", borderRadius: "10px", height: "550px" }}>
				<div>
					<h3 style={{ margin: "0 auto", marginRight: "70px", marginBottom: "40px", fontWeight: "bold" }}>Subscriptions Finance </h3>


					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<div style={{ display: "flex" }} className='paid'>
							<PaidIcon />
							<label style={{ marginLeft: "6px", marginRight: "6px", fontWeight: "bold", fontFamily: "Poppins" }} htmlFor="">Total</label>

							<p style={{ position: "relative", fontWeight: "bold", fontFamily: "Poppins" }}>{Number(paidRemains?.Paid) + Number(paidRemains?.Remaining)} EGP</p>

						</div>
						<div style={{ display: "flex" }} className='paid'>
							<PaidIcon />
							<label style={{ marginLeft: "6px", marginRight: "6px", fontWeight: "bold", fontFamily: "Poppins" }} htmlFor="">Actual  Paid: </label>

							<p style={{ position: "relative", fontWeight: "bold", fontFamily: "Poppins" }}>{Number(paidRemains?.Paid)} EGP</p>

						</div>

						<div style={{ display: "flex" }} className='paid'>
							<PaidIcon />
							<label style={{ marginLeft: "6px", marginRight: "6px", fontWeight: "bold", fontFamily: "Poppins" }} htmlFor="">Total Remaining: </label>

							<p style={{ position: "relative", fontWeight: "bold", fontFamily: "Poppins" }}>{Number(paidRemains?.Remaining)} EGP</p>

						</div>
					</div>

					{loading ? (<CircularProgress />) : (<TableContainer style={{ width: "80%", margin: "0 auto", marginBottom: "20px", position: "relative", height: "350px", maxHeight: "400px" }} component={Paper}>
						<Table sx={{ minWidth: 700 }} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Customer ID</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Customer Name</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Phone</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">National ID</StyledTableCell>

									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Paid Value</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Remaining Value</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Discount</StyledTableCell>

									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Pay Date</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Category</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Dep</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Branch</StyledTableCell>



								</TableRow>
							</TableHead>
							<TableBody sx={{ marginBottom: "40px" }}>
								{
									financeDage
										.map((row) => (
											<StyledTableRow key={row.name}>

												<StyledTableCell align="center">{row.CustomerID}</StyledTableCell>
												<StyledTableCell align="center">{row.Fullname}</StyledTableCell>
												<StyledTableCell align="center">{row.Phone}</StyledTableCell>

												<StyledTableCell align="center">{row.NationalID}</StyledTableCell>

												<StyledTableCell align="center">{row.PaidValue}</StyledTableCell>
												<StyledTableCell align="center">{row.Remaining}</StyledTableCell>
												<StyledTableCell align="center">{row.Discount}</StyledTableCell>

												<StyledTableCell align="center">{row.PayDate}</StyledTableCell>
												<StyledTableCell align="center">{row.Dep}</StyledTableCell>
												<StyledTableCell align="center">{row.Category}</StyledTableCell>
												<StyledTableCell align="center">{row.Branch}</StyledTableCell>


											</StyledTableRow>
										))

								}


							</TableBody>
						</Table>

					</TableContainer>)}
				</div>


				<div>
					<Pagination variant="outlined" shape="rounded" onChange={(e, value) => setCurrentPage(value)} sx={{ margin: "20px auto", width: "fit-content" }} count={pagesCount} color="primary" />

				</div>




			</div>










			<div className='sub-finance-container' style={{ border: "2px solid #e4e4e4", padding: "10px", backgroundColor: "white", alignItems: "center", textAlign: "center", margin: "10px", borderRadius: "10px", height: "550px" }}>
				<div>
					<h3 style={{ margin: "0 auto", marginRight: "70px", marginBottom: "40px", fontWeight: "bold" }}>Pay Debt Finance </h3>


					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<div style={{ display: "flex" }} className='paid'>
							<PaidIcon />
							<label style={{ marginLeft: "6px", marginRight: "6px" }} htmlFor="">Total</label>

							<p style={{ position: "relative" }}>{payDebtValue?.PayDebt} EGP</p>

						</div>

					</div>

					{payDebtLoading ? (<CircularProgress />) : (<TableContainer style={{ width: "80%", margin: "0 auto", marginBottom: "20px", position: "relative", height: "350px", maxHeight: "400px" }} component={Paper}>
						<Table sx={{ minWidth: 700 }} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Customer ID</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Customer Name</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Phone</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">National ID</StyledTableCell>

									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Paid Value</StyledTableCell>

									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Pay Date</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Category</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Dep</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Branch</StyledTableCell>



								</TableRow>
							</TableHead>
							<TableBody sx={{ marginBottom: "40px" }}>
								{
									payDebt?.map((row) => (
										<StyledTableRow key={row.name}>

											<StyledTableCell align="center">{row.CustomerID}</StyledTableCell>
											<StyledTableCell align="center">{row.Fullname}</StyledTableCell>
											<StyledTableCell align="center">{row.Phone}</StyledTableCell>

											<StyledTableCell align="center">{row.NationalID}</StyledTableCell>

											<StyledTableCell align="center">{row.PaidValue}</StyledTableCell>

											<StyledTableCell align="center">{row.Date}</StyledTableCell>
											<StyledTableCell align="center">{row.Dep}</StyledTableCell>
											<StyledTableCell align="center">{row.Category}</StyledTableCell>
											<StyledTableCell align="center">{row.Branch}</StyledTableCell>


										</StyledTableRow>
									))

								}


							</TableBody>
						</Table>

					</TableContainer>)}
				</div>


				<div>
					<Pagination variant="outlined" shape="rounded" onChange={(e, value) => setCurrentPayDebtPage(value)} sx={{ margin: "20px auto", width: "fit-content" }} count={debtPagesCount} color="primary" />

				</div>




			</div>






			<div className='sub-finance-container' style={{ border: "2px solid #e4e4e4", padding: "10px", backgroundColor: "white", alignItems: "center", textAlign: "center", margin: "10px", borderRadius: "10px", height: "550px" }}>
				<div>
					<h3 style={{ margin: "0 auto", marginRight: "70px", marginBottom: "40px", fontWeight: "bold" }}>Return Finance </h3>


					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<div style={{ display: "flex" }} className='paid'>
							<PaidIcon />
							<label style={{ marginLeft: "6px", marginRight: "6px" }} htmlFor="">Total</label>

							<p style={{ position: "relative" }}>{paybackvalue} EGP</p>

						</div>

					</div>

					{paybaclLoading ? (<CircularProgress />) : (<TableContainer style={{ width: "80%", margin: "0 auto", marginBottom: "20px", position: "relative", height: "350px", maxHeight: "400px" }} component={Paper}>
						<Table sx={{ minWidth: 700 }} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Customer ID</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Customer Name</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Phone</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">National ID</StyledTableCell>

									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Paid Value</StyledTableCell>

									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Pay Date</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Cause</StyledTableCell>


									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Category</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Dep</StyledTableCell>
									<StyledTableCell style={{ fontWeight: "bold" }} align="center">Branch</StyledTableCell>




								</TableRow>
							</TableHead>
							<TableBody sx={{ marginBottom: "40px" }}>
								{
									payBack?.map((row) => (
										<StyledTableRow key={row.name}>

											<StyledTableCell align="center">{row.CustomerID}</StyledTableCell>
											<StyledTableCell align="center">{row.Fullname}</StyledTableCell>
											<StyledTableCell align="center">{row.Phone}</StyledTableCell>

											<StyledTableCell align="center">{row.NationalID}</StyledTableCell>

											<StyledTableCell align="center">{row.PaidValue}</StyledTableCell>

											<StyledTableCell align="center">{row.Date}</StyledTableCell>
											<StyledTableCell align="center">{row.Cause}</StyledTableCell>

											<StyledTableCell align="center">{row.Dep}</StyledTableCell>
											<StyledTableCell align="center">{row.Category}</StyledTableCell>
											<StyledTableCell align="center">{row.Branch}</StyledTableCell>


										</StyledTableRow>
									))

								}


							</TableBody>
						</Table>

					</TableContainer>)}
				</div>


				<div>
					<Pagination variant="outlined" shape="rounded" onChange={(e, value) => setCurrentPayBackPage(value)} sx={{ margin: "20px auto", width: "fit-content" }} count={payBackPagesCount} color="primary" />

				</div>




			</div>




			<Container
					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "block",
						backgroundColor: "white"

					}}
				>
				<Box margin="auto" width="fit-content" display="flex" alignItems="center" textAlign="center">
				<HiOutlineDocumentReport size={40} />	<h4>Final Report</h4>
				</Box>
					
					<Box sx={{ display: "flex", width: "100%", justifyContent:"center"}}>



					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px",  display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content"}}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Subscriptions
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(paidRemains?.Paid)} EGP
							</Typography>
					</Box>


					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Paid Debt
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(payDebtValue?.PayDebt)} EGP
							</Typography>
					</Box>


					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Return
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(paybackvalue)} EGP
							</Typography>
					</Box>


					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Net Profit
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(paidRemains?.Paid) + Number(payDebtValue?.PayDebt) - Number(paybackvalue) } EGP
							</Typography>
					</Box>
							
						</Box>
					<Container></Container>

				</Container>


		</div>
	)

}

export default FinancePage