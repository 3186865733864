import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/useAuth";

const PrivateRoute = () => {
    const token = useAuth();
    const location = useLocation();

    if (token) {
        // If user is authenticated and trying to access protected routes
        return <Outlet />;
    }

    // If user is not authenticated, redirect to /login
    return <Navigate to='/login' state={{ from: location }} replace />;
};

const PublicRoute = () => {
    const token = useAuth();
    const location = useLocation();

    if (token) {
        // If user is authenticated and trying to access /login or /
        return <Navigate to={location.state?.from || '/user'}  />;
    }

    // Allow access to login if not authenticated
    return <Outlet />;
};

export { PrivateRoute, PublicRoute };
