import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { BroadcastChannel } from 'broadcast-channel';
// import { useAuth } from "../contexts/isAuthed";
import { AuthProvider, useAuth } from '../contexts/authContext';
import LogoutIcon from '@mui/icons-material/Logout';
import { TextField, InputAdornment } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import AdbIcon from '@mui/icons-material/Adb';

import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PlaceIcon from '@mui/icons-material/Place';
import { useForm } from 'react-hook-form';  // useForm hook for form handling
import { yupResolver } from '@hookform/resolvers/yup';  // Yup resolver for React Hook Form
import * as Yup from 'yup';  // Yup schema for validation
import {

	Drawer,
	List,
	ListItem,
	ListItemText
} from '@mui/material';



const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: "#cbcbcb",
	},
	'&:focus ': {
		backgroundColor: "#cbcbcb",
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(3),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));

const onSubmit = (data) => {
	console.log(data.customerID)
	 window.location.href = `/profile/${data.customerID}`;

};

export default function Navbar() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const [customerID, setCustomerID] = useState("0")

	const { isAuthenticated, logout } = useAuth();
	const User = localStorage.getItem("User")
	const branch = localStorage.getItem("loggedBranch")
	const Role = localStorage.getItem("Roles")
	const [openDrawer, setOpenDrawer] = useState(false);

	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const pages = ['Products', 'Pricing', 'Blog'];
	const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

	const schema = Yup.object({
		customerID: Yup.string()
			.matches(/^\d+$/, "Customer ID must be a number") // Ensure only digits are allowed
			.required("Customer ID is required"), // Required field
	}).required()


	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),  // Passing the validation schema via yupResolver
	});


	const toggleDrawer = () => setOpenDrawer(!openDrawer);
	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const navigate = useNavigate();

	const handleLogout = () => {
		logout();
		navigate('/login'); // Redirect to login page or home page
	};

	// function navigateFunc() {

	// }


	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>

			<div style={{ alignItems: "center", padding: "20px", textAlign: "center" }}>
				<p style={{ fontWeight: "bold" }}>{User}</p>
				<p style={{ fontWeight: "bold" }}>{branch}</p>
			</div>

			<MenuItem sx={{ alignItems: "center", textAlign: "center" }} onClick={handleLogout}> <LogoutIcon /> Logout</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton size="large" aria-label="show 4 new mails" color="inherit">
					<Badge badgeContent={4} color="error">
						<MailIcon />
					</Badge>
				</IconButton>
			</MenuItem>
			<MenuItem>
				<IconButton
					size="large"
					aria-label="show 17 new notifications"
					color="inherit"
					sx={{ borderRadius: "0", }}
				>
					<Badge badgeContent={17} color="error">
						<AddBoxIcon />
					</Badge>
				</IconButton>
				<p>Add user</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
		</Menu>
	);

	return (
		// 	<Box sx={{ flexGrow: 1, marginBottom: "10px", width: "100%" }}>
		// 	<AppBar elevation={0} sx={{ backgroundColor: "#ffffff", color: "black", width: "100%", borderBottom: "1px solid #e4e4e4" }} position="static">
		// 	  <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

		// 		{/* Left side of AppBar */}
		// 		<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
		// 		  <Link to="/user">
		// 			<IconButton
		// 			  size="large"
		// 			  edge="start"
		// 			  color="inherit"
		// 			  aria-label="home"
		// 			  sx={{ mr: 2 }}
		// 			>
		// 			  <HomeIcon />
		// 			</IconButton>
		// 		  </Link>

		// 		  {/* Title for larger screens */}
		// 		  <Typography
		// 			variant="h6"
		// 			noWrap
		// 			component="div"
		// 			sx={{ display: { xs: 'none', sm: 'block' }, fontWeight: 'bold' }}
		// 		  >
		// 			ENERGY
		// 		  </Typography>

		// 		  {/* Search bar for larger screens */}
		// 		  <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
		// 			<IconButton sx={{ border: '1px solid #ccc', padding: 1 }}>
		// 			  <SearchIcon />
		// 			</IconButton>
		// 			<input
		// 			  type="text"
		// 			  placeholder="Search customer"
		// 			  value={customerID}
		// 			  onChange={(e) => setCustomerID(e.target.value)}
		// 			  onKeyDown={(e) => {
		// 				if (e.key === "Enter") navigateFunc();
		// 			  }}
		// 			  style={{
		// 				padding: '6px',
		// 				borderRadius: '4px',
		// 				border: '1px solid #ccc',
		// 				outline: 'none',
		// 			  }}
		// 			/>
		// 		  </Box>
		// 		</Box>

		// 		{/* Right side of AppBar (Buttons and Account) */}
		// 		<Box sx={{ display: 'flex', alignItems: 'center' }}>
		// 		  {/* Conditional render based on Role */}
		// 		  {Role === "Admin" && (
		// 			<Link to="/add-user">
		// 			  <IconButton
		// 				sx={{
		// 				  backgroundColor: "#c7c7c7",
		// 				  borderRadius: "7px",
		// 				  height: "35px",
		// 				  margin: "3px",
		// 				  width: "120px"
		// 				}}
		// 				size="large"
		// 				color="inherit"
		// 			  >
		// 				<AddBoxIcon />
		// 				<label style={{ fontSize: "14px", fontWeight: "bold" }}>User</label>
		// 			  </IconButton>
		// 			</Link>
		// 		  )}

		// 		  {Role === "Admin" || Role === "Receptionise" && (
		// 			<Link to="/add-customer">
		// 			  <IconButton
		// 				sx={{
		// 				  backgroundColor: "#c7c7c7",
		// 				  borderRadius: "7px",
		// 				  height: "35px",
		// 				  margin: "3px",
		// 				  width: "120px"
		// 				}}
		// 				size="large"
		// 				color="inherit"
		// 			  >
		// 				<AddBoxIcon />
		// 				<label style={{ fontSize: "14px", fontWeight: "bold" }}>Customer</label>
		// 			  </IconButton>
		// 			</Link>
		// 		  )}

		// 		  <Link to="/customers">
		// 			<IconButton
		// 			  sx={{
		// 				backgroundColor: "#c7c7c7",
		// 				borderRadius: "7px",
		// 				height: "35px",
		// 				margin: "3px",
		// 				width: "120px"
		// 			  }}
		// 			  size="large"
		// 			  color="inherit"
		// 			>
		// 			  <label style={{ fontSize: "14px", fontWeight: "bold" }}>Gym</label>
		// 			</IconButton>
		// 		  </Link>

		// 		  <Link to="/classes/all-classes">
		// 			<IconButton
		// 			  sx={{
		// 				backgroundColor: "#c7c7c7",
		// 				borderRadius: "7px",
		// 				height: "35px",
		// 				margin: "3px",
		// 				width: "120px"
		// 			  }}
		// 			  size="large"
		// 			  color="inherit"
		// 			>
		// 			  <label style={{ fontSize: "14px", fontWeight: "bold" }}>Class</label>
		// 			</IconButton>
		// 		  </Link>

		// 		  <Link to="/academy">
		// 			<IconButton
		// 			  sx={{
		// 				backgroundColor: "#c7c7c7",
		// 				borderRadius: "7px",
		// 				height: "35px",
		// 				margin: "3px",
		// 				width: "120px"
		// 			  }}
		// 			  size="large"
		// 			  color="inherit"
		// 			>
		// 			  <label style={{ fontSize: "14px", fontWeight: "bold" }}>Academy</label>
		// 			</IconButton>
		// 		  </Link>

		// 		  {/* Account Icon for larger screens */}
		// 		  <IconButton
		// 			size="large"
		// 			edge="end"
		// 			aria-label="account of current user"
		// 			aria-controls="menu-id"
		// 			aria-haspopup="true"
		// 			onClick={handleProfileMenuOpen}
		// 			color="inherit"
		// 			sx={{ display: { xs: 'none', sm: 'flex' } }}
		// 		  >
		// 			<AccountCircle />
		// 		  </IconButton>
		// 		</Box>

		// 		{/* Mobile Menu for small screens */}
		// 		<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
		// 		  <IconButton
		// 			size="large"
		// 			edge="end"
		// 			aria-label="home"
		// 			color="inherit"
		// 			onClick={handleProfileMenuOpen}
		// 		  >
		// 			<HomeIcon />
		// 		  </IconButton>
		// 		</Box>
		// 	  </Toolbar>
		// 	</AppBar>
		// 	{renderMobileMenu}
		// 	{renderMenu}
		//   </Box>

		// 		<Box width="90%" margin="auto" height="50px" backgroundColor="whitesmoke" display="flex" flexDirection="row" alignItems="center" textAlign="center" justifyContent="space-around">
		// 			<Box
		//   display="flex"
		//   flexDirection="row"
		//   alignItems="center"
		//   textAlign="center"
		//   sx={{
		// 	padding: { xs: '10px', sm: '20px' },
		// 	gap: { xs: '10px', sm: '20px' },
		//   }}
		// >
		//   {/* Home Icon */}
		//   <HomeIcon sx={{ fontSize: 30, color: 'gray' }} />

		//   {/* Search Input */}
		//   <TextField
		//   variant="outlined"
		//   fullWidth
		//   placeholder="Search..."
		//   size="small"
		//   sx={{
		//     width: { xs: '100%', sm: '150px' },  // Responsive width (full-width on xs, 150px on sm+)
		//     borderRadius: '10px',
		//     backgroundColor: '#e4e4e4',  // Set background color
		//     '& .MuiOutlinedInput-root': {
		//       height: '40px',  // Adjusts the height of the TextField
		//       backgroundColor: '#e4e4e4',  // Ensures background color inside the input
		//       '& fieldset': {
		//         borderColor: 'transparent',  // Remove default border color
		//       },
		//       '&:hover fieldset': {
		//         borderColor: 'transparent',  // Prevent border change on hover
		//       },
		//     },
		//     // Optionally, add border on focus without changing the fieldset color
		//     '&:focus-within .MuiOutlinedInput-root': {
		//       boxShadow: 'none', // Remove any box-shadow when focused
		//     }
		//   }}
		//   InputProps={{
		//     startAdornment: (
		//       <InputAdornment position="start">
		//         <SearchIcon sx={{ color: 'gray' }} />
		//       </InputAdornment>
		//     ),
		//   }}
		//   />
		// </Box>
		// <Box
		//   display="flex"
		//   alignItems="center"
		//   textAlign="center"
		//   flexDirection={{ xs: 'column', sm: 'row' }}
		//     justifyContent="space-around"
		//   sx={{
		// 	padding: { xs: '10px', sm: '20px' },
		// 	gap: { xs: '10px', sm: '20px' },
		//   }}
		// >
		// <Box
		// sx={{cursor:"pointer"}}
		// display="flex" flexDirection="row">
		// 	<ControlPointIcon  style={{	color:"#1b874b"}}	/>
		// <Typography
		//     fontFamily="Poppins"
		//     fontWeight="500 !important;"
		// 	color="#1b874b "
		//     sx={{
		//       fontSize: { xs: '9px', sm: '13px', md: '15px' },
		//       transition: 'border-bottom 0.3s ease',  // Smooth transition for the border

		//     }}
		//   >
		//      User
		//   </Typography>

		// </Box>
		// <Box
		// sx={{cursor:"pointer"}}
		// display="flex" flexDirection="row">
		// 	<ControlPointIcon  style={{	color:"#1b874b"}}	/>
		// <Typography
		//     fontFamily="Poppins"
		//     fontWeight="500 !important;"
		// 	color="#1b874b "
		//     sx={{
		//       fontSize: { xs: '9px', sm: '13px', md: '15px' },
		//       transition: 'border-bottom 0.3s ease',  // Smooth transition for the border

		//     }}
		//   >
		//      Customer
		//   </Typography>

		// </Box>

		//   <Typography
		//     fontFamily="Poppins"
		//     fontWeight="500 !important;"
		// 	color="black"

		//     sx={{
		//       fontSize: { xs: '9px', sm: '13px', md: '15px' },
		//       transition: 'border-bottom 0.3s ease', 
		// 	  cursor:"pointer"

		//     }}
		//   >
		//     Gym
		//   </Typography>

		//   <Typography
		//     fontFamily="Poppins"
		//     fontWeight="500 !important;"
		// 	color="black"

		//     sx={{
		//       fontSize: { xs: '9px', sm: '13px', md: '15px' },
		//       transition: 'border-bottom 0.3s ease', 
		// 	  cursor:"pointer"

		//     }}
		//   >
		//     Class
		//   </Typography>

		//   <Typography
		//     fontFamily="Poppins"
		//     fontWeight="500 !important;"
		// 	color="black"


		//     sx={{
		//       fontSize: { xs: '9px', sm: '13px', md: '15px' },
		//       transition: 'border-bottom 0.3s ease', 
		// 	  cursor:"pointer"

		//     }}
		//   >
		//     Academy
		//   </Typography>
		// </Box>

		// <Box>
		// <IconButton sx={{height:"30px"}} onClick={handleClick}>
		//         <Avatar  sx={{height:"30px", width:"30px"}} />
		//       </IconButton>
		// <Menu
		//         anchorEl={anchorEl}
		//         id="account-menu"
		//         open={open}
		//         onClose={handleClose}
		//         onClick={handleClose}
		//         slotProps={{
		//           paper: {
		//             elevation: 0,
		//             sx: {
		//               overflow: 'visible',
		//               filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
		//               mt: 1.5,
		//               '& .MuiAvatar-root': {
		//                 width: 32,
		//                 height: 32,
		//                 ml: -0.5,
		//                 mr: 1,
		//               },
		//               '&::before': {
		//                 content: '""',
		//                 display: 'block',
		//                 position: 'absolute',
		//                 top: 0,
		//                 right: 14,
		//                 width: 10,
		//                 height: 10,
		//                 bgcolor: 'background.paper',
		//                 transform: 'translateY(-50%) rotate(45deg)',
		//                 zIndex: 0,
		//               },
		//             },
		//           },
		//         }}
		//         transformOrigin={{ horizontal: 'right', vertical: 'top' }}
		//         anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
		//       >
		//         <MenuItem onClick={handleClose}>
		//           <Avatar /> Profile
		//         </MenuItem>
		//         <MenuItem onClick={handleClose}>
		//           <Avatar /> My account
		//         </MenuItem>
		//         <Divider />
		//         <MenuItem onClick={handleClose}>
		//           <ListItemIcon>
		//             <PersonAdd fontSize="small" />
		//           </ListItemIcon>
		//           Add another account
		//         </MenuItem>
		//         <MenuItem onClick={handleClose}>
		//           <ListItemIcon>
		//             <Settings fontSize="small" />
		//           </ListItemIcon>
		//           Settings
		//         </MenuItem>
		//         <MenuItem onClick={handleClose}>
		//           <ListItemIcon>
		//             <Logout fontSize="small" />
		//           </ListItemIcon>
		//           Logout
		//         </MenuItem>
		//       </Menu>
		// </Box>

		// 		</Box>



		<AppBar position="absloute" sx={{ height: "60px", backgroundColor: "inherit", boxShadow: "none" }}>
			<Container maxWidth="xl">
				<Toolbar sx={{ justifyContent: 'space-between', height: '60px' }} disableGutters>

					{/* Logo and Search Field for large screens */}
					<Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
						<AdbIcon sx={{ mr: 1 }} />
						<Typography
							variant="h6"
							noWrap
							component="a"
							href="#"
							sx={{
								mr: 2,
								fontFamily: 'monospace',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							ENERGY
						</Typography>
						<form style={{ width: "fit-content", height: "fit-content", padding: "0" }} onSubmit={handleSubmit(onSubmit)}>
							<TextField
								{...register("customerID")}  // Register the input field
								variant="outlined"
								placeholder="Search..."
								size="small"
								sx={{
									width: { xs: '100%', sm: '150px' },
									borderRadius: '10px',
									backgroundColor: '#e4e4e4',
									'& .MuiOutlinedInput-root': {
										height: '40px',
										backgroundColor: '#e4e4e4',
										'& fieldset': {
											borderColor: 'transparent',
										},
										'&:hover fieldset': {
											borderColor: 'transparent',
										},
									},
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon sx={{ color: 'gray' }} />
										</InputAdornment>
									),
								}}
								error={!!errors.customerID}  // Show error if validation fails
							/>


						</form>
					</Box>

					{/* Mobile Menu Icon */}
					<Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
						<IconButton

							size="large"
							aria-label="open navigation menu"
							onClick={toggleDrawer}
							color="inherit"
						>
							<MenuIcon sx={{ color: "black" }} />
						</IconButton>
						<Drawer
							anchor="left"
							open={openDrawer}
							onClose={toggleDrawer}
						>
							<List>
								<ListItem>
								<form style={{ width: "fit-content", height: "fit-content", padding: "0" }} onSubmit={handleSubmit(onSubmit)}>
							<TextField
								{...register("customerID")}  // Register the input field
								variant="outlined"
								placeholder="Search..."
								size="small"
								sx={{
									width: { xs: '100%', sm: '150px' },
									borderRadius: '10px',
									backgroundColor: '#e4e4e4',
									'& .MuiOutlinedInput-root': {
										height: '40px',
										backgroundColor: '#e4e4e4',
										'& fieldset': {
											borderColor: 'transparent',
										},
										'&:hover fieldset': {
											borderColor: 'transparent',
										},
									},
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon sx={{ color: 'gray' }} />
										</InputAdornment>
									),
								}}
								error={!!errors.customerID}  // Show error if validation fails
							/>


						</form>
								</ListItem>
								<ListItem button onClick={() => navigate("/user")}>
									<ListItemText fontWeight="Bold" fontFamily="poppins" primary="Dashboard" />
								</ListItem>

								{Role == "Admin" ? (
									<ListItem button onClick={() => navigate("/add-user")}>
									<ListItemText fontWeight="Bold" fontFamily="poppins" primary="User" />
								</ListItem>
								):("")}
								
								<ListItem button onClick={() => navigate("/add-customer")} >
									<ListItemText fontWeight="Bold" fontFamily="poppins" primary="Customer" />
								</ListItem>
								{Role === "Admin" || Role === "Receptionist" ? (<ListItem button onClick={() => navigate("/customers")}>
									<ListItemText fontWeight="Bold" fontFamily="poppins" primary="Gym" />
								</ListItem>):("")}

								
								<ListItem button onClick={() => navigate("/classes/all-classes")}>
									<ListItemText fontWeight="Bold" fontFamily="poppins" primary="Class" />
								</ListItem>
								<ListItem button onClick={() => navigate("/academy")}>
									<ListItemText fontWeight="Bold" fontFamily="poppins" primary="Academy" />
								</ListItem>
							</List>
						</Drawer>
					</Box>

					{/* Navigation Links for large screens */}
					<Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
						<MenuItem onClick={() => navigate("/user")} sx={{ cursor: 'pointer' }}>
							<Typography fontFamily="Poppins" fontWeight="700" color="black" sx={{ fontSize: { xs: 9, sm: 12, md: 14 } }}>
								Home
							</Typography>
						</MenuItem>

						{Role === "Admin" && (
							<Link to="/add-user">
								<MenuItem sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
									<ControlPointIcon sx={{ color: '#1b874b', height: "20px", width: "20px" }} />
									<Typography fontFamily="Poppins" fontWeight="600" color="#1b874b" sx={{ fontSize: { xs: 9, sm: 12, md: 14 } }}>
										User
									</Typography>
								</MenuItem>
							</Link>
						)}


						{Role === "Admin" || Role === "Receptionist" ? (
							<Link to="/add-customer">
								<MenuItem sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
									<ControlPointIcon sx={{ color: '#1b874b', height: "20px", width: "20px" }} />
									<Typography fontFamily="Poppins" fontWeight="600" color="#1b874b" sx={{ fontSize: { xs: 9, sm: 12, md: 14 } }}>
										Customer
									</Typography>
								</MenuItem>
							</Link>
						) : ""}



						<Link to="/customers">
							<MenuItem sx={{ cursor: 'pointer' }}>
								<Typography fontFamily="Poppins" fontWeight="600" color="black" sx={{ fontSize: { xs: 9, sm: 12, md: 14 } }}>
									Gym
								</Typography>
							</MenuItem>
						</Link>


						<Link to="/classes/all-classes">
							<MenuItem sx={{ cursor: 'pointer' }}>
								<Typography fontFamily="Poppins" fontWeight="600" color="black" sx={{ fontSize: { xs: 9, sm: 12, md: 14 } }}>
									Class
								</Typography>
							</MenuItem>
						</Link>

						<Link to="/academy">
							<MenuItem sx={{ cursor: 'pointer' }}>
								<Typography fontFamily="Poppins" fontWeight="600" color="black" sx={{ fontSize: { xs: 9, sm: 12, md: 14 } }}>
									Academy
								</Typography>
							</MenuItem>
						</Link>
					</Box>

					{/* User Avatar / Settings */}
					<Box sx={{ flexGrow: 0 }}>
						<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

							<Tooltip title="Account settings">
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? 'account-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
								>
									<Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
								</IconButton>
							</Tooltip>
						</Box>
						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							slotProps={{
								paper: {
									elevation: 0,
									sx: {
										overflow: 'visible',
										filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
										mt: 1.5,
										'& .MuiAvatar-root': {
											width: 32,
											height: 32,
											ml: -0.5,
											mr: 1,
										},
										'&::before': {
											content: '""',
											display: 'block',
											position: 'absolute',
											top: 0,
											right: 14,
											width: 10,
											height: 10,
											bgcolor: 'background.paper',
											transform: 'translateY(-50%) rotate(45deg)',
											zIndex: 0,
										},
									},
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>

							<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" textAlign="center" onClick={handleClose}>
								<PermIdentityIcon />
								<Typography fontWeight="bold">
									{User}
								</Typography>

							</Box>
							<Box display="flex" justifyContent="center" flexDirection="row" alignItems="center" textAlign="center" onClick={handleClose}>

								<PlaceIcon />
								<Typography fontWeight="bold">
									{branch}
								</Typography>
							</Box>
							<MenuItem onClick={handleLogout}>
								<ListItemIcon>
									<Logout fontSize="small" />
								</ListItemIcon>
								Logout
							</MenuItem>
						</Menu>
					</Box>

				</Toolbar>
			</Container>
		</AppBar>
	);
}