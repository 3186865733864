
// import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
// import ReactDeleteRow from 'react-delete-row';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ToastContainer, toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



const API = process.env.REACT_APP_API
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function CustomerAttanedancePage() {
  const [rerender, setRerender] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [AcademyOpen, setAcademyOpen] = React.useState(false);

  const [ClassOpen, setClassOpen] = React.useState(false);

  const [academyData, setAcadeyData] = React.useState([])
  const [expanded, setExpanded] = React.useState('panel1');
  const role = localStorage.getItem("Roles")

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  
  const handleClickAcademyOpen = () => {
    setAcademyOpen(true );
  };

  const handleAcademyClose = () => {
    setAcademyOpen(false);
  };


  const handleClickClassOpen = () => {
    setClassOpen(true );
  };

  const handleClassClose = () => {
    setClassOpen(false);
  };



  const params = useParams()
  const customerID = params.customerID
  const [loading, setLoading] = React.useState()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [gymAttendance, setGymAttendance] = React.useState([])
  const [cancelSessionID, setCancelSessionID] = React.useState()
  const [sessionDate, setSessionDate] = React.useState()
  const [academyLoading, setAcademyLoading] = React.useState()
  const [gymDateQuery, setGymDateQuerry] = React.useState("")
  const [classData, setClassData] = React.useState()
  const [classLoading, setClassLoading] = React.useState()

  console.log(sessionDate)

  React.useEffect(function () {
    async function fetchGymDate() {
      try {
        setLoading(true)
        const res = await fetch(`${API}/getGymAttendance/${customerID}`)
        const data = await res.json()
        console.log(data)
        setGymAttendance(data.data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)

      }
    }
    fetchGymDate()


  }, [rerender])


  React.useEffect(function () {
    async function fetchAcademyData() {
      try {
        setAcademyLoading(true)
        const res = await fetch(`${API}/academyAttemdance/${customerID}`)
        const data = await res.json()
        setAcadeyData(data.data)
      } catch (e) {
        console.log(e)
      } finally {
        setAcademyLoading(false)

      }
    }
    fetchAcademyData()

  }, [rerender])


  React.useEffect(function () {
    async function fetchClassData() {
      try {
        setClassLoading(true)
        const res = await fetch(`${API}/classAttendance/${customerID}`)
        const data = await res.json()
        setClassData(data)
      } catch (e) {
        console.log(e)
      } finally {
        setClassLoading(false)

      }
    }
    fetchClassData()

  }, [rerender])

  console.log(academyData)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  function handleOnDelete(a) {
    setCancelSessionID(a)
    handleClickOpen()
  }

  function handleOnAcademyDelete(a) {
    setCancelSessionID(a)
    handleClickAcademyOpen()
  }

  function handleOnClassDelete(a) {
    setCancelSessionID(a)
    handleClickClassOpen()
  }


  function handleDeleteSession() {
    fetch(`${API}/camcelSession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'sessionID': cancelSessionID,
        'customerID': customerID,

      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRerender(!rerender)
        }
      })
    handleClose()
  }


  function handleDeleteAcademySession() {
    fetch(`${API}/camcelAcademySession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'sessionID': cancelSessionID,
        'customerID': customerID,

      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRerender(!rerender)
        }
      })
    handleAcademyClose()
  }


  function handleClassDeleteSession() {
    fetch(`${API}/camcelClassSession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'sessionID': cancelSessionID,
        'customerID': customerID,
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRerender(!rerender)
        }
      })
    handleClassClose()
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const data = [
    { title: 'One', body: 'one' },
    { title: 'Two', body: 'two' },
    { title: 'Three', body: 'three' }
  ];


  console.log(classData)
  return (
    <div className="attendandce">
      <ToastContainer
        autoClose={false}
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />






      <Typography>
        <TableContainer style={{ width: "70%", margin: "80px auto" }} component={Paper}>


          <Divider>GYM Attendance</Divider>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
              <StyledTableCell style={{ width: "50%", fontWeight: "bold" }} align="center">Date</StyledTableCell>

                <StyledTableCell style={{ width: "50%,", fontWeight: "bold" }} align="center"> Branch</StyledTableCell>
                <StyledTableCell style={{ width: "50%,", fontWeight: "bold" }} align="center"> </StyledTableCell>



              </TableRow>
            </TableHead>
            <TableBody>
              {gymAttendance?.map((row) => (
                <StyledTableRow key={row.customerID}>
                  <StyledTableCell align="center">{row.AttendanceDate}</StyledTableCell>
                  <StyledTableCell align="center">{row.Branch}</StyledTableCell>

                  <StyledTableCell align="center">
                    {role === "Admin" ? (   <Button onClick={() => handleOnDelete(row.id)} style={{ color: "red", border: "1px solid red" }} variant="outlined" startIcon={<DeleteIcon style={{ color: "red" }} />}>
                      Delete
                    </Button>
                    ) :("")}
                 
                    </StyledTableCell>


                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Typography>

{/* Gym Dialog */}
      <Dialog

        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ fontWeight: "bold", fontFamily: "poppins" }}> {"      Delete GYM session?       "}</DialogTitle>

        <DialogActions>
          <Button style={{ border: "2px solid red", color: "red", fontWeight: "bold" }} onClick={handleClose}>Close</Button>

          <Button style={{ border: "2px solid blue", backgroundColor: "#0065bd", color: "white", fontWeight: "bold" }} onClick={handleDeleteSession}>Confirm</Button>
        </DialogActions>
      </Dialog>


{/* Academy Dialog */}

      <Dialog
        open={AcademyOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAcademyClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ fontWeight: "bold", fontFamily: "poppins" }}> {"     Delete Academy session?      "}</DialogTitle>

        <DialogActions>
          <Button style={{ border: "2px solid red", color: "red", fontWeight: "bold" }} onClick={handleClose}>Close</Button>

          <Button style={{ border: "2px solid blue", backgroundColor: "#0065bd", color: "white", fontWeight: "bold" }} onClick={handleDeleteAcademySession}>Confirm</Button>
        </DialogActions>
      </Dialog>


{/* class Dialog */}
      <Dialog
        open={ClassOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClassClose}
        aria-describedby="alert-dialog-slide-description"
      >



        <DialogTitle style={{ fontWeight: "bold", fontFamily: "poppins" }}> {"      Delete Class session?       "}</DialogTitle>

        <DialogActions>
          <Button style={{ border: "2px solid red", color: "red", fontWeight: "bold" }} onClick={handleClose}>Close</Button>

          <Button style={{ border: "2px solid blue", backgroundColor: "#0065bd", color: "white", fontWeight: "bold" }} onClick={handleClassDeleteSession}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {console.log(academyData)}


      {academyData !== "undefined" ? (<TableContainer style={{ width: "70%", margin: "80px auto" }} component={Paper}>


        <Divider>Academy Attendance</Divider>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "50%", fontWeight: "bold" }} align="center">Date</StyledTableCell>
              <StyledTableCell style={{ width: "50%", fontWeight: "bold" }} align="center">Branch</StyledTableCell>
              <StyledTableCell style={{ width: "50%,", fontWeight: "bold" }} align="center"> </StyledTableCell>



            </TableRow>
          </TableHead>
          <TableBody>
            {academyData?.map((row) => (
              <StyledTableRow key={row.customerID}>
                <StyledTableCell align="center">{row.Date}</StyledTableCell>
                <StyledTableCell align="center">{row.Branch}</StyledTableCell>

                <StyledTableCell align="center">
              {role === "Admin" ? (    <Button onClick={() => handleOnAcademyDelete(row.id)} style={{ color: "red", border: "1px solid red" }} variant="outlined" startIcon={<DeleteIcon style={{ color: "red" }} />}>
                    Delete
                  </Button>) : ("")}
                  </StyledTableCell>


              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>) : ("")}


      {classData !== "undefined" ? (<TableContainer style={{ width: "70%", margin: "80px auto" }} component={Paper}>


        <Divider>Class Attendance</Divider>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "50%", fontWeight: "bold" }} align="center">Date</StyledTableCell>
              <StyledTableCell style={{ width: "50%", fontWeight: "bold" }} align="center">Branch</StyledTableCell>
              <StyledTableCell style={{ width: "50%,", fontWeight: "bold" }} align="center"> </StyledTableCell>



            </TableRow>
          </TableHead>
          <TableBody>
            {classData?.map((row) => (
              <StyledTableRow key={row.customerID}>
                <StyledTableCell align="center">{row.AttendanceDate}</StyledTableCell>
                <StyledTableCell align="center">{row.Branch}</StyledTableCell>

                <StyledTableCell align="center">
              {role === "Admin" ? (    <Button onClick={() => handleOnClassDelete(row.id)} style={{ color: "red", border: "1px solid red" }} variant="outlined" startIcon={<DeleteIcon style={{ color: "red" }} />}>
                    Delete
                  </Button>):("")}
                  </StyledTableCell>


              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>) : ("")}



    </div>
  )
}
export default CustomerAttanedancePage

