// import * as React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import MuiDrawer from '@mui/material/Drawer';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import CssBaseline from '@mui/material/CssBaseline';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import { Outlet, useNavigate } from 'react-router-dom';
// import SendIcon from '@mui/icons-material/Send';
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
// import { deepOrange, deepPurple } from '@mui/material/colors';
// import CircularProgress from '@mui/material/CircularProgress';
// import { IoMdHome } from "react-icons/io";
// import Drawer from '@mui/material/Drawer';

// import Navbar from '../components/navbar'
// const userID = localStorage.getItem("userID")
//   ;

// const drawerWidth = 240;
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(['margin', 'width'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-end',
// }));


// // Avatar

// export default function MiniDrawer() {
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(false);
//   const navigate = useNavigate()


//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   return (
//     <>
//       <Box sx={{ display: 'flex' }}>

//         <CssBaseline />
//         <AppBar position="fixed" open={open}>
//           <Toolbar sx={{ backgroundColor: "#000c19" }}>
//             <IconButton
//               color="inherit"
//               aria-label="open drawer"
//               onClick={handleDrawerOpen}
//               edge="start"
//               sx={{ mr: 2, ...(open && { display: 'none' }) }}
//             >
//               <MenuIcon />
//             </IconButton>

//             <Typography variant="h6" noWrap component="div">
//               Revenue
//             </Typography>

//             <div style={{ marginLeft: "80%", width: "70px", position: "relative", border: "1px solid white" }} onClick={() => navigate(`/user`)} className="home-label">

//               <IoMdHome />
//               <h7 style={{ fontWeight: "bold", fontSize: "13px" }}>Home</h7>
//             </div>

//           </Toolbar>
//         </AppBar>
//         <Drawer sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//           variant="persistent"
//           anchor="left"
//           open={open}>
//           <DrawerHeader>
//             <IconButton onClick={handleDrawerClose}>
//               {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}          </IconButton>
//           </DrawerHeader>
//           <Divider />
//           <List>

//             <ListItem disablePadding sx={{ display: 'block' }}>
//               <ListItemButton onClick={() => navigate("all")}>
//                 <ListItemIcon

//                 >
//                 </ListItemIcon>
//                 <ListItemText primary="All" sx={{ opacity: open ? 1 : 0 }} />

//               </ListItemButton>

//               <ListItemButton onClick={() => navigate("academy")}>
//                 <ListItemIcon

//                 >

//                 </ListItemIcon>
//                 <ListItemText primary="Academy" sx={{ opacity: open ? 1 : 0 }} />

//               </ListItemButton>


//               <ListItemButton onClick={() => navigate("gym")}>
//                 <ListItemIcon

//                 >

//                 </ListItemIcon>
//                 <ListItemText primary="Gym" sx={{ opacity: open ? 1 : 0 }} />

//               </ListItemButton>


//               <ListItemButton onClick={() => navigate("class")}>
//                 <ListItemIcon

//                 >


//                 </ListItemIcon>
//                 <ListItemText primary="Class" sx={{ opacity: open ? 1 : 0 }} />

//               </ListItemButton>


         


//               <ListItemButton onClick={() => navigate("diet")}>
//                 <ListItemIcon

//                 >

//                 </ListItemIcon>
//                 <ListItemText primary="Diet" sx={{ opacity: open ? 1 : 0 }} />

//               </ListItemButton>

//               <ListItemButton onClick={() => navigate("AllFinancetime")}>
//                 <ListItemIcon

//                 >

//                 </ListItemIcon>
//                 <ListItemText primary="All by Time" sx={{ opacity: open ? 1 : 0 }} />

//               </ListItemButton>

//               <ListItemButton onClick={() => navigate("class-trainer-finance")}>
//                 <ListItemIcon

//                 >

//                 </ListItemIcon>
//                 <ListItemText primary="Class Trainers" sx={{ opacity: open ? 1 : 0 }} />

//               </ListItemButton>





//             </ListItem>

//           </List>
//           <Divider />
//           <List>
//             <ListItem disablePadding sx={{ display: 'block' }}>
//               <ListItemButton
//                 onClick={() => navigate("classz")}

//                 sx={{
//                   minHeight: 48,
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   onClick={() => navigate("academy")}

//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : 'auto',
//                     justifyContent: 'center',
//                   }}
//                 >
//                 </ListItemIcon>
//                 <ListItemText sx={{ opacity: open ? 1 : 0 }} />
//               </ListItemButton>
//             </ListItem>

//           </List>
//         </Drawer>
//         <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
//           <DrawerHeader />
//           <Outlet />
//           {/* <Typography paragraph>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//           tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
//           enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
//           imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
//           Convallis convallis tellus id interdum velit laoreet id donec ultrices.
//           Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
//           adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
//           nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
//           leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
//           feugiat vivamus at augue. At augue eget arcu dictum varius duis at
//           consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
//           sapien faucibus et molestie ac.
//         </Typography>
//         <Typography paragraph>
//           Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
//           eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
//           neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
//           tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
//           sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
//           tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
//           gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
//           et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
//           tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
//           eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
//           posuere sollicitudin aliquam ultrices sagittis orci a.
//         </Typography> */}
//         </Box>
//       </Box>

//     </>

//   );
// }


import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ClassIcon from '@mui/icons-material/Class';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PeopleIcon from '@mui/icons-material/People';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Outlet, useNavigate } from 'react-router-dom';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const theme = createTheme({
  typography: {
    fontFamily: 'Cairo, sans-serif', // Set font family here
  },
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}> {/* Wrap your component with ThemeProvider */}

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        
   
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>

          <IconButton
          
            open={open}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
                color: "black",
                margin:"auto",
              },
              open && { marginRight:"80%"},
            ]}
          >
            <MenuIcon />
          </IconButton>
      
      
        </DrawerHeader>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh' }}> {/* Center the list horizontally at the top */}
            <List sx={{ width: '80%', fontFamily: "Cairo" }}>
              {[
                { text: 'Dashboard', icon: <HomeIcon /> , nav:"/user"},
                { text: 'All', icon: <FileDownloadDoneIcon /> , nav:"all"},
                { text: 'Academy', icon: <SportsGymnasticsIcon />, nav:"academy" },
                { text: 'GYM', icon: <FitnessCenterIcon />  , nav:"gym" },
                { text: 'Class', icon: <SelfImprovementIcon /> ,  nav:"class" },
                { text: 'Diet', icon: <FastfoodIcon />  ,  nav:"diet" },
                { text: 'Clinic', icon: <MedicalInformationIcon />  ,  nav:"clinic" },
                { text: 'Rent', icon: <AddLocationAltIcon />  ,  nav:"rent" },


                { text: 'All by time', icon: <ScheduleIcon />  ,nav:"all-finance-time" },
                { text: 'Class Trainers', icon: <PeopleIcon /> , nav:"class-trainer-finance" },
              ].map((item, index) => (
                <ListItem onClick={()=> navigate(item.nav)} key={item.text} disablePadding>
                  <ListItemButton
                    sx={[
                      {
                        minHeight: 48,
                        marginBottom:"8px",
                        px: 2.5,
                        borderRadius: '8px',
                        backgroundColor: 'transparent', // No initial background color
                        color: '#034460', // Default text and icon color
                        '&:hover': {
                          backgroundColor: '#027fb4', // Button color on hover
                          color: 'white', // Text color on hover
                          '& .MuiListItemIcon-root': {
                            color: 'white', // Icon color on hover
                          },
                          '& .MuiListItemText-primary': {
                            color: 'white', // Text color on hover
                          },
                        },
                        '&:focus': {
                          backgroundColor: '#027fb4', // Button color on focus
                          color: 'white', // Text color on focus
                          '& .MuiListItemIcon-root': {
                            color: 'white', // Icon color on focus
                          },
                          '& .MuiListItemText-primary': {
                            color: 'white', // Text color on focus
                          },
                        },
                      },
                      open ? { justifyContent: 'initial' } : { justifyContent: 'center' },
                    ]}
                  >
                    <ListItemIcon
                      sx={[
                        {
                          minWidth: 0,
                          justifyContent: 'center',
                          color: '#034460', // Default icon color
                        },
                        open ? { mr: 3 } : { mr: 'auto' },
                      ]}
                    >
                      {item.icon} {/* Use the descriptive icon here */}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={[
                        {
                          color: '#152238',
                          fontWeight: 'bold',
                          '&:focus': {
                            color: 'black',
                          },
                        },
                        open ? { opacity: 1 } : { opacity: 0 },
                      ]}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
        </Box>

        <Divider />
       
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
       <Outlet/>
      </Box>
    </Box>
    </ThemeProvider>

  );
}