import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Table } from 'antd';


function RentFinance() {
	const API = process.env.REACT_APP_API

	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [count, setCount] = useState()

const [data, setData] = useState([])
const [searched, setSearched] = useState(false)
const sum = data.reduce((accumulator, Paid) => accumulator + Paid, 0);

const [loading, setLoading] = useState(true)
const [tableParams, setTableParams] = useState({
	pagination: {
	  current: 1,
	  pageSize: 10,
	},
  });

  const handleTableChange = (pagination, filters, sorter) => {
	setTableParams({
	  pagination,
	  filters,
	  sortField: sorter.field,
	  sortOrder: sorter.order,
	});
  };

  useEffect(function(){
	async function fetchData() {
		try {
			const res = await fetch(`${API}/rentFinance?startDate=${startDate}&endDate=${endDate}`)
			console.log(res)
			if (res.ok) {
				console.log("ok")
			}
			const data = await res.json()


			setData(data.finance)
			setCount(data.total[0].total)

			console.log(data.total[0].total)



		} catch (e){
			console.log(e)
		} finally{
			setLoading(false)
		}
	} fetchData()
  }, [searched])

	const columns = [

	   {
		 title: 'Full name',
		 dataIndex: 'Name',
		 align:"center"

		 
   
	   },

	   {
		   title: 'Phone',
		   dataIndex: 'Phone',
		   align:"center"

	 
		 },

		



		 
		 {
			title: 'Address',
			dataIndex: 'Address',
			align:"center"
 
	  
		  },
		 {
			title: 'Category',
			dataIndex: 'Category',
			align:"center"
 
	  
		  },
	   //   PayDate	Category	Dep	Trainer	Branch
		 {
		   title: 'Paid Value',
		   dataIndex: 'Paid',
		   sorter: (a, b) => a.Paid - b.Paid,
		   align:"center"

	 
		 },
		
		 {
		   title: 'From',
		   dataIndex: 'StartDate',
		   sorter: (a, b) => new Date(a.PayDate) - new Date(b.PayDate),			
		   align:"center"

	 
		 },

		 {
		   title: 'To',
		   dataIndex: 'EndDate',
		   align:"center",
		   sorter: (a, b) => new Date(a.EndDate) - new Date(b.EndDate),			


		 },

		

		 

		
	 ];


	 function onChange(date, dateString) {
		console.log(dateString)
		setDate(dateString)
		console.log(startDate)

	}





	function onEndDatehange(date, dateString) {
		console.log(dateString)
		setEnDate(dateString)
		console.log(endDate)


	}
	return (
		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">
			<h3 style={{ margin: "0 auto", width: "200px" }}>Rent Finance</h3>
			<Container
    sx={{
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: "white",
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
        marginBottom: "20px",
    }}
>
    <div 
        style={{ 
            borderRadius: "8px", 
            width: "40%", 
            margin: "20px auto", 
            display: "flex", 
            justifyContent: "space-between" 
        }} 
        className="datepicker-container"
    >
        <DatePicker 
            style={{ 
                backgroundColor: "#eaedf4", 
                height: "40px", 
                border: "none", 
                textAlign: "center" 
            }} 
            placeholder='From' 
            format={"YYYY-MM-DD"} 
            onChange={onChange} 
        />
        <DatePicker 
            style={{ 
                backgroundColor: "#eaedf4", 
                height: "40px", 
                border: "none", 
                textAlign: "center" 
            }} 
            placeholder='To' 
            format={"YYYY-MM-DD"} 
            onChange={onEndDatehange} 
        />
    </div>

    <div 
        style={{ 
            display: "flex", 
            justifyContent: "center", 
            marginTop: "10px" 
        }}
    >
        <Button
            variant="contained"
            onClick={() => setSearched(!searched)}
            startIcon={<SearchIcon style={{ color: "black" }} />}
            sx={{
                ":hover": {
                    backgroundColor: '#eaedf4',
                    boxShadow: 'none',
                },
                backgroundColor: "#d7d9e9",
                color: 'black',
                borderRadius: '6px',
                fontWeight: "bold",
                width: "150px",
                boxShadow: 'none',
            }}
        >
            Search
        </Button>
    </div>
</Container>

			<Container

				sx={{
					padding: '25px',
					padding: '25px',
					borderRadius: '8px',
					marginBottom: "20px", // Set background color if needed
					height: "100px",
					alignItems: "center",
					textAlign: "center",
					justifyContent: "center",
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
					height: "180px",
					display: "flex",
					backgroundColor: "white"

				}}
			>
				<Paper
					elevation={0}
					sx={{
						padding: '10px',
						backgroundColor: "#edf0f7",
						border: "1px solid #e4e4e4",
						color: "black",
						borderRadius: '10px',
						display: 'block',
						textAlign: "center",
						alignItems: 'center',
						fontFamily: "poppins",
						width: "250px",
						height: "90px",
						display: "flex",
						justifyContent: "center",
						marginRight: "13px",

					}}
				>


					<Box sx={{ display: "block", width: "100%", }}>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
							<AccountCircleIcon sx={{ fontSize: "30px" }} />

						</Box>
						<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
							{data?.length}
						</Typography>
					</Box>
				</Paper>
				<Paper
					elevation={0}
					sx={{
						padding: '10px',
						backgroundColor: "#edf0f7",
						border: "1px solid #e4e4e4",
						color: "black",
						borderRadius: '10px',
						display: 'block',
						textAlign: "center",
						alignItems: 'center',
						fontFamily: "poppins",
						width: "250px",
						height: "90px",
						display: "flex",
						justifyContent: "center",
						marginRight: "13px",

					}}
				>



					<Box sx={{ display: "block", width: "100%", }}>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
							<PaidIcon />
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
								Total
							</Typography>
						</Box>
						<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
							{Number(count) } EGP
						</Typography>
					</Box>
				 </Paper>

			</Container>

			
			<Table
      columns={columns}
    //   rowKey={(record) => record.login.uuid}
      dataSource={data}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
    />
		</div>
	)
}


export default RentFinance