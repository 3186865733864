


import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));



const API = process.env.REACT_APP_API

function OTSfinance() {
	const [filtered, setFiltered]  = useState("All")

	const [loading, setLoading] = useState(true)
	const [financeDage, setFinanceData] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainer, setTrainer] = useState()
	const [trainersLoading, setTrainersLoading] = useState()
	const [trainerName, settrainerName] = useState()
	
let total = []

	function onChange(date, dateString) {
		console.log(dateString)
		setDate(dateString)
		console.log(startDate)
		
	  }

	  


	  function onEndDatehange(date, dateString) {
		console.log(dateString)
		setEnDate(dateString)
		console.log(endDate)

		
	  }

// console.log(new Date(startDate))
// console.log(new Date(endDate))

// console.log(financeDage)

// const filter_data = financeDage?.filter(f=>{
// 	return new Date (f.PayDate.split("/").reverse()) >= new Date(startDate) &&
// 	new Date (f.PayDate.split("/").reverse()) <= new Date(endDate)
// })


// console.log(financeDage)

// console.log(filter_data)
// let x = []
// let y = []

// console.log(x)
// console.log(y)
// filter_data?.filter(m=>{
// 	x.push(m.PaidValue)
// })

// filter_data?.filter(m=>{
// 	y.push(m.Remaining)
// })



// console.log(x)
// console.log(y)


// var sum = x.reduce((accumulator, currentValue) => {
// 	return accumulator + currentValue
//   },0);
//   var sumRemaining = y.reduce((accumulator, currentValue) => {
// 	return accumulator + currentValue
//   },0);


//   console.log(sum)
//   console.log(sumRemaining)


  async function handleFilter() {
	try {
		setLoading(true)
		const res = await fetch(`${API}/otsfinance?startDate=${startDate}&endDate=${endDate}&trainer=${trainerName}`)
		const data = await res.json()
		setFinanceData(data.result)
	} catch(e) {
		console.log(e)
	} finally{
		setLoading(false)


	}
}

useEffect(function () {
	async function fetchDate() {
		try{
			setLoading(true)
			const res = await fetch(`${API}/otsfinance?startDate=${startDate}&endDate=${endDate}&trainer=${trainerName}`)
		const data = await res.json()
		console.log(data.result)
		setFinanceData(data.result)
		
		} catch (e){
			console.log(e)
		} finally{
			setLoading(false)
		}
	}fetchDate()
	}, [rednder])

	useEffect(function () {
		async function getTrainers() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
				console.log(data.result)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getTrainers()

	}, [])



	const sumPaid = financeDage?.map(item => item.PaidValue).reduce((a, b) => a + b, 0)











var dateFrom = "01/03/2024";
var dateTo = "12/03/2024";
var dateCheck = "05/09/2017";

var d1 = dateFrom.split("/");
var d2 = dateTo.split("/");
var c = dateCheck.split("/");

var from = new Date(d1);  // -1 because months are from 0 to 11
var to   = new Date(d2);
var check = new Date(c);






	return (

		<div className="finance-page-container">
					<h3  style={{margin:"0 auto", width:"300px"}}>One Time Finance</h3>

					<div style={{ display: "block", justifyContent: "space-evenly", margin: "0 auto", alignItems: "center", textAlign: "center" }}>
				<div style={{ borderRadius: "8px", width: "20%", margin: "20px auto", border: " 1px solid #D3D3D3", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
					<DatePicker placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
					<DatePicker placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

				</div>
				<select onChange={(e) => settrainerName(e.target.value)} style={{ width: "200px", height: "30px", border: "none", textAlign: "center", alignItems: "center", right: "0", position: "relative", marginBottom: "10px" }} name="" id="">

					<option value="" disabled selected>Trainer</option>
					{trainer?.map(t => {
						return (
							<option value={t.fullName}>{t.fullName}</option>
						)
					})}
				</select> <br />
				<button style={{ border: "none", color: "white", backgroundColor: "black", borderRadius: "6px", fontSize: "12px", height: "35px", width: "100px", marginBottom: "6px" }} onClick={handleFilter}> Search</button>

			</div>
					
					
			<div style={{display:"flex", justifyContent: "space-evenly"}}> 
			
				<div style={{display:"flex", margin:"0 auto"}} className='paid'>
					<PaidIcon /> 
					<label style={ {marginLeft:"6px", marginRight:"6px"}} htmlFor="">Total </label>
	
					<p style={{position:"relative"}}>{sumPaid} EGP</p>

					</div>

					<div style={{display:"flex"}} className='paid'>
	

					</div>
				</div>

{console.log(financeDage)}



			{loading ? (     <Box sx={{ display: 'flex' , margin:"0 auto" , width:"10%"}}>
      <CircularProgress />
    </Box>) : (<>
			
				<TableContainer style={{width:"70%", margin:"0 auto",  position:"relative"}}component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell  style={{fontWeight:"bold"}}  align="center">Customer Name</StyledTableCell>
							<StyledTableCell style={{fontWeight:"bold"}}   align="center">Phone</StyledTableCell>

							<StyledTableCell  style={{fontWeight:"bold"}}  align="center">Paid Value</StyledTableCell>

							<StyledTableCell style={{fontWeight:"bold"}}  align="center">Pay Date</StyledTableCell>
							<StyledTableCell  style={{fontWeight:"bold"}}  align="center">Category</StyledTableCell>
							<StyledTableCell style={{fontWeight:"bold"}}  align="center">Trainer</StyledTableCell>


						</TableRow>
					</TableHead>
					<TableBody>
						{financeDage
							.map((row) => (
								<StyledTableRow key={row.name}>

									<StyledTableCell align="center">{row.Fullname}</StyledTableCell>
									<StyledTableCell align="center">{row.Phone}</StyledTableCell>


									<StyledTableCell align="center">{row.PaidValue}</StyledTableCell>

									<StyledTableCell align="center">{row.PayDate}</StyledTableCell>
									<StyledTableCell align="center">{row.Category}</StyledTableCell>
									<StyledTableCell align="center">{row.Trainer}</StyledTableCell>
								</StyledTableRow>
							))

						}


					

						
					</TableBody>
				</Table>
			</TableContainer>

			
			</>
			
			
			)

			
			
			
			}

		</div>
	)

}

export default OTSfinance