import { useEffect, useState, useRef } from "react"
import { DateRange } from 'react-date-range';
import { DatePicker } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import { Box, Button, Typography, Container, CircularProgress  } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';



function AddTenant() {
	const [categories, setCategories] = useState()
	const [loading, setLoading] = useState()
	const [selectedCategory, setSelectedCategory] = useState()
	const [selectedID, setSelectedID] = useState()
	const nameRef = useRef()
	const phoneRef = useRef()
	const addressrent = useRef()
	const paidvalueRef = useRef()

	const [endDate, setEnDate] = useState("")
	const [startDate, setDate] = useState("");

	const API = process.env.REACT_APP_API

	function onChange(date, dateString) {
		setDate(dateString)
		// setDate(dateString.split("/").reverse())

	}

	function onEndDatehange(date, dateString) {
		setEnDate(dateString)
		// setEnDate(dateString.split("/").reverse())


	}


	useEffect(function () {
		async function fetchDate() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getRentCategories`)
				const data = await res.json()

				setCategories(data.categories)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchDate()
	}, [])


	function handleSelectCategory(x) {
		const arr = x.split(",")
		setSelectedCategory(arr[0])
		setSelectedID(arr[1])

		

	}


	function handleAddTenant(e) {
		e.preventDefault()
		fetch(`${API}/addTenant`, {
			method: 'POST',
			headers: {
			   'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			   'Name': nameRef.current.value,
			   'PHone': phoneRef.current.value,
			   "startDate": startDate,
			   "endDate": endDate,
			   "category": selectedCategory,
			   "price": paidvalueRef.current.value,
			   "address": addressrent.current.value,
			   "ID" :selectedID
			})
		}).then(response => response.json())
		.then(result=>{
			if (result.status == 200) {
				toast.success(`${result.Message}`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					});
			} else if (result.status == 404) {
				toast.error(`${result.Message}`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					});
			}
		})

	}

	if (loading) {
		return (
		  <Box
			sx={{
			  display: 'flex',
			  justifyContent: 'center',
			  alignItems: 'center',
			  height: '50vh', 
			}}
		  >
			<CircularProgress size={80} sx={{ color: '#0d056a' }} />
		  </Box>
		);

	}

	return (
		<>
		<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
			
			<form onSubmit={(e)=>handleAddTenant(e)} style={{ margin: " 0 auto", border: "2px solid #e4e4e4", backgroundColor: "white", height: "700px" }}>
			<div style={{ margin: "0 auto", width: "50%", alignItems: "center", textAlign: "center", display: "flex", justifyContent: "center", width:"500px" }} className="calender-container">
				<DatePicker required placeholder="From" style={{ backgroundColor: "#e4e4e4", width: "200px", color: "black", margin:"10px" }} format={"YYYY-MM-DD"} onChange={onChange} />
				<DatePicker required placeholder="To" style={{ backgroundColor: "#e4e4e4", width: "200px", color: "black", margin:"10px" }} format={"YYYY-MM-DD"} onChange={onEndDatehange} />
			</div>
				<input   required ref={nameRef} type="text" placeholder="Name" /> <br />
				<input  minLength={11} maxLength={11} required ref={phoneRef} type="text" placeholder="Phone" /> <br />
				{/* <input minLength={14} maxLength={14} required ref={nationalIDRef} type="text" placeholder="National ID" /> <br /> */}
				<input required ref={addressrent} type="text" placeholder="Address" /> <br />
				<input required ref={paidvalueRef} type="text" placeholder="Paid value" /> <br />


				<select required style={{ textAlign: "center", border: "1px solid #e4e4e4" }} onChange={(e) => handleSelectCategory(e.target.value)}> <br />
					<option value="" disabled selected>Category</option>
					{categories?.map(c => {
						return (
							<option value={[c.name,c.id]}>{c.name}</option>
						)
					})}

				</select>
				<button type="submit" style={{ marginTop: "60px" }}>Add</button>
			</form>
			

		</>
	) 
}

export default AddTenant