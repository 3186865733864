import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CountdownTimer from './countDown';
import { MdOutlineErrorOutline } from "react-icons/md";
import { AuthProvider, useAuth }  from '../../contexts/authContext';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  border: '2px solid #000',
  boxShadow: '24',
  padding: '16px',
  outline: 'none',
  borderRadius: '8px',
  
};



export default function TimeOutModal({setOpen , open, handleOpen, handleClose, reset, countdown}) {

  const { logout } = useAuth();

  return (
    <div>
      <Modal

      sx={{borderRadius:"10px"}}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}

        
      >
        <Fade in={open}>
          <Box  sx={style}>
            <Box display="flex">      
                  <MdOutlineErrorOutline style={{margin:"auto"}} size="130px" color='red' />
            </Box>
            <Typography   id="transition-modal-description" sx={{ fontSize:"20px",width:"fit-content" ,fontFamily:"Poppins", margin:"0 auto", fontWeight:"bold",  marginBottom:"20px"}}>
            You will be logged out in

            </Typography>
            <Typography  width="fit-content" margin="auto" fontWeight="bold" fontFamily="poppins" fontSize="50px">
             {/* <CountdownTimer initialMinutes={10} initialSeconds={12} /> */}
{countdown}
            </Typography>

            <Box display="flex" margin="10px" right="2px" justifyContent="center">
            <Button
            onClick={logout}
      variant="contained"
      sx={{
        fontWeight:"bold",
        boxShadow: 'none', // No shadow

        backgroundColor: '#23395d', // Set background color to dark blue
        color: 'white', // Set text color to white
        '&:hover': {
          backgroundColor: '#152238', // Change background on hover
          boxShadow: 'none', // No shadow

        },
      }}
    >
      logout
    </Button>

    <Button
      variant="outlined"
      onClick={handleClose}
      sx={{
        fontWeight:"bold",
        backgroundColor: 'white', // White background
        marginLeft:"5px",
        color: 'red', // Red text color
        border: '2px solid red', // Red border
        boxShadow: 'none', // No shadow
        '&:hover': {
          backgroundColor: 'rgba(255, 0, 0, 0.1)', // Optional: light red background on hover
          border: '2px solid red', // Maintain red border on hover
          boxShadow: 'none', // No shadow

        },
      }}
    >
      Cancel
    </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}