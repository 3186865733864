import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

const API = process.env.REACT_APP_API

function createData(name, calories, fat, carbs, protein) {
    
    return { name, calories, fat, carbs, protein };
}




export default function AllDietCustomer() {
    const  [loading, setLoading] = React.useState()
    const [DietData, setDietData] = React.useState([])
    React.useEffect(function() {
        async function fetchData() {
          try {
            setLoading(true)
            const res = await fetch(`${API}/dietCustomers`)
            const data = await res.json()
            setDietData(data.data)
          } catch(e){
            console.log(e)
          } finally {
            setLoading(false)
          }

        } fetchData()
    }, [])
console.log(DietData)
	

return loading ? (   <div style={{margin: "0 50%"}}> <CircularProgress  /></div>  ):(<TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead  >
      <TableRow >
        <TableCell style={{backgroundColor:"#203354", color:"white"}} align="center">Customer name</TableCell>
        <TableCell style={{backgroundColor:"#203354", color:"white"}} align="center">Captin Name</TableCell>
        <TableCell  style={{backgroundColor:"#203354", color:"white"}}align="center">Date</TableCell>
      </TableRow>
    </TableHead>
    <TableBody >
      {DietData.map((d) => (
        <TableRow
          key={DietData.name}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
     
          <TableCell   style={{textAlign:"center", alignItems:"center"}} >{d.customerName}</TableCell>
          <TableCell  style={{textAlign:"center", alignItems:"center"}}>{d.captinName}</TableCell>
          <TableCell   style={{textAlign:"center", alignItems:"center"}}>{d.Date}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>)
}