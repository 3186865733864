import { useEffect } from "react";
import { useNavigate , useParams} from "react-router-dom";

function RedirectPage() {
	const API = process.env.REACT_APP_API
	const navigate = useNavigate()
	const { id } = useParams();

	useEffect(() => {
		const fetchCustomerId = async () => {
		  try {
			// Call your backend to check the unique ID and retrieve customer ID
			const response = await fetch(`${API}/access/${id}`);
			if (!response.ok) {
			  throw new Error('Link not found');
			}
			const data = await response.json();
			console.log(data)
			// Redirect to the customer page
			navigate(`/customer-info/${data.sentID}`);
		  } catch (error) {
			console.error(error);
			// Optionally handle the error (e.g., show a message)
		  }
		};
	
		fetchCustomerId();
	  }, [id, navigate]);
	return (
		<p>Please Wait</p>
	)
}

export default RedirectPage