import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useState} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { BroadcastChannel } from 'broadcast-channel';
import { AuthProvider, useAuth }  from '../contexts/authContext';
import LogoutIcon from '@mui/icons-material/Logout';


const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: "#cbcbcb",
	},
	'&:focus ': {
		backgroundColor: "#cbcbcb",
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(3),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));

export default function PrimarySearchAppBar() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const [customerID, setCustomerID] = useState("0")

	const { isAuthenticated, logout } = useAuth();
	const User = localStorage.getItem("User")
	const branch = localStorage.getItem("loggedBranch")
	const Role = localStorage.getItem("Roles")


	
	const navigate = useNavigate();
  
	const handleLogout = () => {
	  logout();
	  navigate('/login'); // Redirect to login page or home page
	};

	function navigateFunc() {
		window.location.href=`/profile/${customerID}`;

    }


	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const menuId = 'primary-search-account-menu';
	  const renderMenu = (
	    <Menu
	      anchorEl={anchorEl}
	      anchorOrigin={{
	        vertical: 'top',
	        horizontal: 'right',
	      }}
	      id={menuId}
	      keepMounted
	      transformOrigin={{
	        vertical: 'top',
	        horizontal: 'right',
	      }}
	      open={isMenuOpen}
	      onClose={handleMenuClose}
	    >

<div style={{alignItems:"center",padding:"20px", textAlign:"center"}}>
<p style={{fontWeight:"bold"}}>{User}</p>
<p style={{fontWeight:"bold"}}>{branch}</p>
</div>

	      <MenuItem  sx={{alignItems:"center", textAlign:"center"}} onClick={handleLogout}> <LogoutIcon/> Logout</MenuItem>
	    </Menu>
	  );

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton size="large" aria-label="show 4 new mails" color="inherit">
					<Badge badgeContent={4} color="error">
						<MailIcon />
					</Badge>
				</IconButton>
			</MenuItem>
			<MenuItem>
				<IconButton
					size="large"
					aria-label="show 17 new notifications"
					color="inherit"
					sx={{ borderRadius: "0", }}
				>
					<Badge badgeContent={17} color="error">
						<AddBoxIcon />
					</Badge>
				</IconButton>
				<p>Add user</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
		</Menu>
	);

	return (
		<Box sx={{ flexGrow: 1, marginBottom: "10px", width:"100%" }}>
			<AppBar elevation={0} sx={{ backgroundColor: "#ffffff", color: "black", width:"100%", borderBottom:"1px solid #e4e4e4" }} position="static">

				<Toolbar>

					<Box sx={{display:"flex", alignItems:"center",textAlign:"center"}}>

					{Role == "Admin" || Role == "admin" ? (	<Link to="/user">
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="open drawer"
						sx={{ mr: 2 }}
					>
						<HomeIcon />
					</IconButton></Link>
                ) : ("")}
				
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ display: { xs: 'none', sm: 'block', fontWeight: "bold" } }}
					>

						ENERGY
					</Typography>
					<Search sx={{ border: "1px solid white" }}>
						<SearchIconWrapper>
							<SearchIcon />
						</SearchIconWrapper>
						<StyledInputBase
						  onKeyDown={(e) => {
							if (e.key === "Enter")
								navigateFunc();
							}}
							placeholder="Search customer"
							inputProps={{ 'aria-label': 'search' }}
							onChange={(e)=> setCustomerID(e.target.value)}
						/>
					</Search>
					</Box>

					
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>

						<Link to="/add-user">
						<IconButton
							sx={{ backgroundColor: "#c7c7c7", borderRadius: "7px", height: "35px", margin: "3px", width: "120px" }}
							size="large"
							edge="end"
							aria-label="account of current user"

							color="inherit"
						>
							<AddBoxIcon />
							<label style={{ fontSize: "14px", fontWeight: "bold" }}>User</label>
						</IconButton>
						</Link>


					<Link to="/add-customer">
					<IconButton
							sx={{ backgroundColor: "#c7c7c7", borderRadius: "7px", height: "35px", margin: "3px", width: "120px" }}
							size="large"
							edge="end"
							aria-label="account of current user"

							color="inherit"
						>
							<AddBoxIcon />
							<label style={{ fontSize: "14px", fontWeight: "bold" }}>CUSTOMER</label>
						</IconButton>
					</Link>

						
						<Link to="/customers">
						<IconButton
							sx={{ backgroundColor: "#c7c7c7", borderRadius: "7px", height: "35px", margin: "3px", width: "120px" }}
							size="large"
							edge="end"
							aria-label="account of current user"

							color="inherit"
						>
							<label style={{ fontSize: "14px", fontWeight: "bold" }}>GYM</label>
						</IconButton>
						</Link>


						<Link to="/classes/all-classes">
						<IconButton
							sx={{ backgroundColor: "#c7c7c7", borderRadius: "7px", height: "35px", margin: "3px", width: "120px" }}
							size="large"
							edge="end"
							aria-label="account of current user"

							color="inherit"
						>
							<label style={{ fontSize: "14px", fontWeight: "bold" }}>CLASS</label>
						</IconButton>
						</Link>
						

						<Link to="/academy">
						<IconButton
							sx={{ backgroundColor: "#c7c7c7", borderRadius: "7px", height: "35px", margin: "3px", width: "120px" }}
							size="large"
							edge="end"
							aria-label="account of current user"

							color="inherit"
						>
							<label style={{ fontSize: "14px", fontWeight: "bold" }}>ACADMEY</label>
						</IconButton> 
						</Link>

						<IconButton
							size="large"
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
					</Box>


					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						
							<HomeIcon />
					</Box>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</Box>
	);
}