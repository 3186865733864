import Button from '@mui/material/Button';
import CheckInIcon from '@mui/icons-material/CheckCircle'; // Use CheckCircle as a check-in icon
import { Transition } from 'react-transition-group';
import { css } from '@emotion/react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { FaRegistered, FaWeight } from "react-icons/fa";

import { useState, useEffect } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PreCheckInDialog from "../components/precheck/clinic"
import { Table } from 'antd';
import qs from 'qs';
import ErrorIcon from '@mui/icons-material/Error';
import Navbar from "../components/navbar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CardContent from '@mui/joy/CardContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { IoMdClose } from "react-icons/io";
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Drawer from '@mui/material/Drawer';
import LoadingButton from '@mui/lab/LoadingButton';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CloseIcon from '@mui/icons-material/Close';
import { Box, ColumnLayout, Container } from '@cloudscape-design/components';
import { Box as MUIBOX, TextField, Container as MUIContainer } from '@mui/material';

import { GoPackage } from "react-icons/go";
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
function Clinic() {
    const API = process.env.REACT_APP_API
    const [clinicPackData, setData] = useState([])
    const [loading, setLoading] = useState()
    const branch = localStorage.getItem("loggedBranch");
    const [render, rerender] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [subInfo, setSubInfo] = useState("")
    const [customerInfo, setCustomerInfo] = useState("")
	const [financeInfo, setFinanceInfo] = useState("")
	const [customerID, setCustomerID] = useState()
	const [packageID, setPackageID] = useState()
	const navigate = useNavigate()
	const role = localStorage.getItem("Roles")

    const handleClose = () => {
        setOpen(false);
    };

    const [open, setOpen] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false);

    const [data, setCustomersData] = useState([]);
    const [CustomersLoading, setCustomersLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const validationSchema = Yup.object().shape({
        customerId: Yup.string().required('Customer ID is required'),
        clinicPack: Yup.string().required('Clinic package is required'),

    });
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));


    const columns = [
        {
            title: 'Name',
            dataIndex: 'Fullname',
            sorter: true,
            //   render: (name) => `${name.first} ${name.last}`,
            width: '20%',
        },
        {
            title: 'Customer ID',
            dataIndex: 'customerID',
            fontWeight: "bold"
        },
        {
            title: 'Phone',
            dataIndex: 'Phone',
            fontWeight: "bold"
        },

        {
            title: 'Package',
            dataIndex: 'category',
            fontWeight: "bold"

        },

        {
            title: 'Used sessions',
            dataIndex: 'usedSessions',
            fontWeight: "bold"

        },
        {
            title: 'Remaining sessions',
            dataIndex: 'remainingSession',
            fontWeight: "bold",
        },
        {
            title: 'Start date',
            dataIndex: 'startDate',
            fontWeight: "bold",

        },
        {
            title: 'End date',
            dataIndex: 'endDate',
            fontWeight: "bold",

        },

    ];

    const getRandomuserParams = (params) => ({
        results: params.pagination?.pageSize,
        page: params.pagination?.current,
        ...params,
    });
    const fetchData = () => {
        setLoading(true);
        fetch(`${API}/clinicCustomers?${qs.stringify(getRandomuserParams(tableParams))}`)
            .then((res) => res.json())
            .then((results) => {
                setCustomersData(results.result[0]);
                console.log(results)
                console.log(data)
                setCustomersLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: results.count[0].count,
                        // 200 is mock data, you should read it from server
                        // total: data.totalCount,
                    },
                });
            });
    };
    useEffect(fetchData, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    }

    useEffect(function() {
        async function fetchData() {
            try {
                const res = await fetch(`${API}/getClinicPack`)
                const data = await res.json()
                setData(data.data)

            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        } fetchData()
    }, [])

    async function preCheckIn(values) {
        try {
            const response = await fetch(`${API}/clinicPreCheck?customerID=${values.customerId}&packID=${values.clinicPack}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setSubInfo(data.subInfo)
            setCustomerInfo(data.info)
			setFinanceInfo(data.finance)
			setOpen(true)
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }

	async function CheckIn() {
        try {
            const response = await fetch(`${API}/clinicCheck?customerID=${customerID}&packID=${packageID}&branch=${branch}`);
            if (!response.ok) {
				toast.error("Something went wrong", {
					position: "top-center",
					autoClose: 2000,
				});            }
			console.log(response)
            const data = await response.json();
			if (data.status == 200) {
				rerender(!render);
				toast.success(data.Message, {
					position: "top-center",
					autoClose: 2000,
				});
				handleClose(); // Close the dialog after success
			} else {
				toast.error(data.Message, {
					position: "top-center",
					autoClose: false,
				});
                handleClose();
			}
         
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }
    return (
        <MUIContainer >
			<Navbar />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <PreCheckInDialog
                CheckIcon={CheckIcon}
                subInfo={subInfo}
                customerInfo={customerInfo}
                IconButton={IconButton}
                Button={Button}
                open={open}
                BootstrapDialog={BootstrapDialog}
                DialogTitle={DialogTitle}
                DialogContent={DialogContent}
                DialogActions={DialogActions}
                CloseIcon={CloseIcon}
                ErrorIcon={ErrorIcon}
                Checkbox={Checkbox}
                Container={Container}
                ColumnLayout={ColumnLayout}
                GoPackage={GoPackage}
                DateRangeIcon={DateRangeIcon}
                FaRegistered={FaRegistered}
                PaidIcon={PaidIcon}
                CancelPresentationIcon={CancelPresentationIcon}
                LoadingButton={LoadingButton}
                handleClose={handleClose}
                setButtonLoading={setButtonLoading}
                buttonLoading={buttonLoading} // Adjust according to your state
                render={render}
                rerender={rerender}
                toast={toast}
                loggedBranch={branch}
				financeInfo={financeInfo}
				CheckIn={CheckIn}
            />

            <Transition in={ModalOpen} timeout={400}>
                {(state) => (
                    <Modal
                        keepMounted
                        open={!['exited', 'exiting'].includes(state)}
                        onClose={() => setModalOpen(false)}
                        slotProps={{
                            backdrop: {
                                sx: {
                                    opacity: 0,
                                    backdropFilter: 'none',
                                    transition: `opacity 400ms, backdrop-filter 400ms`,
                                    ...{
                                        entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                        entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                                    }[state],
                                },
                            },
                        }}
                        sx={[
                            state === 'exited'
                                ? { visibility: 'hidden' }
                                : { visibility: 'visible' },
                        ]}
                    >
                        <ModalDialog
                            sx={{
                                opacity: 0,
                                transition: `opacity 300ms`,
                                ...{
                                    entering: { opacity: 1 },
                                    entered: { opacity: 1 },
                                }[state],
                            }}
                        >
                            <DialogTitle sx={{ margin: "auto", fontWeight: "Bold", fontFamily: "poppins" }}>Clinic check in</DialogTitle>
                            <Formik

                                initialValues={{ customerId: '', clinicPack: '' }}
                                validationSchema={validationSchema}
                                onSubmit={(values, { resetForm, setSubmitting }) => {
                                    console.log(values)
                                    console.log('Customer ID:', values.customerId);
                                    preCheckIn(values)
									setCustomerID(values.customerId)
									setPackageID(values.clinicPack)
                                    // resetForm();
                                    // setSubmitting(false);
                                    // Show success message (You can also handle success state here)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <form onSubmit={handleSubmit} style={{ margin: '20px', padding: "10px", width: "400px", height: "400px", backgroundColor: "white"}}>
                                        <TextField
                                            label="Customer ID"
                                            variant="outlined"
                                            name="customerId"
                                            value={values.customerId}
                                            onChange={handleChange}
                                            error={touched.customerId && Boolean(errors.customerId)}
                                            helperText={touched.customerId && errors.customerId}
                                            fullWidth
                                            margin="normal"
                                            sx={{ width: "70%" }}
                                        />

                                        <FormControl sx={{ width: "70%" }} fullWidth margin="normal" error={touched.clinicPack && Boolean(errors.clinicPack)}>
                                            <InputLabel id="clinic-pack-label">Clinic Package</InputLabel>
                                            <Select
                                                labelId="clinic-pack-label"
                                                name="clinicPack"
                                                value={values.clinicPack}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {clinicPackData?.map(pack => (
                                                    <MenuItem key={pack.packID} value={pack.packID}>{pack.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {touched.clinicPack && errors.clinicPack && (
                                                <span style={{ color: 'red' }}>{errors.clinicPack}</span>
                                            )}
                                        </FormControl>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#152238',
                                                color: 'white',
												width:"120px",
                                                '&:hover': {
                                                    backgroundColor: '#1a2a4d',
                                                },
                                                borderRadius: '6px',
                                                fontWeight: 'bold',
                                                padding: '8px 16px',
                                                marginTop: '16px',
                                                height: "40px",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            Check In
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        </ModalDialog>
                    </Modal>
                )}
            </Transition>

            <MUIBOX sx={{display:"flex", justifyContent:"center", marginBottom:"30px", marginTop:"30px"}} >

				{role == "Admin" ? (			<Button
                    variant="contained"
					onClick={()=>navigate("/clinic/add-package")}
                    // onClick={onClick}
                    startIcon={<EditIcon style={{ color: 'white' }} />}
                    sx={{
                        backgroundColor: '#152238',
						width:"170px",
                        color: 'white',
						whiteSpace: 'nowrap',
						overflow: 'hidden',   
						marginRight:"10px",
						textOverflow: 'ellipsis' ,
                        '&:hover': {
                            backgroundColor: '#1a2a4d', // Slightly lighter on hover
                        },
                        borderRadius: '6px',
                        fontWeight: 'bold',
                        padding: '8px 16px',
                    }}
                >
                    Add package
                </Button>):("")} 


				{role == "Admin"? (    <Button
                    variant="contained"
					onClick={()=>navigate("/clinic/packages")}
                    startIcon={<EditIcon style={{ color: 'white' }} />}
                    sx={{
                        backgroundColor: '#152238',
						marginRight:"10px",

                        color: 'white',
						width:"170px",
						whiteSpace: 'nowrap',
						overflow: 'hidden',   
						textOverflow: 'ellipsis' ,
						

                        '&:hover': {
                            backgroundColor: '#1a2a4d', // Slightly lighter on hover
                        },
                        borderRadius: '6px',
                        fontWeight: 'bold',
                        padding: '8px 16px',
                    }}
                >
                    Edit package
                </Button>):("") }
            

                <Button
                    variant="contained"
                    onClick={() => setModalOpen(true)}
                    // onClick={onClick}
                    startIcon={<CheckInIcon style={{ color: 'white' }} />}
                    sx={{
                        backgroundColor: '#152238',
						width:"170px",
						whiteSpace: 'nowrap',
						overflow: 'hidden',   
						textOverflow: 'ellipsis' ,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#1a2a4d', // Slightly lighter on hover
                        },
                        borderRadius: '6px',
                        fontWeight: 'bold',
                        padding: '8px 16px',
                    }}
                >
                    Check in
                </Button>
            </MUIBOX>

            <Table
                components={{
                    header: {
                        cell: ({ children, ...restProps }) => (
                            <th
                                {...restProps}
                                css={css`
			background-color: #f0f0f0; /* Header background color */
			color: #000; /* Header text color */
			font-weight: bold; /* Bold font weight */
			font-family: 'Poppins', sans-serif; /* Poppins font family */
		  `}
                            >
                                {children}
                            </th>
                        ),
                    },
                }}
                columns={columns}
                //   rowKey={(record) => record.login.uuid}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={CustomersLoading}
                onChange={handleTableChange}
            />
        </MUIContainer>
    )
}

export default Clinic