import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from 'react';

function RateModal({ customerID, handleOpenRate, handleCloseRate, openRateModal, setOpenRateModal }) {
	const API = process.env.REACT_APP_API

	const RateModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		borderRadius:"10px",
		boxShadow: 24,
		p: 4,
	};

	const percentage = 66;
	const [rateLoading, setRateLoading] = useState(true)
	const [gymRage, setGymRate] = useState([])
	const [classRate, setClassRate] = useState([])
	const [acadmyRate, setAcademyRate] = useState([])


	useEffect(() => {
		if (openRateModal) {
		  async function fetchData() {
			try {
			  const res = await fetch(`${API}/customerRate/${customerID}`);
			  const data = await res.json();
			  console.log(data); // You can use this data to update your state
			  setGymRate(data.gym)
			  setClassRate(data.class)
			  setAcademyRate(data.academy)
			} catch (e) {
			  console.error(e);
			} finally {
			  setRateLoading(false);
			}
		  }
	  
		  fetchData();
		} else {
		  setRateLoading(true);
		}
	  }, [openRateModal]);


	  const Gympercentage = (Number(gymRage[0]?.GYMused) / (Number(gymRage[0]?.GYMused) + Number(gymRage[0]?.GYMRemains))) * 100;

	  const Classpercentage = (Number(classRate[0]?.Classused) / (Number(classRate[0]?.ClassRemains) + Number(classRate[0]?.Classused))) * 100;
	  
	  const Academypercentage = (Number(acadmyRate[0]?.Academyused) / (Number(acadmyRate[0]?.AcademyRemains) + Number(acadmyRate[0]?.Academyused))) * 100;
	  
	//   const Academypercentage = Number(acadmyRate[0].AcademyRemains) + Number(acadmyRate[0].Academyused ) * 100;



	return (
		<Modal
			open={openRateModal}
			onClose={handleCloseRate}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box justifyContent="space-around" display="flex" flexDirection="row" sx={RateModalStyle}>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
						<Typography
							fontWeight="bold"
							fontSize="15px"
							fontFamily="poppins"
							id="modal-modal-title"
							variant="h6"
							component="h2"
							color="black"
							sx={{ marginBottom: 1 }} // Add space between title and progress bar
						>
							GYM
						</Typography>
						

						<Box sx={{ width: '100px', height: 'fit-content' }}>
							<CircularProgressbar
								value={Gympercentage}
								text={`${Gympercentage.toFixed(2)}%`}								styles={buildStyles({
									rotation: 0.25,
									strokeLinecap: 'butt',
									textSize: '16px',
									pathTransitionDuration: 0.5,
									trailColor: '#d6d6d6',
									backgroundColor: '#3e98c7',
									textColor: '#333', // Optional: Adjust text color
									pathColor: '#4db8ff', // Optional: Adjust path color
								})}
							/>
						

						</Box>
						<Box width="fit-content">
							<Typography  width="fit-content" color="green" fontWeight="bold" fontFamily="poppins">{Number(gymRage[0]?.GYMRemains) + Number(gymRage[0]?.GYMused ) }</Typography>
							<Typography    width="fit-content"  color="red" fontWeight="bold" fontFamily="poppins">{ Number(gymRage[0]?.GYMused ) }</Typography>
							</Box>
					</Box>

					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					{/* Title */}
						<Typography
							fontWeight="bold"
							fontSize="15px"
							color="black"

							fontFamily="poppins"
							id="modal-modal-title"
							variant="h6"
							component="h2"
							sx={{ marginBottom: 1 }} // Add space between title and progress bar
						>
							Class
						</Typography>

						{/* Circular Progress Bar */}
						<Box sx={{ width: '100px', height: 'fit-content' }}>
							<CircularProgressbar
								value={Classpercentage}
								text={`${Classpercentage.toFixed(2)}%`}
								styles={buildStyles({
									rotation: 0.25,
									strokeLinecap: 'butt',
									textSize: '16px',
									pathTransitionDuration: 0.5,
									trailColor: '#d6d6d6',
									backgroundColor: '#3e98c7',
									textColor: '#333', // Optional: Adjust text color
									pathColor: '#4db8ff', // Optional: Adjust path color
								})}
							/>
						</Box>
						<Box width="fit-content">
							<Typography  width="fit-content" color="green" fontWeight="bold" fontFamily="poppins">{Number(classRate[0]?.ClassRemains) + Number(classRate[0]?.Classused ) }</Typography>
							<Typography    width="fit-content"  color="red" fontWeight="bold" fontFamily="poppins">{ Number(classRate[0]?.Classused ) }</Typography>
							</Box>
					</Box>



					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					{/* Title */}
						<Typography
							fontWeight="bold"
							fontSize="15px"
							color="black"

							fontFamily="poppins"
							id="modal-modal-title"
							variant="h6"
							component="h2"
							sx={{ marginBottom: 1 }} // Add space between title and progress bar
						>
							Academy
						</Typography>

						{/* Circular Progress Bar */}
						<Box sx={{ width: '100px', height: 'fit-content' }}>
							<CircularProgressbar
								value={Academypercentage}
								text={`${Academypercentage.toFixed(2)}%`}
								styles={buildStyles({
									rotation: 0.25,
									strokeLinecap: 'butt',
									textSize: '16px',
									pathTransitionDuration: 0.5,
									trailColor: '#d6d6d6',
									backgroundColor: '#3e98c7',
									textColor: '#333', // Optional: Adjust text color
									pathColor: '#4db8ff', // Optional: Adjust path color
								})}
							/>
						</Box>
						<Box width="fit-content">
							<Typography  width="fit-content" color="green" fontWeight="bold" fontFamily="poppins">{Number(acadmyRate[0]?.AcademyRemains) + Number(acadmyRate[0]?.Academyused ) }</Typography>
							<Typography    width="fit-content"  color="red" fontWeight="bold" fontFamily="poppins">{ Number(acadmyRate[0]?.Academyused ) }</Typography>
							</Box>
					</Box>
				
			</Box>

		</Modal>
	)
}

export default RateModal