import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { result } from 'lodash';
import { CircularProgress } from '@mui/material';

const API = process.env.REACT_APP_API


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));




function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function ClassPackages() {


	const [packages, setPackages] = useState([])
	const [loading, setLoading] = useState()
	const [packID, setPackID] = useState()
	const [render, setRender] = useState(false)
	const [open, setOpen] = React.useState(false);
	const [DialogOpen, setDialogOpen] = React.useState(false);
	const [updatedSessions, setUpdatedSessions] = useState()
	const [updatedPrice, setUpdatedPrice] = useState()
	const [packageID, setPackageID] = useState()


	const handleClickOpenDialog = () => {
		setDialogOpen(true);
	};
	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};



	function handleUpdatePackage(x, y, z) {
		setUpdatedSessions(x)

		setUpdatedPrice(y)
		setPackID(z)

		handleClickOpenDialog()
	}





	useEffect(function () {
		async function fetchPackages() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getAllClassPackages`)
				const data = await res.json()
				console.log(data)
				setPackages(data.classePackages)


			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchPackages()
	}, [render])


	function DeleteClassPack() {
		fetch(`${API}/deleteClassPackage`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'packageID': packID

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

					setRender(!render)
					
				}

			})
	}
	function handleClassPackageStatus(id) {
		fetch(`${API}/editClassPackagestatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ packageID: id }),
		})
		.then(response => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			return response.json();
		})
		.then(result => {
			console.log('API Response:', result); // Log the actual response
			if (result.status === "200") {
				setRender(!render)

				toast.success(result.Message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				
			} else {
				toast.error(`Error: ${result.Message || 'An unexpected error occurred.'}`, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
			}
		}).catch(error => {
			console.error('Fetch error:', error);
			toast.error(`Fetch error: ${error.message}`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		});
	}
	
	function updatePackage(e) {
		e.preventDefault()
		fetch(`${API}/editClassPackage`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'packageID': packID,
				'sessionsCount': updatedSessions,
				'price': updatedPrice
			})
		})
			.then(response => response.json())
			.then(result => {
				handleCloseDialog();

				if (result.status === "200") {
					setRender(!render)

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});




				} else {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});				}
			}).then(setRender(!render)
		)
			.catch(error => {
				toast.error("Network error: " + error.message);
			});





	}

	{
		if (loading) return <CircularProgress sx={{
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)', // Centers the spinner
			height: '100vh', // Full height of the viewport
			width: '100vw', // Full width of the viewport
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}} />
	}
	return (
		<div >
					<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle sx={{ fontWeight: "bold" }} id="alert-dialog-title">
					{"    Are you sure?       "}
				</DialogTitle>

				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={() => { DeleteClassPack(); handleClose() }} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>


			<BootstrapDialog
				onClose={handleCloseDialog}
				aria-labelledby="customized-dialog-title"
				open={DialogOpen}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Modal title
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleCloseDialog}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<form onSubmit={(e) => updatePackage(e)} style={{ display: "block", width: "300px", height: "300px", padding: "20px", marginLeft: "0", backgroundColor: "white" }}>
					<TextField onChange={(e) => setUpdatedSessions(e.target.value)} value={updatedSessions} style={{ marginBottom: "20px", backgroundColor: "white" }} id="outlined-basic" label="Count" variant="outlined" /> <br />
					<TextField onChange={(e) => setUpdatedPrice(e.target.value)} value={updatedPrice} id="outlined-basic" label="Price" variant="outlined" />
					<Button style={{ margin: "20px auto", color: "white", fontWeight: "bold", height: "35px", backgroundColor: "black", borderRadius: "15px", width: "200px" }} autoFocus onClick={updatePackage}>
						confirm
					</Button>
				</form>

			</BootstrapDialog>

			<TableContainer sx={{ width: "70%", margin: "100px auto" }} component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ backgroundColor: "#e4e4e4", fontWeight: "bold" , fontFamily:"poppins"}} align="center">Family</TableCell>
							<TableCell sx={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily:"poppins" }} align="center">Sessions Count</TableCell>
							<TableCell sx={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily:"poppins" }} align="center">Price</TableCell>
							<TableCell sx={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily:"poppins" }} align="center"></TableCell>
							<TableCell sx={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily:"poppins" }} align="center"></TableCell>
							<TableCell sx={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily:"poppins" }} align="center"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{packages?.map((row) => (
							<TableRow key={row.ClassPackageID}>
								<TableCell  sx={{fontWeight: "bold", fontFamily:"poppins"}} align="center">{row.ClassPackageDesc}</TableCell>
								<TableCell sx={{fontWeight: "bold", fontFamily:"poppins"}} align="center">{row.SessionsCount}</TableCell>
								<TableCell sx={{fontWeight: "bold", fontFamily:"poppins"}} align="center">{row.price}</TableCell>
								<TableCell align="center">
									<Button
										onClick={() => handleUpdatePackage(row.SessionsCount, row.price, row.ClassPackageID)}
										variant="contained"
										sx={{ backgroundColor: '#23395d', borderRadius: "7px", color: 'white' }}
										startIcon={<EditIcon sx={{ color: 'white' }} />}
									>
										Edit
									</Button>
								</TableCell>
								<TableCell align="center">
									<Button
										onClick={() => { setPackID(row.ClassPackageID); handleClickOpen(); }}
										variant="contained"
										sx={{ backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
										startIcon={<DeleteIcon sx={{ color: 'white' }} />}
									>
										Delete
									</Button>
								</TableCell>
								<TableCell align="center">
            <Button
              variant="contained"
              sx={{     backgroundColor: row.status === "active" ? '#4cc764' : '#e4e4e4',borderRadius: "7px", color: 'white' }}
              startIcon={<AutorenewIcon sx={{ color: 'white' }} />}
              onClick={ () => handleClassPackageStatus(row.ClassPackageID)}>
              {row.status == "active"? ("deactivate"):("activate")}
            </Button>
          </TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>


		
		</div>
	)
}

export default ClassPackages