import { useNavigate, useParams } from "react-router-dom";
import "../styles/userpage.css";
import ClassNav from "../components/classNav";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, Button, CircularProgress, Typography, Box } from '@mui/material';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import SettingsIcon from '@mui/icons-material/Settings';
import { AuthProvider, useAuth }  from '../contexts/authContext';

import LogoutIcon from '@mui/icons-material/Logout';
import {  Card, CardContent, CardMedia } from '@mui/material';



const list = [
    { icon: "user.png", name: "Show users" },
    { icon: "user.png", name: "Add user" },
    { icon: "dumbbell.png", name: "GYM" },
    { icon: "karate.png", name: "Academy" },
    { icon: "yoga.png", name: "Group Fitness" },
    { icon: "dollar.png", name: "Finance" },
    { icon: "rent.png", name: "Rent" },
    { icon: "ot.png", name: "One Time" },
    { icon: "diet.png", name: "diet" },
    { icon: "injury.png", name: "Clinic" },






]

function IconComponent({ m }) {
    return (
        <Card 
            sx={{ 
                maxWidth: 300,
                height: "100px",
                width: "100%", // Make the card full width
                border: "1px solid #dbf0f1",
                textAlign: 'center', 
                borderRadius: '10px', 
                boxShadow: 0,
                backgroundColor: "#f5f7fb",
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': { 
                    transform: 'scale(1.05)',
                  border: "1px solid #e4e4e4",
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // More mild shadow
                },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 4px', // Narrow horizontal space
            }}
        >
            <CardMedia
                component="img"
                height="40"
                image={m.icon}
                alt={m.name} // Ensure alt text is provided for accessibility
                sx={{ margin: 'auto', objectFit: 'contain' }}
            />
            <Typography fontSize="18px" fontWeight="bold" fontFamily="Poppins" color="black">
                {m.name}
            </Typography>
        </Card>
    );
}

const UserPage = () => {
    const API = process.env.REACT_APP_API;
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState();
    const [Role, setRole] = useState();
    const userid = localStorage.getItem('userID');
    const branch = localStorage.getItem("loggedBranch");
    const {logout} = useAuth()

    useEffect(() => {
        const items = localStorage.getItem('Roles');
        if (items) {
            setRole(items);
        }
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(`${API}/${userid}`);
                const data = await res.json();
                setUserData(data.data);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);
    if (loading) return <CircularProgress style={{ display: "flex", margin: "auto", top: "50%" }} />;

    return (
        <Grid container spacing={2} justifyContent="center" sx={{ padding: 2, backgroundColor: "#f5f7fb" }}>
        <Box
            sx={{
                display: "flex",
                backgroundColor: "white",
                borderBottom: "1px solid #e4e4e4",
                flexDirection: "row",
                minWidth: "80%",
                width: "auto",
                height: "fit-content",
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Mild shadow
                borderRadius: "10px", // Optional: Add rounded corners
                padding: "10px", // Optional: Add padding for better spacing
                justifyContent: "space-between",
                height: "100px"
            }}
        >
            <Box>
                <img
                    src="./logo100.png"
                    alt="User"
                    style={{ borderRadius: '50%', width: '90px', height: '90px' }}
                />
            </Box>
    
            <Box
                sx={{
                    textAlign: 'center',
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    width: "auto",
                    borderRadius: "10px",
                    padding: "10px"
                }}
            >
                <Box sx={{ display: "flex", width: "fit-content", alignItems: "center", textAlign: "center" }}>
                    <img
                        src="./figure.png"
                        alt="User"
                        style={{ borderRadius: '50%', width: '70px', height: '70px' }}
                    />
                    <Box display="block" alignItems="center" textAlign="center" marginRight="14px">
                        <Typography variant="h6" fontWeight="bold" fontFamily="Poppins">
                            {userData[0]?.Fullname}
                        </Typography>
                        <Typography variant="h10" fontFamily="Poppins">{branch}</Typography>
                    </Box>
                    <Dropdown>
                        <MenuButton
                            slots={{ root: IconButton }}
                            slotProps={{ root: { variant: 'none', color: 'neutral' } }}
                        >
                            <SettingsIcon sx={{ color: "black" }} />
                        </MenuButton>
                        <Menu placement="bottom-end">
                            <MenuItem onClick={logout} fontWeight="bold" variant="soft" color="danger">
                                <ListItemDecorator sx={{ color: 'inherit' }}>
                                    <LogoutIcon />
                                </ListItemDecorator>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                </Box>
            </Box>
        </Box>
    
        <Grid
            container
            spacing={1} // Adjusted for narrower horizontal gaps
            justifyContent="center"
            sx={{
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                margin: "auto",
                backgroundColor: "white",
                width: "90%",
                maxWidth: "80%",
                borderRadius: "12px",
                marginTop: "30px",
                padding:"30px"
            }}
        >
            {[
                { to: "/all-customers", name: "All customers" , icon: "customers.png" },
                Role === "Admin" && { to: "/trainers-dashboard", icon: "trainerIcon.png", name: "Trainers" },
                { to: "/attendance/gym", icon: "checked.png", name: "Attendance" },
                (Role === "Admin" || Role === "General Manager") && { to: "/users", icon: "user.png", name: "Show users" },
                (Role === "Admin" || Role === "General Manager") && { to: "/add-user", icon: "user.png", name: "Add user" },
                { to: "/customers", icon: "dumbbell.png", name: "GYM" },
                (Role === "Admin" || Role === "General Manager") && { to: "/financial/all", icon: "dollar.png", name: "Finance" },
                { to: "/academy", icon: "karate.png", name: "Academy" },
                { to: "/classes/all-classes", icon: "yoga.png", name: "Group Fitness" },
                Role === "Admin" && { to: "/add-branch", icon: "add.png", name: "Add Branch" },
                (Role === "Admin" || Role === "Receptionist") && { to: "/rent/add-tentant", icon: "rent.png", name: "Rent" },
                Role === "Admin" && { to: "/diet", icon: "diet.png", name: "Diet" },
                { to: "/invitations", icon: "invite.png", name: "Invitations" },
                { to: "/clinic", name: "Clinic", icon: "injury.png" },
            ].filter(Boolean).map((item) => (
                <Grid item xs={6} sm={4} md={3} key={item.name}>
                    <Link to={item.to} style={{ textDecoration: 'none' }}>
                        <IconComponent m={item} />
                    </Link>
                </Grid>
            ))}
        </Grid>
    </Grid>
    
    );
};

export default UserPage;

