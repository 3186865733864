import { Cases } from "@mui/icons-material"
import "../../styles/otspage.css"
import { useEffect, useReducer, useRef, useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import e from "cors";
import { render } from "@testing-library/react";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogContentText from '@mui/material/DialogContentText';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Tooltip from '@mui/material/Tooltip';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from '@mui/material/List';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';

import Avatar from '@mui/material/Avatar';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import CancelIcon from '@mui/icons-material/Cancel';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    Box,
    MenuItem,
    Select,
    Button,
    FormControl,
    InputLabel,
    Typography,
  } from '@mui/material';
const API = process.env.REACT_APP_API


// const todayDate = moment().tz("Africa/Cairo").format("YYYY-MM-DD hh:mm:ss").split(" ")
// const today = todayDate[0]

function AddDiet({ customerID, rerender, render }) {
    // const [customerName, setCustomerName]=useState()
    const [capName, setCapName] = useState()
    const [packages, setPackagees] = useState()
    const [packagesLoading, setPackagesLoading] = useState()
    const [selectedPackageName, setSelectedPackageName] = useState()
    const [selectedPackagePrice, setSelectedPackagePrice] = useState()
    const [buttonLoading, setButtonLoading] = useState(false)
    const [openPurge, setOpenPurge] = useState(false);
    const [subID, setSubID] = useState()
	const [Role, setRole] = useState()


	useEffect(() => {
		const items = localStorage.getItem('Roles')
		if (items) {
			setRole(items);
		}
	}, []);


	function PurgeConfirmation() {
		return (
			<Modal open={openPurge} onClose={() => setOpenPurge(false)}>
				<ModalDialog variant="outlined" role="alertdialog">
					<DialogTitle sx={{ padding: "0", fontWeight: "bold" }}>
						<WarningRoundedIcon />
						Confirmation
					</DialogTitle>
					<Divider />
					<DialogContent sx={{ padding: "0" }}>
						Are you sure you want to entirely delete the subscription?
					</DialogContent>
					<DialogActions>

						<Button variant="plain" color="neutral" onClick={() => setOpenPurge(false)}>
							Cancel
						</Button>

						<Button variant="solid" sx={{
							backgroundColor: '#C41C1C',
							color: "white",
							":hover": {
								backgroundColor: '#C41C1C',
								color: "white",
							}
						}} onClick={() => handleDietSubPurge()}>
							Delete
						</Button>
					</DialogActions>
				</ModalDialog>
			</Modal>
		)
	}
    function handleDietSubPurge() {
		fetch(`${API}/purgeDietSub`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({

				"subID": subID,
			})
		}).then(response => response.json())
			.then(result => {
				console.log(result)
				if (result.status == "200") {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					setOpenPurge(false)


				} else {
					toast.error(`Something went wrong`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

					setOpenPurge(false)

				}
			})

	}

    // const customerName = useRef()
    const phone = useRef()
    const [dietData, setDietData] = useState([])
    const [loading, setLoading] = useState()
    const loggedBranch = localStorage.getItem("loggedBranch");

    const User = localStorage.getItem("User");
    const validationSchema = Yup.object().shape({
        captain: Yup.string(),
        package: Yup.number().integer().required('Package is required'),
      });
    function DietDataComponent({ sub }) {
        return (


			<Card orientation="vertical" variant="outlined" sx={{
				position: 'relative',
				transition: 'transform 0.3s, box-shadow 0.3s',
				padding: "20px",
				paddingTop: "50px", // Extra space for the button
				width: "auto",
				minWidth: "fit-content",
				margin: "9px auto"
			}}>{Role == "Admin" || Role == "admin" || Role == "Receptionist" ? ( <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
    
                <IconButton 
                backgroundColor="white"
                     aria-label="cancel" 
                     sx={{
                       color: 'grey', 
                       
                     }}
                     onClick={() => {
                        setSubID(sub?.id)
                        setOpenPurge(true);
                     }}    >
                     <ClearIcon />
                   </IconButton>
                 </Box>) : ("")}
            
                <CardContent>
                    <div style={{ display: "flex", justifyContent: "space-evenly", }} fontWeight="md" textColor="success.plainColor">
                        <div className='package-name'>
                            <div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Package Name</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", alignItems: "center", textAlign: "center" }}>{sub?.packname}</label></div>

                        </div>

                        <div className='pack-endDate'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <CalendarMonthIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}> Date</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.Date}</p></div>

                        </div>


                        {sub?.captinName  ? (<div className='pack-endDate'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Personal Trainer</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.captinName}</p></div>

                        </div>) : ("")}

                       

{sub?.employee?  (
                        <div className='pack-endDate'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonAddIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Employee</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.employee}</p></div>

                        </div>
):("")}




                    </div>



                </CardContent> <br />



            </Card>



        )
    }




    const initialState = {
        status: "loading",
        data: []
    }
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(function () {
        async function fetchOTSPack() {
            try {
                setPackagesLoading(true)
                const res = await fetch(`${API}/dietPackages`)
                const data = await res.json()
                console.log(data)
                setPackagees(data.data)
            } catch (e) {
                console.log(e)
            } finally {
                setPackagesLoading(false)
            }

        }
        fetchOTSPack()
    }, [])

    useEffect(function () {
        async function fetchData() {
            const res = await fetch(`${API}/getAllPTrainers`)
            const data = await res.json()
            dispatch({ status: "fetched", payload: data.result })
        } fetchData()
    }, [])

    function handleSelectedPack(x) {
        const seletedArr = x.split(",")
        setSelectedPackageName(seletedArr[0])
        setSelectedPackagePrice(seletedArr[1])



    }


    function reducer(state, action) {
        switch (action.status) {
            case "fetched":
                return {
                    ...state, status: action.status, data: action.payload

                }
            default:
                return state;
        }

    }


    useEffect(function () {
        async function fetchDiet() {
            try {
                setLoading(true)
                const res = await fetch(`${API}/dietCustomer/${customerID}`)
                const data = await res.json()
                console.log(data)
                setDietData(data.data)

            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }

        } fetchDiet()
    }, [render])




    function handleAddOTSCustomer(values) {
        fetch(`${API}/addDietCustomer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                //    'customerName': customerName.current.value,
                'captinName': values.captain,
                "packageID": values.package,
                //    "phone": phone.current.value,
                "customerID": customerID,
                "branch": loggedBranch,
                "user": User
            })
        }).then(response => response.json())
            .then(result => {

                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                rerender(!render)
            })
    }

    return (
        <div style={{ margin: "0 auto", marginBottom:"100px" }} className="add-ots">
       
<PurgeConfirmation />
            {loading ? (<p>Loading</p>) : (
                
                
         dietData?.map(sub=>{
            return (
                <DietDataComponent sub={sub} />
            )
         })
        
        
        )}

            {state.status == "loading" || packagesLoading ? (<p>Loading</p>) : (
                
                <Formik
      initialValues={{ captain: '', package: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values)
        handleAddOTSCustomer(values);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleBlur }) => (
   <Form style={{ backgroundColor: 'white', marginBottom: "30px", width: "400px", height: "200px", border: '1px solid #e4e4e4', padding: '20px', margin: "auto" }}>
   <FormControl fullWidth margin="normal">
     <InputLabel id="captain-label">Select Captain</InputLabel>
     <Field
       as={Select}
       labelId="captain-label"
       name="captain"
       onChange={handleChange}
       onBlur={handleBlur}
       variant="outlined"
       sx={{ border: '1px solid #dddddd', fontSize: '14px' }}
     >
       <MenuItem value="" disabled>Select Captain</MenuItem>
       {state.data.map((m) => (
         <MenuItem key={m.fullName} value={m.fullName}>
           {m.fullName}
         </MenuItem>
       ))}
     </Field>
     <ErrorMessage name="captain" component={Typography} color="error" />
   </FormControl>
 
   <FormControl fullWidth margin="normal">
     <InputLabel id="package-label">Select Package</InputLabel>
     <Field
       as={Select}
       labelId="package-label"
       name="package"
       onChange={handleChange}
       onBlur={handleBlur}
       variant="outlined"
       sx={{ border: '1px solid #dddddd', fontSize: '14px' }}
     >
       <MenuItem value="" disabled>Select Package</MenuItem>
       {packages?.map((m) => (
         <MenuItem key={m.packageID} value={m.packageID}> {/* Ensure unique key and correct value */}
           {m.Name} {m.Price} EGP
         </MenuItem>
       ))}
     </Field>
     <ErrorMessage name="package" component={Typography} color="error" /> {/* Display error message here */}
   </FormControl>
 
   <LoadingButton
     sx={{
       height: "40px",
       fontWeight: "bold",
       borderRadius: "12px",
       backgroundColor: "#152238",
       width: "250px",
       color: "white",
       margin: "auto",
       ":hover": {
         backgroundColor: "#152238",
       },
     }}
     type="submit"
     loading={buttonLoading}
     variant="contained"
   >
     Submit
   </LoadingButton>
 </Form>
 
      )}
    </Formik>
        
        
        )}
        </div>
    )
}


export default AddDiet