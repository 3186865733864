import SideInfoBar from "../components/usersideInfo";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import Navbar from "../components/navbar";
import "../styles/mainpage.css"
import { useState, useRef } from "react";
import moment, { invalid } from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { render } from "@testing-library/react";
import SideBar from "./sidenav";
import { wait } from "@testing-library/user-event/dist/utils";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { searchParams, useSearchParams } from "react-router-dom";
import { DatePicker } from "antd";
import { TextField, Button, Grid, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';
import Select from '@mui/material/Select';
import { Select as AntdSelect } from 'antd';
import Webcam from 'react-webcam';
import { IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import LoadingButton from '@mui/lab/LoadingButton';

import { v4 as uuidv4 } from 'uuid';

function AddCustomerPage() {
  const API = process.env.REACT_APP_API

  const [age, setAge] = useState()
  const [searchParams] = useSearchParams();
  const [idLoading, setIdLoading] = useState()
  const [availableId, setAvailableID] = useState([])
  const [selectedID, setSelectedID] = useState()
  const branch = localStorage.getItem("loggedBranch");
  const [birthDate, setDate] = useState()
  const [Role, setRole] = useState()
  const value = new Date();
  const result = value.toDateString();

  const [visibility, setVisibilty] = useState(true)
  const [customerData, setcustomerData] = useState({})
  const navigate = useNavigate()

  const [optionsArr, setOptionsArr] = useState([]);
  const [proceed, setProceed] = useState()

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const [error, setError] = useState()
  const [isCameraActive, setIsCameraActive] = useState(false);


  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const webcamRef = useRef(null);
  const [imageSrc, setImgeSrc] = useState()
  const formData = new FormData()
	const employee = localStorage.getItem("User")
  const [btnloading, setBtnLoading] = useState()




  const capture = () => {
    const screenshot = webcamRef.current.getScreenshot();
    if (screenshot) {
      setImgeSrc(screenshot);
      console.log(imageSrc)
    }
  };



  const [formValues, setFormValues] = useState({
    fullname: '',
    nationalID: '',
    phone: '',
    secPhone: '',
    birthdate: '',
    specialID: '',
    address: '',
    gender: '',
    Work: '',




  });
  const [errors, setErrors] = useState({
    fullname: '',
    nationalID: '',
    phone: '',
    secPhone: '',
    birthdate: '',
    specialID: '',
    address: '',
    gender: '',
    Work:""

  });

  // Handle input changes

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };




  const onIDChange = (value) => {

    setSelectedID(value)
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  // Validate form fields
  const validate = () => {
    const newErrors = {};

    if (!formValues.fullname) {
      newErrors.fullname = 'Fullname is required';
    }
    if (!formValues.Work) {
      newErrors.Work = 'Work is required';
    }


    if (!formValues.phone) {
      newErrors.phone = 'Phone is required';
    } else if (formValues.phone.length != 11) {
      newErrors.phone = 'Should be 11 numbers';
    }

    if (!formValues.address) {
      newErrors.address = 'Address is required';
    }
    if (formValues.nationalID.length != 0 && formValues.nationalID.length != 14) {
      newErrors.nationalID = 'Should be 14 numbers';
    }
    if (!formValues.gender) {
      newErrors.gender = 'Gender is required';
    }



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  useEffect(() => {
    const items = localStorage.getItem('Roles')
    console.log(items)
    if (items) {
      setRole(items);
    }
  }, []);



  useEffect(function () {
    async function fetchDate() {
      try {
        setIdLoading(true)
        const res = await fetch(`${API}/getUniqueID`)
        const data = await res.json()
        setAvailableID(data.data)


      } catch (e) {
        console.log(e)
      } finally {


        setIdLoading(false)

      }


    } fetchDate()
  }, [])

  useEffect(() => {
    if (availableId.length != 0) {
      const processData = async () => {
        availableId.map(m => {
          setOptionsArr(prevItems => [...prevItems, {
            value: m.customerID,
            label: m.customerID

          }]);

        });
      };

      processData();
    }
  }, [availableId]);






  console.log(formValues)

  async function handleSubmit(e) {
    e.preventDefault()

    formData.append("FullName", formValues.fullname)
    formData.append("NationalID", formValues.nationalID)
    formData.append("Phone", formValues.phone)
    formData.append("birthdate", formValues.birthdate)
    formData.append("Address", formValues.address)
    formData.append("branch", branch)
    formData.append("secPhone", formValues.secPhone)
    formData.append("employee", employee)




    // formData.append("specialID", formValues.specialID)
    formData.append("Gender", formValues.gender)
    formData.append("uniqueID",selectedID)
    formData.append("work",formValues.Work)




    if (imageSrc) {
			const response = await fetch(imageSrc);
			const blob = await response.blob();
			const uniqueFileName = `photo-${uuidv4()}.png`;
			formData.append('image', blob, uniqueFileName);

		  }
    if (validate()) {
      setBtnLoading(true)
      // Submit form data
      fetch(`${API}/customers`, {
        method: 'POST',

        body: formData,


      }).then(response => response.json()).then(result => {
        if (result.status == 401) {
          toast.error(`${result.Message}`, {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
          console.log(result.data)
          wait(3500).then(() => {
            setBtnLoading(false)

            setVisibilty(false)

          })



        }


        if (result.status === 200) {
          toast.success(`${result.Message}`, {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",

          },
            setTimeout(() => {
              setBtnLoading(false)

              navigate(`/profile/${result.data}`)
            }, 3000),

          )
        }


      })
    }

  }





  function onChange(date, dateString) {
    setFormValues({
      ...formValues,
      birthdate: dateString
    });



  }

  return (

    <>
      {idLoading ? (<CircularProgress sx={{ alignItems: "center", display: "flex", justifyContent: "center", top: "50%", left: "50%", transform: "translate(-50%, -50%)", position: "absolute" }} />) : (<div className="customer-page-components">


        <div>
          <img src="customer.jpg" />

        </div>
        <form style={{ height: "auto", padding: "10px", zIndex: "100" }} onSubmit={(e) => handleSubmit(e)}>
          <h4>Add Customer</h4>
  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
    <TextField
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          fontWeight: 'bold',
          '& input': {
            backgroundColor: 'white',
            height: "20px",
            '&:focus': {
              backgroundColor: '#e4e4e4',
            },
          },
          borderColor: 'gray',
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: '#',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#23395d',
            fontWeight: 'bold',
          },
        },
        '& .MuiInputLabel-root': {
          fontWeight: "bold",
          color: 'gray',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#23395d',
        },
        '& .MuiInputBase-input': {
          color: 'black',
        },
      }}
      label="Full Name"
      name="fullname"
      type="fullname"
      value={formValues.fullname}
      onChange={handleChange}
      error={!!errors.fullname}
      helperText={errors.fullname}
      margin="normal"
    />

    <TextField
       sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          fontWeight: 'bold',
          '& input': {
            backgroundColor: 'white',
            height: "20px",
            '&:focus': {
              backgroundColor: '#e4e4e4',
            },
          },
          borderColor: 'gray',
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: '#',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#23395d',
            fontWeight: 'bold',
          },
        },
        '& .MuiInputLabel-root': {
          fontWeight: "bold",
          color: 'gray',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#23395d',
        },
        '& .MuiInputBase-input': {
          color: 'black',
        },
      }}
      label="National ID"
      name="nationalID"
      value={formValues.nationalID}
      onChange={handleChange}
      error={!!errors.nationalID}
      helperText={errors.nationalID}
      margin="normal"
    />
  </div>
  
  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
    <TextField
       sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          fontWeight: 'bold',
          '& input': {
            backgroundColor: 'white',
            height: "20px",
            '&:focus': {
              backgroundColor: '#e4e4e4',
            },
          },
          borderColor: 'gray',
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: '#',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#23395d',
            fontWeight: 'bold',
          },
        },
        '& .MuiInputLabel-root': {
          fontWeight: "bold",
          color: 'gray',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#23395d',
        },
        '& .MuiInputBase-input': {
          color: 'black',
        },
      }}
      label="Phone"
      name="phone"
      type="text"
      value={formValues.phone}
      onChange={handleChange}
      error={!!errors.phone}
      helperText={errors.phone}
      margin="normal"
    />

    <TextField
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          fontWeight: 'bold',
          '& input': {
            backgroundColor: 'white',
            height: "20px",
            '&:focus': {
              backgroundColor: '#e4e4e4',
            },
          },
          borderColor: 'gray',
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: '#',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#23395d',
            fontWeight: 'bold',
          },
        },
        '& .MuiInputLabel-root': {
          fontWeight: "bold",
          color: 'gray',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#23395d',
        },
        '& .MuiInputBase-input': {
          color: 'black',
        },
      }}
      label="Second Phone"
      name="secPhone"
      value={formValues.secPhone}
      onChange={handleChange}
      error={!!errors.secPhone}
      helperText={errors.secPhone}
      margin="normal"
    />
  </div>

  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
    <TextField
       sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          fontWeight: 'bold',
          '& input': {
            backgroundColor: 'white',
            height: "20px",
            '&:focus': {
              backgroundColor: '#e4e4e4',
            },
          },
          borderColor: 'gray',
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: '#',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#23395d',
            fontWeight: 'bold',
          },
        },
        '& .MuiInputLabel-root': {
          fontWeight: "bold",
          color: 'gray',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#23395d',
        },
        '& .MuiInputBase-input': {
          color: 'black',
        },
      }}
      label="Work"
      name="Work"
      value={formValues.Work}
      onChange={handleChange}
      error={!!errors.Work}
      helperText={errors.Work}
      margin="normal"
    />

    <TextField
    sx={{
      width: '100%',
      '& .MuiOutlinedInput-root': {
        fontWeight: 'bold',
        '& input': {
          backgroundColor: 'white',
          height: "20px",
          '&:focus': {
            backgroundColor: '#e4e4e4',
          },
        },
        borderColor: 'gray',
        '& fieldset': {
          borderColor: 'gray',
        },
        '&:hover fieldset': {
          borderColor: '#',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#23395d',
          fontWeight: 'bold',
        },
      },
      '& .MuiInputLabel-root': {
        fontWeight: "bold",
        color: 'gray',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#23395d',
      },
      '& .MuiInputBase-input': {
        color: 'black',
      },
    }}
      label="Address"
      name="address"
      value={formValues.address}
      onChange={handleChange}
      error={!!errors.address}
      helperText={errors.address}
      margin="normal"
    />
  </div>

  <FormControl sx={{ width: "250px", marginTop: "15px", marginRight: "10px" }}>
    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={formValues.gender}
      name="gender"
      label="Gender"
      onChange={handleChange}
      error={!!errors.gender}
      helperText={errors.gender}
    >
      <MenuItem value="Male">Male</MenuItem>
      <MenuItem value="Female">Female</MenuItem>
    </Select>
  </FormControl>

  <DatePicker
    required
    placeholder="Birthdate"
    style={{ width: "250px", height: "50px", marginTop: "20px", marginRight: "10px" }}
    onChange={onChange}
  />

  {Role == "Admin" ? (
    <AntdSelect
      style={{ width: "250px", height: "50px", marginTop: "20px", fontWeight: "bold", marginRight: "10px" }}
      showSearch
      placeholder="Unique ID"
      optionFilterProp="label"
      onChange={onIDChange}
      onSearch={onSearch}
      options={optionsArr}
    />
  ) : null}

  <div>
    <Button
      variant="contained"
      startIcon={<PhotoCamera />}
      onClick={() => setIsCameraOpen(!isCameraOpen)}
      sx={{
        marginTop: 2,
        width: "160px",
        backgroundColor: "#e4e4e4",
        color: "black",
        borderBottom: "none",
        whiteSpace: 'nowrap',
        alignItems: "center",
        fontWeight: "bold",
        '&:hover': {
          backgroundColor: 'grey',
        },
      }}
    >
      {isCameraOpen ? 'Close Camera' : 'Open Camera'}
    </Button>

 <div style={{display:"flex" , flexDirection:"row" ,alignItem:"center", textAlign:"center"}}>
 {isCameraOpen && (
      <div>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={200}
          height={150}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={capture}
          sx={{
            marginTop: 2,
            width: "160px",
            backgroundColor: "#e4e4e4",
            color: "black",
            borderBottom: "none",
            whiteSpace: 'nowrap',
            alignItems: "center",
            fontWeight: "bold",
            '&:hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          Capture Photo
        </Button>
      </div>
    )}

{isCameraOpen && imageSrc && (
       <div>
       <img src={imageSrc} alt="Captured" width="200" height="150" />
     </div>
     
      )}
 </div>
  </div>

  {/* <Button
    style={{ width: "200px", height: "40px", borderRadius: "15px", margin: "20px auto" }}
    type="submit"
    variant="contained"
    color="primary"
  >
    Submit
  </Button> */}

<LoadingButton
                        sx={{
                            height: "40px",
                            fontWeight: "bold",
                            borderRadius: "12px",
                            backgroundColor: "#152238",
                            width: "250px",
                            color: "white",
                            margin: "auto",
                            ":hover": {
                                backgroundColor: "#152238",
                            },
                        }}
                        type="submit" // Change to submit
                        loading={btnloading}
                        variant="contained"
                    >
                        Submit
                    </LoadingButton>
</form>

      </div>)}

      <Navbar />
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />



    </>
  )
}

export default AddCustomerPage;