import * as React from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import {useRef} from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API = process.env.REACT_APP_API
const userID = localStorage.getItem("userID")


const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));



function StatusCard({o, handleClickOpen, setSelectedID}) {

  return (
    <div className='current-status' style={{marginTop:"20px", display:"flex",borderRadius:"6px",alignItems:"center",textAlign:"center", height:"60px", backgroundColor:"#ebeff8",padding:"10px", borderRadius:"7px", justifyContent:"space-between", width:"70%", margin:"0 auto", marginBottom:"5px"}}>
    <p style={{width:"60%", fontSize:"14px",color:"black", marginBottom:"0"}}>{ o.Description}</p>
    <p style={{width:"20%",fontSize:"12px", color:"black", marginBottom:"0"}}>Added by  { o .Fullname}</p>
    <p style={{width:"20%",fontSize:"12px", color:"black", marginBottom:"0"}}> { o .Date}</p>

      <Button onClick={()=> { setSelectedID(o.id); handleClickOpen()}} endIcon={<DeleteIcon   sx={{color:"black"}}/>}/>
    </div>
  )
}



function NoStatusCard({o, handleClickOpen, setSelectedID}) {

  return (
    <div className='current-status' style={{marginTop:"20px", display:"flex",borderRadius:"6px",alignItems:"center",textAlign:"center", height:"60px", backgroundColor:"#ebeff8",padding:"10px", borderRadius:"7px", justifyContent:"space-between", width:"70%", margin:"0 auto", marginBottom:"5px"}}>
    <p style={{width:"60%", fontSize:"14px",color:"black", marginBottom:"0"}}>{ o.Description}</p>
    <p style={{width:"20%",fontSize:"12px", color:"black", marginBottom:"0"}}>Added by Cap { o.Fullname}</p>
    <p style={{width:"20%",fontSize:"12px", color:"black", marginBottom:"0"}}> { o.Date}</p>

    </div>
  )
}

function HealthProfile() {
  const [description, setDescription] = React.useState()
  const [loading, setLoading] = React.useState()
  const [medicalData, setMedicalData] = React.useState()

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [selectedStatusId, setSelectedID] = React.useState()
  const [chronic, setChronic] = useState()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState()
    const [rerender, setRender] = useState(value)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  console.log(medicalData)

const params = useParams()
const id = params.customerID
console.log(id)

  function AddStatus(e) {
    e.preventDefault()

    fetch(`${API}/addHealthstatus`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({
         'customerID': id,
         'TrainerID': userID ,
         'Description': description
      })
  }).then(response => response.json())
  .then(result => {
    if (result.status == 200) {
      toast(`${result.Message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
    setRender(!rerender)

  }
  
)
  }

  function AddChronic(e) {
    e.preventDefault()

    fetch(`${API}/addHealtChronic`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({
         'cstomerByID': id,
         'chronic': chronic,
         'TrainerID': userID ,

      })
  }).then(response => response.json())
  .then(result=>{
    if (result.status == 200) {
      toast(`${result.Message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }

    setRender(!rerender)

  })
  }


  
  function deleteStatus(e) {
    e.preventDefault()

    fetch(`${API}/deleteSTatus`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({
         'cstomerByID': id,
         'statusID': selectedStatusId ,
      })
  }).then(response => response.json())
  .then(  result=>{
    if (result.status == 200) {
      toast(`${result.Message}`, {
        position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "dark",
        });
    }

    setRender(!rerender)
      
    }
  
)

  }

  React.useEffect(function(){
    async function fetchDate() {
      try {
        setLoading(true)
        const res = await fetch (`${API}/healthHistory/${id}`)
        const data = await res.json()
        setMedicalData(data.data)
        console.log(data)
      } catch(e) {
        console.log(e)
      } finally{
        setLoading(false)
      }
    }      fetchDate()

  },[rerender])
  const content = (
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit.`}</p>
  );
  return (
    <div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>

        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        
        <DialogContent>
          <DialogContentText>
           Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={ (e)=> {deleteStatus(e); handleClose()}} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ backgroundColor: "white", borderRadius: "10px", height: "300%", position: "relative", width: "70%", border: "1px solid #e4e4e4", margin: "40px auto" }} className='up-health'>
        <h6 style={{margin:"10px", fontWeight:"bold"}}>Status And Notes</h6>
        <div style={{display:"flex",border:"1px solid #e4e4e4",borderRadius:"7px",height:"50px",width:"70%",margin:"0 auto", justifyContent:"space-evenly", marginTop:"50px", textAlign:"center", alignItems:"center"}} className='add-note'>
          <form  onSubmit={(e)=>AddStatus(e)} style={{backgroundColor:"none",marginLeft:"0", width:"500px", height:"40px", padding:"10px", display:"flex"}}>
          <input  required  onChange={(e)=> setDescription(e.target.value)} placeholder='Type status or note' type="text" style={{fontSize:"13px", outlineStyle:"none",backgroundColor:"#f1f4f9", border:"1px solid #e4e4e4", marginBottom:"0",borderRadius:"5px",width:"300px",height:"30px"}} />
          <button style={{height:"30px",border:"none", backgroundColor:"#2c608f", color:"white", width:"100px",  marginBottom:"0",borderRadius:"5px", fontSize:"14px"}}>Add</button>
          </form>
        </div>

        <label style={{backgroundColor:"#008631", display:"grid",color:"white", height:"25px", alignItems:"center", textAlign:"center", borderRadius:"20px", padding:"3px", marginLeft:"50%", fontSize:"12px", width:"90px" , marginTop:"20px", marginBottom:"20px"}}>Current</label>

       {medicalData?.filter(m=>{
        return (
          m.Status == "current"
        )
       }).map(o=>{
        return (
                      <StatusCard  setSelectedID={setSelectedID} handleClickOpen={handleClickOpen} o ={o}/>

        )
       })}

<label style={{backgroundColor:"red", display:"grid",color:"white", height:"25px", alignItems:"center", textAlign:"center", borderRadius:"20px", padding:"3px", marginLeft:"50%", fontSize:"12px", width:"90px" , marginTop:"20px", marginBottom:"20px"}}>History</label>

{medicalData?.filter(m=>{
 return (
   m.Status == "history"
 )
}).map(o=>{
 return (
               <NoStatusCard  setSelectedID={setSelectedID} handleClickOpen={handleClickOpen} o ={o}/>

 )
})}


      </div>
      <div style={{ backgroundColor: "white", borderRadius: "10px", height: "300%", position: "relative", width: "70%", border: "1px solid #e4e4e4", margin: "40px auto" }} className='down-health'>

        <h6 style={{margin:"10px", fontWeight:"bold"}}>Chronic</h6>
        <div style={{display:"flex",border:"1px solid #e4e4e4",borderRadius:"7px",height:"50px",width:"70%",margin:"0 auto", justifyContent:"space-evenly", marginTop:"50px", textAlign:"center", alignItems:"center"}} className='add-note'>
          <form  onSubmit={(e)=>AddChronic (e)} style={{backgroundColor:"none",marginLeft:"0", width:"500px", height:"40px", padding:"10px", display:"flex"}}>
          <input  required  onChange={(e)=> setChronic(e.target.value)} placeholder='Type chronic' type="text" style={{fontSize:"13px", outlineStyle:"none",backgroundColor:"#f1f4f9", border:"1px solid #e4e4e4", marginBottom:"0",borderRadius:"5px",width:"300px",height:"30px"}} />
          <button style={{height:"30px",border:"none", backgroundColor:"#2c608f", color:"white", width:"100px",  marginBottom:"0",borderRadius:"5px", fontSize:"14px"}}>Add</button>
          </form>
        </div>


       {medicalData?.filter(m=>{
        return (
          m.Status == "chronic"
        )
       }).map(o=>{
        return (
                      <NoStatusCard  setSelectedID={setSelectedID} handleClickOpen={handleClickOpen} o ={o}/>

        )
       })}




      </div>

      </div>
  )
}


export default HealthProfile