import { useState, useEffect, useRef, useReducer } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "../../styles/customerProfile.css"
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaidIcon from '@mui/icons-material/Paid';
import TextField from '@mui/material/TextField';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { MdPhoneIphone } from "react-icons/md";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
const API = process.env.REACT_APP_API


function AddCustomerOTS({ hideShowDiv, isActive }) {
    const params = useParams()
    const [capName, setCapName] = useState()
    const [packages, setPackagees] = useState()
    const [packagesLoading, setPackagesLoading] = useState()
    const [selectedPackageName, setSelectedPackageName] = useState()
    const [selectedPackagePrice, setSelectedPackagePrice] = useState()
    const [otsloading, setOtsLoading] = useState()
    const [otsData, setOtsData] = useState([])
    const customerName = useRef()
    const phone = useRef()
    const loggedBranch = localStorage.getItem("loggedBranch");


    const initialState = {
        status: "loading",
        data: []
    }
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(function () {
        async function fetchOTSPack() {
            try {
                setPackagesLoading(true)
                const res = await fetch(`${API}/getAllOTSPack`)
                const data = await res.json()
                setPackagees(data.result)
            } catch (e) {
                console.log(e)
            } finally {
                setPackagesLoading(false)
            }

        }
        fetchOTSPack()
    }, [])

    useEffect(function () {
        async function fetchData() {
            const res = await fetch(`${API}/getAllPTrainers`)
            const data = await res.json()
            dispatch({ status: "fetched", payload: data.result })
        } fetchData()
    }, [])


    useEffect(function () {
        async function fetchOTS() {
            try {
                setOtsLoading(true)
                const res = await fetch(`${API}/getOneTimeByid/${customerID}`)
                const data = await res.json()
                setOtsData(data.data)



            } catch (e) {
                console.log(e)
            } finally {
                setOtsLoading(false)


            }


        } fetchOTS()
    }, [])


    // var lastOTS = otsData.slice(-1)[0]


    function handleSelectedPack(x) {
        const seletedArr = x.split(",")
        setSelectedPackageName(seletedArr[0])
        setSelectedPackagePrice(seletedArr[1])
    }


    function reducer(state, action) {
        switch (action.status) {
            case "fetched":
                return {
                    ...state, status: action.status, data: action.payload

                }
            default:
                return state;
        }

    }


    // one time sessions



    console.log(otsData)

    // var lastOTS = otsData.slice(-1)[0]

    function handleAddOTSCustomer(e) {
        e.preventDefault()
        fetch(`${API}/addGymOTS`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerID,
                'captinName': capName,
                "packName": selectedPackageName,
                "packPrice": selectedPackagePrice,
                "branch": loggedBranch
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                
            })
    }

    const customerID = params.id

    return (

        <div className={isActive.id === 'divFour' ? `divFour` : 'divFour d-none'}>
            {/* <ToastContainer
                position="top-center"
                autoClose={false}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            /> */}


            <table style={{ width: "50%", margin: "0 auto", marginBottom: "20px" }} id="customers">
                <tr>
                    <th style={{ backgroundColor: "#e4e4e4", fontSize: "15px", fontFamily: "Poppins", color: "black" }}> Category</th>
                    <th style={{ backgroundColor: "#e4e4e4", fontSize: "15px", fontFamily: "Poppins", color: "black" }}>Date</th>
                    <th style={{ backgroundColor: "#e4e4e4", fontSize: "15px", fontFamily: "Poppins", color: "black" }}>Captin</th>
                </tr>

                <tr>
                    <td>{otsData[0]?.packname}</td>
                    <td>{otsData[0]?.Date}</td>
                    <td>{otsData[0]?.captinName}</td>
                </tr>
            </table>

            <div className="add-ots">

                {state.status == "loading" || packagesLoading ? (<p>Loading</p>) : (<form style={{ backgroundColor: "white", border: "1px solid #e4e4e4" }} onSubmit={(e) => handleAddOTSCustomer(e)} className='add-ots-form'>
                    <select onChange={(e) => setCapName(e.target.value)} style={{border: "1px solid #dddddd", fontSize:"14px", marginBottom:"10px", alignItems:"center", textAlign:"center", width:"150px"}}  name="" id="">
                        <option value="" disabled selected>Select Captin</option>
                        {state.data.map(m => {
                            return (
                                <option value={m.fullName}>  {m.fullName}</option>
                            )
                        })}

                    </select>  <br />
                    <select onChange={(e) => handleSelectedPack(e.target.value)} style={{border: "1px solid #dddddd", fontSize:"14px", marginBottom:"10px", alignItems:"center", textAlign:"center", width:"150px"}} name="" id="">
                        <option value="" disabled selected>Select package</option>
                        {packages.map(m => {
                            return (
                                <option value={[m.Name, m.Price]}> {m.Name} {m.Price} EGP</option>
                            )
                        })}

                    </select> <br />
                    <button style={{ backgroundColor: "#203354", width: "100px", margin: "0 auto", marginTop: "30px", position: "relative" }}>Add</button>

                </form>)}
            </div>
        </div>


    )
}


export default AddCustomerOTS