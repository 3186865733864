import { TextField, Button, Container } from '@mui/material'; 
import { Formik } from 'formik';
import * as Yup from 'yup';
import Navbar from '../../components/navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toHaveFormValues } from '@testing-library/jest-dom/matchers';
function AddClinicPackage() {

	const API = process.env.REACT_APP_API

	const validationSchema = Yup.object({
		packageName: Yup.string()
			.required('Package Name is required'),
		packagePrice: Yup.number()
			.required('Package Price is required')
			.positive('Package Price must be a positive number')
			.min(1, 'Package Price must be at least 1'),
	});



	
	return (
		<Container maxWidth={false} sx={{ width: "100%" }}>
			<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
			<Navbar />
			<Formik
				initialValues={{ packageName: '', packagePrice: '' }}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					console.log('Form Submitted:', values);
					fetch(`${API}/addClinicPack`, {
						method: 'POST',
						headers: {
						   'Content-Type': 'application/json',
						},
					
						body: JSON.stringify({
						  values
						})
					
					}).then(response => response.json())
					.then(result =>{
						if (result.status == 200) {
							toast.success( `${result.Message}`, {
								position: "top-center",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "colored",
								});
						} else {
							toast.error( `${result.Message}`, {
								position: "top-center",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "colored",
								});
						}
					})	
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
					<form style={{ padding: "14px", backgroundColor: "white", border: "2px solid #e4e4e4", margin: "100px auto" }} onSubmit={handleSubmit}>
						<TextField

							fullWidth
							sx={{
								width: "300px",
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderWidth: 2,
									},
									'&:hover fieldset': {
										borderColor: '#1976d2',
									},
									'&.Mui-focused fieldset': {
										borderColor: 'black',
									},
									'& input': {
										color: 'black', // Input text color
										'&::placeholder': {
											color: 'rgba(0, 0, 0, 0.5)', // Placeholder color
										},
									},
								},
								'& .MuiInputLabel-root': {
									color: 'gray', // Default label color
									'&.Mui-focused': {
										color: 'black', // Change to black on focus
									},
								},
							}}
							margin="normal"
							label="Package Name"
							name="packageName"
							value={values.packageName}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.packageName && Boolean(errors.packageName)}
							helperText={touched.packageName && errors.packageName}
						/>
						<TextField
							sx={{
								width: "300px",

								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderWidth: 2,
									},
									'&:hover fieldset': {
										borderColor: '#1976d2',
									},
									'&.Mui-focused fieldset': {
										borderColor: 'black',
									},
									'& input': {
										color: 'black', // Input text color
										'&::placeholder': {
											color: 'rgba(0, 0, 0, 0.5)', // Placeholder color
										},
									},
								},
								'& .MuiInputLabel-root': {
									color: 'gray', // Default label color
									'&.Mui-focused': {
										color: 'black', // Change to black on focus
									},
								},
							}}
							fullWidth
							margin="normal"
							label="Package Price"
							name="packagePrice"
							type="text"
							value={values.packagePrice}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.packagePrice && Boolean(errors.packagePrice)}
							helperText={touched.packagePrice && errors.packagePrice}
						/>
						<Button sx={{ height: "45px", width: "200px", borderRadius: "17px" }} type="submit" variant="contained" color="primary">
							Submit
						</Button>
					</form>
				)}
			</Formik>
		</Container>
	)
}

export default AddClinicPackage