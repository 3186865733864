import Select from 'react-select'
import { useState, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import "../styles/addAcademy.css"
import { ToastContainer, toast } from 'react-toastify';
import Navbar from '../components/navbar';
import { useNavigate } from 'react-router-dom';
import { TimePicker } from "antd";
import dayjs from 'dayjs';

import { PiNumberSquareOneBold } from "react-icons/pi";
import { PiNumberSquareTwo } from "react-icons/pi";





function AddAcademy() {
    const API = process.env.REACT_APP_API

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedAcademyID, setSelectedAcademyID] = useState();
    const [selectedHourOption, setSelectedHourOption] = useState([]);
    const [academyName, setAcademyName] = useState()
    const [trainerName, settrainerName] = useState()

    const [rerender, setRerender] = useState(false)
    const [price1, setPrice1] = useState("0")
    const [price2, setPrice2] = useState("0")
    const [price3, setPrice3] = useState("0")
    const [selectedBranch, setSelectedBrach] = useState()
    const [branchesLoading, setBranchesLoading] = useState()
    const [branches, setAllBranches] = useState()
    const [trainer, setTrainer] = useState()
    const [trainersLoading, setTrainersLoading] = useState()
    const [academiesLoading, setAcLoading] = useState()
    const [allAcademies, setAllAcademies] = useState()
    const navigate = useNavigate()
    const selectedDays = []
    const selectedHours = []
    const selectedCityDays = []
    const format = 'HH:mm';

    const cityHours = []


    const onChange = (time, timeString) => {
        // energyHours.push(timeString)
        setSelectedHourOption([...selectedHourOption, timeString])


    };
    const onCityChange = (time, timeString) => {
        cityHours.push(timeString)

    };

    selectedOption?.map(day => {
        selectedDays.push(day.value)
    });

    selectedHourOption?.map(hour => {
        selectedHours.push(hour.value)
    })





    useEffect(function () {
        async function getBranches() {
            try {
                setBranchesLoading(true)
                const res = await fetch(`${API}/branches`)
                const data = await res.json()
                setAllBranches(data.branches)
                console.log(data)

            } catch (e) {
                console.log(e)
            } finally {
                setBranchesLoading(false)
            }
        } getBranches()

    }, [])

    useEffect(function () {
        async function fetchAcademies() {
            try {
                setAcLoading(true)
                const res = await fetch(`${API}/getAllAcademies`)
                const data = await res.json()
                setAllAcademies(data.academies)


            } catch (e) {
                console.log(e)
            } finally {
                setAcLoading(false)

            }
        } fetchAcademies()
    }, [rerender])


    function handleAddTimes(e) {
        e.preventDefault()

        fetch(`${API}/addAcademyTimes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "branch": selectedBranch,
                "AcademyID": selectedAcademyID,
                "Days": selectedOption,
                "Hours": selectedHourOption,
                "trainer": trainerName,




            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    // setTimeout(() => {
                    //     navigate("/academy")
                    // }, 2000);
                    setRerender(!rerender)


                } else {
                    toast.error(`${result.Message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    

                }
            })

    }

    function handleFormSubmit(e) {
        e.preventDefault()

        fetch(`${API}/academy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "description": academyName,
                // "cityDays": selectedCityDays,
                // "cityHours": cityHours,
                // 'Days': selectedDays,
                // "Hours": cityHours,
                "price1": price1,
                "price2": price2,
                "price3": price3


            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

setRerender(!rerender)

                    // setTimeout(() => {
                    //     navigate("/academy")
                    // }, 2000);


                } else {
                    toast.error(`${result.Message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }
            })

    }
    useEffect(function () {
        async function getTrainers() {
            try {
                setTrainersLoading(true)
                const res = await fetch(`${API}/getAllPTrainers`)
                const data = await res.json()
                setTrainer(data.result)
                console.log(data.result)
                console.log(data)
            } catch (e) {
                console.log(e)
            } finally {
                setTrainersLoading(false)
            }
        } getTrainers()

    }, [])

    const days = [
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' }


    ]


    const Hours = [
        { value: '1PM', label: '1PM' },
        { value: '2PM', label: '2PM' },
        { value: '3PM', label: '3PM' },
        { value: '4PM', label: '4PM' },
        { value: '5PM', label: '5PM' },
        { value: '6PM', label: '6PM' },
        { value: '7PM', label: '7PM' },
        { value: '8PM', label: '8PM' },
        { value: '9PM', label: '9PM' },
        { value: '10PM', label: '10PM' },
        { value: '11PM', label: '11PM' },
        { value: '12PM', label: '12PM' },


    ]

    const animatedComponents = makeAnimated();


    return (
        <>

            <Navbar />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />
            <div className="add-Academy-page">
                <div style={{ width: "100%" }} className="add-academy-container" >
                    {trainersLoading ? (<p>Loading</p>) : (<div style={{ display: "inline-block" }}><form style={{ border: "3px solid #e4e4e4", height: "auto", margin: "70px auto 20px auto", backgroundColor: "white", padding: "10px" }} className="add-academy-from" onSubmit={(e) => handleFormSubmit(e)}>
                        <div style={{ display: "flex", margin: "0 auto", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}> <PiNumberSquareOneBold size={30} /> <h2 style={{ marginBottom: "0" }}>Add Academy</h2></div>
                        <input style={{ width: "250px", height: "40px", borderRadius: "7px", marginLeft: "0", marginBottom: "5px" }} required onChange={(e) => setAcademyName(e.target.value)} type="text" placeholder="Academy" /> <br />
                        {/* <input required onChange={(e) => settrainerName(e.target.value)} type="text" placeholder="Trainer name" /> */}
                    


                        <div className='academy-price'>
                            <input style={{ width: "250px", height: "40px", borderRadius: "7px" }} onChange={(e) => setPrice3(e.target.value)} className='price-text' type="text" placeholder='1 Price' />
                        </div>

                        <div className='academy-price'>
                            <input style={{ width: "250px", height: "40px", borderRadius: "7px" }} onChange={(e) => setPrice1(e.target.value)} className='price-text' type="text" placeholder='8 Price' />
                        </div>
                        <div className='academy-price'>
                            <input style={{ width: "250px", height: "40px", borderRadius: "7px" }} onChange={(e) => setPrice2(e.target.value)} className='price-text' type="text" placeholder='12 Price' />
                        </div>




                        <button style={{ width: "250px", borderRadius: "16px" }}>Add</button>


                    </form>
                        <form className="add-academy-from"  onSubmit={(e)=>handleAddTimes(e)} style={{ border: "2px solid #e4e4e4", height: "auto", margin: "20px auto", backgroundColor: "white", padding: "10px" }}>
                            <div style={{ display: "flex", margin: "0 auto", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>            <PiNumberSquareTwo size={30} />  <h2 style={{ marginLeft: "5px", marginBottom: "0" }}>Times</h2></div>

                            <div style={{ width: "70%", margin: "0 auto", borderRadius: "10px", padding: "10px" }} className="days-hours-drop-container">
                                <div>
                                    <select onChange={(e) => setSelectedAcademyID(e.target.value)} style={{ width: "250px", height: "40px", backgroundColor: "white", marginBottom: "10px" }}>
                                        <option disabled selected>Select Academy</option>
                                        {allAcademies?.map(b => {
                                            return (
                                                <option value={b.academyID}>{b.description}</option>
                                            )
                                        })}
                                    </select>

                                     <select style={{ width: "250px", height: "40px", backgroundColor: "white", marginBottom: "10px" }} onChange={(e) => settrainerName(e.target.value)} name="" id="">

                            <option value="" disabled selected>Trainer</option>
                            {trainer?.map(t => {
                                return (
                                    <option value={t.fullName}>{t.fullName}</option>
                                )
                            })}
                        </select>
                                    <select onChange={(e) => setSelectedBrach(e.target.value)} style={{ width: "250px", height: "40px", backgroundColor: "white", marginBottom: "10px" }}>
                                        <option disabled selected>Select Branch</option>
                                        {branches?.map(b => {
                                            return (
                                                <option value={b.name}>{b.name}</option>
                                            )
                                        })}
                                    </select>




                                    <Select
                                        styles={{ marginLeft: "0", marginRight: "0" }}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={"Days"}
                                        isMulti
                                        options={days}
                                        onChange={setSelectedOption}
                                        placeholder="Days"
                                    />
                                </div>


                                <br />


                                <TimePicker placeholder={selectedHours} onChange={onChange} style={{ marginBottom: "10px", height: "40px", width: "250px" }} defaultValue={dayjs('12:08', format)} format={format} />

                            </div>
                            <button style={{ width: "250px", borderRadius: "16px", height:"40px" }}>Confirm</button>

                        </form>

                    </div>



                    )}


                </div>

            </div>
        </>


    )
}


export default AddAcademy;