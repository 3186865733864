import "../styles/mainpage.css"
import Navbar from "../components/navbar";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import { render } from "@testing-library/react";
import { redirect, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";



function AddNewUser() {

    const [fullName, setFullName] = useState()
    const [nationId, setNationalID] = useState()
    const [passwordOne, setPassword1] = useState()
    const [passwordTwo, setPassword2] = useState()
    const [selectedBranch, setSelectedBranch] = useState()
    const [address , setAddress] = useState()
    const [qualification, setQualification] = useState()
    // const [hireDate, setHireDate] = useState()
    const [Role, setRole] = useState()
    const [hireDate, setDate] = useState()
    const [phone, setPhone] = useState()
    const [secondePhone, setSecondPhone] = useState(null)
    const [realName, setRealName] = useState()

    const API = process.env.REACT_APP_API



    //handles storing the value of role select menu


    // function setSelctedBranch() {
    //     const branch = document.getElementById('branch-select').value;
    //     setBranch(branch)
    // }


    console.log(selectedBranch)
    // AOS Transaction
    useEffect(() => {
        AOS.init({ duration: 2000 });
        AOS.refresh();

    }, [])

    
	function onChange(date, dateString) {
		setDate(dateString)
		// setDate(dateString.split("/").reverse())

	}

    useEffect(() => {
        let password1 = document.getElementById("password-input1")
        let password2 = document.getElementById("password-input2")
        let lastName = document.getElementById("lastName-input")
        let branch_branch = document.getElementById("branch-select")
        let role_branch = document.getElementById("role-select")

        let nationlID_input = document.getElementById("nationID")
        const fieldList = [lastName, password1, password2, nationlID_input,]

        //custom error message to handle empty fields

        fieldList.map(field => {
            field.addEventListener("input", function (e) {
                field.setCustomValidity('');
            });
            field.addEventListener("invalid", function (e) {
                field.setCustomValidity('This field is required');
            });
        })







        // handle passwords matching 
        function validateForm() {
            const firstPass = password1.value.trim()
            const secPass = password2.value.trim()
            const nationalID = nationlID_input.value.trim()
            var regExp = /[a-zA-Z]/g;

            if (regExp.test(nationalID) || nationalID.length !== 14) {
                let text = "Invalid National ID"
                document.getElementById("validation-message").innerHTML = text;
                document.getElementById('submit').disabled = true;
                return false



            } else {
                document.getElementById('submit').disabled = false;
                let text = ""
                document.getElementById("validation-message").innerHTML = text;


            }
            if (firstPass !== "" && secPass !== "") {
                if (firstPass != secPass) {
                    document.getElementById('submit').disabled = true;
                    let text = "Passwords don't match"
                    document.getElementById("validation-message2").innerHTML = text;
                    return false


                } else {
                    document.getElementById('submit').disabled = false;
                    let text = ""
                    document.getElementById("validation-message2").innerHTML = text;

                }
            }
        }



        password1.addEventListener('change', validateForm);
        password2.addEventListener('change', validateForm);
        nationlID_input.addEventListener('change', validateForm);


    }, [])





    function handleSubmit(e) {
        e.preventDefault()
        fetch(`${API}/auth/addUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'fullName': fullName,
                'password': passwordOne,
                "Role": Role,
                'NationalID': nationId,
                "Branch": selectedBranch,
                "phone": phone,
                "secPhone": secondePhone,
                "qualifications": qualification,
                "address" : address,
                "hiringDate": hireDate,
                "realName" : realName
                

            }),
        }).then(response => response.json()).then((result) => {
            if (result.status == 406) {
                toast.error(`${result.Message}`, {

                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
            }


            else if (result.status == 200) {
                toast.success(`${result.Message}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })


            }
        }

        )
    }

    return (

        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />

            <Navbar />
            <div className="mainpage-container">
                <form onSubmit={handleSubmit} name="signup-from" data-aos="zoom-out">
                    <h2>Add new user</h2>
                    <div className="name-container">
                        <div className="form-elements">
                            <input onChange={(e) => setRealName(e.target.value)} required id="lastName-input" type="text" placeholder="Full name" />
                            <input onChange={(e) => setFullName(e.target.value)} required id="lastName-input" type="text" placeholder="Username" />


                            <input required onChange={(e) => setNationalID(e.target.value)} id="nationID" type="text" placeholder="National ID" />


                        </div>
                    </div>

                    
                    <div className="form-elements">
                        <input required onChange={(e) => setPhone(e.target.value)}  type="text" placeholder="Phone" />
                        <input  onChange={(e) => setSecondPhone(e.target.value)}  type="text" placeholder="second phone" />

                    </div>
                    <div className="form-elements">
                    </div>



                    <div className="form-elements">
                    </div>
                    <div className="form-elements">
                        <input required onChange={(e) => setAddress(e.target.value)}  type="text" placeholder="Address" />
                        <input required onChange={(e) => setQualification(e.target.value)} type="text" placeholder="Qualifications" />

                    </div>

                

                    <div className="form-elements">
                    <DatePicker placeholder="Hiring date"  style={{ backgroundColor: "#e4e4e4", color: "black", height:"30px", padding:"20px" , alignItems:"center", textAlign:"center", marginBottom:"30px"}} format={"DD/MM/YYYY"} onChange={onChange} />
                    </div>


                    <div className="password-elemet">
                        <div className="form-elements">
                            <input required onChange={(e) => setPassword1(e.target.value)} id="password-input1" type="password" placeholder="Password" />
                        </div>
                        <div className="form-elements">
                            <input required onChange={(e) => setPassword2(e.target.value)} id="password-input2" type="password" placeholder="Re-enter Password" />
                        </div>


                    </div>

                    <div id="branch-select" className="select-container">
                        {/* <select onChange={(e) => setSelectedBranch(e.target.value)} required id="branch-select">
                            <option value="" selected hidden>Branch</option>
                            <option value="Cairo">Cairo</option>
                            <option value="6th of October">6th of October</option>
                            <option value="ِAlexandria">Alexandria</option>

                        </select> */}
                        <select onChange={(e) => setRole(e.target.value)} required id="role-select">
                            <option value="" selected hidden>Role</option>
                            <option value="Admin">Admin</option>
                            <option value="General Manager">General Manager</option>
                            <option value="Supervisor">Supervisor</option>
                            <option value="Trainer">Trainer</option>
                            <option value="Receptionist">Receptionist</option>



                        </select>
                    </div>

                    <button id="submit">Add</button>
                    <span id="validation-message"></span> <br />
                    <span id="validation-message2"></span>



                </form>


                <img data-aos="zoom-in" className="vector-img" src="./register.jpg" />


            </div></>
    )
}


export default AddNewUser;