import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Textarea from '@mui/joy/Textarea';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { TextField } from 'react-aria-components';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

function SMSSystem() {
	const [value, setValue] = React.useState([20, 37]);
	const [gender, setGender] = React.useState('');
	function valuetext(value) {
		return `${value}`;
	}
	const handleGenderChange = (event) => {
		setGender(event.target.value);
	};


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<Container sx={{ fontWeight: "bold", fontFamily: "Cairo", display: "block", alignItems: "center", height: "100%", width: "100%" }} maxWidth="sm">
			<img src="/sms.png" />
			<Card type="form" sx={{ width: "700px", left: "50%", top: "50%", transform: "translate(-50%, -50%)", position: "relative" }} variant="outlined">
		<form style={{width:"100%", height:"100%", backgroundColor:"white", margin:"auto"}}>
		<TextField>
					Age Range
				</TextField>
				<Slider
					required
					sx={{
						width: "60%",
						color: 'secondary.main', // Change this to your desired color
						'& .MuiSlider-thumb': {
							backgroundColor: '#7f96d1', // Thumb color
						},
						'& .MuiSlider-track': {
							backgroundColor: 'green', // Track color
						},
						'& .MuiSlider-rail': {
							backgroundColor: 'black', // Rail color
						},
					}}
					getAriaLabel={() => 'Temperature range'}
					value={value}
					onChange={handleChange}
					valueLabelDisplay="auto"
					getAriaValueText={valuetext}
				/>

				<InputLabel id="demo-simple-select-label" sx={{ fontWeight: "bold", fontFamily: "poppins" }}>Gender</InputLabel>
				<Select
					required
					sx={{width:"60%"}}

					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={gender}
					label="Gender"
					onChange={handleGenderChange}
				>
					<MenuItem value="Male">Male</MenuItem>
					<MenuItem value="Female">Female</MenuItem>
				</Select>


				<Textarea
					required
					minRows={2}
					placeholder="Message ..."
					size="lg"
					sx={{ fontFamily: "Cairo" }}
				/>
				<Button
					type='submit'
					sx={{ backgroundColor: "#7f96d1", margin: "auto", width: "70%", marginTop: "30px", fontWeight: "bold" }}
					variant="contained"
					color="primary" // You can change this to 'secondary' or any other color
					startIcon={<SendIcon style={{ color: "white" }} />}
				>
					Send
				</Button>
		</form>
			</Card>

		</Container>

	)
}

export default SMSSystem


