import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import Navbar from '../components/navbar';
import { useNavigate } from "react-router-dom";
import { Outlet } from 'react-router-dom'

function RentPage( ) {
  const role = localStorage.getItem("Roles")

	const [value, setValue] = React.useState(0);
	const navigate = useNavigate()
	return (
		<>
		<Navbar/>
		 <Box sx={{ width: "auto", margin:"0 auto" }}>
      <BottomNavigation
	  style={{margin:"20px auto", width:"400px"}}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction onClick={()=> navigate("add-tentant")}  label="Rent" icon={<AddIcon />} />

        {role == "Admin" ? (        <BottomNavigationAction   onClick={()=> navigate("add-category")} label="Add category" icon={<AddIcon />} />
):("")}
        <BottomNavigationAction onClick={()=> navigate("tenants")} label="Tenants" icon={<ListIcon  />} />
        <BottomNavigationAction onClick={()=> navigate("categories")} label="Categories" icon={<ListIcon  />} />
      </BottomNavigation>
    </Box>
	<Outlet/>
		</>
	)
}


export default RentPage