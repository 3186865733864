import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Table } from 'antd';

import { HiOutlineDocumentReport } from "react-icons/hi";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));



const API = process.env.REACT_APP_API

function GymFinance() {
	const [filtered, setFiltered] = useState("All")
	const [selectedBranch, setSelectedBranch] = useState("All")
	const [branches, setBranches] = useState()
	const [branchesLoading, setBranchesLoading] = useState()
	const [loading, setLoading] = useState(true)
	const [financeDage, setFinanceData] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainer, setTrainer] = useState()
	const [trainersLoading, setTrainersLoading] = useState()
	const [trainerName, settrainerName] = useState("All")
	const [gymPackages, setGymPackages] = useState([])
	const [selectedPackage, setSelectedPackage] = useState("All")
	const [gender, setSelectedGender] = useState("All")

	const [gymPackagesLoading, setGymPackagesLoading] = useState(true)
	const [ageFrom, setAgeFrom] = useState("All")
	const [ageTo, setAgeTo] = useState("All")
	const [searched, setSearched] = useState(false)

	// debt
	const [debtdata, setDebtData] = useState()
	const [debtTotal, setDebtTotal] = useState()


	// retrub 

	const [returndata, setReturnData] = useState()
	const [returnTotal, setReturnTotal] = useState()

	const [customerID, setCustomerID] = useState("All")

	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});

	const [debtTableParams, setDebtTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});


	const [returnTableParams, setReturnTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});


	const fetchData = () => {
		setLoading(true);
		fetch(`${API}/getGymFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`).then((res) => res.json())
			.then((results) => {
				setFinanceData(results.result);
				setLoading(false);
				setTableParams({
					...tableParams,
					pagination: {
						...tableParams.pagination,
						total: 200,
						// 200 is mock data, you should read it from server
						// total: data.totalCount,
					},
				});
			});
	};
	useEffect(fetchData, [			searched
	]);
	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};





	const fetchDebtData = () => {
		setLoading(true);
		fetch(`${API}/gymPaydebtFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`).then((res) => res.json())
			.then((results) => {
				setDebtData(results.gymPayDebt);
				console.log(results)
				setDebtTotal(results.total[0].total)

				console.log(results.total[0].total)
				setLoading(false);
				setDebtTableParams({
					...tableParams,
					pagination: {
						...tableParams.pagination,
						total: 200,

					},
				});
			});
	};
	useEffect(fetchDebtData, [			searched
	]);
	const handleDebtTableChange = (pagination, filters, sorter) => {
		setDebtTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
			
		});
	};




	const fetchRetrunData = () => {
		setLoading(true);
		fetch(`${API}/gymRetrunFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`).then((res) => res.json())
			.then((results) => {
				setReturnData(results.gymReturn);
				console.log(results)
				setReturnTotal(results.total[0].total)

				console.log(results.total[0].total)
				setLoading(false);
				setDebtTableParams({
					...tableParams,
					pagination: {
						...tableParams.pagination,
						total: 200,

					},
				});
			});
	};
	useEffect(fetchRetrunData, [searched]);
	const handleRetrunTableChange = (pagination, filters, sorter) => {
		setReturnTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const columns = [
		{
			title: 'Customer ID',
			dataIndex: 'CustomerID',
			sorter: (a, b) => a.CustomerID - b.CustomerID,
			align: "center",

		},
		{
			title: 'Full name',
			dataIndex: 'Fullname',
			align: "center",
			sorter: (a, b) => a.Fullname - b.Fullname,




		},

		{
			title: 'Phone',
			dataIndex: 'Phone',
			align: "center"


		},

		{
			title: 'Age',
			dataIndex: 'customerAge',
			sorter: (a, b) => a.customerAge - b.customerAge,
			align: "center"



		},


		{
			title: 'National ID',
			dataIndex: 'NationalID',
			align: "center"


		},

		{
			title: 'Paid Value',
			dataIndex: 'PaidValue',
			sorter: (a, b) => a.PaidValue - b.PaidValue,
			align: "center"


		},
		{
			title: 'Remianing Value',
			dataIndex: 'Remaining',
			sorter: (a, b) => a.Remaining - b.Remaining,
			align: "center"

		},
		{
			title: 'Paid Debt',
			dataIndex: 'TotalPaydebtPaidValue',
			sorter: (a, b) => a.TotalPaydebtPaidValue - b.TotalPaydebtPaidValue,
			align: "center"

		},
		{
			title: 'Actual Remains',
			render: (_, record) => Number(record.Remaining) - Number(record.TotalPaydebtPaidValue),
			align: "center",
		},
		{
			title: 'Discount',
			dataIndex: 'Discount',
			sorter: (a, b) => a.discount - b.discount,
			align: "center"

		},
		{
			title: 'Pay Date',
			dataIndex: 'PayDate',
			sorter: (a, b) => new Date(a.PayDate) - new Date(b.PayDate),
			align: "center"


		},

		{
			title: 'Package',
			dataIndex: 'package',
			align: "center",
			sorter: (a, b) => a.package - b.package,



		},

		{
			title: 'Category',
			dataIndex: 'Category',
			align: "center"

		},

		{
			title: 'Dep',
			dataIndex: 'Dep',
			align: "center"

		},

		{
			title: 'Trainer',
			dataIndex: 'Trainer',
			align: "center"

		},

		{
			title: 'Branch',
			dataIndex: 'Branch',
			align: "center"

		},

	];


	const Debtcolumns = [
		{
			title: 'Customer ID',
			dataIndex: 'CustomerID',
			align: "center",

			sorter: (a, b) => a.CustomerID - b.CustomerID,
		},
		{
			title: 'Full name',
			dataIndex: 'Fullname',
			align: "center",
			sorter: (a, b) => a.Fullname - b.Fullname,




		},

		{
			title: 'Phone',
			dataIndex: 'Phone',
			align: "center"


		},

		{
			title: 'Age',
			dataIndex: 'customerAge',
			sorter: (a, b) => a.customerAge - b.customerAge,
			align: "center"



		},


		{
			title: 'National ID',
			dataIndex: 'NationalID',
			align: "center"


		},

		{
			title: 'Paid Value',
			dataIndex: 'PaidValue',
			sorter: (a, b) => a.PaidValue - b.PaidValue,
			align: "center"


		},


		{
			title: 'Pay Date',
			dataIndex: 'Date',
			sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
			align: "center"


		},



		{
			title: 'Category',
			dataIndex: 'Category',
			align: "center"

		},

		{
			title: 'Dep',
			dataIndex: 'Dep',
			align: "center"

		},


		{
			title: 'Branch',
			dataIndex: 'Branch',
			align: "center"

		},

	];

	function onChange(date, dateString) {
		setDate(dateString)
		setFiltered("filtered")

	}




	function onEndDatehange(date, dateString) {
		setEnDate(dateString)
		setFiltered("filtered")


	}

	useEffect(function () {
		async function getGymPackages() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getGymPackages`)
				const data = await res.json()
				setGymPackages(data.packages)
			} catch (e) {
				console.log(e)
			} finally {
				setGymPackagesLoading(false)
			}
		} getGymPackages()

	}, [])










	useEffect(function () {
		async function fetchBranches() {
			try {
				setBranchesLoading(true)
				const res = await fetch(`${API}/branches`)
				const data = await res.json()
				setBranches(data.branches)

			} catch (e) {
				console.log(e)
			} finally {
				setBranchesLoading(false)
			}
		} fetchBranches()
	}, [])

	useEffect(function () {
		async function getTrainers() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
				console.log(data.result)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getTrainers()

	}, [])





	// async function handleFilter() {
	// 	try {
	// 		setLoading(true)
	// 		const res = await fetch(`${API}/getGymFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`)
	// 		const data = await res.json()
	// 		setFinanceData(data.result)
	// 		setSearched(true)

	// 	} catch (e) {
	// 		console.log(e)
	// 	} finally {
	// 		setLoading(false)


	// 	}
	// }



console.log(financeDage)


	const sumPaid = financeDage?.map(item => item.PaidValue).reduce((a, b) => a + b, 0)
	const sumRemaining = financeDage?.map(item => item.Remaining).reduce((a, b) => a + b, 0)
	console.log(sumPaid)

	const paidDebt = financeDage?.map(item => Number(item.TotalPaydebtPaidValue)).reduce((a, b) => a + b, 0)

console.log(paidDebt)

	{ if (branchesLoading || trainersLoading || gymPackagesLoading) return <CircularProgress sx={{ display: "flex", width: "fit-content", top: "50%", left: "50%", position: "absolute" }} /> }



	return (

		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">
			<h3 style={{ margin: "0 auto", width: "200px" }}>Gym Finance</h3>

			<Container
				sx={{
					padding: '20px',
					borderRadius: '8px',
					backgroundColor: "white",
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
					marginBottom: "20px",


				}}
			>

				<div style={{ borderRadius: "8px", width: "40%", margin: "20px auto", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

				</div>

				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: '20px', marginTop: "40px" }}>
					<div style={{ display: "flex", margin: "0 auto", width: "auto", flexDirection: "row", alignItems: "flex-start", textAlign: "center", height: "80px" }}>
						{['Trainer', 'Branch', 'Gender', 'Package'].map((placeholder, index) => (
							<div key={index} style={{ position: "relative", marginLeft: index > 0 ? "5px" : "0" }}>
								<label style={{
									position: "absolute",
									top: "-20px", // Position the label higher
									fontSize: "12px",
									color: "#777",
									padding: "0 5px",
									zIndex: 1,
								}}>
									{placeholder}
								</label>
								<select
									onChange={index === 0 ? (e) => settrainerName(e.target.value) :
										index === 1 ? (e) => setSelectedBranch(e.target.value) :
											index === 2 ? (e) => setSelectedGender(e.target.value) :
												(e) => setSelectedPackage(e.target.value)}
									style={{
										width: index === 0 ? "170px" : "150px",
										height: "40px",
										border: "none",
										textAlign: "center",
										alignItems: "center",
										marginTop: "10px", // Add space below the label
										backgroundColor: "#eaedf4",
										borderRadius: "8px",
									}}
									disabled={index === 2 && customerID !== "All"}
								>
									<option value="All" >All</option>
									{index === 0 && trainer?.map(t => (
										<option key={t.fullName} value={t.fullName}>{t.fullName}</option>
									))}
									{index === 1 && (
										<>
											{branches?.map(branch => (
												<option key={branch.name} value={branch.name}>{branch.name}</option>
											))}
										</>
									)}
									{index === 2 && (
										<>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</>
									)}
									{/* {index === 3 &&  (
											<>
												<option value="Package1">Package 1</option>
												<option value="Package2">Package 2</option>
											</>
										)} */}

									{index === 3 && gymPackages?.map(pack => (
										<option key={pack.packageName} value={pack.packageName}>{pack.packageName}</option>
									))}
								</select>
							</div>
						))}
					</div>
					<div style={{
						position: "relative", marginLeft: "5",
					}}>

						<div style={{ display: "flex", flexDirection: "row", marginBottom: "15px", }}>
							<div style={{ marginRight: "10px" }}>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",
							}}>
								Age from
							</label>

								<select
									disabled={customerID !== "All"}

									onChange={(e) => setAgeFrom(e.target.value)} style={{
										width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
									}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
							<div>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",


							}}>
								Age to
							</label>

								<select
									disabled={customerID !== "All"}

									onChange={(e) => setAgeTo(e.target.value)} style={{
										width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
									}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
						</div>
					</div>
					<input onChange={(e) => setCustomerID(e.target.value)} style={{ width: "200px", height: "40px", backgroundColor: "#eaedf4", border: "none", fontSize: "14px", textAlign: "center", margin: "10px" }} placeholder='Customer ID' type="text" />

					<Button
						variant="contained"
						onClick={()=> setSearched(!searched)}
						startIcon={<SearchIcon style={{ color: "black" }} />}
						sx={{
							":hover": {
								backgroundColor: '#eaedf4',

								boxShadow: 'none',

							},
							backgroundColor: "#d7d9e9",

							color: 'black',
							borderRadius: '6px',
							fontWeight: "bold",
							marginTop: "10px",
							margin: "auto",
							width: "150px",
							fontWeight: "Bold",
							boxShadow: 'none',
						}}
					>
						Search
					</Button>

					{searched ? (
						<lebel style={{ marginTop: "10px" }}>
							<strong>{`${gender}`}</strong>
							{` Customers  `}
							<strong>{` between ${ageFrom} years and ${ageTo} years`}</strong>
							{` in `}
							<strong>{`${selectedBranch} branch`}</strong>
							{` within `}
							<strong>{`${startDate} and ${endDate}`}</strong>
							{` with  `}
							<strong>{`${trainerName} `}</strong>
						</lebel>
					) : (
						""
					)}
				</div>
			</Container>






			{loading ? (<Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
				<CircularProgress />
			</Box>) : (<>

				<h4 style={{ margin: "auto", width: "fit-content" }}>Subscriptions Finance</h4>
				<Container

					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "flex",
						backgroundColor: "white"

					}}
				>
					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>


						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<AccountCircleIcon sx={{ fontSize: "30px" }} />

							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
								{financeDage?.length}
							</Typography>
						</Box>
					</Paper>
					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>



						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Total
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumPaid) + Number(sumRemaining)} EGP
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Actual Paid
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{sumPaid} EGP
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Total Remaining
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{sumRemaining} EGP
							</Typography>
						</Box>


						


					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Remaining Debt
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumRemaining) - Number(paidDebt)} EGP
							</Typography>
						</Box>


						


					</Paper>
				</Container>

				<Table
			


					columns={columns}
					//   rowKey={(record) => record.login.uuid}
					dataSource={financeDage}
					pagination={tableParams.pagination}
					loading={loading}
					onChange={handleTableChange}
				/>

				<Container
					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "block",
						flexDirection:"column",
						backgroundColor: "white"

					}}
				>
									<h4 style={{ margin: "auto", width: "fit-content" }}>Debt Finance</h4>

					<Paper
						elevation={0}
						sx={{
							margin:"auto",
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Total Paid
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(debtTotal)} EGP
							</Typography>
						</Box>
					</Paper>
				</Container>



				<Table
					style={{ width: "70%", margin: "auto" }}

					columns={Debtcolumns}
					//   rowKey={(record) => record.login.uuid}
					dataSource={debtdata}
					pagination={debtTableParams.pagination}
					loading={loading}
					onChange={handleDebtTableChange}
				/>



				<Container
					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "block",
						flexDirection:"column",
						backgroundColor: "white"

					}}
				>
									<h4 style={{ margin: "auto", width: "fit-content" }}>Return Finance</h4>

					<Paper
						elevation={0}
						sx={{
							margin:"auto",
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Total Paid
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(returnTotal)} EGP
							</Typography>
						</Box>
					</Paper>
				</Container>


				<Table
					style={{ width: "70%", margin: "auto" }}
					columns={Debtcolumns}
					//   rowKey={(record) => record.login.uuid}
					dataSource={returndata}
					pagination={returnTableParams.pagination}
					loading={loading}
					onChange={handleRetrunTableChange}
				/>

<Container
					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "block",
						backgroundColor: "white"

					}}
				>
				<Box margin="auto" width="fit-content" display="flex" alignItems="center" textAlign="center">
				<HiOutlineDocumentReport size={40} />	<h4>Final Report</h4>
				</Box>
					
					<Box sx={{ display: "flex", width: "100%", justifyContent:"center"}}>



					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px",  display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content"}}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Subscriptions
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumPaid)} EGP
							</Typography>
					</Box>


					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Paid Debt
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(debtTotal)} EGP
							</Typography>
					</Box>


					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Return
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(returnTotal)} EGP
							</Typography>
					</Box>


					<Box width= "250px" border= "1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px"  height="90px" borderRadius="12px" >
					<Box sx={{padding:"10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Net Profit
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumPaid) + Number(debtTotal) - Number(returnTotal) } EGP
							</Typography>
					</Box>
							
						</Box>
					<Container></Container>

				</Container>

			</>


			)




			}

		</div>
	)

}

export default GymFinance