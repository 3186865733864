import { useEffect, useState, useRef } from 'react'
import '../styles/academiesPage.css'
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment, { weekdays } from 'moment';
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { TimePicker } from "antd";
import dayjs from 'dayjs';
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import { IoCloseOutline } from "react-icons/io5";
import TodayIcon from '@mui/icons-material/Today';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import Drawer from '@mui/material/Drawer';
import LoadingButton from '@mui/lab/LoadingButton';
import PaidIcon from '@mui/icons-material/Paid';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { FaRegistered } from "react-icons/fa";
import { Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Box as MUIBox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box, ColumnLayout, Container, Header, Table } from '@cloudscape-design/components';
import { GoPackage } from "react-icons/go";
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { IoMdClose } from "react-icons/io";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Checkbox,
} from '@mui/material';

import Navbar from '../components/navbar';
const days = { "1": "monday", "2": "tuesday", "3": "wednesday", "4": "thursday", "5": "friday", "6": "saturday", "0": "sunday", "7": "sunday" }
const date = new Date()
const todayNumber = (date.getDay())
const todayName = days[todayNumber]
const API = process.env.REACT_APP_API

const weekDays = [
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' }


]


const animatedComponents = makeAnimated();




// packagesInAcademy(); setPackageSideNavVisibility(!PackageSideNavVisibility) }
function Academies() {
    const { handleSubmit, register, formState: { errors } } = useForm();

    const [academiesData, setAcademiesData] = useState()
    const Branch = localStorage.getItem("loggedBranch")
    const [branches, setBranches] = useState()
    const [all, setAll] = useState("All");
    const [isLoading, setIsloading] = useState()
    const [academyID, setAcademyID] = useState("")
    const [selectedBranch, setSelectedBranch] = useState(Branch)
    const [sideOpen, setSideOpen] = React.useState(false);
    const [registeringCustomerID, setRegisteringCustomerID] = useState()
    const [open, setOpen] = React.useState(false);
    const [InfoOpen, setInfoOpen] = React.useState(false);
    const customerRef = useRef()
    const DaysRef = useRef()
    const SessionsRef = useRef()
    const [selectedOption, setSelectedOption] = useState(null);
    const [trainersLoading, setTrainersLoading] = useState()
    const [changedClassTrainer, setChangedClassTrainer] = useState()
    const [classID, setClassID] = useState()
    const [loading, setLoading] = useState()
    const [trainer, setTrainer] = useState()
    const [trainerOpen, setTrainerOpen] = useState(false)
    const [value, setValue] = useState(false)
    const [rerender, setRender] = useState(value)
    const [Role, setRole] = useState()
    const [checkInDesc, setCheckInDesc] = useState()
    const [timesID, setTimesID] = useState()
    const [academyName, setAcademyName] = useState()
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [customerInfo, setCustomerInfo] = useState("")
    const [subInfo, setSubInfo] = useState("")
    const timeValues = []
    const DateOFToday = moment.tz('Africa/Cairo').format('YYYY-MM-DD')
    const RemainingDays = Math.abs(new Date(subInfo[0]?.EndDate) - new Date(DateOFToday)) / (1000 * 60 * 60 * 24)
    const loggedBranch = localStorage.getItem("loggedBranch");
    const [inActiveCount, setInActiveCount] = useState("")

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const isExpired = new Date(DateOFToday).valueOf() >= new Date(subInfo[0]?.EndDate).valueOf();
    const hasGraceSession = subInfo[0]?.grace_session === 1;
    const noRemainingSessions = subInfo[0]?.RemainingSessions === 0;
    const isInactiveCountZero = inActiveCount === 0;
    const shouldRenderBox = (isExpired && hasGraceSession && isInactiveCountZero) ||
        (noRemainingSessions && hasGraceSession && isInactiveCountZero)

    const onSubmit = async (data) => {
        setRegisteringCustomerID(data.customerID);
        await getCustomerInfo(data.customerID);
    };
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleClickOpenDelete = () => {
        setDeleteOpen(true);
    };

    const handleCloseDelete = () => {
        setDeleteOpen(false);
    };


    function toggleDrawer(newOpen) {
        setSideOpen(newOpen);
    };

    function checkin(x, y) {
        toggleDrawer(true)
        setAcademyID(x)
        setCheckInDesc(y)
    }

    function Modify(academy) {
        setAcademyID(academy.academyID)
        setTimesID(academy.timeId)
        setAcademyName(academy.description)

        handleTrainerOpen()
    }


    function Delete(academy) {
        setTimesID(academy.timeId)
        setAcademyID(academy.academyID)


        handleClickOpenDelete()
    }
    const DrawerList = (

        <>
            <IoMdClose style={{
                position: 'absolute',
                left: '0',
                cursor: "pointer"
            }}
                size="30px"
                color="black" onClick={() => toggleDrawer(false)} />
            <h6 style={{ width: "fit-content", margin: "80px auto" }}>{checkInDesc}</h6>
            <MUIBox
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    margin: "20px auto",
                    display: "flex",
                    flexDirection: "column", // Align items in a column layout
                    alignItems: "center", // Center align items horizontally
                    justifyContent: "center", // Center align items vertically
                    textAlign: "center",
                    height: { xs: "auto", sm: "200px" }, // Auto height for smaller screens
                    padding: { xs: "10px", sm: "20px" }, // Adjust padding for smaller screens
                    borderRadius: "8px",
                    boxShadow: 3,
                    width: { xs: "90%", sm: "90%", md: "70%" }, // Responsive width
                }}
            >
                {/* Input Field */}
                <TextField
                    {...register("customerID", { required: true })}
                    autoFocus
                    id="customerID"
                    type="text"
                    variant="outlined"
                    label="Customer ID"
                    error={Boolean(errors.customerID)}
                    helperText={errors.customerID ? "Customer ID is required" : ""}
                    sx={{
                        width: "100%", // Full width for responsiveness
                        maxWidth: "400px", // Optional max width for larger screens
                        marginBottom: "20px", // Space between input and button
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderWidth: 2,
                            },
                            "&:hover fieldset": {
                                borderColor: "#1976d2", // Hover effect color
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "black", // Focused border color
                            },
                            "& input": {
                                color: "black", // Input text color
                                "&::placeholder": {
                                    color: "rgba(0, 0, 0, 0.5)", // Placeholder color
                                },
                            },
                        },
                        "& .MuiInputLabel-root": {
                            color: "gray", // Label color
                            "&.Mui-focused": {
                                color: "black", // Focused label color
                            },
                        },
                    }}
                />

                {/* Submit Button */}
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        height: { xs: "35px", sm: "45px" }, // Adjust height
                        borderRadius: "15px", // Rounded corners
                        fontWeight: "bold",
                        fontSize: { xs: "12px", sm: "16px" }, // Adjust font size for readability
                        textTransform: "none", // Optional: Keep text capitalization as is
                    }}
                >
                    Check In
                </Button>
            </MUIBox>



        </>


    )


    const selectedDays = []
    selectedOption?.map(day => {
        selectedDays.push(day.value)
    });

    const format = 'HH:mm';


    const onChange = (time, timeString) => {
        timeValues.push(timeString)
        console.log(timeString)
    };


    useEffect(() => {
        const items = localStorage.getItem('Roles')
        if (items) {
            setRole(items);
        }
    }, []);
    useEffect(function () {
        async function getBranches() {
            try {
                setLoading(true)
                const res = await fetch(`${API}/branches`)
                const data = await res.json()
                setBranches(data.branches)
                console.log(branches)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(true)
            }

        } getBranches()
    }, [])







    function changeTrainer(e) {
        e.preventDefault()
        fetch(`${API}/changeAcademyTRainer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'academyID': academyID,
                'trainerName': changedClassTrainer,
                "timesID": timesID,
                "time": timeValues,
                "days": selectedDays,
                "academyName": academyName

            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                handleTrainerClose()
                setRender(!rerender)
            })


    }


    function DeleteTime(e) {
        e.preventDefault()
        fetch(`${API}/deleteAcademy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({

                "timeID": timesID,
                "academyID": academyID


            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                handleCloseDelete()
                setRender(!rerender)
            })


    }







    useEffect(function () {
        async function getTrainers() {
            try {
                setTrainersLoading(true)
                const res = await fetch(`${API}/getAllPTrainers`)
                const data = await res.json()
                setTrainer(data.result)

            } catch (e) {
                console.log(e)
            } finally {
                setTrainersLoading(false)
            }
        } getTrainers()

    }, [])



    const handleTrainerOpen = () => {
        setTrainerOpen(true);
    };

    const handleTrainerClose = () => {
        setTrainerOpen(false);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleClickOpenInfo = () => {
        setInfoOpen(true);
    };

    const handleCloseInfo = () => {
        setInfoOpen(false);
    };

    console.log(academyID)

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));


    // const branch = localStorage.getItem("branch");


    async function getCustomerInfo(customerID) {
        try {
            const res = await fetch(`${API}/AcademyPreSub/${customerID}/${academyID}`)
            const data = await res.json()
            console.log(data)
            setCustomerInfo(data.customerInfo)
            setSubInfo(data.subInfo)
            setInActiveCount(data.inactiveNum[0].count)

        } catch (e) {
            console.log(e)
        } finally {
            handleClickOpenInfo()


        }
    }


    const navigate = useNavigate()

    const handleBtns = (e) => {
        let word = e.target.value;
        setAll(word);
    };

    const getAllAcademy = () => {
        setAll("All");
    };

    function test(e) {
        e.preventDefault()
        console.log("Hello")
    }






    function increaseQuota(e) {
        e.preventDefault()
        fetch(`${API}/increaseCustomerAcQuota`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerRef.current.value,
                'days': DaysRef.current.value,
                'sessions': SessionsRef.current.value
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    handleClose()
                } else if (result.status === 404) {
                    toast.error(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }

    useEffect(() => {
        if (all === "All") {
            setAcademiesData(academiesData);
        } else {
            const filtered = academiesData.filter((academy) => {
                return (
                    academy.Days.toLowerCase().includes(todayName.toLowerCase())
                );
            });
            setAcademiesData(filtered);

        }
    }, [all]);




    function handleCustomerAcademyCheckIn(e) {
        e.preventDefault(e)
        setButtonLoading(true)
        fetch(`${API}/academy/checkinCustomer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "AcademyID": academyID,
                "CustomerID": registeringCustomerID,
                "branch": Branch,
                "checked": checked
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 2000,

                    });

                    setTimeout(() => {
                        setButtonLoading(false)
                    }, 1500);

                    handleCloseInfo()
                    setChecked(false)

                } else  {
                    toast.error(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: false,

                    });
                }
                setTimeout(() => {
                    setButtonLoading(false)

                }, 1500);
                setChecked(false)

                handleCloseInfo()


                {

                }
            })

    }




    useEffect(function () {
        async function fetchData() {
            try {
                setIsloading(true)
                const res = await fetch(`${API}/academy`)
                const data = await res.json()
                setAcademiesData(data.academies)
            } catch (e) {
                console.log(e)
            } finally {
                setIsloading(false)

            }

        } fetchData()
    }, [rerender])





    return (

        <Box
            sx={{
                width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },  // Adjust width based on screen size
                padding: { xs: 2, sm: 3, md: 4 },  // Adjust padding based on screen size
                margin: 'auto',  // Center it horizontally
                backgroundColor: 'lightblue',  // Add background color
                borderRadius: 2,  // Rounded corners
            }}
        >
            <Navbar />


            <MUIBox sx={{ marginTop: "70px" }}>
                {customerInfo.length == 0 || subInfo == 0 ? (<BootstrapDialog
                    PaperProps={{
                        style: {
                            minHeight: '40%',
                            padding: "20px",
                            maxHeight: '40%',
                            width: "500px",
                            borderRadius: "13px"
                        }
                    }}
                    onClose={handleCloseInfo}
                    aria-labelledby="customized-dialog-title"
                    open={InfoOpen}
                >
                    <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", fontSize: "22px", margin: "0 auto", fontFamily: "Poppins" }} id="customized-dialog-title">
                        No Customer Or Active Subscription!

                    </DialogTitle>

                    <IconButton
                        aria-label="close"
                        onClick={handleCloseInfo}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <lord-icon
                        src="https://cdn.lordicon.com/usownftb.json"
                        trigger="in"
                        colors="primary:#e83a30,secondary:#e83a30"
                        style={{ width: "250px", height: "250px", margin: "0 auto" }}>
                    </lord-icon>

                    <DialogActions>
                        <Button onClick={handleCloseInfo} style={{ backgroundColor: "white", color: "red", fontWeight: "bold", margin: "0 auto", fontSize: "20px" }}>Cancel</Button>
                    </DialogActions>
                </BootstrapDialog>) : (
                    <BootstrapDialog
                        PaperProps={{
                            style: {
                                minHeight: '60%',
                                maxHeight: '60%',
                                width: "500px",
                                borderRadius: "13px"
                            }
                        }}
                        onClose={handleCloseInfo}
                        aria-labelledby="customized-dialog-title"
                        open={InfoOpen}
                    >
                        <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", margin: "0 auto", fontFamily: "Poppins" }} id="customized-dialog-title">
                            Customer Information

                        </DialogTitle>

                        <IconButton
                            aria-label="close"
                            onClick={handleCloseInfo}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {/* sub details dialog */}



                        <DialogContent style={{ alignItems: "center", textAlign: "center" }} >

                            <div style={{ border: "2px solid #dedede ", width: "auto", borderRadius: "16px", margin: "0 auto 5px auto", padding: "10px" }}>
                            <div style={{  width: "auto", borderRadius: "16px", margin: "0 auto 5px auto", padding: "10px" }}>
{customerInfo[0]?.avatar ? (	 <img style={{ borderRadius:"10px", border:"1px solid #e4e4e4"}} src={customerInfo[0]?.avatar} />	):(					<img style={{ height: "150px", marginLeft: "0 auto", border: "1px solid #f5f5f5", borderRadius: "50%" }} src="./figure.png" />
)}
</div>
                                <h2 style={{ margin: "0 auto", width: "auto", fontWeight: "bold" }}>  {customerInfo[0]?.customerID}</h2>
                                <h2 style={{ margin: "0 auto", width: "auto", fontWeight: "bold", fontSize:"17px" }}>  {customerInfo[0]?.Fullname}</h2>
                                {(new Date(DateOFToday).valueOf() >= new Date(subInfo[0]?.EndDate).valueOf() || subInfo[0]?.RemainingSessions == "0") ? (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <ErrorIcon sx={{ color: "red" }} />
                                        <h5 style={{ fontWeight: "bold", color: "red" }}>Subscription has ended</h5>
                                    </div>
                                ) : (RemainingDays <= 5 && new Date(DateOFToday).valueOf() < new Date(subInfo[0]?.EndDate).valueOf()) ? (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <ErrorIcon sx={{ color: "red" }} />
                                        <h5 style={{ fontWeight: "bold", color: "red" }}>Subscription ends in {RemainingDays} Days</h5>
                                    </div>
                                ) : null}

                            </div>

                            {shouldRenderBox ? (



                                <Box alignItems="center" margin="auto" width="fit-content" textAlign="center" display="flex">
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <p style={{ fontWeight: "bold" }}>Session <DoDisturbOnIcon sx={{ color: "red" }} /> from next subscription</p>

                                </Box>



                            ) : ("")}



                            <Container
                                variant="stacked"

                            >
                                <ColumnLayout columns={3} variant="text-grid">


                                    <div>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <GoPackage size={20} style={{ color: "black", marginBottom: "0", marginRight: "7px", size: "12px" }} />   <h6 style={{ fontWeight: "bold", marginBottom: "0" }}>{subInfo[0]?.AcademyName} </h6>
                                        </div>

                                    </div>




                                    <div style={{ alignItems: "center", textAlign: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <DateRangeIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>End Date {subInfo[0]?.EndDate}  </label>
                                        </div>

                                    </div>






                                    <div style={{ alignItems: "center", textAlign: "center", margin: "0 auto" }}>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <FaRegistered size={23} sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", marginLeft: "0" }}>Registration Branch {subInfo[0]?.branch} </label>
                                        </div>

                                    </div>


                                    <div style={{ alignItems: "center", textAlign: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <DateRangeIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Last Check {subInfo[0]?.LastCheck} </label>
                                        </div>

                                    </div>





                                    <div style={{ alignItems: "center", textAlign: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <PaidIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Discount {subInfo[0]?.discount} </label>
                                        </div>

                                    </div>

                                    <div style={{ alignItems: "center", textAlign: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <PaidIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Debt {subInfo[0]?.Remains} </label>
                                        </div>

                                    </div>






                                    <div>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <CancelPresentationIcon sx={{ color: "red", marginBottom: "0", marginRight: "5px", width: "20px", height: "20px" }} />   <h6 style={{ fontWeight: "bold", margin: "0" }}>Used {subInfo[0]?.UsedSessions} </h6>
                                        </div>

                                    </div>


                                    <div style={{ alignItems: "center", textAlign: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                            <CheckIcon sx={{ color: "green", marginBottom: "0", fontWeight: "bold", border: "2px solid green", height: "15px", width: "20px", marginRight: "5px" }} />   <h6 style={{ fontWeight: "bold", margin: "0" }}>Remains {subInfo[0]?.RemainingSessions} </h6>
                                        </div>

                                    </div>





                                </ColumnLayout>
                            </Container>
                        </DialogContent>




                        <DialogActions>
                            <Button onClick={handleCloseInfo} style={{ backgroundColor: "white", color: "red", fontWeight: "bold" }}>Cancel</Button>

                            <LoadingButton
                                className="loading-button"
                                style={{ width: "100px", marginTop: "0", borderRadius: "7px", height: "35px", backgroundColor: "#00ab41", fontSize: "14px" }}
                                loading={buttonLoading}
                                loadingPosition="end"
                                variant="contained"
                                type="submit"
                                onClick={handleCustomerAcademyCheckIn}

                            >
                                <span style={{ color: "white" }}>Confirm</span>
                            </LoadingButton>



                        </DialogActions>
                    </BootstrapDialog>
                )}

                <Drawer

                    PaperProps={{
                        style: {
                            width: "30%"
                        }
                    }}
                    anchor="right" open={sideOpen} onClose={() => toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>



                <ToastContainer
                    autoClose={false}
                    position="top-center"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />







                {/* Delete Academy Dialog */}
                <Dialog
                    open={deleteOpen}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle sx={{ fontWeight: "bold" }} id="alert-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>

                    <DialogActions>
                        <Button onClick={handleCloseDelete}>Cancel</Button>
                        <Button onClick={DeleteTime} >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>



                {/* modify Academy Dialog            */}
                <Dialog
                    sx={{ zIndex: "1000", fontWeight: "bold", fontFamily: "poppins" }}
                    open={trainerOpen}
                    onClose={handleTrainerClose}

                >
                    <DialogTitle>Modify</DialogTitle>
                    <DialogContent>
                        <form onSubmit={(e) => changeTrainer(e)} style={{ border: "1px solid #e4e4e4", backgroundColor: "white", height: "600px", padding: "10px", width: "400px", margin: "0 auto" }}>
                            <TextField onChange={(e) => setAcademyName(e.target.value)} value={academyName} sx={{ marginBottom: "10px", width: "250px", fontWeight: "Bold", fontFamily: "poppins" }} id="outlined-basic" label="Academy name" variant="outlined" />                                    <select required onChange={(e) => setChangedClassTrainer(e.target.value)} style={{ margin: "0 auto", width: "250px", backgroundColor: "white", height: "40px", border: "2px solid #e4e4e4", textAlign: "center", alignItems: "center", marginBottom: "20px" }} name="" id="">

                                <option value="" disabled selected>Trainer</option>
                                {trainer?.map(t => {
                                    return (
                                        <option value={t.fullName}>{t.fullName}</option>
                                    )
                                })}
                            </select>

                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={"Days"}
                                isMulti
                                options={weekDays}
                                onChange={setSelectedOption}
                                placeholder="Days"
                            />

                            <br />


                            <TimePicker sx={{ zIndex: "10000", width: "250px", margin: "0 auto", position: "relative" }} onChange={onChange} style={{ marginBottom: "10px", height: "40px", width: "250px" }} defaultValue={dayjs('12:08', format)} format={format} />


                            <div style={{ margin: "0 auto", alignItems: "center", textAlign: "center" }}>
                                {/* <Button onClick={handleClose}>Cancel</Button> */}
                                <button className='confitm-button' style={{ width: "200px", marginBottom: "20px", height: "40px", borderRadius: "18px", bottom: "0px", position: "relative", margin: "0 auto" }} type="submit">Confirm</button>
                            </div>
                        </form>

                    </DialogContent>

                </Dialog>


                <Grid container spacing={2} mb={2} justifyContent="center" alignItems="center">
                    <Grid item xs={10} sm="auto">
                        <Button
                            sx={{
                                height: "40px",
                                backgroundColor: "black",
                                color: "white",
                                borderRadius: "8px",
                                fontWeight: "bold",
                                "&:hover": { bgcolor: "black" }
                            }}
                            variant="outlined"
                            onClick={handleBtns}
                            startIcon={<TodayIcon sx={{ color: "white" }} />}
                            fullWidth
                        >
                            Academys today
                        </Button>
                    </Grid>

                    {Role === "Admin" && (
                        <>
                            <Grid item xs={10} sm="auto">
                                <Button
                                    sx={{
                                        height: "40px",
                                        backgroundColor: "#192841",
                                        color: "white",
                                        borderRadius: "8px",
                                        fontWeight: "bold",
                                        "&:hover": { bgcolor: "#192841" }
                                    }}
                                    variant="outlined"
                                    startIcon={<AddIcon sx={{ color: "white" }} />}
                                    onClick={() => navigate("/add-academy")}
                                    fullWidth
                                >
                                    Add Academy
                                </Button>
                            </Grid>

                            <Grid item xs={10} sm="auto">
                                <Button
                                    sx={{
                                        height: "40px",
                                        backgroundColor: "#192841",
                                        borderRadius: "8px",
                                        fontWeight: "Bold",
                                        color: "white",
                                        "&:hover": { bgcolor: "#192841" }
                                    }}
                                    startIcon={<EditIcon sx={{ color: "white" }} />}
                                    onClick={() => navigate("/edit-academy-packages")}
                                    fullWidth
                                >
                                    Edit Packages
                                </Button>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={10} sm="auto">
                        <select
                            onChange={(e) => setSelectedBranch(e.target.value)}
                            style={{
                                width: "100%",
                                height: "40px",
                                color: "black",
                                alignItems: "center",
                                textAlign: "center",
                                borderRadius: "7px",
                                backgroundColor: "#e4e4e4",
                                fontWeight: "bold",
                                fontFamily: "poppins",
                                border: "none"
                            }}
                        >
                            <option selected disabled>Select Branch</option>
                            {branches?.map(b => (
                                <option key={b.name} value={b.name}>{b.name}</option>
                            ))}
                        </select>
                    </Grid>
                </Grid>

                <TableContainer sx={{ width: "80%", margin: "auto" }} component={Paper}>
                    <MUITable style={{ fontFamily: "Poppins" }}>
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#e4e4e4' }}>
                                <TableCell style={{ borderTopLeftRadius: "10px", fontWeight: 'bold', fontSize: "16px", fontFamily: 'Poppins' }}>Academy</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: 'Poppins' }}>Trainer</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: 'Poppins' }}>Dates</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: 'Poppins' }}>Time</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: 'Poppins' }}>Branch</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{ borderTopRightRadius: "10px" }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {academiesData?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">No Academies Today</TableCell>
                                </TableRow>
                            ) : (
                                academiesData?.filter(a => a.Branch.toLowerCase().includes(selectedBranch.toLowerCase())).map(academy => (
                                    <TableRow key={academy.academyID}>
                                        <TableCell style={{ cursor: "pointer", fontWeight: "Bold", fontSize: "15px", fontFamily: 'Poppins' }} onClick={() => navigate(`/academies/${academy.academyID}`)}>{academy.description}</TableCell>
                                        <TableCell style={{ cursor: "pointer", fontWeight: "Bold", fontSize: "15px", fontFamily: 'Poppins' }}>{academy.Trainer}</TableCell>
                                        <TableCell style={{ cursor: "pointer", fontWeight: "Bold", fontSize: "15px", fontFamily: 'Poppins' }}>{academy.Days}</TableCell>
                                        <TableCell style={{ cursor: "pointer", fontWeight: "Bold", fontSize: "15px", fontFamily: 'Poppins' }}>{academy.Hours}</TableCell>
                                        <TableCell style={{ cursor: "pointer", fontWeight: "Bold", fontSize: "15px", fontFamily: 'Poppins' }}>{academy.Branch}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: '#23395d', color: 'white', borderRadius: "7px" }}
                                                startIcon={<CheckIcon sx={{ color: 'white' }} />}
                                                onClick={() => checkin(academy.academyID, academy.description)}
                                                fullWidth
                                            >
                                                Check-in
                                            </Button>
                                        </TableCell>
                                        {Role === "Admin" && (
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#23395d', borderRadius: "7px", color: 'white' }}
                                                    startIcon={<EditIcon sx={{ color: 'white' }} />}
                                                    onClick={() => Modify(academy)}
                                                    fullWidth
                                                >
                                                    Modify
                                                </Button>
                                            </TableCell>
                                        )}

                                        {Role === "Admin" && (
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
                                                    startIcon={<DeleteIcon sx={{ color: 'white' }} />}
                                                    onClick={() => Delete(academy)}
                                                    fullWidth
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </MUITable>
                </TableContainer>







            </MUIBox>

        </Box >



    )
}


export default Academies