import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useState } from 'react';
import "../../styles/classStyles/class.css"
import Select from "react-select";
import e from 'cors';
import { data } from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/navbar';
import ClassNav from '../../components/classNav';
function ClassPackagePage() {
    const API = process.env.REACT_APP_API

    let x = ""
    const [sessionsCount, setSessionsCount] = useState()

    const [classes, setClasses] = useState()
    const [loading, setLoading] = useState()
    const [selectedClasses, setSelectedClasses] = useState();
    const [ClassPackageDesc, setClassPackageDesc] = useState()
    const [classLoading, setClassLoading] = useState()
    const [selectedFamily, setSelectedFamily] = useState()
    const [classFamilies, setClassesFamily] = useState([])
    const [price, setPriceCount] = useState()





    useEffect(function () {
        async function fetchClassFamily() {
            setLoading(true)
            const res = await fetch(`${API}/getAllClassFamilies`)
            const data = await res.json()
            console.log(data.data)
            setClassesFamily(data.data)
            setLoading(false)
        }
        fetchClassFamily()
    }, [])







    function handleAddClass(e) {
        e.preventDefault()
        fetch(`${API}/classPackage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'sessionsCount': sessionsCount,
                'price': price,
                "classes": selectedClasses,
                "ClassPackageDesc": ClassPackageDesc,
                "selectedFamily": selectedFamily

            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }
            })
    }
    return (
        <div className="add-class-page">
            <ToastContainer
                position="top-center"
                autoClose={false}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <form style={{backgroundColor:"white", height:"300px", padding:"20px"}} onSubmit={(e) => handleAddClass(e)}>
                <h2>Add class package</h2>
                <select style={{backgroundColor: "whitesmoke", color: "black",border:"1px solid #e4e4e4",height:"40px", alignItems:"center",textAlign:"center",width:"170px" }} required onChange={(e) => setSessionsCount(e.target.value)} className="class-select" name="" id="">
                    <option value="" disabled selected>Sessions count</option>
                    <option value="1"  >1</option>
                    <option value="8"  >8</option>
                    <option value="12" >12</option>
                </select> <br />

                <input style={{ width:"170px"}} required onChange={(e) => setPriceCount(e.target.value)} className='add-class-page-input' type="text" placeholder="Price" /> <br />
                <select style={{backgroundColor: "whitesmoke", color: "black",border:"1px solid #e4e4e4",height:"40px", alignItems:"center",textAlign:"center" , width:"170px"}} required onChange={(e) => setSelectedFamily(e.target.value)} className="class-select" name="" id="">
                    <option value="" disabled selected>Family</option>

                    {classFamilies?.map(m => {
                        return <option value={m.ClassFamilyID} >{m.Description}</option>

                    })}





                </select>



                <button style={{margin:"0 auto"}}>Add</button>

            </form>
        </div>
    )
}

export default ClassPackagePage