import {useForm} from "react-hook-form";
import { useRef } from "react";
import { validate } from "@material-ui/pickers";
import { Password } from "@mui/icons-material";
import "../styles/validationForm.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function ResetPasswordPage() {
	const navigate = useNavigate()
	const { register,formState: { errors }, handleSubmit, watch } = useForm();
	const oldPassword = useRef({});
	oldPassword.current = watch("oldPassword", "");


	const username = useRef({});
	username.current = watch("username", "");



  const password = useRef({});
  password.current = watch("password", "");




  const API = process.env.REACT_APP_API

  const onSubmit = async  => {
	handleResetPassword()
  };



  function handleResetPassword() {
	fetch(`${API}/resetpassword`, {
		method: 'POST',
		headers: {
		   'Content-Type': 'application/json',
		},
		body: JSON.stringify({
		   'username': username.current,
		   'password': oldPassword.current,
		   'newpassword':  password.current
		})
	}).then(response => response.json())
	.then(result=> {
		if (result.status == "404" || result.status == "401") {
			toast.error(`${result.Message}`, {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				});

		} else if (result.status == "200") {

			toast.success(`${result.Message}`, {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				});


				setTimeout(() => {
					navigate("/login")
				}, 1000);
		}
	})
  }
	return(

		<>
		<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
		<form className="validation-from" onSubmit={e => e.preventDefault()}>
			<h5 style={{marginBottom:"20px"}}>Change password</h5>


			<input className="validtion-input"
        type="text"
		placeholder="Enter username"

        {...register( 'username',
		{
          required: "You must specify a username",
        
        })}
      />
      {errors.username && <p>{errors.username.message}</p>}
	 <input className="validtion-input"
        type="password"
		placeholder="Enter your password"

        {...register( 'oldPassword',
		{
          required: "You must specify a password",
        
        })}
      />
      {errors.password && <p  className="reset-error-messsage">{errors.password.message}</p>}



      <input className="validtion-input"
        type="password"
		placeholder="Enter new password"

        {...register( 'password',
		{
          required: "You must specify a password",
        
        })}
      />
      {errors.password && <p  className="reset-error-messsage">{errors.password.message}</p>}

      <input
	   className="validtion-input"
	   placeholder="Re-enter new password"
        type="password"
        {...register("password_repeat",{
          validate: value =>
            value === password.current || "The passwords do not match"
        })}
      />
      {errors.password_repeat && <p  className="reset-error-messsage">{errors.password_repeat.message}</p>}

      <button  onClick={handleSubmit(onSubmit)}  type="submit" >Submit</button>
    </form>
		
		</>
		
	)
}


export default ResetPasswordPage