import SideInfoBar from "../components/usersideInfo";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/navbar";
import "../styles/mainpage.css";
import { useAuth } from "../contexts/authContext";
import NonAuthorizedNavbar from "../components/nonAuthorizedNav";
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid'; // Import Grid from MUI

const API = process.env.REACT_APP_API;

function Login() {
    const navigate = useNavigate();

    const [userName, setUserName] = useState();
    const [loading, setLoading] = useState(true);
    const [branches, setBranches] = useState();
    const [selectedBranch, setSelectedBranch] = useState();
    const name = useRef();
    const password = useRef();
    const [showPassword, setShowPassword] = useState(false);
    
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const { loginAction } = useAuth();


    const useStyles = makeStyles((theme) => ({
        root: {
            '& label': {
                color: '#00125b',
                fontWeight: "bold"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: "2px solid #00125b"
                },
                '&:hover fieldset': {
                    border: "2px solid #00125b"
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'yellow',
                },
            },
        }
    }));
    const classes = useStyles();


    function handleSubmit(e) {
        e.preventDefault();
        if (userName !== "" && password !== "") {
          loginAction({ userName: name.current.value, password: password.current.value, branch: selectedBranch });
        }
    }

    useEffect(() => {
        AOS.init({ duration: 2000 });
        AOS.refresh();
    }, []);

    useEffect(() => {
        async function getBranches() {
            try {
                setLoading(true);
                const res = await fetch(`${API}/branches`);
                const data = await res.json();
                setBranches(data.branches);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        getBranches();
    }, []);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className="customer-page-components">
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12} md={6} display="flex" justifyContent="center">
                        <img src="loginImage.jpg" style={{ maxHeight: "100%", width: "100%", objectFit: "cover" }} alt="Login" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <form onSubmit={handleSubmit} className="signin-form" name="signup-from" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2 style={{ color: "#00125b" }}>LOGIN</h2>
                            <img style={{ width: "200px" }} src="/logo100.png" alt="Logo" />
                            <TextField
                                className={classes.root}
                                sx={{ marginBottom: "10px", width: "300px" }} 
                                InputLabelProps={{ required: false }} 
                                inputRef={name} 
                                required 
                                label="Name" 
                                variant="outlined" 
                            />
                            <TextField
                                className={classes.root}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    style: { fontWeight: "bold" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} 
                                sx={{ width: "300px", marginBottom: "10px" }} 
                                InputLabelProps={{ required: false }} 
                                inputRef={password} 
                                required 
                                label="Password" 
                                variant="outlined" 
                            />
                            <select onChange={(e) => setSelectedBranch(e.target.value)} required style={{ border: "1px solid #e4e4e4", textAlign: "center", marginBottom: "40px", width: "300px", height: "40px", backgroundColor: "#e4e4e4", fontWeight: "bold" }}>
                                <option value="" disabled selected>Branch</option>
                                {branches?.map(m => (
                                    <option key={m.name} value={m.name}>{m.name}</option>
                                ))}
                            </select>
                            <label style={{ cursor: "pointer" }} onClick={() => navigate("/reset-password")}>Reset password?</label>
                            <button style={{ width: "300px", height: "40px", marginTop: "20px", backgroundColor: "#00125b", color: "white" }} id="submit">Login</button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Login;
