import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { v4 as uuidv4 } from 'uuid';
import {  toast } from 'react-toastify';

function CameraModal({ dataRender, setDataRender, customerID,openCamera, isCameraOpen, setOpenCamera, handleOpenCamera, handleCloseCamera }) {
	const API = process.env.REACT_APP_API

	const webcamRef = useRef(null);
	const [imageSrc, setImgeSrc] = useState()
	const formData = new FormData()

	const capture = () => {
		const screenshot = webcamRef.current.getScreenshot();
		if (screenshot) {
			setImgeSrc(screenshot);
			console.log(imageSrc)
		}
	};


	async function handleChangeAvatar() {
		formData.delete('image');    
		formData.delete('customerID');
		if (imageSrc) {
			const response = await fetch(imageSrc);
			const blob = await response.blob();
			const uniqueFileName = `photo-${uuidv4()}.png`;
			formData.append('image', blob, uniqueFileName);

		  }

		  formData.append('customerID',customerID);

		fetch(`${API}/updateAvatar`, {
			method: 'POST',	
			body: formData,

		
		}).then(response => response.json()).then(result=> {
			console.log(result)
			if (result.status == "200") {
				toast.success(`${result.Message}`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
		
				  })

				  handleCloseCamera()
				  setDataRender(!dataRender)
			}
		})
	}
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		height: 300,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		borderRadius: "10px",
		display:"block"
	};
	return (
		<>
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={openCamera}
			onClose={handleCloseCamera}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={openCamera}>
				<Box sx={style}>

					<div style={{ display: "flex", flexDirection: "row", alignItem: "center", textAlign: "center" , justifyContent:"center"}}>
						{isCameraOpen && (
							<div>
								<Webcam
									audio={false}
									ref={webcamRef}
									screenshotFormat="image/jpeg"
									width={200}
									height={150}
								/>

							</div>
						)}

						{isCameraOpen && imageSrc && (
							<div>
								<img src={imageSrc} alt="Captured" width="200" height="150" />
							</div>

						)}
						
					</div>

					<div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
					<Button
							variant="contained"
							color="primary"
							onClick={capture}
							sx={{
								margin: "auto",
								marginTop: 2,
								width: "160px",
								backgroundColor: "#e4e4e4",
								color: "black",
								borderBottom: "none",
								whiteSpace: 'nowrap',
								alignItems: "center",
								fontWeight: "bold",
								'&:hover': {
									backgroundColor: 'grey',
								},
							}}
						>
							Capture Photo
						</Button>
						<Button
						
							variant="contained"
							color="primary"
							onClick={()=> handleChangeAvatar()}
							sx={{
								margin: "auto",
								marginTop: 2,
								width: "160px",
								backgroundColor: "#e4e4e4",
								color: "black",
								borderBottom: "none",
								whiteSpace: 'nowrap',
								alignItems: "center",
								fontWeight: "bold",
								'&:hover': {
									backgroundColor: 'grey',
								},
							}}
						>
						Confirm
						</Button>
					</div>
				</Box>
			</Fade>
		</Modal>
		</>
		
	)
}


export default CameraModal