import { useEffect, useState } from "react"
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FormControl } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Slider } from 'antd';

const API = process.env.REACT_APP_API




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));



function EditGymPackagesPage() {
	const [Loading, setLoading] = useState()
	const [packageData, setPackageData] = useState([])
	const [packageID, setPackageID] = useState()
	const [packageName, setPackageName] = useState()
	const [open, setOpen] = useState(false);
	const [packagePrice, setPackagePrice] = useState()
	const [packageSessionsCount, setPackageSessionsCount] = useState()
	const [packagePeriod, setPackagePeriod] = useState()
	const [packageDiscount, setPackageDiscount] = useState()

	const [render, rerender] = useState(false);
	const [name, setName] = useState()

	const [inbody, setInbody] = useState(false)
	const [extraClass, setExtraClass] = useState(false)
	const [ageRange, setAgeRange] = useState([20, 50]);
	const [openDelete, setOpenDelete] = React.useState(false);

	function valuetext(value) {
		return `${value}`;
	}

	const handleChange = (event, newValue) => {
		setAgeRange(newValue);
	};

	const handleClickOpenDelete = () => {
		setOpenDelete(true);
	};

	const handleCloseDelete = () => {
		setOpenDelete(false);
	};
	const marks = {
		0: '0',
		20: '20',
		40: '40',
		60: '60',
		100: '100',
	};


	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	function handleEditPackage(m) {
		setName(m.packageName)
		setPackageName(m.packageName)
		setPackageID(m.PackageID)
		setPackagePeriod( m.Period)
		setPackagePrice( m.Price)
		setPackageSessionsCount(m.sessionsCount)
		setPackageDiscount(m.Discount)

		if (m.Inbody) {
			setInbody(true)
		}

		if (m.ClassInvite) {
			setExtraClass(true)
		}
		handleClickOpen()

	}

	// m.packageName, m.PackageID, m.Period, m.Price, m.sessionsCount


	function handleDeletePackage(x) {
		setPackageID(x)
		handleClickOpenDelete()


	}


	function handleGymPackageStatus(id) {
		setPackageID(id)

		fetch(`${API}/gymPackageStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'packageID': id,

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

				}
				rerender(!render)
				handleCloseDelete()


			})
	}


	function handleGymPackageDelete(e) {
		e.preventDefault()
		fetch(`${API}/deleteGymPackage`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'packageID': packageID,

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

				}
				rerender(!render)
				handleCloseDelete()


			})
	}



	function handleGymPackageUpdate(e) {
		e.preventDefault()
		fetch(`${API}/updateGymPackage`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'packageID': packageID,
				'packagePrice': packagePrice,
				'packageSessionsCount': packageSessionsCount,
				"packagePeriod": packagePeriod,
				"PackageName": packageName,
				"packageDiscount":packageDiscount,
				"ClassInvite": extraClass,
				"Inbody" : inbody,
				"ageRange":ageRange

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(`${result.Message}`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

				}else {
					toast.error(`Something went wrong!`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

				}
				rerender(!render)
				handleClose()


			})
	}
	useEffect(function () {
		async function fetchAllGymPackages() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getGymPackages`)
				const data = await res.json()
				setPackageData(data.packages)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchAllGymPackages()
	}, [render])
	return (
		<div className="edit-gym-packages-page">

			<Dialog
				open={openDelete}
				onClose={handleCloseDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Are You Sure?"}
				</DialogTitle>

				<DialogActions>
					<Button onClick={handleCloseDelete}>cancel</Button>
					<Button onClick={handleGymPackageDelete} autoFocus>
						Agree
					</Button>
				</DialogActions>
			</Dialog>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>


			{Loading ? (<p>Loading</p>) : (<TableContainer component={Paper}>
				<Table sx={{ minWidth: 700, width: "70%", borderTopLeftRadius: "10px", margin: "80px auto" }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontFamily: "poppins", fontWeight: "bold", borderTopLeftRadius: "10px" }}>Package Name</TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center">Price</TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center">Sessions Count</TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center">Discount</TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center">Period (days)</TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center">Age Range</TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center">Class</TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center">Inbody</TableCell>

							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center"></TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center"></TableCell>
							<TableCell style={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins", }} align="center"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{packageData.map(m => (
							<TableRow key={m.PackageID}>
								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.packageName}</TableCell>
								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.Price}</TableCell>
								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.sessionsCount}</TableCell>
								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.Discount}</TableCell>

								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.Period}</TableCell>
								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.startAge}:{m.endAge} years</TableCell>
								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.ClassInvite	}</TableCell>
								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{m.Inbody	}</TableCell>

								<TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">
									<Button
										variant="contained"
										sx={{ backgroundColor: '#23395d', borderRadius: "7px", color: 'white' }}
										startIcon={<EditIcon sx={{ color: 'white' }} />}
										onClick={() => handleEditPackage(m)}>
										Edit
									</Button>
								</TableCell>
								<TableCell align="center">
									<Button
										variant="contained"
										sx={{ backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
										startIcon={<DeleteIcon sx={{ color: 'white' }} />}
										onClick={() => handleDeletePackage(m.PackageID)}>
										Delete
									</Button>
								</TableCell>

								<TableCell align="center">
									<Button
										variant="contained"
										sx={{ backgroundColor: m.status === "active" ? '#4cc764' : '#e4e4e4', borderRadius: "7px", color: 'white' }}
										startIcon={<AutorenewIcon sx={{ color: 'white' }} />}
										onClick={() => handleGymPackageStatus(m.PackageID)}>
										{m.status == "active" ? ("deactivate") : ("activate")}
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>)}
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					{`${name}`}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<form style={{ display: "list-item", backgroundColor: "white", width: "340px", height: "auto", margin: "0 auto", padding: "20px" }} >
					<input value={packageName} type="text" placeholder="Name" required onChange={(e) => setPackageName(e.target.value)} id="outlined-basic" label="Period" variant="outlined" /> <br />

					<input value={packagePrice} type="text" placeholder="Price" required onChange={(e) => setPackagePrice(e.target.value)} id="outlined-basic" label="Price" variant="outlined" /> <br />
					<input value={packageSessionsCount} type="text" placeholder="Sessions count" required onChange={(e) => setPackageSessionsCount(e.target.value)} id="outlined-basic" label="Sessions count" variant="outlined" /> <br />
					<input value={packageDiscount} type="text" placeholder="Discount" required onChange={(e) => setPackageDiscount(e.target.value)} id="outlined-basic" label="Sessions count" variant="outlined" /> <br />

					<input value={packagePeriod} type="text" placeholder="Period" required onChange={(e) => setPackagePeriod(e.target.value)} id="outlined-basic" label="Period" variant="outlined" /> <br />
					<div style={{ display: "inline-block", margin: "0 auto", width: "70%" }}>
					<div style={{backgroundColor:"#e4e4e4", alignItems:"cemter", textAlign:"center", borderRadius:"10px", width:"80%", margin:"0 auto", padding:"10px"}}>
					<Slider
                        range={{
                            draggableTrack: true,
                        }}
                        defaultValue={[20, 50]}
                        style={{ width: "70%", margin: "0 auto" }}
                        onAfterChange={(value) => setAgeRange(value)}
						
                    />
                </div >
						<div style={{alignItems:"left", textAlign:"left"}}>
						<FormControlLabel sx={{ fontWeight: "bold", width: "auto", margin: "0 auto" }} checked={inbody} onChange={(e) => setInbody(!inbody)} control={<Checkbox sx={{ color: "black" }} />} label="Inbody" /> <br />
						<FormControlLabel sx={{ width: "auto", margin: "0 auto" }} checked={extraClass} onChange={(e) => setExtraClass(!extraClass)} control={<Checkbox />} label="Class Invitation" />
						</div>


					</div>


					<Button onClick={(e) => handleGymPackageUpdate(e)} style={{ backgroundColor: "#001833",height:"40px", color: "white", width: "200px", borderRadius: "30px", fontWeight: "bold" }} type="submit" autoFocus >
						Confirm
					</Button>


				</form>


			</BootstrapDialog>
		</div>
	)
}

export default EditGymPackagesPage