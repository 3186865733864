import "../styles/trainerContract.css"
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { DatePicker } from "antd";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios'
import { Upload, Form } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Button, Flex } from 'antd';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});




function TrainerContract() {
	const API = process.env.REACT_APP_API

	const [gender, setGender] = useState('');
	const [btnLoading, setBtnLoading] = useState(false);
	const [name, setName] = useState()
	const [nationalID, setNationalID] = useState()
	const [selectedGender, setSelectedGender] = useState()
	const [department, setDepartment] = useState()
	const [qualifications, setQualifications] = useState()
	const [phone, setPhone] = useState()
	const [secPhone, setSecPhone] = useState()

	const [email, setEmail] = useState()
	const [birthDate, setBirthdate] = useState()
	const [hiringDate, sethHiringdate] = useState()
	const [salary, setSalary] = useState()
	const [percent, setPercent] = useState()
	const [address, setAddress] = useState()
	const [contractType, setContractType] = useState()
	const [selectedFile, setSelectedFile] = useState()
	const [pdf, setPDF] = useState()

	const [loadings, setLoadings] = useState(false);

	
	const enterLoading = (index) => {
	  setLoadings((prevLoadings) => {
		const newLoadings = [...prevLoadings];
		newLoadings[index] = true;
		return newLoadings;
	  });
	  setTimeout(() => {
		setLoadings((prevLoadings) => {
		  const newLoadings = [...prevLoadings];
		  newLoadings[index] = false;
		  return newLoadings;
		});
	  }, 6000);
	};

	
	const [fileList, setFileList] = useState([
		{
		  uid: '-1',
		  name: 'image.png',
		  status: 'done',
		  url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		},
	  ]);
	//   const onChange = ({ fileList: newFileList }) => {
	// 	setFileList(newFileList);
	//   };
	  const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
		  src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj);
			reader.onload = () => resolve(reader.result);
		  });
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	  };






	const onChange = (date, dateString) => {
		setBirthdate(dateString)
	};

	const onHiringChange = (date, dateString) => {
		sethHiringdate(dateString)
	};
	const handleChange = (e) => {
		setSelectedGender(e.target.value);
	};


	const handleContractChange = (e) => {
		setContractType(e.target.value);
	};

	const beforeUpload = (file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
		  setFileList((prev) => [...prev, { url: reader.result }]);
		};
	  
		// then upload `file` from the argument manually
		return false;
	  };
	  const handleRemove = (file) => {
		setFileList(prevFileList => prevFileList.filter(item => item.uid !== file.uid));
	  };

function	fileUpload(e)  {

        // Update the state
		setSelectedFile(e.fileList[0]?.originFileObj)
    }

	function handleAddContract(e) {
		setLoadings(true)

		e.preventDefault()

		 let formData = new FormData();
		 formData.append("files", pdf)

		formData.append("files", selectedFile )

        formData.append("name", name);
		formData.append("nationalID", nationalID);
        formData.append("selectedGender", selectedGender);
        formData.append("department", department);
        formData.append("qualifications", qualifications);
        formData.append("phone", phone,);
        formData.append("secPhone", secPhone);
        formData.append("email", email);
        formData.append("brithDate", birthDate);
		formData.append("hiringDate", hiringDate);
        formData.append("salary", salary);
        formData.append("percent", percent);
        formData.append("address", address);
		formData.append("contractType", contractType);
			axios({
			method: 'post',
			url: `${API}/addContract`,   
			data: formData,
			headers: {'Content-Type': 'multipart/form-data' }
			})
			.then(function (response) {
				//handle success
				console.log(response);

				if (response.status == 200) {
								toast.success(`${response.data.Message}`, {
									position: "top-center",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "colored",
								});
								setLoadings(false)
							} else {
								toast.error('Something went wrong', {
									position: "top-center",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "colored",
								});

								setLoadings(false)
							}
						
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
			
			// body: JSON.stringify({
			// 	"name": name,
			// 	"nationalID": nationalID,
			// 	"selectedGender": selectedGender,
			// 	"department": department,
			// 	"qualifications": qualifications,
			// 	"phone": phone,
			// 	"secPhone": secPhone,
			// 	// "gender":selectedGender,
			// 	// "birthdate":birthDate,
			// 	"email": email,
			// 	"brithDate": birthDate,
			// 	"hiringDate": hiringDate,
			// 	"salary": salary,
			// 	"percent": percent,
			// 	"address": address,
			// 	"contractType": contractType,
			// })
		// }).then(response => response.json())
		// 	.then(result => {
		// 		if (result.status == 200) {
		// 			toast.success(`${result.Message}`, {
		// 				position: "top-center",
		// 				autoClose: 5000,
		// 				hideProgressBar: false,
		// 				closeOnClick: true,
		// 				pauseOnHover: true,
		// 				draggable: true,
		// 				progress: undefined,
		// 				theme: "colored",
		// 			});
		// 		} else {
		// 			toast.error('Something went wrong', {
		// 				position: "top-center",
		// 				autoClose: 5000,
		// 				hideProgressBar: false,
		// 				closeOnClick: true,
		// 				pauseOnHover: true,
		// 				draggable: true,
		// 				progress: undefined,
		// 				theme: "colored",
		// 			});
		// 		}
		// 	})
	}



	return (
		<div className="trainer-contract-page">

			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<div className="trainer-contract-page-left" >
				<form onSubmit={(e) => handleAddContract(e)} className="trainer-contract-form">
					<h3 style={{ fontWeight: "bold" }}>Trainer Contract</h3>

					<div className="trainer-contract-form-data">

						<div className="trainer-contract-form-right">
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} onChange={(e) => setName(e.target.value)} id="outlined-basic" label="Name" variant="outlined" /> <br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} onChange={(e) => setNationalID(e.target.value)} id="outlined-basic" label="National ID" variant="outlined" /> <br />
							{/* <TextField InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" label="Outlined" variant="outlined" /><br /> */}

							<FormControl sx={{ width: "300px", margin: "10px" }}>
								<InputLabel id="demo-simple-select-label">Gender</InputLabel>
								<Select
									required

									SelectDisplayProps={{ style: { color: 'black', fontWeight: "bold" }, }}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									// value={gender}
									label="Gender"
									onChange={handleChange}
								>
									<MenuItem value="Male">Male</MenuItem>
									<MenuItem value="Female">Female</MenuItem>
								</Select>
							</FormControl> <br />


							<DatePicker required style={{ width: "300px", height: "50px", color: "black" }} placeholder="Birth Date" onChange={onChange} /><br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setDepartment(e.target.value)} label="Department" variant="outlined" /><br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setQualifications(e.target.value)} label="Qualifications" variant="outlined" /> <br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setPhone(e.target.value)} label="Phone" variant="outlined" /><br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setSecPhone(e.target.value)} label="Second phone" variant="outlined" /><br />

							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setEmail(e.target.value)} label="Email" variant="outlined" /><br />



						</div>

						<div className="trainer-contract-form-left">


							<FormControl sx={{ width: "300px", margin: "10px" }}>
								<InputLabel id="demo-simple-select-label">Contract Type</InputLabel>
								<Select
									required
									SelectDisplayProps={{ style: { color: 'black', fontWeight: "bold" }, }}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									// value={gender}
									label="Gender"
									onChange={handleContractChange}
								>
									<MenuItem value="Salary">Salary</MenuItem>
									<MenuItem value="Percantege">Percantege</MenuItem>
									<MenuItem value="Salay and percantege">Salay and percantege </MenuItem>
								</Select>
							</FormControl> <br />


							<DatePicker required style={{ width: "300px", height: "50px", color: "black" }} placeholder="Hiring Date" onChange={onHiringChange} /><br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setSalary(e.target.value)} label="Salary" variant="outlined" /><br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setPercent(e.target.value)} label="percantege %" variant="outlined" /> <br />
							<TextField required InputLabelProps={{ style: { color: 'black', fontWeight: "bold" }, }} sx={{ width: "300px", margin: "10px" }} id="outlined-basic" onChange={(e) => setAddress(e.target.value)} label="Address" variant="outlined" /><br />

							<ImgCrop rotationSlider>
						
						<Upload
						        // beforeUpload={beforeUpload}
								onRemove={handleRemove}
						
						//    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
						  listType="picture-card"
						//   fileList={fileList}
						  onChange={(e)=>fileUpload(e)}
						//    onPreview={onPreview}
						//   style={{zinde}}
						
						>
						  {fileList.length < 2 && '+ Upload'}
						</Upload>
					  </ImgCrop>

					  <input type="file"   accept="application/pdf" onChange={(e)=> setPDF(e.target.files[0])}/>


						</div>


					</div>

{/* 
					<LoadingButton
						loading={btnLoading}
						type="submit"

						sx={{


							':hover': {
								backgroundColor: '#152238',
								color: 'white',
							},

							backgroundColor: "#152238",
							color: "white",
							fontWeight: "bold",
							width: "200px",
							height: "45px",
							margin: "0 auto",
							borderRadius: "7px",
							marginTop: "20px"

						}}
						loadingPosition="start"
						variant="outlined"
					>
						Submit
					</LoadingButton> */}

<Button
loading={loadings}
htmlType="submit"
type="primary"
sx={{


	
}}

style={{width:"250px",
	height:"45px",
	color:"white",	
	borderRadius:"20px"}}
	>
          Confirm
        </Button>

				</form>

			</div>

			<div className="trainer-contract-page-right" >
				<img src="./trainer.png" />
			</div>
		</div>
	)
}


export default TrainerContract