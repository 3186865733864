import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Button } from '@mui/material';
import Card from '@mui/joy/Card';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';

import CardContent from '@mui/joy/CardContent';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogContentText from '@mui/material/DialogContentText';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import List from '@mui/material/List';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CardOverflow from '@mui/joy/CardOverflow';
import AspectRatio from '@mui/joy/AspectRatio';
const API = process.env.REACT_APP_API


function GymComponent({ sub }) {
	return (
		<Card
			variant="outlined"
			sx={{
				padding: "20px",
				margin: "20px auto",
				marginBottom: "30px",
				maxWidth: { xs: '100%', sm: '600px' },
			}}
		>
			<CardContent sx={{ fontFamily: "Cairo" }}>
				<Box sx={{ display: 'flex', fontFamily: "Cairo", flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Package Name</Typography>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.packageName}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Status</Typography>
							<Box
								sx={{
									backgroundColor: sub?.status === "inactive" || sub?.status === "canceled" ? "red" : "green",
									color: "white",
									borderRadius: "7px",
									textAlign: "center",
									padding: "2px",
								}}
							>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" fontWeight="bold">{sub?.status}</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box display="flex" alignItems="center">
								<CancelPresentationIcon sx={{ color: "red" }} />
								<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">used sessions</Typography>
							</Box>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.UsedSessions}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box display="flex" alignItems="center">
								<CheckBoxIcon sx={{ color: 'green' }} />
								<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Remaining sessions</Typography>
							</Box>
							<Typography variant="body2">{sub?.RemainingSessions}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Tooltip title={sub?.EndDate} arrow>
								<Box display="flex" alignItems="center">
									<CalendarMonthIcon />
									<Typography variant="body1 " sx={{ fontFamily: "Cairo" }} fontWeight="bold"> End Date</Typography>
								</Box>
							</Tooltip>
							<Typography
								variant="body2"
								sx={{
									fontFamily: "Cairo",
									lineHeight: 1.5,
									maxHeight: '3em',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{sub?.EndDate}
							</Typography>
						</Grid>

						{sub?.PersonalTrainer != "undefined" ? (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<PersonIcon />
									<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Personal Trainer</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.PersonalTrainer}</Typography>
							</Grid>
						) : ""}

						{sub?.Inbody && (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<ListAltIcon />
									<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Inbody</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" color={sub?.Inbody === "Available" ? "green" : "red"}>
									{sub?.Inbody}
								</Typography>
							</Grid>
						)}

						{sub?.ClassInvite && (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<CardGiftcardIcon />
									<Typography variant="body1" fontWeight="bold">Free Class</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" color={sub?.ClassInvite === "Available" ? "green" : "red"}>
									{sub?.ClassInvite}
									{sub?.ClassInvite !== "Available" && <Typography variant="body2">({sub?.classUseDate})</Typography>}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Box>
			</CardContent>

			{sub?.Remains !== 0 && (
				<List sx={{ width: '100%', border: "1px solid #e4e4e4", bgcolor: 'background.paper', borderRadius: "10px", margin: "20px 0" }}>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<MoneyOffIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`Paid ${sub.Paid}`}</Typography>
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<AttachMoneyIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`Remains ${sub.Remains}`}</Typography>
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<DateRangeIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`${sub.RemainsGraceEndDate}`}</Typography>
					</ListItem>
				</List>
			)}
		</Card>
	)
}


function ClassComponent({ sub }) {
	return (
		<Card
			variant="outlined"
			sx={{
				padding: "20px",
				margin: "20px auto",
				marginBottom: "30px",
				maxWidth: { xs: '100%', sm: '600px' },
			}}
		>
			<CardContent sx={{ fontFamily: "Cairo" }}>
				<Box sx={{ display: 'flex', fontFamily: "Cairo", flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Family</Typography>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.family}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Status</Typography>
							<Box
								sx={{
									backgroundColor: sub?.status === "inactive" || sub?.status === "canceled" ? "red" : "green",
									color: "white",
									borderRadius: "7px",
									textAlign: "center",
									padding: "2px",
								}}
							>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" fontWeight="bold">{sub?.status}</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box display="flex" alignItems="center">
								<CancelPresentationIcon sx={{ color: "red" }} />
								<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">used sessions</Typography>
							</Box>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.UsedSessions}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box display="flex" alignItems="center">
								<CheckBoxIcon sx={{ color: 'green' }} />
								<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Remaining sessions</Typography>
							</Box>
							<Typography variant="body2">{sub?.RemainingSessions}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Tooltip title={sub?.EndDate} arrow>
								<Box display="flex" alignItems="center">
									<CalendarMonthIcon />
									<Typography variant="body1 " sx={{ fontFamily: "Cairo" }} fontWeight="bold"> End Date</Typography>
								</Box>
							</Tooltip>
							<Typography
								variant="body2"
								sx={{
									fontFamily: "Cairo",
									lineHeight: 1.5,
									maxHeight: '3em',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{sub?.EndDate}
							</Typography>
						</Grid>

						{sub?.Trainer != "undefined" ? (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<PersonIcon />
									<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Personal Trainer</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.Trainer}</Typography>
							</Grid>
						) : ""}

						{sub?.Inbody && (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<ListAltIcon />
									<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Inbody</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" color={sub?.Inbody === "Available" ? "green" : "red"}>
									{sub?.Inbody}
								</Typography>
							</Grid>
						)}

						{sub?.ClassInvite && (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<CardGiftcardIcon />
									<Typography variant="body1" fontWeight="bold">Free Class</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" color={sub?.ClassInvite === "Available" ? "green" : "red"}>
									{sub?.ClassInvite}
									{sub?.ClassInvite !== "Available" && <Typography variant="body2">({sub?.classUseDate})</Typography>}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Box>
			</CardContent>

			{sub?.Remains !== 0 && (
				<List sx={{ width: '100%', border: "1px solid #e4e4e4", bgcolor: 'background.paper', borderRadius: "10px", margin: "20px 0" }}>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<MoneyOffIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`Paid ${sub.Paid}`}</Typography>
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<AttachMoneyIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`Remains ${sub.Remains}`}</Typography>
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<DateRangeIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`${sub.RemainsGraceEndDate}`}</Typography>
					</ListItem>
				</List>
			)}
		</Card>
	)
}




function AcademyComponent({ sub }) {
	return (
		<Card
			variant="outlined"
			sx={{
				padding: "20px",
				margin: "20px auto",
				marginBottom: "30px",
				maxWidth: { xs: '100%', sm: '600px' },
			}}
		>
			<CardContent sx={{ fontFamily: "Cairo" }}>
				<Box sx={{ display: 'flex', fontFamily: "Cairo", flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Academy</Typography>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.AcademyName }</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Status</Typography>
							<Box
								sx={{
									backgroundColor: sub?.status === "inactive"  || sub?.status === "canceled" ? "red" : "green",
									color: "white",
									borderRadius: "7px",
									textAlign: "center",
									padding: "2px",
								}}
							>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" fontWeight="bold">{sub?.status}</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box display="flex" alignItems="center">
								<CancelPresentationIcon sx={{ color: "red" }} />
								<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">used sessions</Typography>
							</Box>
							<Typography sx={{ fontFamily: "Cairo" }} variant="body2">{sub?.UsedSessions}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box display="flex" alignItems="center">
								<CheckBoxIcon sx={{ color: 'green' }} />
								<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Remaining sessions</Typography>
							</Box>
							<Typography variant="body2">{sub?.RemainingSessions}</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Tooltip title={sub?.EndDate} arrow>
								<Box display="flex" alignItems="center">
									<CalendarMonthIcon />
									<Typography variant="body1 " sx={{ fontFamily: "Cairo" }} fontWeight="bold"> End Date</Typography>
								</Box>
							</Tooltip>
							<Typography
								variant="body2"
								sx={{
									fontFamily: "Cairo",
									lineHeight: 1.5,
									maxHeight: '3em',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{sub?.EndDate}
							</Typography>
						</Grid>

				

						{sub?.Inbody && (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<ListAltIcon />
									<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">Inbody</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" color={sub?.Inbody === "Available" ? "green" : "red"}>
									{sub?.Inbody}
								</Typography>
							</Grid>
						)}

						{sub?.ClassInvite && (
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<CardGiftcardIcon />
									<Typography variant="body1" fontWeight="bold">Free Class</Typography>
								</Box>
								<Typography sx={{ fontFamily: "Cairo" }} variant="body2" color={sub?.ClassInvite === "Available" ? "green" : "red"}>
									{sub?.ClassInvite}
									{sub?.ClassInvite !== "Available" && <Typography variant="body2">({sub?.classUseDate})</Typography>}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Box>
			</CardContent>

			{sub?.Remains !== 0 && (
				<List sx={{ width: '100%', border: "1px solid #e4e4e4", bgcolor: 'background.paper', borderRadius: "10px", margin: "20px 0" }}>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<MoneyOffIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`Paid ${sub.Paid}`}</Typography>
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<AttachMoneyIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`Remains ${sub.Remains}`}</Typography>
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<DateRangeIcon sx={{ color: "black" }} />
							</Avatar>
						</ListItemAvatar>
						<Typography sx={{ fontFamily: "Cairo" }} variant="body1" fontWeight="bold">{`${sub.RemainsGraceEndDate}`}</Typography>
					</ListItem>
				</List>
			)}
		</Card>
	)
}


export default function CustomerQRInfo() {
	const [gymData, setGymData] = useState([])
	const [classData, setClassData] = useState([])
	const [AcademyData, setAcademyData] = useState([])
	const [loading, setLoading] = useState()
	const [info, setInfo] = useState([])
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const params = useParams()
	const id = params.id

	useEffect(function () {
		async function fetchData() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/customerprofileQR/${id} `)
				const data = await res.json()
				console.log(data)
				setGymData(data.gym)
				setClassData(data.class)
				setAcademyData(data.academy)
				setInfo(data.info)
			} catch (e) {
				console.log()
			} finally {
				setLoading(false)
			}
		} fetchData()
	}, [])

	{ if (loading) return <CircularProgress sx={{display:"flex", top:"50%", left:"50%",position:"absolute", transform:"translate(-50%, -50%)"}} /> }
	return (

		<Container maxWidth="lg">
			<Card variant="outlined">
			{console.log(info[0])}

			 {info[0]?.avatar === null ? (<img  style={{ width: "150px", height: "150px", margin:"auto" }} src="/figure.png" />) : (<img   style={{ width: "150px", height: "150px", margin:"auto" }} src={info[0]?.avatar} />)}
			 <Typography sx={{ fontWeight: "bold", fontFamily: "Cairo",fontSize:"30px",width: "fit-content", margin: "0 auto" }} level="title-md">{info[0]?.Fullname}</Typography> 
				<Typography sx={{ fontWeight: "bold", fontFamily: "Cairo", fontSize:"50px",width: "fit-content", margin: "0 auto" }} level="title-md">{info[0]?.customerID}</Typography> 
			</Card>

			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', fontWeight: "bold" }}>
					<TabList sx={{ margin: "0 auto", width: "fit-content" }} onChange={handleChange} aria-label="lab API tabs example">
						<Tab sx={{ fontWeight: "bold" }} label="GYM" value="1" />
						<Tab sx={{ fontWeight: "bold" }} label="Group Fitness" value="2" />
						<Tab sx={{ fontWeight: "bold" }} label="Academy" value="3" />
					</TabList>
				</Box>
				<TabPanel value="1">
					{gymData.map(sub => {
						return (
							<GymComponent sub={sub} />
						)
					})}

				</TabPanel>
				<TabPanel value="2">
					{classData.map(sub => {
						return (
							<ClassComponent sub={sub} />
						)
					})}
				</TabPanel>
				<TabPanel value="3">
					{AcademyData?.map(sub => {
					return (
						<AcademyComponent sub={sub} />
					)
				})}</TabPanel>
			</TabContext>


			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>

				</Grid>

				<Grid item xs={12} sm={6}>

				</Grid>


			</Grid>
		</Container>
	);
}

