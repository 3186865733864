


import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';
import { Box, Typography } from '@mui/material';


import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Table } from 'antd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));



const API = process.env.REACT_APP_API

function ClassFinance() {
	const [filtered, setFiltered] = useState("All")
	const [selectedBranch, setSelectedBranch] = useState("All")
	const [branches, setBranches] = useState()
	const [branchesLoading, setBranchesLoading] = useState()




	const [loading, setLoading] = useState(true)
	const [financeDage, setFinanceData] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainerName, settrainerName] = useState("All")
	const [trainer, setTrainer] = useState()
	const [trainersLoading, setTrainersLoading] = useState()
	const [classes, setClasses] = useState()
	const [classesLoading, setClassesLoadingLoading] = useState()
	const [className, setClassName] = useState("All")
	const [ageFrom, setAgeFrom] = useState("All")
	const [ageTo, setAgeTo] = useState("All")
	const [searched, setSearched] = useState(false)
	const [selectedPackage, setSelectedPackage] = useState("All")
	const [gender, setSelectedGender] = useState("All")

	const [customerID, setCustomerID] = useState("")

	const [tableParams, setTableParams] = useState({
		pagination: {
		  current: 1,
		  pageSize: 10,
		},
	  });
  
	  const fetchData = () => {
		setLoading(true);
		fetch(`${API}/classFinance?startDate=${startDate}&endDate=${endDate}&dep=${className}&trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`) 	
		.then((res) => res.json())
	
		  .then(( data ) => {
			setFinanceData(data.result)
			setLoading(false);
			setTableParams({
			  ...tableParams,
			  pagination: {
				...tableParams.pagination,
				total: 200,
				// 200 is mock data, you should read it from server
				// total: data.totalCount,
			  },
			});
		  });
	  };
	  useEffect(fetchData, []);
	  const handleTableChange = (pagination, filters, sorter) => {
		  setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		  });
		};
	  const columns = [
		   {
	  title: 'Customer ID',
	  dataIndex: 'CustomerID',
	  sorter: (a, b) => a.CustomerID - b.CustomerID,
	},
		  {
			title: 'Full name',
			dataIndex: 'Fullname',
			align:"center"
  
			
	  
		  },
  
		  {
			  title: 'Phone',
			  dataIndex: 'Phone',
			  align:"center"
  
		
			},
  
			{
			  title: 'Age',
			  dataIndex: 'customerAge',
			  sorter: (a, b) => a.customerAge - b.customerAge,
			  align:"center"
  
  
		
			},
  
  
			{
			  title: 'National ID',
			  dataIndex: 'NationalID',
			  align:"center"
  
		
			},
		  //   PayDate	Category	Dep	Trainer	Branch
			{
			  title: 'Paid Value',
			  dataIndex: 'PaidValue',
			  sorter: (a, b) => a.PaidValue - b.PaidValue,
			  align:"center"
  
		
			},
			{
			  title: 'Remianing Value',
			  dataIndex: 'Remaining',
			  sorter: (a, b) => a.Remaining - b.Remaining,
			  align:"center"
  
			},
			{
				title: 'Discount',
				dataIndex: 'Discount',
				sorter: (a, b) => a.discount - b.discount,
				align:"center"
	
			  },
			{
			  title: 'Pay Date',
			  dataIndex: 'PayDate',
			  sorter: (a, b) => new Date(a.PayDate) - new Date(b.PayDate),			
			  align:"center"
  
		
			},
  
			{
			  title: 'Category',
			  dataIndex: 'Category',
			  align:"center"
  
			},
  
			{
			  title: 'Dep',
			  dataIndex: 'Dep',
			  align:"center"
  
			},
			{
				title: 'Package',
				dataIndex: 'package',
				align:"center"
	
			  },
  
			{
			  title: 'Trainer',
			  dataIndex: 'Trainer',
			  align:"center"
		
			},
  
			{
			  title: 'Branch',
			  dataIndex: 'Branch',
			  align:"center"
  
			},
	  
		];
  
	let total = []

	function onChange(date, dateString) {
		console.log(dateString)
		setDate(dateString.split("/"))


	}




	function onEndDatehange(date, dateString) {
		console.log(dateString)
		setEnDate(dateString)
		console.log(endDate)


	}




	async function handleFilter() {
		try {
			setLoading(true)
			const res = await fetch(`${API}/classFinance?startDate=${startDate}&endDate=${endDate}&dep=${className}&trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`) 
			const data = await res.json()
			setFinanceData(data.result)
		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)


		}
	}






	const rows = [
		createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
		createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
		createData('Eclair', 262, 16.0, 24, 6.0),
		createData('Cupcake', 305, 3.7, 67, 4.3),
		createData('Gingerbread', 356, 16.0, 49, 3.9),
	];


	function createData(name, calories, fat, carbs, protein) {
		return { name, calories, fat, carbs, protein };
	}



	// useEffect(function () {
	// 	async function fetchDate() {
	// 		try {
	// 			setLoading(true)

	// 			const res = await fetch(`${API}/classFinance?startDate=${startDate}&endDate=${endDate}&dep=${className}&trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}`) 
	// 			const data = await res.json()
	// 			setFinanceData(data.result)

	// 		} catch (e) {
	// 			console.log(e)
	// 		} finally {
	// 			setLoading(false)
	// 		}
	// 	} fetchDate()
	// }, [rednder])


	useEffect(function () {
		async function fetchBranches() {
			try {
				setBranchesLoading(true)
				const res = await fetch(`${API}/branches`)
				const data = await res.json()
				setBranches(data.branches)

			} catch (e) {
				console.log(e)
			} finally {
				setBranchesLoading(false)
			}
		} fetchBranches()
	}, [])
	useEffect(function () {
		async function getClasses() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
				console.log(data.result)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getClasses()

	}, [])

	useEffect(function () {
		async function getTrainers() {
			try {
				setClassesLoadingLoading(true)
				const res = await fetch(`${API}/getAllClassFamilies`)
				const data = await res.json()
				setClasses(data.data)
				console.log(data.data)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setClassesLoadingLoading(false)
			}
		} getTrainers()

	}, [])


	const sumPaid = financeDage?.map(item => item.PaidValue).reduce((a, b) => a + b, 0)
	const sumRemaining = financeDage?.map(item => item.Remaining).reduce((a, b) => a + b, 0)

	var dateFrom = "01/03/2024";
	var dateTo = "12/03/2024";
	var dateCheck = "05/09/2017";

	var d1 = dateFrom.split("/");
	var d2 = dateTo.split("/");
	var c = dateCheck.split("/");

	var from = new Date(d1);  // -1 because months are from 0 to 11
	var to = new Date(d2);
	var check = new Date(c);






	return (

		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">
			<h3 style={{ margin: "0 auto", width: "fit-content" }}>Class Finance</h3>

			<Container
				sx={{
					padding: '20px',
					borderRadius: '8px',
					border: "2px solid #e4e4e4",

					marginBottom: "20px",

				}}
			>
				<Container
					sx={{
						padding: '20px',
						borderRadius: '8px',
						backgroundColor: "white",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						marginBottom: "20px",


					}}
				>

					<div style={{ borderRadius: "8px", width: "40%", margin: "20px auto", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
						<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
						<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

					</div>

					<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: '20px', marginTop: "40px" }}>
						<div style={{ display: "flex", margin: "0 auto", width: "auto", flexDirection: "row", alignItems: "flex-start", textAlign: "center", height: "80px" }}>
							{['Trainer', 'Branch', 'Gender', 'Package'].map((placeholder, index) => (
								<div key={index} style={{ position: "relative", marginLeft: index > 0 ? "5px" : "0" }}>
									<label style={{
										position: "absolute",
										top: "-20px", // Position the label higher
										fontSize: "12px",
										color: "#777",
										padding: "0 5px",
										zIndex: 1,
									}}>
										{placeholder}
									</label>
									<select
										onChange={index === 0 ? (e) => settrainerName(e.target.value) :
											index === 1 ? (e) => setSelectedBranch(e.target.value) :
												index === 2 ? (e) => setSelectedGender(e.target.value) :
													(e) => setSelectedPackage(e.target.value)}
										style={{
											width: index === 0 ? "170px" : "150px",
											height: "40px",
											border: "none",
											textAlign: "center",
											alignItems: "center",
											marginTop: "10px", // Add space below the label
											backgroundColor: "#eaedf4",
											borderRadius: "8px",
										}}
										defaultValue=""
									>
										<option value="All" >All</option>
										{index === 0 && trainer?.map(t => (
											<option key={t.fullName} value={t.fullName}>{t.fullName}</option>
										))}
										{index === 1 && (
											<>
												{branches?.map(branch => (
													<option key={branch.name} value={branch.name}>{branch.name}</option>
												))}
											</>
										)}
										{index === 2 && (
											<>
												<option value="Male">Male</option>
												<option value="Female">Female</option>
											</>
										)}
										{/* {index === 3 &&  (
											<>
												<option value="Package1">Package 1</option>
												<option value="Package2">Package 2</option>
											</>
										)} */}

										{index === 3 &&
											<>
												<option value="1">1</option>
												<option value="8">8</option>
												<option value="12">12</option>
											</>
										}
									</select>
								</div>
							))}
						</div>
						<div style={{
							position: "relative", marginLeft: "5",
						}}>

							<div style={{ display: "flex", flexDirection: "row", marginBottom: "15px", }}>
								<div style={{ marginRight: "10px" }}>	<label style={{
									position: "absolute",
									top: "-20px", // Position the label higher
									fontSize: "12px",
									color: "#777",
									zIndex: 1,
									marginBottom: "5px",
									width: "100px",
								}}>
									Age from
								</label>

									<select onChange={(e) => setAgeFrom(e.target.value)} style={{
										width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
									}}>

										{Array.from({ length: 80 }, (_, index) => (
											<option value={index}>
												{index}
											</option>

										))}
									</select></div>
								<div>	<label style={{
									position: "absolute",
									top: "-20px", // Position the label higher
									fontSize: "12px",
									color: "#777",
									zIndex: 1,
									marginBottom: "5px",
									width: "100px",


								}}>
									Age to
								</label>

									<select onChange={(e) => setAgeTo(e.target.value)} style={{
										width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
									}}>

										{Array.from({ length: 80 }, (_, index) => (
											<option value={index}>
												{index}
											</option>

										))}
									</select></div>
							</div>
						</div>
						<input onChange={(e)=>setCustomerID(e.target.value)} style={{width:"200px", height:"40px", backgroundColor:"#eaedf4", border:"none" , fontSize:"14px", textAlign:"center", margin:"10px"}} placeholder='Customer ID' type = "text" />

						<Button
							variant="contained"
							onClick={handleFilter}
							startIcon={<SearchIcon style={{ color: "black" }} />}
							sx={{
								":hover": {
									backgroundColor: '#eaedf4',

									boxShadow: 'none',

								},
								backgroundColor: "#d7d9e9",

								color: 'black',
								borderRadius: '6px',
								fontWeight: "bold",
								marginTop: "10px",
								margin: "auto",
								width: "150px",
								fontWeight: "Bold",
								boxShadow: 'none',
							}}
						>
							Search
						</Button>

						{searched ? (
							<lebel style={{ marginTop: "10px" }}>
								<strong>{`${gender}`}</strong>
								{` Customers  `}
								<strong>{` between ${ageFrom} years and ${ageTo} years`}</strong>
								{` in `}
								<strong>{`${selectedBranch} branch`}</strong>
								{` within `}
								<strong>{`${startDate} and ${endDate}`}</strong>
								{` with  `}
								<strong>{`${trainerName} `}</strong>
							</lebel>
						) : (
							""
						)}
					</div>
				</Container>




			</Container>


	
			<Container

sx={{
	padding: '25px',
	borderRadius: '8px',
	marginBottom: "20px", // Set background color if needed
	height: "100px",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
	height: "180px",
	display: "flex",
	backgroundColor: "white"

}}
>

<Paper
	elevation={0}

	sx={{
		padding: '10px',
		// background: 'linear-gradient(to right, #025d82, #0094d4)',
		backgroundColor: "#edf0f7",
		border: "1px solid #e4e4e4",
		color: "black",

		borderRadius: '10px',
		display: 'block',
		textAlign: "center",
		alignItems: 'center',
		fontFamily: "poppins",
		color: "black",
		width: "250px",
		height: "90px",
		display: "flex",
		justifyContent: "center",
		marginRight: "13px",
	}}
>


	<Box sx={{ display: "block", width: "100%", }}>
		<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
			<AccountCircleIcon sx={{ fontSize: "30px" }} />

		</Box>
		<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
			{financeDage?.length}
		</Typography>
	</Box>
</Paper>

<Paper
	elevation={0}
	sx={{
		padding: '10px',
		backgroundColor: "#edf0f7",
		border: "1px solid #e4e4e4",
		borderRadius: '10px',
		display: 'block',
		textAlign: "center",
		alignItems: 'center',
		fontFamily: "poppins",
		color: "black",
		width: "250px",
		height: "90px",
		display: "flex",
		justifyContent: "center",
		marginRight: "13px",

	}}
>


	<Box sx={{ display: "block", width: "100%", }}>
		<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
			<PaidIcon sx={{ fontSize: "30px" }} />
			<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
				Total
			</Typography>
		</Box>
		<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
			{Number(sumPaid) + Number(sumRemaining)} EGP
		</Typography>
	</Box>
</Paper>

<Paper
	elevation={10}
	sx={{
		padding: '10px',
		backgroundColor: "#edf0f7",
		border: "1px solid #e4e4e4",
		color: "black",
		borderRadius: '10px',
		display: 'block',
		textAlign: "center",
		alignItems: 'center',
		fontFamily: "poppins",
		width: "250px",
		height: "90px",
		display: "flex",
		justifyContent: "center",
		marginRight: "13px",


		boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
	}}
>
	<Box sx={{ display: "block", width: "100%", }}>
		<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
			<PaidIcon sx={{ fontSize: "30px" }} />
			<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px", fontSize: "30px" }}>
				Actual Paid
			</Typography>
		</Box>
		<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
			{sumPaid} EGP
		</Typography>
	</Box>
</Paper>

<Paper
	elevation={0}
	sx={{
		padding: '10px',
		backgroundColor: "#edf0f7",
		border: "1px solid #e4e4e4",
		color: "black",
		borderRadius: '10px',
		display: 'block',
		textAlign: "center",
		alignItems: 'center',
		fontFamily: "poppins",
		width: "250px",
		height: "90px",
		display: "flex",
		justifyContent: "center",

		boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
	}}
>
	<Box sx={{ display: "block", width: "100%", }}>
		<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
			<PaidIcon sx={{ fontSize: "30px" }} />
			<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "23px" }}>
				Total Remaining
			</Typography>
		</Box>
		<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
			{sumRemaining} EGP
		</Typography>
	</Box>

</Paper>
</Container>





			{loading ? (<Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
				<CircularProgress />
			</Box>) : (<>
				<Table
      columns={columns}
    //   rowKey={(record) => record.login.uuid}
      dataSource={financeDage}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
    />


			</>


			)




			}

		</div>
	)

}

export default ClassFinance