import React from 'react';
import { Box, Button, Typography, Container, CircularProgress  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const LandingPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 1500);

    return () => clearTimeout(timer); 
  }, []);


  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <CircularProgress size={80} sx={{ color: '#0d056a' }} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        flexDirection: 'column', // Stacks the content vertically
      }}
    >
      <Container sx={{ width: '90%', margin: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile, row on larger screens
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mb: 4, // margin-bottom for spacing between text and button
          }}
        >
          <Box sx={{ display: 'block' }}>
            <Typography
              sx={{
                fontSize: { xs: '70px', sm: '120px' }, // Responsive font size
                color: '#0d056a',
                margin: 'auto',
                fontWeight:"bold"
              }}
            >
              ENERGY
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '30px', sm: '35px' }, // Adjust font size for mobile
                color: '#91b3f9',
                marginBottom: '20px',
                fontWeight:"Bold"
              }}
            >
              Fitness Center
            </Typography>

            <Button
              variant="contained"
              sx={{
                width: '180px',
                fontWeight:"bold",
                height: '40px',
                backgroundColor: '#0d056a',
                color: 'white',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#0b0348', // Darker shade on hover
                },
                fontSize: { xs: '14px', sm: '16px' }, // Adjust font size for mobile
              }}
              onClick={() => {
                navigate('login');
              }}
            >
              Login
            </Button>
          </Box>

          <Box
            component="img"
            src="landingFigure.png"
            alt="Landing Figure"
            sx={{
              height: { xs: '300px', sm: '500px' }, // Make image smaller on mobile
              marginLeft: { xs: 0, sm: '20px' }, // Adjust margin for mobile
              width: 'auto',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
