import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import PaidIcon from '@mui/icons-material/Paid';

function TrainerProfile() {
	return (
		<div className='trainer-profile'>

			<Card sx={{ width: "80%", margin: "0 auto", height: "80%" }} variant="outlined">
				<CardContent>
					<img style={{ width: "100px", height: "100px", margin: "0 auto" }} src="/figure.png" />
					<Tabs sx={{ margin: "0 auto", width: "100%" }} aria-label="Basic tabs" defaultValue={0}>
						<TabList sx={{ margin: "0 auto" }}>
							<Tab sx={{ fontWeight: "bold" }}>Energy</Tab>
							<Tab sx={{ fontWeight: "bold" }}>City Club</Tab>
						</TabList>
						<TabPanel sx={{ margin: "0 auto", width:"100%" }} value={0}>
							<Card sx={{ width: "80%", margin: "0 auto", height: "80%" }} variant="outlined">
								<CardContent>
									<div style={{display:"flex", flexDirection:"row"}} >
									<PaidIcon sx={{ marginRight:"5px"}}  />
									<p style={{fontWeight:"bold" }}> Basic Salary</p>
									</div>
								</CardContent>

								<CardContent>
									<div style={{display:"flex", flexDirection:"row"}} >
									<PaidIcon sx={{ marginRight:"5px"}}  />
									<p style={{fontWeight:"bold" }}> Salary</p>
									</div>
								</CardContent>
							</Card>
						</TabPanel>
						<TabPanel sx={{ margin: "0 auto" }} value={1}>
							<b>City Club</b> tab panel
						</TabPanel>
					</Tabs>
				</CardContent>

			</Card>



		</div>
	)
}

export default TrainerProfile