function CustomersInClass() {
    return (
        <p>Hello</p>
        // <div className="customers-class-page">

        //     <div class="wrapper">
        //         <table class="c-table">
        //             <thead class="c-table__header">
        //                 <tr>
        //                     <th>Customer ID</th>
        //                     <th>Used sessions</th>
        //                     <th>Remaining sessions</th>
        //                     <th>Start date</th>
        //                     <th>EndDate</th>
        //                     <th>Last Check in</th>
        //                 </tr>
        //             </thead>
        //             {/* <tbody class="c-table__body">
        //                 {data?.filter(customer => {
        //                     return customer.CustomerID?.toString().includes(query.toString())
        //                 }).map(customer => {
        //                     return (
        //                         <tr>
        //                             <td className="c-table__cell">{customer.CustomerID}</td>
        //                             <td className="c-table__cell">{customer.UsedSessions} </td>
        //                             <td className="c-table__cell">{customer.RemainingSessions}</td>
        //                             <td className="c-table__cell">{customer.StartDate}</td>
        //                             <td className="c-table__cell">{customer.EndDate}</td>
        //                             <td className="c-table__cell">{customer.LastCheck}</td>
        //                         </tr>
        //                     )

        //                 })}

        //             </tbody> */}
        //         </table>
        //     </div>


        // </div>
    )
}

export default CustomersInClass