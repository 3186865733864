import { useRef } from "react"

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Container } from '@mui/material';
import Navbar from "../navbar";
function AddDietPack() {
	const packageName  = useRef()
	const packPrice  = useRef()
	const API = process.env.REACT_APP_API
	const initialValues = {
		packageName: '',
		packagePrice: '',
	  };
	
	  const validationSchema = Yup.object().shape({
		packageName: Yup.string()
		  .required('Package name is required'),
		packagePrice: Yup.number()
		  .required('Package price is required')
		  .positive('Package price must be a positive number')
		  .integer('Package price must be an integer'),
	  });
	
	  const handleSubmit = (values) => {
		console.log(values);
		addPackage(values)
		// Handle the form submission (e.g., add the package)
	  };

	function addPackage(values) {
		fetch(`${API}/addDietPack`, {
			method: 'POST',
			headers: {
			   'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			   'packName': values.packageName,
			   'Price': values.packagePrice,
			})
		}).then(response => response.json())
		.then(result => {
			if (result.status == 200) {
				toast.success(`${result.Message}`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					});
			} else {
				toast.error("Something went wrong", {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					});

			}
		})
	}

	return (
		<Container>
			<Navbar />
			<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
<Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
        <form style={{backgroundColor:"white", padding:"20px",borderRadius:"10px", margin:"auto", minHeight:"400px"}}  onSubmit={handleSubmit}>
			<h4>Add Diet Package</h4>
          <Box 
            sx={{ 
              height: 'auto', 
              padding: '30px', 
              border: '1px solid #d4d4d4', 
              backgroundColor: 'white', 
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
			  borderRadius:"10px",
              gap: 2
            }}
          >
            <TextField
              name="packageName"
			  sx={{width:"70%", margin:"auto"}}
              label="Package Name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.packageName}
              error={touched.packageName && Boolean(errors.packageName)}
              helperText={touched.packageName ? errors.packageName : ''}
            />
            <TextField
						  sx={{width:"70%", margin:"auto"}}

              name="packagePrice"
              label="Package Price"
              type="number"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.packagePrice}
              error={touched.packagePrice && Boolean(errors.packagePrice)}
              helperText={touched.packagePrice ? errors.packagePrice : ''}
            />
            <Button 
              type="submit" 
              variant="contained" 
              sx={{ backgroundColor: '#203354', width: '100px', alignSelf: 'flex-start' , borderRadius:"10px", width:"80%", height:"40px", margin:"Auto"}}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
		</Container>
	)
}


export default AddDietPack